import ModalComponent from '../Modal/Index';
import './AIModal.css';
interface AIModalProps {
	showAIResponse: boolean;
	handleClosePreview: () => void;
	userTypedDescription: string;
	AiGeneratedDescription: string;
	handleAccept: () => void;
	handleReject: () => void;
}

const AIModal: React.FC<AIModalProps> = ({
	showAIResponse,
	userTypedDescription,
	AiGeneratedDescription,
	handleClosePreview,
	handleAccept,
	handleReject,
}) => {
	return (
		<>
			<ModalComponent show={showAIResponse} className="preview-AI-modal" handleClose={handleClosePreview} title="">
				<div className="row">
					<div className="col-md-6 cstm_aii_flex">
						<h1>Ai Generated Description</h1>
						<p>{AiGeneratedDescription}</p>
					</div>
					<div className="col-md-6 cstm_aii_flex">
						<h1>Your Description</h1>
						<p>{userTypedDescription || 'No description available'}</p>
					</div>

					<div className="cstmai_btn_prnt">
						{' '}
						<div className=" ai-popup-button">
							<button className="cmn-btn" onClick={handleAccept}>
								Accept Ai Response
							</button>
						</div>
						<div className=" ai-popup-button ">
							<button className="cmn-btn" onClick={handleReject}>
								Accept Your Response
							</button>{' '}
						</div>
					</div>
				</div>
			</ModalComponent>
		</>
	);
};
export default AIModal;

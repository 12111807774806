import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/stylesheet.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

import { TrackJS } from 'trackjs';
import { HelmetProvider } from 'react-helmet-async';

TrackJS.install({
	token: process.env.REACT_APP_TRACKJS_TOKEN ?? '31e385220aac4c5d85bd4fdca9d1bc03',
	application: 'streamefy',
});

TrackJS.addMetadata('environment', process.env.NODE_ENV ?? 'development');
TrackJS.addMetadata('appVersion', process.env.REACT_APP_VERSION ?? 'unknown');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<HelmetProvider>
			<App />
		</HelmetProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { showToast } from '../../components/toaster/Index';
import { BackgroundMediaCommand, BackGroundMediaResponsedModel, PreSignedUrlResponse } from '../../types/type/BackgroundMedia'; // Import necessary types
import * as handler from '../api/Handler'; // Import the handler for making API requests
import { ApiEndpoints } from '../Endpoints'; // Import API endpoints
import { ResponseData } from '../../types/type/BackgroundMedia';
/**
 * Fetches pre-signed URLs for background media uploads to BunnyCDN.
 *
 * @param uploadRequest - An object that implements the MediaUploadtoBunny interface, containing details of the media to be uploaded.
 * @returns A promise that resolves to an array of PreSignedUrlResponse objects containing the pre-signed URLs.
 * @throws Throws an error if the API request fails.
 */
export const BackgroundMedia = async (uploadRequest: BackgroundMediaCommand): Promise<PreSignedUrlResponse[]> => {
	try {
		// Send a POST request to the BackgroundMedia endpoint with the JSON body
		const response = await handler.post<PreSignedUrlResponse[]>(ApiEndpoints.BackgroundMedia, uploadRequest);

		// Return the array of PreSignedUrlResponse objects from the API response
		return response.response;
	} catch (error) {
		// Log the error to the console for debugging purposes
		console.error('Error fetching pre-signed URLs:', error);
		// Rethrow the error to be handled by the calling function
		throw error;
	}
};

export const GetBackGroundMediaByProjectId = async (projectId: number) => {
	return await handler.get<BackGroundMediaResponsedModel[]>(ApiEndpoints.BackgroundMedia + projectId);
};

export const DeleteBackgroundMediaById = async (mediaID: number): Promise<any> => {
	try {
		// Send a POST request to the 'delete-media' endpoint with the media ID as the payload
		// Construct the URL with the mediaID as a query parameter
		const urlWithQuery = `${ApiEndpoints.DeleteBackgroundMediaByID}${mediaID}`;
		// Send a POST request to the constructed URL
		const response = await handler.delet<any>(urlWithQuery);
		// Return the API response
		return response; // Adjust this if the API response structure is different
	} catch (error) {
		// Log the error for debugging purposes
		console.error('Error deleting media:', error);

		// Rethrow the error to be handled by the calling function
		throw new Error('Failed to delete media');
	}
};

/**
 * Sends the mediaId to the backend to update the video as failed to upload.
 *
 * @param mediaId - The media ID of the uploaded background video.
 * @returns A promise that resolves to the response from the backend.
 */
export const postBackgroundMediaUploadFailed = async (mediaId: number): Promise<any> => {
	try {
		// Create the request body
		const requestPayload = {
			mediaId: mediaId,
		};

		// Send a POST request
		const response = await handler.post(ApiEndpoints.AfterBackgroundMediaUpload, requestPayload);

		// Return the backend response
		return response.response;
	} catch (error) {
		// Handle errors if any occur during the API request
		console.error('Error posting media upload failed:', error);
		throw error;
	}
};

export const reencodeVideo = async (projectID: number): Promise<any> => {
	try {
		// Send a GET request
		const response = await handler.get(ApiEndpoints.transcoding + projectID);

		// Return the backend response
		return response.response as ResponseData;
	} catch (error) {
		if (error instanceof Error) {
		} else {
			showToast('An unknown error occurred', { type: 'error' });
		}
		return error;
	}
};

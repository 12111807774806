import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';

interface ModalProps {
	title: string | undefined;
	children: React.ReactNode;
	show: boolean;
	handleClose: () => void;
	className?: string | undefined;
}

const ModalComponent: React.FC<ModalProps> = ({ title, children, show, handleClose, className }) => {
	return (
		<>
			<Modal backdrop={false} className={className} show={show} onHide={handleClose} centered>
				<Modal.Header closeButton>{title}</Modal.Header>
				<Modal.Body>{children}</Modal.Body>
				{/* <Modal.Footer>{footer}</Modal.Footer> */}
			</Modal>
		</>
	);
};

export default ModalComponent;

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { LoginModel } from '../../types/models/LoginModel';
import ApiService from '../../helpers/api/Index';
import IAPIResponseModel from '../../types/interfaces/IAPIResponseModel';
import { AuthResponseModel } from '../../types/models/AuthResponseModel';
import { fetchCountryCode, Get_Remember_Me, Set_Access_Token, Set_Refresh_Token, Set_Remember_Me, SetCompanyLogo } from '../../helpers/Utils';
import { App_Urls } from '../../routes/AppUrls';
import { showToast } from '../toaster/Index';
import Button from '../button/Index';
import Loader from '../loader/Index';

const Login = (props: { tabId: number }) => {
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [loading, setLoading] = useState(false);
	// Define validation schema using Yup
	const validationSchema = Yup.object().shape({
		Email: Yup.string().email('Invalid email address').required('Email address is required'),
		Password: Yup.string().required('Password is required'),
		RememberMe: Yup.boolean().default(false),
	});

	const initialValues: LoginModel = {
		Email: '',
		Password: '',
		RememberMe: false,
	};
	useEffect(() => {
		fetchCountryCode()
		// Check local storage for the "rememberMe" value
		const storedRememberMe = Get_Remember_Me();
		if (storedRememberMe === 'true') {
			setRememberMe(true);
		}
	}, []);
	/**
	 * Handles the login form submission by calling the API service to authenticate the user.
	 * If the authentication is successful, it sets the access and refresh tokens, and navigates to the profile page.
	 * If the authentication fails, it displays an error message.
	 */
	const handleSubmitClick = async (values: LoginModel) => {
		setLoading(true);
		await ApiService.Login(values)
			.then((response: IAPIResponseModel<AuthResponseModel>) => {
				if (response) {
					if (response.isSuccess) {
						showToast('Successfully Logged In');
						Set_Access_Token(response.response?.accessToken);
						Set_Refresh_Token(response.response?.refreshToken, response.response?.expiration);
						SetCompanyLogo(response?.response?.logo);
						Set_Remember_Me(rememberMe ? 'true' : 'false')
						navigate(App_Urls.Profile, { replace: true });
					} else if (response.error.userMessage.toLowerCase() === 'otp not verified') {
						navigate(App_Urls.Otp, { state: { email: values.Email, password: values.Password }, replace: true });
					} else {
						showToast(response.error?.userMessage || 'Something went wrong ', { type: 'error' });
					}
				}
			})
			.catch((error) => {
				showToast(error.message, { type: 'error' });
			}).finally(() => {
				setLoading(false);
			});
	};
	// Passowrd show and Hide
	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const handleCheckboxChange = (event: any) => {
		setRememberMe(event.target.checked);
	};

	return (
		<>
			{loading && <Loader customMessage='Logging you in, just a moment...' />}
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					handleSubmitClick(values);
				}}
			>
				{({ values, errors, touched, isValid, dirty }) => (
					<Form>
						<div
							className={`tab-pane fade ${props && props.tabId && props.tabId === 1 ? 'show active' : null}`}
							id="login"
							role="tabpanel"
							aria-labelledby="login-tab"
						>
							<div className="signup-login-form">
								<div className="row">
									<div className="col-md-12">
										<div className="form-box">
											<div className="input-field">
												<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clipPath="url(#clip0_853_5911)">
														<path
															d="M13 11.7814C13 12.4542 12.4541 13.0001 11.7812 13.0001H1.21875C0.545898 13.0001 4.629e-08 12.4542 4.629e-08 11.7814V5.096C-5.09215e-05 4.91106 0.0419874 4.72854 0.122931 4.56225C0.203874 4.39596 0.321599 4.25028 0.467188 4.13623C1.09941 3.64112 1.62246 3.23741 4.63633 1.05127C5.06289 0.741508 5.91094 -0.00751527 6.5 0.000101922C7.08906 -0.0100543 7.93711 0.741508 8.36367 1.05127C11.3775 3.23741 11.9006 3.64112 12.5328 4.13623C12.6784 4.25028 12.7961 4.39596 12.8771 4.56225C12.958 4.72854 13.0001 4.91106 13 5.096V11.7814ZM11.3318 6.78956C11.2658 6.69561 11.1363 6.67276 11.0449 6.74131C10.466 7.17041 9.63574 7.77471 8.36367 8.69893C7.93711 9.01124 7.08906 9.76026 6.5 9.7501C5.91094 9.75772 5.06289 9.01124 4.63633 8.69893C3.36426 7.77471 2.53652 7.17041 1.95508 6.74131C1.86367 6.67276 1.73164 6.69561 1.66816 6.78956L1.43711 7.12471C1.4069 7.16807 1.39478 7.22148 1.40331 7.27363C1.41185 7.32577 1.44036 7.37254 1.48281 7.40401C2.06426 7.83311 2.89199 8.43741 4.15645 9.35655C4.67188 9.73233 5.59102 10.5702 6.5 10.5651C7.40645 10.5702 8.32812 9.73233 8.84355 9.35655C10.1105 8.43741 10.9357 7.83311 11.5172 7.40401C11.5596 7.37254 11.5882 7.32577 11.5967 7.27363C11.6052 7.22148 11.5931 7.16807 11.5629 7.12471L11.3318 6.78956Z"
															fill="#C1C1C1"
														/>
													</g>
													<defs>
														<clipPath id="clip0_853_5911">
															<rect width="13" height="13" fill="white" />
														</clipPath>
													</defs>
												</svg>
												<Field name="Email" type="email" placeholder="" className={values && values.Email && 'input-value'} />
												<label>
													Email address <span style={{ color: 'red' }}>*</span>
												</label>
											</div>
											{errors.Email && touched.Email ? <div className="error">{errors.Email}</div> : null}
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-box">
											<div className="input-field">
												<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clipPath="url(#clip0_853_5915)">
														<path
															d="M10.7143 5.6875H10.0714V3.85938C10.0714 1.73164 8.24464 0 6 0C3.75536 0 1.92857 1.73164 1.92857 3.85938V5.6875H1.28571C0.575893 5.6875 0 6.2334 0 6.90625V11.7812C0 12.4541 0.575893 13 1.28571 13H10.7143C11.4241 13 12 12.4541 12 11.7812V6.90625C12 6.2334 11.4241 5.6875 10.7143 5.6875ZM7.92857 5.6875H4.07143V3.85938C4.07143 2.85137 4.93661 2.03125 6 2.03125C7.06339 2.03125 7.92857 2.85137 7.92857 3.85938V5.6875Z"
															fill="#C1C1C1"
														/>
													</g>
													<defs>
														<clipPath id="clip0_853_5915">
															<rect width="12" height="13" fill="white" />
														</clipPath>
													</defs>
												</svg>
												<Field
													autoComplete="new-password"
													name="Password"
													type={showPassword ? 'text' : 'password'}
													placeholder=""
													className={values && values.Password && 'input-value'}
												/>
												<label>
													Password <span style={{ color: 'red' }}>*</span>
												</label>
												<Button
													type="button"
													onClick={togglePasswordVisibility}
													className="password-toggle"
													style={{
														position: 'absolute',
														right: '10px',
														top: '50%',
														transform: 'translateY(-50%)',
														background: 'none',
														border: 'none',
														cursor: 'pointer',
													}}
													id={'pswrd'}
													label={''}
												>
													{showPassword ? (
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M10 4.37C3.75 4.37 1.25 10 1.25 10C1.25 10 3.75 15.63 10 15.63C16.25 15.63 18.75 10 18.75 10C18.75 10 16.25 4.37 10 4.37Z"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M10 13.13C11.7259 13.13 13.13 11.7259 13.13 10C13.13 8.27411 11.7259 6.87 10 6.87C8.27411 6.87 6.87 8.27411 6.87 10C6.87 11.7259 8.27411 13.13 10 13.13Z"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													) : (
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M12.1083 7.89166L7.89166 12.1083C7.35 11.5667 7.01666 10.825 7.01666 10C7.01666 8.35 8.35 7.01666 10 7.01666C10.825 7.01666 11.5667 7.35 12.1083 7.89166Z"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M14.85 4.80834C13.3917 3.70834 11.725 3.10834 10 3.10834C7.05833 3.10834 4.31666 4.84167 2.40833 7.84167C1.65833 9.01667 1.65833 10.9917 2.40833 12.1667C3.06666 13.2 3.83333 14.0917 4.66666 14.8083"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.01666 16.2750C7.96666 16.6750 8.975 16.8917 10 16.8917C12.9417 16.8917 15.6833 15.1583 17.5917 12.1583C18.3417 10.9833 18.3417 9.00833 17.5917 7.83333C17.3167 7.40000 17.0167 6.99166 16.7083 6.60833"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M12.925 10.5833C12.7083 11.7583 11.75 12.7167 10.575 12.9333"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.89166 12.1083L1.66666 18.3333"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M18.3333 1.66666L12.1083 7.89166"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													)}
												</Button>
											</div>
											{errors.Password && touched.Password ? <div className="error">{errors.Password}</div> : null}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="forgot-checkbox">
											<div className="input-checkbox">
												<Field
													name="RememberMe"
													type="checkbox"
													checked={rememberMe}
													onChange={handleCheckboxChange}
												/>
												<p>Remember me</p>
											</div>
											<Link to={App_Urls.Forgot}>Forgot password?</Link>
										</div>
									</div>
								</div>
								{/* <div className="login-signup-btn"></div> */}
								<div className="button-after-login-screens">
									<div className="login-signup-btn d-flex two_btn_sec">
										<hr />
										<Button disabled={loading || !(isValid && dirty)} id="loginNow" label="login now" className="cmn-btn" type="submit" />
									</div>
								</div>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default Login;

import { Helmet } from 'react-helmet-async';
import React from 'react';
import { MetaDecoratorsConstants } from '../../constants/Constants';

// Define the prop types using an interface
interface MetaDecoratorProps {
	title: string;
	description: string;
	keywords: string;
	image_Url?: string; // Optional prop
	url: string;
}

const MetaDecorator: React.FC<MetaDecoratorProps> = ({ title, description, keywords, image_Url, url }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="keywords" content={keywords} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description ?? MetaDecoratorsConstants.Default_Description} />
			<meta property="og:image" content={image_Url ?? MetaDecoratorsConstants.Default_Og_Image} />
			<meta property="og:type" content="website" />
			<meta property="og:url" content={url} />
		</Helmet>
	);
};

export default MetaDecorator;

import Subscription from '../pages/subscription/Index';
import ForgotPassword from '../components/forgotPassword/Index';
import OneTimePassword from '../components/one_time_password/Index';
import ResetPassword from '../components/resetPassword/Index';
import AuthPage from '../pages/auth/Index';
import AuthorizeProject from '../pages/authProject/Index';
import ClientViewProject from '../pages/clientViewProject/Index';
import DashBoard from '../pages/dashboard/Index';
import Play from '../pages/play/Index';
import Profile from '../pages/profile/Index';
import { RouteConfig } from '../types/interfaces/RouteConfig';
import { App_Urls } from './AppUrls';

export const PublicRoutePath: RouteConfig[] = [
	{
		path: App_Urls.Auth,
		component: AuthPage,
	},
	{
		path: App_Urls.Otp,
		component: OneTimePassword,
	},
	{
		path: App_Urls.Forgot,
		component: ForgotPassword,
	},
	{
		path: App_Urls.Reset,
		component: ResetPassword,
	},
	{
		path: App_Urls.ClientViewProject,
		component: ClientViewProject,
	},
	{
		path: App_Urls.AuthorizeProject,
		component: AuthorizeProject,
	},
	{
		path: App_Urls.Play,
		component: Play,
	},
];

export const PrivateRoutePath: RouteConfig<any>[] = [
	{
		path: App_Urls.Profile,
		component: Profile,
	},
	{
		path: App_Urls.Subscription,
		component: Subscription,
	},
	{
		path: App_Urls.DashBoard,
		component: DashBoard,
	},
];

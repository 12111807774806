import React, { useRef, useEffect, useState } from 'react';
import Hls, { Level } from 'hls.js';
import Loader from '../loader/Index';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/audio.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { MediaPlayer, MediaProvider, Poster } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
interface HlsPlayerProps {
	src: string;
	autoPlay?: boolean;
	width?: string;
	height?: string;
	playbackTime?: number;
	onEndedVideo?: () => void;
	updateVideoPlayback?: (duration: number) => Promise<void>;
	setCurrentPlaybackTime?: (duration: number) => void;
	setRemainingTime?: (timeLeft: number) => void;
	mediaCount?: number;
	isMuted: boolean;
	showCustomControls: boolean;
	isShowLoading?: boolean;
	thumbnailFile?: string | null;
}
const VideoPlayer: React.FC<HlsPlayerProps> = ({
	src,
	mediaCount,
	onEndedVideo,
	updateVideoPlayback,
	setCurrentPlaybackTime,
	setRemainingTime,
	playbackTime = 0,
	autoPlay = true,
	isMuted = false,
	showCustomControls = true,
	isShowLoading = false,
	thumbnailFile,
}) => {
	const playerRef = useRef<any>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const hlsRef = useRef<any>();
	const playVideo = async () => {
		if (playerRef.current) {
			playerRef.current.play().catch((e: any) => {});
		}
	};

	const pauseVideo = () => {
		playerRef?.current?.pause();
	};
	/** Function triggered when the video metadata is loaded (such as duration, dimensions, etc.)
	 * Sets the initial playback time for the video based on the stored `playbackTime`
	 **/
	const handleLoadedMetadata = () => {
		// Check if the video player reference is available
		if (playerRef.current) {
			// Ensure the playback time is set properly, fallback to 0 if the playbackTime is invalid
			const time: number = playbackTime && playbackTime > 0 ? playbackTime / 1000 : 0; // Default to 0 if playbackTime is not provided or is invalid
			// Set the current time of the video to the parsed value (in seconds)
			playerRef.current.currentTime = time;
		}
	};

	// Handles updates to the video's current playback time and updates state
	const handleTimeUpdate = () => {
		if (playerRef && playerRef.current) {
			// Calculate the remaining time in seconds
			const timeLeft = Math.ceil(playerRef.current.duration - playerRef.current?.currentTime);

			// If there are 15 seconds or less remaining, show the remaining time, otherwise hide it
			if (timeLeft <= 15 && timeLeft > 0) {
				setRemainingTime?.(timeLeft); // Update the remaining time state
			} else {
				setRemainingTime?.(0); // Hide countdown when time is greater than 15 seconds
			}
		}
	};
	// The `handleVisibilityChange` function is responsible for pausing and playing a video based on the visibility of the browser tab.
	// It checks if the player element (referenced by `playerRef`) exists, and if the document visibility state is 'hidden', it pauses the video.
	// Otherwise, if the document is visible, it plays the video.
	const handleVisibilityChange = () => {
		if (playerRef.current) {
			if (document.visibilityState === 'hidden') {
				pauseVideo();
			} else {
				playVideo();
			}
		}
	};
	useEffect(() => {
		// Adds an event listener to the document that listens for visibility changes (tab switching or minimizing)
		document.addEventListener('visibilitychange', handleVisibilityChange);

		// Cleanup function that removes the event listener when the component is unmounted or dependencies change
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []); // Empty dependency array ensures this effect runs only once when the component mounts and unmounts

	// This effect will set the playback position when the component is mounted
	useEffect(() => {
		if (playerRef.current) {
			const time: number = playbackTime && playbackTime > 0 ? playbackTime / 1000 : 0; // Default to 0 if playbackTime is not provided or is invalid

			playerRef.current.currentTime = time;
		}
	}, [playbackTime]);

	// This effect will continuously update the playback time during the video playback
	useEffect(() => {
		const interval = setInterval(() => {
			if (playerRef.current) {
				setCurrentPlaybackTime?.(playerRef?.current?.currentTime * 1000);
				handleTimeUpdate();
			}
		}, 100);

		return () => clearInterval(interval); // Cleanup on unmount
	}, [setCurrentPlaybackTime]);
	useEffect(() => {
		const video = playerRef.current;
		if (!video || !Hls.isSupported() || !src) return;

		const hls = new Hls();
		hlsRef.current = hls;

		hls.attachMedia(video);
		hls.on(Hls.Events.MEDIA_ATTACHED, () => {
			hls.loadSource(src);
			setLoading(true);
		});

		const onPlaying = () => setLoading(false);
		const onWaiting = () => setLoading(true);
		const onStalled = () => setLoading(true);
		const onCanPlay = () => setLoading(false);

		video.addEventListener('playing', onPlaying);
		video.addEventListener('waiting', onWaiting);
		video.addEventListener('stalled', onStalled);
		video.addEventListener('canplay', onCanPlay);

		return () => {
			video.removeEventListener('playing', onPlaying);
			video.removeEventListener('waiting', onWaiting);
			video.removeEventListener('stalled', onStalled);
			video.removeEventListener('canplay', onCanPlay);
			hls.destroy();
		};
	}, [src]);
	return (
		<>
			{isShowLoading && loading && <Loader customMessage="We’re getting your video ready. Please wait." />}
			<div
				className="react-hls_player_fullscreen"
				ref={containerRef}
				style={{
					maxWidth: '100%',
				}}
			>
				<MediaPlayer
					viewType="video"
					streamType="on-demand"
					logLevel="warn"
					crossOrigin
					playsInline
					src={src}
					autoPlay={autoPlay}
					ref={playerRef}
					muted={isMuted}
					onEnded={async () => {
						if (playerRef.current) {
							await updateVideoPlayback?.(playerRef?.current?.currentTime * 1000);
							playerRef.current.currentTime = 0;
						}
						if (mediaCount === 1 && playerRef && playerRef?.current) {
							playVideo();
						}
						onEndedVideo?.();
					}}
					onPause={() => {
						updateVideoPlayback?.(playerRef?.current?.currentTime * 1000);
					}}
					onLoadedMetadata={handleLoadedMetadata}
					onTimeUpdate={handleTimeUpdate}
					onCanPlay={() => setLoading(true)} // Event to detect when video can start playing
					style={{
						'--media-slider-thumb-bg': 'rgba(148, 26, 242, 1)', // Red color for slider dot
						'--media-slider-thumb-border': 'rgba(148, 26, 242, 1)', // Optional black border for the dot
						'--media-button-hover-bg': 'rgba(172, 74, 248, 0.7)', // Hover background for buttons
						'--media-menu-checkbox-bg-active': 'rgba(148, 26, 242, 1)', // Background color when checked
						'--media-progress-bar-bg': 'rgba(148, 26, 242, 1)', // Progress bar background color (light purple)
						'--media-progress-bar-active-bg': 'rgba(148, 26, 242, 1)', // Active progress bar color (purple)
						'--media-menu-slider-track-fill-bg': 'rgba(148, 26, 242, 1)' /* Active track color */,
					}}
				>
					<MediaProvider>
						<Poster className="vds-poster" />
					</MediaProvider>
					{showCustomControls && (
						<DefaultVideoLayout
							thumbnails={thumbnailFile}
							icons={{
								...defaultLayoutIcons, // Spread default icons
								GoogleCastButton: {
									Default: () => null, // Completely remove the icon for GoogleCastButton
									Connecting: () => null,
									Connected: () => null,
								},
							}}
							style={{
								'--video-brand': 'rgba(148, 26, 242, 1)', // Custom brand color for video
								'--audio-brand': 'rgba(148, 26, 242, 1)', // Custom brand color for audio
								'--plyr-color-main': 'rgba(148, 26, 242, 1)', // Main color for player controls
								'--media-button-hover-bg': 'rgba(172, 74, 248, 0.7)', // Hover background for buttons
							}}
						>
							<style>
								{`
                                /* Ensure the button is hidden via CSS */
                                  .vds-google-cast-button {
                                   display: none !important; /* Forcefully hide the Google Cast button */
                                  }
                                `}
							</style>
						</DefaultVideoLayout>
					)}
				</MediaPlayer>
			</div>
		</>
	);
};

export default VideoPlayer;

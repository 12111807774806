// Importing necessary modules and types
import { BackGroundMedia, BackgroundMediaCommand, PreSignedUrlResponse } from '../../types/type/BackgroundMedia'; // Type definitions for media upload
import eventEmitter, { fileToBase64, formatFileSize, getVideoFormat } from '../Utils'; // Utility functions for formatting file size and getting video format
import ApiService from '../api/Index'; // Service for making API calls
import uploadVideo from './TUSUploadService';
import { TrackJS } from 'trackjs';
/**
 * Converts an array of background media items into a BackgroundMediaCommand Body object.
 * @param data - An array of background media items.
 * @param projectId - The optional project ID to include in the FormData.
 * @returns The constructed BackgroundMediaCommand Body object.
 */
const convertToBackgroundMedia = async (data: BackGroundMedia[], projectId?: number): Promise<BackgroundMediaCommand> => {
	// Create the background media request object
	const backgroundMediaCommand: BackgroundMediaCommand = {
		projectId: projectId ?? 0,
		media: [],
	};

	// Loop through each media item and convert its properties to the required format
	for (const item of data) {
		// Convert the image file to a base64 string
		const thumbnailBase64 = await fileToBase64(item.thumbnail);
		const videoSize = formatFileSize(item?.backgroundFile?.size);
		const format = getVideoFormat(item?.backgroundFile);

		// Append media properties to the background media object
		backgroundMediaCommand.media.push({
			format: format,
			size: videoSize,
			thumbnail: thumbnailBase64, // Base64 string
			thumbnailFileName: item.thumbnail.name,
			VideoFileName: item?.backgroundFile?.name ?? null,
		});
	}

	return backgroundMediaCommand;
};
/**
 * Handles the upload of an array of background media files.
 *
 * @param mediaArray - An array of objects implementing the BackGroundMedia interface, representing media files to be uploaded.
 * @returns A promise that resolves when all media files are uploaded successfully, or rejects if any upload fails.
 * @throws Throws an error if any of the upload operations fail.
 */
const BackgroundMediaUpload = async (
	mediaArray: BackGroundMedia[],
	projectId: number | undefined,
	onProgressCallback: (index: number, progress: number) => void, // Callback function to track progress
): Promise<
	Array<{
		mediaId: any;
		mediaIndex: number;
		fileName: string;
		message: string;
		progressBar?: boolean;
		isMediaUploded: boolean;
	}>
> => {
	// Maintain a set of active media IDs
	const activeMediaIds = new Set<number>();

	// Event handler to prompt the user and conditionally notify backend on page reload
	const handleBeforeUnload = (event: BeforeUnloadEvent) => {
		// Check if there are active uploads
		if (activeMediaIds.size > 0) {
			event.preventDefault();
			event.returnValue = ''; // Show the confirmation dialog
		}
	};

	// Event handler to store active media IDs in local storage on reload
	const handleUnload = () => {
		if (activeMediaIds.size > 0) {
			// Create an array of objects with mediaId and mediaType
			const mediaItems = Array.from(activeMediaIds).map((mediaId) => ({
				mediaId,
				mediaType: 'Background',
			}));
			// Store the array in localStorage
			localStorage.setItem('activeMedia', JSON.stringify(mediaItems));
		}
	};

	// Add event listeners for beforeunload and unload
	window.addEventListener('beforeunload', handleBeforeUnload);
	window.addEventListener('unload', handleUnload);
	try {
		// Create an array of promises for each media item to get pre-signed URLs
		const preSignedUrlPromises = mediaArray.map(async (media) => {
			const backgroundMedia = convertToBackgroundMedia(mediaArray, projectId);
			return ApiService.BackgroundMedia(await backgroundMedia); // Return the API call promise
		});

		// Wait for all pre-signed URL promises to resolve
		const preSignedUrlResponses = await Promise.all(preSignedUrlPromises);

		// Create an array of promises to upload each media file using the uploadVideo method
		const uploadPromises = mediaArray.map((media, index) => {
			const response = preSignedUrlResponses[index];

			// Upload each media using the uploadVideo method
			return Promise.all(
				response.map((uploadConfig: PreSignedUrlResponse) => {
					// Add mediaId to active uploads
					activeMediaIds.add(uploadConfig.mediaId);
					return uploadVideo(media.backgroundFile, uploadConfig, media.index, (progress) => {
						// Emit the progress event globally
						eventEmitter.emit('backgroundUploadProgress', {
							progress: progress === 100 ? 99.9 : progress, // Temporary hold at 99.9%
							mediaId: uploadConfig.mediaId,
							fileName: media.backgroundFile.name,
							fileSize: media.backgroundFile.size,
							projectId: uploadConfig.projectId,
						});
						// Invoke the progress callback to update the UI or perform other actions
						onProgressCallback(media.index, progress);
					})
						.then((uploadResult) => {
							// Emit success event with message
							eventEmitter.emit('backgroundUploadProgress', {
								progress: 100, // Final progress at 100%
								mediaId: uploadConfig.mediaId,
								fileName: media.backgroundFile?.name,
								message: 'Background Media Uploaded successfully',
								fileSize: media.backgroundFile?.size,
								projectId: uploadConfig.projectId,
							});
							// Remove from active uploads
							activeMediaIds.delete(uploadConfig.mediaId);
							// Return the upload result and include the mediaId from the preSignedUrlResponses
							return {
								...uploadResult,
								mediaId: uploadConfig.mediaId, // Add mediaId from the preSignedUrlResponse
								mediaIndex: media.index,
								fileName: media.backgroundFile.name, // file name
								isMediaUploded: true,
								message: 'Background Media Uploaded successfully',
								projectId: uploadConfig.projectId,
							};
						})
						.catch(async (error) => {
							// Remove from active uploads
							activeMediaIds.delete(uploadConfig.mediaId);

							if (uploadConfig.mediaId) {
								// Call the API to notify the backend of the failed upload with the mediaId
								await ApiService.postBackgroundMediaUploadFailed(uploadConfig.mediaId);
							}
							TrackJS?.track(error);
							// Return error details
							const errorResponse = uploadConfig.errorResponse || {};
							return {
								mediaId: null,
								mediaIndex: media.index, // Use the current index
								fileName: media.backgroundFile.name,
								isMediaUploded: false,
								message: errorResponse.error || 'Unknown error occurred', // Use the `error` field
								progressBar: false,
							};
						});
				}),
			);
		});

		// Wait for all upload promises to complete and return the results
		const results = await Promise.all(uploadPromises);
		// Clean up active uploads and event listener after completion
		window.removeEventListener('beforeunload', handleBeforeUnload);
		window.removeEventListener('unload', handleUnload);
		return results.flat(); //Return the results in one array
	} catch (error) {
		// Use 'instanceof' to narrow down the type of 'error'
		if (error instanceof Error) {
			TrackJS?.track(error);
		} else {
		}
		// Handle errors here
		throw error;
	}
};

const DeleteBackgroundMedia = async (backgroundMediaId: number): Promise<{ success: boolean; message: string }> => {
	try {
		// Make an API call to delete the media by mediaID
		const response = await ApiService.DeleteBackgroundMediaById(backgroundMediaId);

		// Check if the response status is successful (status code 200-299)
		if (response.isSuccess) {
			return {
				success: true,
				message: `Media was successfully deleted.`,
			};
		}
		// If the response is not successful, return a failure message
		return {
			success: false,
			message: `Failed to delete media. Status: ${response.status}`,
		};
	} catch (error: any) {
		TrackJS?.track(error);
		// Catch any network or unexpected errors and return a failure message
		console.error('Error occurred while deleting media:', error);
		return {
			success: false,
			message: `Error occurred while deleting media with ID ${backgroundMediaId}.`,
		};
	}
};

const uploadThumbnailToExistingMedia = async (thumbnail: File, mediaId: string, videoType: string) => {
	try {
		const base64Thumbnail = await fileToBase64(thumbnail);

		// JSON payload with base64 data and existing mediaId
		const payload = {
			mediaId,
			thumbnailBase64: base64Thumbnail,
			thumbnailName: thumbnail.name,
			videoType,
		};

		return ApiService.UploadThumbnail(payload); // uploadThumbnail API call
	} catch (error) {
		console.error('Failed to convert thumbnail to base64:', error);
		throw new Error('Thumbnail conversion failed.');
	}
};

export { convertToBackgroundMedia, BackgroundMediaUpload, DeleteBackgroundMedia, uploadThumbnailToExistingMedia };

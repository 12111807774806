import { CheckboxProps } from '../../types/type/CheckBoxProps';

/**
 * A reusable checkbox component that renders a standard HTML checkbox input element.
 *
 * This component accepts various props for customization, including an `id`, `checked` state,
 * `onChange` handler, and additional CSS classes through the `className` prop.
 *
 * @param {string} id - The unique identifier for the checkbox element.
 * @param {boolean} checked - The checked state of the checkbox.
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} onChange - The function to be called when the checkbox state changes.
 * @param {string} [className] - Additional CSS classes to style the checkbox.
 * @param {string} name - Added name
 * @param {string} disabled - Added disabled 
 *
 * @returns {JSX.Element} A styled checkbox input element with a checked state and change handler.
 */
const Checkbox: React.FC<CheckboxProps> = ({ id, checked, onChange, className, name, disabled }) => (
	<input type="checkbox" id={id} checked={checked} onChange={onChange} className={className} name={name} disabled={disabled} />
);

export default Checkbox;

// src/services/TourStepsService.ts
import Shepherd, { StepOptions } from 'shepherd.js';
import styles from '../../helpers/TakeaTour/Tour.module.css';
import { disablePointerEvents, enablePointerEvents } from '../Utils';

// Add tour as a parameter and pass it in the steps
export const getTourSteps = (tour: Shepherd.Tour): StepOptions[] => [
	{
		id: 'step-1',
		text: '<strong>You can select any project from the menu to edit or preview.</strong>',
		attachTo: { element: '#projects-link', on: 'left' }, // Adjust `on` property to position tooltip
		classes: styles.customTooltip,
		//Disable Button of element
		beforeShowPromise: () => disablePointerEvents('#projects-link'),
		when: { hide: () => enablePointerEvents('#projects-link') },
	},
	{
		id: 'step-2',
		text: '<strong>By clicking on this Button you can create a new project.</strong>',
		attachTo: { element: '#createproject-link', on: 'top' }, // Adjust `on` property to position tooltip
		classes: styles.customTooltips,
		//Disable Button of element
		beforeShowPromise: () => disablePointerEvents('#createproject-link'),
		when: { hide: () => enablePointerEvents('#createproject-link') },
	},
	{
		id: 'step-3',
		text: '<strong>By clicking on this Button you can share your project.</strong>',
		attachTo: { element: '#shareproject-link', on: 'bottom' }, // Adjust `on` property to position tooltip
		classes: styles.customTooltips,
		//Disable Button of element
		beforeShowPromise: () => disablePointerEvents('#shareproject-link'),
		when: { hide: () => enablePointerEvents('#shareproject-link') },
	},
	{
		id: 'step-4',
		text: '<strong>By clicking on this Button you can preview your project.</strong>',
		attachTo: { element: '#project-preview', on: 'bottom' }, // Adjust `on` property to position tooltip
		classes: styles.customTooltips,
		//Disable Button of element
		beforeShowPromise: () => disablePointerEvents('#project-preview'),
		when: { hide: () => enablePointerEvents('#project-preview') },
	},
	{
		id: 'step-5',
		text: '<strong>You can edit your profile from here.</strong>',
		attachTo: { element: '.admin-user', on: 'top' }, // Adjust `on` property to position tooltip
		classes: styles.customTooltips,
		//Disable Button of element
		beforeShowPromise: () => disablePointerEvents('.admin-user'),
		when: { hide: () => enablePointerEvents('.admin-user') },
	},
	{
		id: 'step-6',
		text: '<strong>You can edit the crew members and also change the font style of the project title</strong>',
		attachTo: { element: '#editproject', on: 'bottom' }, // Adjust `on` property to position tooltip
		classes: styles.customTooltips,
		//Disable Button of element
		beforeShowPromise: () => disablePointerEvents('#editproject'),
		when: { hide: () => enablePointerEvents('#editproject') },
	},
	{
		id: 'step-7',
		text: '<strong>You can add more media in the existing event</strong>',
		attachTo: { element: '#addproject', on: 'bottom' }, // Adjust `on` property to position tooltip
		classes: styles.customTooltips,
		//Disable Button of element
		beforeShowPromise: () => disablePointerEvents('#addproject'),
		when: { hide: () => enablePointerEvents('#addproject') },
	},
	{
		id: 'step-8',
		text: '<strong>You can edit event  by clicking on the edit icon.</strong>',
		attachTo: { element: '#singleEventProject', on: 'bottom' }, // Adjust `on` property to position tooltip
		classes: styles.customTooltips,
		//Disable Button of element
		beforeShowPromise: () => disablePointerEvents('#singleEventProject'),
		when: { hide: () => enablePointerEvents('#singleEventProject') },
	},
	{
		id: 'step-9',
		text: '<strong>This is the description of your event media</strong>',
		attachTo: { element: '#important-heading', on: 'bottom' }, // Adjust `on` property to position tooltip
		classes: styles.customTooltips,
		//Disable Button of element
		beforeShowPromise: () => disablePointerEvents('#important-heading'),
		when: { hide: () => enablePointerEvents('#important-heading') },
	},
	{
		id: 'step-10',
		text: '<strong>This feature changes all source files to the lowest resolution for client review. Each change to the source file counts as one revision.</strong>',
		attachTo: { element: '.switch-sidebox', on: 'bottom' }, // Adjust `on` property to position tooltip
		classes: styles.customTooltips,
		//Disable Button of element
		beforeShowPromise: () => disablePointerEvents('.switch-sidebox'),
		when: { hide: () => enablePointerEvents('.switch-sidebox') },
	},
	{
		id: 'step-11',
		text: '<strong>You can view all the notifications by clicking on notification icon.</strong>',
		attachTo: { element: '.notify', on: 'bottom' }, // Adjust `on` property to position tooltip
		classes: styles.customTooltips,
		//Disable Button of element
		beforeShowPromise: () => disablePointerEvents('.notify'),
		when: { hide: () => enablePointerEvents('.notify') },
	},
];

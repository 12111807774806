import { useState } from 'react';
import { IStepperProps } from '../../types/interfaces/IStepperProps';
import Stepper from '../stepper/Index';
import CrewMember from './CrewMember';
import ApiService from '../../helpers/api/Index';
import { AddProjectModel } from '../../types/models/ProjectModel';
import { showToast } from '../toaster/Index';
import { CrewMemberModel, CrewMembersModel } from '../../types/models/CrewMember';
import BackgroundMedia from './BackGroundMedia';
import CreateEvent, { ProjectEvents } from './CreateEvent';
import { DescriptionRequestModel, EventDescription } from '../../types/type/EventMedia';
import { BackGroundMedia } from '../../types/type/BackgroundMedia';
import Project from './Project';
import eventEmitter from '../../helpers/Utils';
import { ICreateProject } from '../../types/interfaces/ICreateProject';
interface ProjectStepperProps {
	onResetStepper: () => void;
	setCurrentProjectId?: (projectId: number) => void;
	setCreateProject: (createProject: ICreateProject) => void;
	createProject: ICreateProject;
	setProgressProjectId: (projectId: number) => void;
}
interface UploadStatus {
	uploadId?: string;
	mediaID?: number; // Adjust type as necessary
	progress: number; // This should be a number, e.g., percentage
	isUploading: boolean;
}

const ProjectStepper: React.FC<ProjectStepperProps> = ({
	onResetStepper,
	setCurrentProjectId,
	setCreateProject,
	createProject,
	setProgressProjectId,
}) => {
	const [projectId, setProjectId] = useState<number | undefined>();
	const [eventMediaInfo, setEventMediaInfo] = useState<DescriptionRequestModel | undefined>();
	const [progressEvent, setProgressEvent] = useState<boolean>(false);
	const [currentProjectStep, setCurrentProjectStep] = useState(0);
	const [uploadStatus, setUploadStatus] = useState<UploadStatus[]>([]);
	const [videoInProgress, setvideoINProgress] = useState<boolean>(false);
	const [uploadProgress, setUploadProgress] = useState<number[]>(new Array(3).fill(0));
	const [presistBackGroundMedia, setPresistBackGroundMedia] = useState<BackGroundMedia[]>([]);

	const handleEventMediaInfo = (mediaInfo: DescriptionRequestModel) => {
		setEventMediaInfo(mediaInfo);
	};

	const handleUploadProgress = (index: number, progress: number, eventId: number, mediaID: number, uploadId: string) => {
		setUploadStatus((prevStatus) => {
			const newStatus = [...prevStatus];
			const foundIndex = newStatus.findIndex((status) => status.mediaID === mediaID);

			if (foundIndex !== -1) {
				newStatus[foundIndex].progress = progress;
				newStatus[foundIndex].isUploading = true;
			} else {
				newStatus.push({ mediaID, progress, isUploading: true, uploadId });
			}
			setProgressEvent(true);
			return newStatus;
		});
	};
	// Handles project information updates
	const handleProjectInfo = (projectInfo: AddProjectModel) => {
		projectInfo.id = projectId ?? 0;
		setCreateProject({
			...createProject,
			projectInfo: projectInfo,
		});
	};
	const handleCrewInformation = (crew: CrewMemberModel[]) => {
		setCreateProject({
			...createProject,
			crewInfo: crew,
		});
	};
	const handleBackGroundMediaInformation = (background: BackGroundMedia[]) => {
		setCreateProject({
			...createProject,
			trailerDetails: background,
		});
	};
	const handleCreateEventMediaInfo = (projectEvent: ProjectEvents[]) => {
		setCreateProject({
			...createProject,
			projectEvent: projectEvent,
		});
	};
	// Handles project creation response
	const handleProject = (projectId: number) => {
		setProjectId(projectId);
	};
	const handleCallBackGroundMediaProgress = (progress: boolean) => {
		setProgressEvent(progress);
	};
	const handleVideoProgress = (value: boolean) => {
		setvideoINProgress(value);
	};
	// Callback function to update upload progress in the parent
	const handleUploadProgressUpdate = (index: number, progress: number, backGround?: BackGroundMedia[]) => {
		setUploadProgress((prevProgress) => {
			setProgressEvent(true);
			const updatedProgress = [...prevProgress];
			updatedProgress[index] = progress;
			return updatedProgress;
		});
	};
	const handleBackGroundMediaForProgressBar = (background: BackGroundMedia[]) => {
		setPresistBackGroundMedia(background);
	};
	// Stepper configuration for project creation process
	const allSteps: IStepperProps[] = [
		{
			// Hide stepper button for this step
			hideStepperButton: false,
			// Render ProjectName component and pass handleProjectInfo as prop
			content: <Project handleProjectInfo={handleProjectInfo} projectInformation={createProject?.projectInfo} />,
		},
		{
			// Hide stepper button for this step
			hideStepperButton: false,
			// Render BackgroundMedia component and pass projectId as prop
			content: (
				<BackgroundMedia
					projectId={projectId ?? 0}
					handleBackGroundMediaInformation={handleBackGroundMediaInformation}
					isSmartRevision={createProject?.projectInfo?.isSmartRevision ?? false}
					handleCallBackGroundMediaProgress={handleCallBackGroundMediaProgress}
					backgroundMediaInformation={createProject?.trailerDetails}
					onProgressUpdate={handleUploadProgressUpdate}
					getUploadProgress={uploadProgress}
					progressEvent={progressEvent}
					handleBackGroundMediaForProgressBar={handleBackGroundMediaForProgressBar}
					presistBackGroundMedia={presistBackGroundMedia}
				/>
			),
		},
		{
			// Hide stepper button for this step
			hideStepperButton: false,
			// Render BackgroundMedia component and pass projectId as prop
			content: (
				<CreateEvent
					handleCreateEventMediaInfo={handleCreateEventMediaInfo}
					projectId={projectId ?? 0}
					handleEventMediaInfo={handleEventMediaInfo}
					isSmartRevision={createProject?.projectInfo?.isSmartRevision ?? false}
					handleCallBackGroundMediaProgress={handleCallBackGroundMediaProgress}
					onUploadProgress={handleUploadProgress}
					uploadStatus={uploadStatus}
					isVideoUploading={handleVideoProgress}
					ProjectEvent={createProject?.projectEvent}
				/>
			),
		},
		{
			hideStepperButton: false,
			content: (
				<CrewMember
					handleCrewInformation={handleCrewInformation}
					isSmartRevision={createProject?.projectInfo?.isSmartRevision ?? false}
					crewInformation={createProject?.crewInfo}
				/>
			),
		},
	];

	// Determines if the next button is enabled for the current step
	const isNextButtonEnabled = (currentStep: number): boolean => {
		setCurrentProjectStep(currentStep);

		if (!createProject) return false;

		const { projectInfo, trailerDetails, projectEvent, crewInfo } = createProject;

		switch (currentStep) {
			case 0: {
				if (!projectInfo) return false;
				return !!projectInfo?.name?.trim() && !!projectInfo?.description?.trim();
			}

			case 1: {
				return trailerDetails.length > 0 && trailerDetails.some(({ backgroundFile, thumbnail }) => backgroundFile?.size > 0 && thumbnail?.size > 0);
			}

			case 2: {
				if (projectEvent.length === 0) return false;
				const isValidEvents = projectEvent.every(
					({ title, eventMedia }) =>
						typeof title === 'string' &&
						title?.trim() !== '' &&
						eventMedia.length > 0 &&
						eventMedia.every((member) => member.description && member.thumbnailUrl && member.eventMediaUrl),
				);
				const hasUniqueTitles = projectEvent.every((event, index) => projectEvent.findIndex((e) => e.title === event.title) === index);
				return isValidEvents && hasUniqueTitles;
			}

			case 3: {
				if (crewInfo.length <= 1) return false;
				return crewInfo.every(
					(member) =>
						typeof member.title === 'string' && member.title?.trim() !== '' && typeof member.name === 'string' && member.name?.trim() !== '',
				);
			}

			default:
				return true;
		}
	};

	// Handles next step button click
	const onNextStep = async (currentStep: number) => {
		setCurrentProjectStep(currentStep);
		// Create project on first step
		if (currentProjectStep === 0) {
			//Check if project information is available
			try {
				if (createProject && createProject?.projectInfo) {
					// Call API to create project
					var response = await ApiService.CreateProject(createProject?.projectInfo);

					if (response && response.isSuccess) {
						// Handle successful project creation
						handleProject(response.response);
						setProgressProjectId(response.response);
					} else {
						// Handle project creation error
						showToast(response?.error.userMessage, { type: 'error' });
					}
				}
			} catch (error: any) {
				showToast(error.message, { type: 'error' });
			}
		} // Handle different steps of the stepper
		else if (currentProjectStep === 1) {
		} else if (currentProjectStep === 2) {
			if (projectId === undefined) {
				showToast('Project ID is missing', { type: 'error' });
				return;
			}
			if (videoInProgress) {
				setProgressEvent(true);
			}
			if (eventMediaInfo?.Events) {
				// Ensure you are passing the array of events
				// Construct the payload with the events
				const requestPayload = buildDescriptionRequestPayload(projectId, eventMediaInfo.Events);

				// Save event media info
				await ApiService.AddDescriptionOfMedia(requestPayload)
					.then((res) => {
						if (res.isSuccess) {
							//showToast(res.response ?? 'Event saved successfully', { type: 'success' });
						} else {
							showToast(res?.error?.userMessage, { type: 'error' });
						}
					})
					.catch((error) => {
						showToast(error.message, { type: 'error' });
					});
			}
		} else if (currentProjectStep === 3) {
			/**
			 * Add crew members to the project if crew info is available and project ID is valid
			 *
			 * @summary Adds crew members to a project and shows a success or error toast message
			 */
			if (createProject?.crewInfo) {
				// Check if project ID and crew info are valid
				if (projectId && createProject?.crewInfo.length > 0) {
					// Create a new project object with crew members
					const project: CrewMembersModel = {
						projectId,
						crew: createProject?.crewInfo,
					};
					// Call API service to add crew members to the project
					await ApiService.AddCrewMember(project)
						.then(async (response) => {
							// Show success toast message if API call is successful
							if (response.isSuccess) {
								setCurrentProjectId?.(projectId);
								showToast('Crew Member Added SuccessFully');
								// Add a notification when a success toast is shown
								try {
									await ApiService.addNotification(`${createProject?.projectInfo?.name} project created successfully.`);
								} catch (error) {
									console.error('Failed to add notification:', error);
								}
								// Fetch notifications after saving the event
								const notificationRes = await ApiService.getAllnotifications();
								if (notificationRes.isSuccess && notificationRes.response) {
									// Emit notifications using the event emitter
									eventEmitter.emit('newNotifications', notificationRes.response);
								}
							}
						})
						.catch((error) => {
							// Show error toast message if API call fails
							showToast(error.message, { type: 'error' });
						});
				}
			}
			setCreateProject({
				...createProject,
				projectInfo: undefined,
				crewInfo: [],
				trailerDetails: [],
				projectEvent: [],
			});
			setProgressProjectId(0);
			// Reset the stepper
			onResetStepper();
		}
	};

	const buildDescriptionRequestPayload = (projectId: number, events: EventDescription[]): DescriptionRequestModel => {
		return {
			ProjectId: projectId,
			Events: events,
		};
	};

	return (
		<div className="stepper-sidebox">
			<Stepper
				customButtonLabel={currentProjectStep === 3 ? 'Proceed to preview' : undefined}
				onNextStep={onNextStep}
				steps={allSteps}
				isNextButtonEnabled={isNextButtonEnabled}
			/>
		</div>
	);
};

export default ProjectStepper;

import { PreSignedUrlResponse } from '../../types/type/BackgroundMedia';
import { EventMediaCommand, ProjectEvents } from '../../types/type/EventMedia';
import ApiService from '../api/Index';
import eventEmitter, { fileToBase64, formatFileSize, getVideoFormat } from '../Utils';
import uploadVideo from './TUSUploadService';
import { TrackJS } from 'trackjs';
/**
 * Converts the ProjectEvents model to a EventMediaCommand Body object for submitting background media data.
 *
 * @param {ProjectEvents} data - The ProjectEvents data model that contains the event media information.
 * @param {number | undefined} projectId - Optional project ID to be included if available.
 * @param {number | undefined} eventID - The ID of the event to be included.
 *
 * @returns {eventBody} - The EventMediaCommand Body object with the necessary fields for uploading background media.
 */
const convertToEventMedia = async (data: ProjectEvents, projectId?: number, eventID?: number): Promise<EventMediaCommand> => {
	// Construct the event media command object
	const eventBody: EventMediaCommand = {
		id: eventID ?? null, // Send null if eventID is not provided
		projectId: projectId!, // Ensure projectId is always defined
		eventTitle: data.title, // Title of the event
		eventMedias: [], // Initialize eventMedias array
	};

	// Loop through each media item and map its properties to the eventMedias array
	for (const item of data.eventMedia) {
		if (item.eventMedia && item.thumbnail) {
			// Convert the image file to a base64 string and wait for the promise to resolve
			const thumbnailBase64 = await fileToBase64(item.thumbnail);
			const videoSize = formatFileSize(item.eventMedia.size); // Get formatted size
			const format = getVideoFormat(item.eventMedia); // Get video format

			// Push media properties to the eventMedias array
			eventBody.eventMedias.push({
				mediaName: item.eventMedia.name, // Media name
				description: item.description, // Media description
				size: videoSize, // Media size
				format: format, // Media format
				thumbnailName: item.thumbnail.name, // Thumbnail image name
				thumbnail: thumbnailBase64, // Thumbnail (Base64 string)
			});
		}
	}

	return eventBody;
};

/**
 * Uploads media files related to project events with progress tracking.
 *
 * @param {ProjectEvents} projectEvents - The project events containing media files to upload.
 * @param {number | undefined} projectId - The ID of the project to which the media is linked.
 * @param {number | undefined} eventID - The ID of the event to which the media is linked.
 * @param {(index: number, progress: number) => void} onProgressCallback - Callback to report upload progress for each media file.
 *
 * @returns {Promise<Array<{ mediaIndex: number; message: string }>>} - A promise that resolves to an array of results,
 * each containing the media index and a status message.
 */
export const EventMediaUpload = async (
	projectEvents: ProjectEvents,
	projectId: number | undefined,
	eventID: number,
	onProgressCallback: (index: number, progress: number, eventId: number, mediaID: number) => void, // Progress callback
): Promise<Array<{ mediaIndex: number; message: string; mediaId: number | null; fileName: string }>> => {
	// Maintain a set of active media IDs
	const activeMediaIds = new Set<number>();

	// Event handler to prompt the user and conditionally notify backend on page reload
	const handleBeforeUnload = (event: BeforeUnloadEvent) => {
		// Check if there are active uploads
		if (activeMediaIds.size > 0) {
			event.preventDefault();
			event.returnValue = ''; // Show the confirmation dialog
		}
	};

	// Event handler to store active media IDs in local storage on reload
	const handleUnload = () => {
		if (activeMediaIds.size > 0) {
			// Create an array of objects with mediaId and mediaType
			const mediaItems = Array.from(activeMediaIds).map((mediaId) => ({
				mediaId,
				mediaType: 'Event',
			}));
			// Store the array in localStorage
			localStorage.setItem('activeMedia', JSON.stringify(mediaItems));
		}
	};

	// Add event listeners for beforeunload and unload
	window.addEventListener('beforeunload', handleBeforeUnload);
	window.addEventListener('unload', handleUnload);
	try {
		// Retrieve pre-signed URLs for each media file
		const preSignedUrlPromises = async () => {
			const eventMedia = convertToEventMedia(projectEvents, projectId, eventID);
			// Await the API call to get pre-signed URLs
			return ApiService.EventMedia(await eventMedia);
		};

		// Wait for all pre-signed URL promises to resolve
		const preSignedUrlResponses = await Promise.all([preSignedUrlPromises()]);

		// Create an array of promises to upload each media file
		const uploadPromises = projectEvents.eventMedia.map((media, index) => {
			const response = preSignedUrlResponses[index];

			// Upload each media file using the pre-signed URLs and report progress
			return Promise.all(
				(response?.preSignedUrlResponse?.length ?? 0) > 0
					? (response.preSignedUrlResponse ?? []).map((uploadConfig: PreSignedUrlResponse, index: number) => {
							if (media.eventMedia) {
								// Add mediaId to active uploads
								activeMediaIds.add(uploadConfig.mediaId);

								return uploadVideo(
									media.eventMedia,
									uploadConfig,
									index,
									(progress) => {
										// Emit the progress event globally
										eventEmitter.emit('eventUploadProgress', {
											progress: progress === 100 ? 99.9 : progress, // Temporary hold at 99.9%
											mediaId: uploadConfig.mediaId,
											eventId: response.eventId || eventID,
											fileName: media.eventMedia?.name,
											fileSize: media.eventMedia?.size,
											eventTitle: response.eventTitle,
											projectId: uploadConfig.projectId,
										});
										onProgressCallback(index, progress, response.eventId, uploadConfig.mediaId);
									}, // Report progress
								)
									.then((uploadResult) => {
										// Emit success event with message
										eventEmitter.emit('eventUploadProgress', {
											progress: 100, // Final progress at 100%
											mediaId: uploadConfig.mediaId,
											eventId: response.eventId || eventID,
											fileName: media.eventMedia?.name,
											message: 'Event Media Uploaded successfully',
											fileSize: media.eventMedia?.size,
											eventTitle: response.eventTitle,
											projectId: uploadConfig.projectId,
										});
										// Remove from active uploads
										activeMediaIds.delete(uploadConfig.mediaId);
										// Include mediaId in the result after the upload completes
										return {
											...uploadResult,
											mediaId: uploadConfig.mediaId, // Add mediaId to the result
											fileName: media.eventMedia?.name || '', // Provide a default value for fileName
											message: 'Event Media Uploaded successfully',
										};
									})
									.catch(async (error) => {
										TrackJS?.track(error);
										// Remove from active uploads
										activeMediaIds.delete(uploadConfig.mediaId);
										if (uploadConfig.mediaId) {
											// Call the API to notify the backend of the failed upload with the mediaId
											await ApiService.postEventMediaUploadFailed(uploadConfig.mediaId);
										}
										// Include detailed error response
										const errorResponse = uploadConfig.errorResponse || {};
										return {
											mediaIndex: index,
											mediaId: null,
											fileName: media.eventMedia?.name || '',
											message: errorResponse.error || 'Unknown error occurred', // Include `error` field if available
										};
									});
							} else {
								// Handle the case when eventMedia is null
								const errorMessage = `eventMedia is null for index ${index}`;
								console.error(errorMessage);

								TrackJS?.track(errorMessage);
								return Promise.reject(new Error(`No media file to upload at index ${index}`));
							}
						})
					: [],
			);
		});
		// Wait for all upload promises to complete and return the results
		const results = await Promise.all(uploadPromises);
		// Clean up active uploads and event listener after completion
		window.removeEventListener('beforeunload', handleBeforeUnload);
		window.removeEventListener('unload', handleUnload);
		return results.flat(); // Flatten the results into one array
	} catch (error: any) {
		TrackJS?.track(error);
		// Handle errors and return an empty
		console.error('Error submitting project events:', error);
		return []; // Return an empty array in case of an error
	}
};

/**
 * Deletes media by the provided mediaID.
 *
 * @param mediaID - The ID of the media to be deleted (integer).
 * @returns A promise that resolves with an object containing a success boolean and a message string.
 */
export const DeleteMediaById = async (mediaID: number): Promise<{ success: boolean; message: string }> => {
	try {
		// Make an API call to delete the media by mediaID
		const response = await ApiService.DeleteMedia(mediaID);

		// Check if the response status is successful (status code 200-299)
		if (response.isSuccess) {
			return {
				success: true,
				message: `Media was successfully deleted.`,
			};
		}

		// If the response is not successful, return a failure message
		return {
			success: false,
			message: `Failed to delete media. Status: ${response.status}`,
		};
	} catch (error) {
		// Catch any network or unexpected errors and return a failure message
		console.error('Error occurred while deleting media:', error);
		return {
			success: false,
			message: `Error occurred while deleting media with ID ${mediaID}.`,
		};
	}
};

/**
 * Deletes media by the provided mediaID.
 *
 * @param eventId - The ID of the event to be deleted (integer).
 * @returns A promise that resolves with an object containing a success boolean and a message string.
 */
export const DeleteEventWithMedia = async (eventId: number): Promise<{ success: boolean; message: string }> => {
	try {
		// Make an API call to delete the media by mediaID
		const response = await ApiService.DeleteEventWithMedia(eventId);

		// Check if the response status is successful (status code 200-299)
		if (response.isSuccess) {
			return {
				success: true,
				message: `Event with media was successfully deleted.`,
			};
		}

		// If the response is not successful, return a failure message
		return {
			success: false,
			message: `Failed to delete event and its media. Status: ${response.status}`,
		};
	} catch (error) {
		// Catch any network or unexpected errors and return a failure message
		console.error('Error occurred while deleting media:', error);
		return {
			success: false,
			message: `Error occurred while deleting media with ID ${eventId}.`,
		};
	}
};

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PlanDetailsModel } from '../../../../src/types/models/PersonalDetailsModel';
import ApiService from '../../../../src/helpers/api/Index';
import { showToast } from '../../../../src/components/toaster/Index';
import Button from '../../../../src/components/button/Index';
import { confirmAlert } from 'react-confirm-alert';
import ModalComponent from '../../../components/Modal/Index';
import Swal from 'sweetalert2';
import { App_Urls } from '../../../../src/routes/AppUrls';
import Pricing from '../../../components/pricing/Index';
import 'chart.js/auto';
import { TransactionResponseModel } from '../../../types/models/TransactionResponseModel';
import LineChart from '../../../components/LineChart/Index';
import { IntlDateFormat } from '../../../helpers/Utils';
interface PlanDetailsProps {
	planDetails: PlanDetailsModel | null;
	handleOpenClose: () => void;
}

const PlanDetails: React.FC<PlanDetailsProps> = ({ planDetails, handleOpenClose }) => {
	const [planInfo, setPlanInfo] = useState<PlanDetailsModel>();
	const [showZohoBilling, setShowZohoBilling] = useState(false);
	const [transactionLogs, setTransactionLogs] = useState<TransactionResponseModel[]>([]);
	const navigate = useNavigate();
	// Static data transformed from the provided response
	// Assuming `transactionLogs` is an array of TransactionResponseModel

	const chartData = {
		labels: transactionLogs?.map((entry) => IntlDateFormat(entry.logDate)).filter((label): label is string => label !== undefined),
		datasets: [
			{
				label: 'Used Data (GB)',
				data: transactionLogs.map((entry) => entry.usedData),
				fill: false,
				backgroundColor: 'rgba(27, 27, 169, 0.4)',
				borderColor: 'rgba(75,192,192,1)',
				tension: 0.1,
			},
			{
				label: 'Available Data (GB)',
				data: transactionLogs.map((entry) => entry.allocatedData),
				fill: false,
				backgroundColor: 'rgba(99, 102, 255, 0.4)',
				borderColor: 'rgba(255,99,132,1)',
				tension: 0.1,
			},
		],
	};
	const chartOptions = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const, // Use 'as const' to ensure it's treated as a literal type
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: 'Date',
				},
			},
			y: {
				title: {
					display: true,
					text: 'Data (GB)',
				},
			},
		},
	};

	/**
	 * Cancels the user's current plan and handles the response.
	 * If successful, it refreshes the user's authentication token and shows a success message.
	 * If there is an error, it displays the appropriate error message.
	 */
	const handleCancelUserPlan = async () => {
		// Show confirmation modal before proceeding with cancellation
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<>
						<div className="modal-content-delete">
							<div>
								<h5>
									{planDetails && planDetails?.dataUsed <= 0 && checkDateWithin14Days(planDetails?.startDate) ? (
										<>
											You’ve used {planDetails?.dataUsed} GB out of {(planDetails && planDetails.totalData - planDetails.dataUsed).toFixed(2)} GB your{' '}
											{planDetails?.planName}. Your plan will no longer renew and a full refund will be initiated. The access will end immediately
										</>
									) : (
										<>
											You’ve used {planDetails?.dataUsed} GB of your {planDetails?.totalData} GB plan. By cancelling now, no refunds will be issued,
											but you’ll retain access to Streamefy until the remaining{' '}
											{(planDetails && planDetails?.totalData - planDetails?.dataUsed)?.toFixed(2)} GB is utilized.
										</>
									)}
								</h5>
							</div>
							<div className="buttons-modal-delete">
								<Button
									className="cmn-btn blank-btn"
									label={'Yes, Cancel Renewal'}
									onClick={async () => {
										try {
											const result = await ApiService.CancelUserPlan();
											if (result && result?.isSuccess) {
												// If the cancellation is successful, refresh the token
												Swal.fire({
													icon: 'success',
													title: 'Success',
													text: 'Subscription canceled successfully',
													confirmButtonText: 'OK',
													customClass: {
														confirmButton: 'cmn-btn',
													},
												}).then((result) => {
													if (result.isConfirmed) {
														navigate(App_Urls.Profile);
														handleOpenClose();
													}
												});
											} else {
												Swal.fire({
													icon: 'error',
													title: 'Error',
													text: result?.error?.userMessage ?? 'Something went wrong', // Corrected the typo here
													confirmButtonText: 'OK',
												});
											}
										} catch (error: any) {
											showToast(error.message ?? 'Something went wrong while canceling the plan', { type: 'error' });
										} finally {
											onClose(); // Close the modal after action
										}
									}}
									id={'Cancel'}
								/>
								<Button
									className="cmn-btn"
									label="No, Keep Subscription"
									onClick={() => onClose()} // Close modal without doing anything
									id={'No'}
								/>
							</div>
						</div>
					</>
				);
			},
			closeOnClickOutside: false, // Prevent modal from closing when clicking outside
		});
	};
	const checkDateWithin14Days = (dateToCheck: string) => {
		// Convert the input date to a Date object
		const inputDate = new Date(dateToCheck);

		if (isNaN(inputDate.getTime())) {
			console.error('Invalid date provided');
			return false;
		}

		// Get today's date at midnight for accurate comparison
		const today = new Date();
		today.setHours(0, 0, 0, 0);

		// Calculate the difference in days
		const timeDifference = inputDate.getTime() - today.getTime();
		const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
		// Return whether the date is within 14 days from today
		return daysDifference >= 0 && daysDifference <= 14;
	};

	// Type explicitly added for the event parameter
	const handleUpgradePlan = (e: React.MouseEvent<HTMLAnchorElement>) => {
		try {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className="modal-content-delete">
							<h5>
								{planInfo?.planName.toLowerCase().includes('free')
									? `You’re upgrading your current plan.`
									: `You’re upgrading your current plan. All remaining data from your existing subscription will be seamlessly carried over and added to your new plan, ensuring uninterrupted access to Streamefy.`}
								{/* {`You’re upgrading your current plan. All remaining data from your existing subscription will be seamlessly carried over and added to your new plan, ensuring uninterrupted access to Streamefy.`} */}
							</h5>
							<h5>Thank you for growing with us!</h5>
							<div className="buttons-modal-delete">
								<Button
									className="cmn-btn"
									label="Confirm Upgrade"
									onClick={() => {
										e.preventDefault();
										setShowZohoBilling(true); // Trigger the upgrade modal or action

										setTimeout(() => {
											onClose();
										}, 300);
									}}
									id={''}
								/>
								<Button className="cmn-btn blank-btn" label="Go Back" onClick={onClose} id={''} />
							</div>
						</div>
					);
				},
				closeOnClickOutside: false,
			});
		} catch (error: any) {
			showToast(error?.message ?? ' Something went wrong', { type: 'error' });
		}
	};
	const getTransactionLogs = async () => {
		const logs = await ApiService.DataTransactionLogs();

		if (logs.isSuccess && logs.response?.length > 0) {
			setTransactionLogs(logs.response);
		}
	};

	useEffect(() => {
		if (planDetails) setPlanInfo(planDetails);
	}, [planDetails]);

	useEffect(() => {
		getTransactionLogs();
	}, []);
	return (
		<>
			{planInfo ? (
				<>
					<div className="single-tab-profile">
						<div className="plan-tab-detail">
							<div className="plan-active-details">
								<label>Current Plan:</label>
								<div className="current-plan-upgrade-ex">
									<span>{planInfo?.planName && planInfo?.planName}</span>
									<Link
										to=""
										className="cmn-btn"
										onClick={handleUpgradePlan} // Use the handler here
									>
										<span>Upgrade Plan</span>
									</Link>
									{planInfo?.planName && !planInfo?.planName?.toLowerCase()?.includes('free trial') && (
										<Link
											to=""
											className={planInfo.isCancelled ? 'disabled-link' : 'cmn-btn'}
											onClick={(e) => {
												e && e.preventDefault();
												handleCancelUserPlan();
											}}
										>
											<span>Cancel Plan</span>
										</Link>
									)}
									<p>
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M9 4.56445V4.55557M9 12.4444V8.22222M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
												stroke="#951CF1"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										{planInfo?.expireDate && <>Expiring on: {new Date(planInfo.expireDate).toLocaleDateString()}</>}
									</p>
								</div>
							</div>
							<div className="current-plan-usage">
								<div className="plan-detail-profile">
									<label>Plan Usage:</label>
									<p>
										You have used <b>{planInfo?.dataUsed ?? 0}GB</b> data from <b>{planInfo?.totalData ?? 0}GB</b>
									</p>
								</div>
								<div className="pie-chart">
									<LineChart chartData={chartData} options={chartOptions} />
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				'No Plan details available'
			)}
			{
				<ModalComponent
					show={showZohoBilling}
					className="zoho-modal"
					handleClose={() => {
						setShowZohoBilling(false);
					}}
					title=""
				>
					<Pricing />
				</ModalComponent>
			}
		</>
	);
};

export default PlanDetails;

import ApiService from '../../helpers/api/Index';
import { showToast } from '../toaster/Index';

/**
 * Initiates the Google OAuth sign-in process by opening a new window with the Google login URL.
 * Displays an error toast message if the process fails or if there's an error.
 */
export const GoogleOAuth = () => {
	ApiService.SignInWithGoogle_Get()
		.then((result) => {
			if (result.isSuccess) {
				// Open the Google login URL in a new window
				window.open(result.response, '_self', `width=600,height=700,top=${(window.innerHeight - 700) / 2},left=${(window.innerWidth - 600) / 2}`);
			} else {
				// Show an error toast message if the result is not successful
				showToast(result.error.userMessage || 'Something went wrong', { type: 'error' });
			}
		})
		.catch((error) => {
			// Show an error toast message if an exception occurs
			showToast(error.message, { type: 'error' });
		});
};

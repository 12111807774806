import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import profilePic from '../../../assets/images/user-avatar.png';
import Input from '../../../components/input/Index';
import { Link, useNavigate } from 'react-router-dom';
import { PersonalDetailsModel } from '../../../types/models/PersonalDetailsModel';
import Image from '../../../components/image/Index';
import FileUpload from '../../../components/fileUpload/fileUpload';
import { Constants } from '../../../constants/Constants';
import { ImageProps } from '../../../types/type/ImageProps';
import { showToast } from '../../../components/toaster/Index';
import Button from '../../../components/button/Index';
import ModalComponent from '../../../components/Modal/Index';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { ResetPasswordModel } from '../../../types/models/ResetPasswordModel';
import ApiService from '../../../helpers/api/Index';
import { EmailOtpVerificationModel } from '../../../types/models/EmailOtpVerificationModel';
import PhoneInputComponent from '../../../components/phoneNumber/Index';
import Loader from '../../../components/loader/Index';
import { confirmAlert } from 'react-confirm-alert';
import { Clear_Local_storage } from '../../../helpers/Utils';
import { App_Urls } from '../../../routes/AppUrls';

interface ProfileDetailsProps {
	isEdit: boolean;
	personalInformation: PersonalDetailsModel;
	handleInputChangeforPersonalDetails: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleFileSelect: (file: File, type: string) => void;
	phoneError: string;
	personalDetails?: PersonalDetailsModel;
}
const ProfileDetails: React.FC<ProfileDetailsProps> = ({
	isEdit,
	personalInformation,
	handleInputChangeforPersonalDetails,
	handleFileSelect,
	phoneError,
	personalDetails,
}) => {
	const [uploadedImage, setUploadedImage] = useState<ImageProps>();
	const [otp, setOtp] = useState(['', '', '', '', '', '']);
	const inputRefs = useRef<(HTMLInputElement | null)[]>([]); // Ref to manage input fields
	const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
	const [showPasswords, setShowPasswords] = useState<{ password: boolean; confirmPassword: boolean }>({
		password: false,
		confirmPassword: false,
	});

	const [secondsLeft, setSecondsLeft] = useState(20);
	const [isResetLinkDisabled, setIsResetLinkDisabled] = useState(true);
	const [isEmailEdit, setIsEmailEdit] = useState<boolean>(true);
	const [resetPassowrd, setIsResetPassword] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	// Define validation schema using Yup
	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.required('Password is required')
			.min(8, 'Password must be at least 8 characters long')
			.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
			.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
			.matches(/[0-9]/, 'Password must contain at least one number')
			.matches(/[\W_]/, 'Password must contain at least one special character'),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password')], 'Passwords must match')
			.required('Confirm password is required'),
	});

	const initialValues: ResetPasswordModel = {
		email: null,
		token: null,
		password: null,
		confirmPassword: null,
	};
	const handleOtpChange = (index: number, value: string) => {
		// Allow only numbers and limit input to a single character
		if (value.match(/^\d$/) || value === '') {
			const newOtp = [...otp];
			newOtp[index] = value;
			setOtp(newOtp);

			if (value && index < otp.length - 1) {
				inputRefs.current[index + 1]?.focus();
			}
		}
	};

	// Handle file upload and set uploaded image
	const handleFileUpload = (file: File, type: string) => {
		if (file) {
			const reader = new FileReader();
			// On file load completion, process the file data
			reader.onloadend = () => {
				setUploadedImage({ src: reader.result as string, format: file.type });
			};
			// Read the file as a data URL
			reader.readAsDataURL(file);
			handleFileSelect(file, type); // Update state with the new image URL
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		// Check if the pressed key is 'Backspace' and the current input is empty
		// If so, move focus to the previous input field if it exists
		if (event.key?.toLowerCase() === 'backspace' && !otp[index] && index > 0) {
			inputRefs.current[index - 1]?.focus();
		}
	};
	/**The handleSubmitClick function is an asynchronous handler designed for processing a password reset request.
	 * It accepts a ResetPasswordModel object, constructs a new payload with the user's email, password, and confirmation
	 * password (with the token set to null), and sends this payload to an API service for resetting the password.
	 *  */
	const handleSubmitClick = async (values: any) => {
		setLoading(true); // Set loading state to true to indicate the process has started
		var payload = {
			password: values.password,
			confirmPassword: values.confirmPassword,
		};

		try {
			// Attempt to reset the profile password
			var res = await ApiService.ResetProfilePassword(payload);

			// If the request is successful (isSuccess is true), display a success toast message
			if (res && res.isSuccess) {
				showToast('Password updated successfully', { type: 'success' });
				setIsResetPassword(false); //close the modal after that
			}
			// If the request fails, show an error toast with a user-friendly message
			else {
				showToast(res.error?.userMessage ?? 'something went wrong');
			}
		} catch (error) {
			showToast('An unexpected error occurred. Please try again.', { type: 'error' });
		} finally {
			// This block will run regardless of success or error; reset loading state
			setLoading(false); // Set loading state to false to indicate the process has ended
		}
	};

	const ChangePassword = async () => {
		try {
			if (otp && otp.length > 0) {
				var payload: EmailOtpVerificationModel = {
					email: personalInformation.email ?? '',
					otp: otp.join('')?.trim(),
				};
				var res = await ApiService.OtpVerification(payload);
				if (res && res.isSuccess) {
					setIsResetPassword(true);
				} else {
					showToast(res.error?.userMessage ?? 'Unable to verify otp', { type: 'error' });
				}
			}
		} catch (error: any) {
			showToast(error?.message ?? 'Something went wrong', { type: 'error' });
		} finally {
			setOtp(['', '', '', '', '', '']);
		}
	};

	useEffect(() => {
		setUploadedImage({ src: personalInformation?.profileImage });
	}, [personalInformation?.profileImage]);

	const getOTP = async () => {
		try {
			var res = await ApiService.ResetPasswordOTP();
			if (res.isSuccess) {
				showToast(res.response, { type: 'success' });
			} else showToast(res.error.userMessage, { type: 'error' });
		} catch (error) {
			setIsChangePassword(false);
			showToast('Something went wrong', { type: 'error' });
		}
	};

	/**
	 * useEffect hook that manages a countdown timer.
	 *
	 * This hook decrements the `secondsLeft` state by 1 every second, simulating a countdown.
	 * When the `secondsLeft` value is greater than 0, it sets a timeout to decrease the value
	 * after 1 second. If `secondsLeft` reaches 0, it enables a link by setting `setIsLinkDisabled(false)`.
	 * The hook also cleans up the timer on component unmount to prevent memory leaks.
	 *
	 * @param {number} secondsLeft - The number of seconds left in the countdown.
	 * @param {React.Dispatch<React.SetStateAction<number>>} setSecondsLeft - The state setter for `secondsLeft`.
	 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsLinkDisabled - The state setter for the link's disabled status.
	 */
	useEffect(() => {
		if (secondsLeft > 0 && isChangePassword) {
			const timer = setTimeout(() => {
				setSecondsLeft(secondsLeft - 1);
			}, 1000);

			return () => clearTimeout(timer); // Cleanup the timer on unmount
		} else {
			setIsResetLinkDisabled(false); // Enable the link after the countdown
		}
	}, [secondsLeft, isChangePassword]);

	// Function to toggle the visibility of password fields
	const togglePasswordVisibility = (field: 'password' | 'confirmPassword') => {
		// Update the state to toggle the visibility of the specified password field
		setShowPasswords((prev) => ({
			...prev, // Spread the previous state to retain other fields' values
			[field]: !prev[field], // Toggle the visibility for the specified field
		}));
	};
	// Function to handle paste events
	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		const pastedOtp = event.clipboardData.getData('Text').toUpperCase().replace(/\s+/g, ''); // Remove spaces

		// Ensure the OTP length is valid before updating the state
		if (pastedOtp.length === otp.length) {
			const newOtp = pastedOtp.split('');
			setOtp(newOtp); // Update state with the pasted OTP

			// Focus the last input field after pasting
			inputRefs.current[otp.length - 1]?.focus();
		}
	};
	// Explicitly define the type of onClose parameter
	const handleDeleteAccount = async () => {
		setLoading(true); // Show loader when deletion starts
		try {
			const { isSuccess, error } = await ApiService.DeleteAccount(); // Wait for DeleteAccount to complete
			if (isSuccess) {
				showToast('Account Deleted', { type: 'success' });
				Clear_Local_storage();
				navigate(App_Urls.Auth);
			} else showToast(error?.userMessage ?? 'Failed to delete account', { type: 'error' });
		} catch (error: any) {
			showToast(error?.message ?? 'Failed to delete account', { type: 'error' });
		} finally {
			setLoading(false); // Hide the loader when done
		}
	};
	const triggerDeleteAccount = async () => {
		try {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className="modal-content-delete">
							<div className="icon-modal-delete">
								<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="39" cy="39" r="38" fill="white" stroke="#EFBD8A" strokeWidth="2" />
									<path
										d="M41.3491 47.128H37.4291V44.44C37.5971 42.312 37.9891 41.472 40.8451 39.848C45.2691 37.216 48.9651 34.472 48.9651 29.936C48.9651 25.68 45.5491 22.936 40.3971 22.936C35.2451 22.936 32.6131 25.456 32.6131 28.648C32.6131 29.152 32.6691 29.712 32.8371 30.44H28.9731C28.6931 29.768 28.5811 28.984 28.5811 28.088C28.5811 24.336 31.8291 19.184 40.5651 19.184C47.6771 19.184 53.3891 23.272 53.3891 29.824C53.3891 36.432 47.2291 40.296 43.8131 42.368C41.0691 44.048 41.3491 44.048 41.3491 47.128ZM36.0851 56.368C36.0851 54.52 37.5411 53.008 39.3891 53.008C41.2371 53.008 42.6931 54.52 42.6931 56.368C42.6931 58.16 41.2371 59.616 39.3891 59.616C37.5411 59.616 36.0851 58.16 36.0851 56.368Z"
										fill="#941AF2"
									/>
								</svg>
							</div>
							<h5>Your Account will be permanently deleted</h5>
							<h5>Are you sure you want to delete your Account? You won't be able to recover any backups.</h5>
							<div className="buttons-modal-delete">
								<Button
									className="cmn-btn"
									label="Yes I'm Sure"
									onClick={async () => {
										onClose();
										await handleDeleteAccount();
									}} // Pass onClose to the delete handler
									id={''}
								/>
								<Button className="cmn-btn blank-btn" label="Cancel" onClick={onClose} id={'DeleteAccountCancel'} />
							</div>
						</div>
					);
				},
				closeOnClickOutside: false,
			});
		} catch (error: any) {
			showToast(error?.message ?? ' Something went wrong', { type: 'error' });
		}
	};
	return (
		<>
			<>
				<Button className="cmn-btn" label="Delete Account" onClick={triggerDeleteAccount} id="DeleteAccountButton">
					<div className="svg-container">
						<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 120 130" height={'25px'} width={'25px'}>
							<defs>
								<style>{`.cls-1 { fill-rule: evenodd; fill: white; }`}</style>
							</defs>

							<path
								className="cls-1"
								d="M100.43,72.47A22.45,22.45,0,1,1,78,94.91a22.44,22.44,0,0,1,22.44-22.44Zm-55.56-2.9c-.25-.44.77-3.22,1-3.66-3-2.67-5.37-5.36-5.87-10.9h-.33a4.31,4.31,0,0,1-2.12-.56,5.84,5.84,0,0,1-2.34-2.85c-1.08-2.48-1.94-9,.78-10.87l-.51-.34-.06-.72c-.1-1.31-.13-2.9-.15-4.57-.1-6.14-.23-11.54-5.16-13L28,21.43l1.39-1.72A79.54,79.54,0,0,1,41.78,7.17C46.53,3.43,51.36.94,56.09.22A17.19,17.19,0,0,1,70,4.11a26.36,26.36,0,0,1,3.73,3.74,15.83,15.83,0,0,1,11.14,6.52,22.71,22.71,0,0,1,3.63,7.32,25.21,25.21,0,0,1,1,8.29c-.26,5-2.09,7.86-5.78,11.42a4.18,4.18,0,0,1,1.8.47c2.06,1.1,2.12,3.49,1.59,5.49C86.54,49,85.86,51,85.22,52.61c-.78,2.21-1.92,2.62-4.11,2.38-.12,5.45-2.64,8.13-6,11.33L75.85,69c-2.14,10.38-24.85,11.5-31,.57Zm29,39H0c0-33,19.32-25.24,39-37.83C43.8,83.57,63.43,86.4,75.09,79.19a29.83,29.83,0,0,0-1.18,29.36Zm36.83-9c.86.85,1.55,1.38.47,2.42l-3.46,3.37c-1.09,1.11-1.73,1-2.75,0l-4.66-4.66-4.63,4.63c-.85.86-1.38,1.54-2.42.47l-3.37-3.45c-1.11-1.1-1-1.74,0-2.76l4.66-4.66-4.66-4.66c-1-1-1.1-1.66,0-2.76L93.29,84c1-1.08,1.57-.39,2.42.47l4.63,4.63L105,84.48c1-1,1.66-1.1,2.75,0l3.46,3.37c1.08,1,.39,1.57-.47,2.41l-4.63,4.64,4.63,4.63Z"
							/>
						</svg>
					</div>
				</Button>
			</>
			<div className="single-tab-profile">
				<div className="profile-tab">
					<div className="left-image-profile">
						<Image src={uploadedImage?.src ? uploadedImage?.src : profilePic} alt="" />
						<div className="edit-image-profile">
							{isEdit && (
								<>
									<FileUpload
										id="fileInput"
										allowedFileTypes={['image/jpeg', 'image/png']}
										fileUploadingType="profilepic"
										onFileSelect={(e) => handleFileUpload(e, Constants.userProfileImage)}
									/>
									{/* <FileUpload id="fileInput" onFileSelect={(e) => handleFileUpload(e, Constants.userProfileImage)} /> */}
									<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="21" cy="21" r="19.5" fill="url(#paint0_linear_2991_12888)" stroke="white" strokeWidth="3" />
										<path
											d="M27.2559 23.3287V26.476C27.2559 26.8934 27.0902 27.2936 26.7951 27.5888C26.5001 27.8839 26.1 28.0497 25.6828 28.0497H14.6708C14.2536 28.0497 13.8534 27.8839 13.5584 27.5888C13.2634 27.2936 13.0977 26.8934 13.0977 26.476V15.4604C13.0977 15.043 13.2634 14.6428 13.5584 14.3476C13.8534 14.0525 14.2536 13.8867 14.6708 13.8867H17.8171"
											stroke="white"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M21.356 23.1698L28.8284 15.6162L25.5248 12.3115L18.0524 19.7864L17.8164 23.3272L21.356 23.1698Z"
											stroke="white"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<defs>
											<linearGradient id="paint0_linear_2991_12888" x1="3" y1="21" x2="39" y2="21" gradientUnits="userSpaceOnUse">
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
										</defs>
									</svg>
								</>
							)}
						</div>
					</div>
					{isEdit ? (
						<>
							<div className="right-profile-info edit-box-only">
								<ul>
									{personalInformation.userName && (
										<li>
											<>
												<div className="input-field">
													<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path
															d="M6 7C7.89375 7 9.42857 5.4332 9.42857 3.5C9.42857 1.5668 7.89375 0 6 0C4.10625 0 2.57143 1.5668 2.57143 3.5C2.57143 5.4332 4.10625 7 6 7ZM8.4 7.875H7.95268C7.35804 8.15391 6.69643 8.3125 6 8.3125C5.30357 8.3125 4.64464 8.15391 4.04732 7.875H3.6C1.6125 7.875 0 9.52109 0 11.55V11.6106C0 12.3352 0.575893 12.9231 1.28571 12.9231H10.7143C11.4241 12.9231 12 12.3352 12 11.6106V11.55C12 9.52109 10.3875 7.875 8.4 7.875Z"
															fill="#A9A9A9"
														/>
													</svg>
													<Input
														required={true}
														type="text"
														value={personalInformation.userName ?? ''}
														_key={'userName'}
														onChange={handleInputChangeforPersonalDetails}
														name={'userName'}
														maxLength={50}
														disabled={true}
													/>
													<label>
														UserName<span style={{ color: 'red' }}>*</span>
													</label>
													<span className="svg-right-input">
														<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M12.7724 10.3237V12.7783C12.7724 13.1038 12.6431 13.4159 12.413 13.6461C12.183 13.8762 11.8709 14.0056 11.5455 14.0056H2.95735C2.63196 14.0056 2.3199 13.8762 2.08981 13.6461C1.85973 13.4159 1.73047 13.1038 1.73047 12.7783V4.18725C1.73047 3.86175 1.85973 3.54959 2.08981 3.31943C2.3199 3.08926 2.63196 2.95996 2.95735 2.95996H5.41111"
																stroke="#626262"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M8.17064 10.1997L13.9983 4.30875L11.4219 1.73145L5.59419 7.56106L5.41016 10.3225L8.17064 10.1997Z"
																stroke="#626262"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													</span>
												</div>
											</>
										</li>
									)}

									<li>
										<>
											<div className="input-field">
												<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M6 7C7.89375 7 9.42857 5.4332 9.42857 3.5C9.42857 1.5668 7.89375 0 6 0C4.10625 0 2.57143 1.5668 2.57143 3.5C2.57143 5.4332 4.10625 7 6 7ZM8.4 7.875H7.95268C7.35804 8.15391 6.69643 8.3125 6 8.3125C5.30357 8.3125 4.64464 8.15391 4.04732 7.875H3.6C1.6125 7.875 0 9.52109 0 11.55V11.6106C0 12.3352 0.575893 12.9231 1.28571 12.9231H10.7143C11.4241 12.9231 12 12.3352 12 11.6106V11.55C12 9.52109 10.3875 7.875 8.4 7.875Z"
														fill="#A9A9A9"
													/>
												</svg>
												<Input
													// onKeyDown={(event) => {
													// 	if (/\d/.test(event.key)) {
													// 		event.preventDefault();
													// 	}
													// }}
													onKeyDown={(event) => {
														// Allow letters and numbers, prevent other characters
														if (!/[a-zA-Z0-9]/.test(event.key)) {
															event.preventDefault();
														}
													}}
													required={true}
													type="text"
													value={personalInformation.firstName ?? ''}
													_key={'firstName'}
													onChange={handleInputChangeforPersonalDetails}
													name={'firstName'}
													maxLength={50}
												/>
												<label>
													FirstName<span style={{ color: 'red' }}>*</span>
												</label>
												<span className="svg-right-input">
													<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path
															d="M12.7724 10.3237V12.7783C12.7724 13.1038 12.6431 13.4159 12.413 13.6461C12.183 13.8762 11.8709 14.0056 11.5455 14.0056H2.95735C2.63196 14.0056 2.3199 13.8762 2.08981 13.6461C1.85973 13.4159 1.73047 13.1038 1.73047 12.7783V4.18725C1.73047 3.86175 1.85973 3.54959 2.08981 3.31943C2.3199 3.08926 2.63196 2.95996 2.95735 2.95996H5.41111"
															stroke="#626262"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M8.17064 10.1997L13.9983 4.30875L11.4219 1.73145L5.59419 7.56106L5.41016 10.3225L8.17064 10.1997Z"
															stroke="#626262"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
											</div>
											{isEdit && !personalInformation.firstName && (
												<div className="error">
													<p>This field is required. Please enter your firstName.</p>
												</div>
											)}
											{isEdit && personalInformation.firstName && personalInformation.firstName.length >= 50 && (
												<div className="error">
													<p>The firstName must be 50 characters or lower.</p>
												</div>
											)}
										</>
									</li>
									<li>
										<>
											<div className="input-field">
												<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M6 7C7.89375 7 9.42857 5.4332 9.42857 3.5C9.42857 1.5668 7.89375 0 6 0C4.10625 0 2.57143 1.5668 2.57143 3.5C2.57143 5.4332 4.10625 7 6 7ZM8.4 7.875H7.95268C7.35804 8.15391 6.69643 8.3125 6 8.3125C5.30357 8.3125 4.64464 8.15391 4.04732 7.875H3.6C1.6125 7.875 0 9.52109 0 11.55V11.6106C0 12.3352 0.575893 12.9231 1.28571 12.9231H10.7143C11.4241 12.9231 12 12.3352 12 11.6106V11.55C12 9.52109 10.3875 7.875 8.4 7.875Z"
														fill="#A9A9A9"
													/>
												</svg>
												<Input
													onKeyDown={(event) => {
														if (!/[a-zA-Z0-9]/.test(event.key)) {
															event.preventDefault();
														}
													}}
													required={true}
													type="text"
													value={personalInformation.lastName ?? ''}
													_key={'lastName'}
													onChange={handleInputChangeforPersonalDetails}
													name={'lastName'}
													maxLength={50}
												/>
												<label>
													LastName<span style={{ color: 'red' }}>*</span>
												</label>
												<span className="svg-right-input">
													<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path
															d="M12.7724 10.3237V12.7783C12.7724 13.1038 12.6431 13.4159 12.413 13.6461C12.183 13.8762 11.8709 14.0056 11.5455 14.0056H2.95735C2.63196 14.0056 2.3199 13.8762 2.08981 13.6461C1.85973 13.4159 1.73047 13.1038 1.73047 12.7783V4.18725C1.73047 3.86175 1.85973 3.54959 2.08981 3.31943C2.3199 3.08926 2.63196 2.95996 2.95735 2.95996H5.41111"
															stroke="#626262"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M8.17064 10.1997L13.9983 4.30875L11.4219 1.73145L5.59419 7.56106L5.41016 10.3225L8.17064 10.1997Z"
															stroke="#626262"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
											</div>
											{isEdit && (!personalInformation?.lastName || personalInformation.lastName === '') && (
												<div className="error">
													<p>This field is required. Please enter your lastName.</p>
												</div>
											)}

											{isEdit && typeof personalInformation.lastName === 'string' && personalInformation.lastName.length >= 50 && (
												<div className="error">
													<p>The lastName must be 50 characters or lower.</p>
												</div>
											)}
										</>
									</li>
									<li>
										<>
											<div className="input-field">
												<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clipPath="url(#clip0_2991_12857)">
														<path
															d="M13 11.7814C13 12.4542 12.4541 13.0001 11.7812 13.0001H1.21875C0.545898 13.0001 4.629e-08 12.4542 4.629e-08 11.7814V5.096C-5.09215e-05 4.91106 0.0419874 4.72854 0.122931 4.56225C0.203874 4.39596 0.321599 4.25028 0.467188 4.13623C1.09941 3.64112 1.62246 3.23741 4.63633 1.05127C5.06289 0.741508 5.91094 -0.00751527 6.5 0.000101922C7.08906 -0.0100543 7.93711 0.741508 8.36367 1.05127C11.3775 3.23741 11.9006 3.64112 12.5328 4.13623C12.6784 4.25028 12.7961 4.39596 12.8771 4.56225C12.958 4.72854 13.0001 4.91106 13 5.096V11.7814ZM11.3318 6.78956C11.2658 6.69561 11.1363 6.67276 11.0449 6.74131C10.466 7.17041 9.63574 7.77471 8.36367 8.69893C7.93711 9.01124 7.08906 9.76026 6.5 9.7501C5.91094 9.75772 5.06289 9.01124 4.63633 8.69893C3.36426 7.77471 2.53652 7.17041 1.95508 6.74131C1.86367 6.67276 1.73164 6.69561 1.66816 6.78956L1.43711 7.12471C1.4069 7.16807 1.39478 7.22148 1.40331 7.27363C1.41185 7.32577 1.44036 7.37254 1.48281 7.40401C2.06426 7.83311 2.89199 8.43741 4.15645 9.35655C4.67188 9.73233 5.59102 10.5702 6.5 10.5651C7.40645 10.5702 8.32812 9.73233 8.84355 9.35655C10.1105 8.43741 10.9357 7.83311 11.5172 7.40401C11.5596 7.37254 11.5882 7.32577 11.5967 7.27363C11.6052 7.22148 11.5931 7.16807 11.5629 7.12471L11.3318 6.78956Z"
															fill="#A9A9A9"
														/>
													</g>
													<defs>
														<clipPath id="clip0_2991_12857">
															<rect width="13" height="13" fill="white" />
														</clipPath>
													</defs>
												</svg>

												<Input
													disabled={!isEmailEdit}
													required={true}
													type="email"
													value={personalInformation.email ?? ''}
													_key="email"
													onChange={handleInputChangeforPersonalDetails}
													name="email"
												/>
												<label>
													Email<span style={{ color: 'red' }}>*</span>
												</label>
												<span
													className={`svg-right-input ${personalDetails && personalDetails.phoneNumber !== personalInformation.phoneNumber ? 'disabled' : ''}`}
													onClick={() => setIsEmailEdit(true)}
												>
													<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path
															d="M12.7724 10.3237V12.7783C12.7724 13.1038 12.6431 13.4159 12.413 13.6461C12.183 13.8762 11.8709 14.0056 11.5455 14.0056H2.95735C2.63196 14.0056 2.3199 13.8762 2.08981 13.6461C1.85973 13.4159 1.73047 13.1038 1.73047 12.7783V4.18725C1.73047 3.86175 1.85973 3.54959 2.08981 3.31943C2.3199 3.08926 2.63196 2.95996 2.95735 2.95996H5.41111"
															stroke="#626262"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M8.17064 10.1997L13.9983 4.30875L11.4219 1.73145L5.59419 7.56106L5.41016 10.3225L8.17064 10.1997Z"
															stroke="#626262"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
											</div>
											{isEdit && !personalInformation.email ? (
												<p className="error">This field is required. Please enter your email address.</p>
											) : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(personalInformation.email ?? '') ? (
												<p className="error">Please enter a valid email address.</p>
											) : null}
										</>
									</li>
									<li>
										<>
											<div className="input-field">
												<PhoneInputComponent
													value={personalInformation.phoneNumber ?? ''}
													inputProps={{
														name: 'phoneNumber',
														required: true,
														disabled: personalDetails?.isInstaUser && !personalDetails.phoneNumber && !personalDetails.email ? false : isEmailEdit,
													}}
													onChange={(value, data, event, formattedValue) => handleInputChangeforPersonalDetails(event)}
													disableDropdown={
														personalDetails?.isInstaUser && !personalDetails.phoneNumber && !personalDetails.email ? false : isEmailEdit
													}
												/>
												<label style={{ position: 'absolute', top: '0', left: '40px', backgroundColor: 'white' }}>
													{/* <span className="whtsap_icon_cstm ">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															xmlnsXlink="http://www.w3.org/1999/xlink"
															height="800px"
															width="800px"
															version="1.1"
															id="Capa_1"
															viewBox="0 0 58 58"
															xmlSpace="preserve"
														>
															<g>
																<path
																	style={{ fill: 'green' }}
																	d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5   S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z"
																></path>
																<path
																	style={{ fill: 'white' }}
																	d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42   c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242   c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169   c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097   c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z"
																></path>
															</g>
														</svg>
													</span> */}
													Phone<span style={{ color: 'red' }}>*</span>
												</label>
												{phoneError && <div className="error">{phoneError}</div>}
												<span
													className={`svg-right-input ${personalDetails && personalDetails.email !== personalInformation.email ? 'disabled' : ''}`}
													onClick={() => setIsEmailEdit(false)}
												>
													<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path
															d="M12.7724 10.3237V12.7783C12.7724 13.1038 12.6431 13.4159 12.413 13.6461C12.183 13.8762 11.8709 14.0056 11.5455 14.0056H2.95735C2.63196 14.0056 2.3199 13.8762 2.08981 13.6461C1.85973 13.4159 1.73047 13.1038 1.73047 12.7783V4.18725C1.73047 3.86175 1.85973 3.54959 2.08981 3.31943C2.3199 3.08926 2.63196 2.95996 2.95735 2.95996H5.41111"
															stroke="#626262"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M8.17064 10.1997L13.9983 4.30875L11.4219 1.73145L5.59419 7.56106L5.41016 10.3225L8.17064 10.1997Z"
															stroke="#626262"
															strokeWidth="1.5"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</span>
											</div>
											{isEdit && !personalInformation.phoneNumber && (
												<div className="error">
													<p>Phone number is required. Please enter a 10-digit number.</p>
												</div>
											)}
										</>
									</li>
								</ul>
								{!personalInformation?.isFacebookUser && !personalInformation?.isGoogleUser && !personalInformation?.isInstaUser && (
									<p className="change-password-link">
										<Link
											to=""
											onClick={async () => {
												setIsChangePassword(true);
												await getOTP();
											}}
										>
											Change Password
										</Link>
									</p>
								)}
							</div>
						</>
					) : (
						<>
							<div className="right-profile-info">
								<ul>
									{personalInformation.userName && (
										<li>
											<>
												<span className="icon-profile">
													<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path
															d="M9 10C11.8406 10 14.1429 7.76172 14.1429 5C14.1429 2.23828 11.8406 0 9 0C6.15938 0 3.85714 2.23828 3.85714 5C3.85714 7.76172 6.15938 10 9 10ZM12.6 11.25H11.929C11.0371 11.6484 10.0446 11.875 9 11.875C7.95536 11.875 6.96696 11.6484 6.07098 11.25H5.4C2.41875 11.25 0 13.6016 0 16.5V16.5865C0 17.6217 0.863839 18.4615 1.92857 18.4615H16.0714C17.1362 18.4615 18 17.6217 18 16.5865V16.5C18 13.6016 15.5812 11.25 12.6 11.25Z"
															fill="#941AF2"
														/>
													</svg>
												</span>
												<label>UserName: {personalInformation.userName}</label>
											</>
										</li>
									)}

									<li>
										<>
											<span className="icon-profile">
												<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M9 10C11.8406 10 14.1429 7.76172 14.1429 5C14.1429 2.23828 11.8406 0 9 0C6.15938 0 3.85714 2.23828 3.85714 5C3.85714 7.76172 6.15938 10 9 10ZM12.6 11.25H11.929C11.0371 11.6484 10.0446 11.875 9 11.875C7.95536 11.875 6.96696 11.6484 6.07098 11.25H5.4C2.41875 11.25 0 13.6016 0 16.5V16.5865C0 17.6217 0.863839 18.4615 1.92857 18.4615H16.0714C17.1362 18.4615 18 17.6217 18 16.5865V16.5C18 13.6016 15.5812 11.25 12.6 11.25Z"
														fill="#941AF2"
													/>
												</svg>
											</span>
											<label>FirstName: {personalInformation.firstName}</label>
										</>
									</li>
									<li>
										<>
											<span className="icon-profile">
												<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M9 10C11.8406 10 14.1429 7.76172 14.1429 5C14.1429 2.23828 11.8406 0 9 0C6.15938 0 3.85714 2.23828 3.85714 5C3.85714 7.76172 6.15938 10 9 10ZM12.6 11.25H11.929C11.0371 11.6484 10.0446 11.875 9 11.875C7.95536 11.875 6.96696 11.6484 6.07098 11.25H5.4C2.41875 11.25 0 13.6016 0 16.5V16.5865C0 17.6217 0.863839 18.4615 1.92857 18.4615H16.0714C17.1362 18.4615 18 17.6217 18 16.5865V16.5C18 13.6016 15.5812 11.25 12.6 11.25Z"
														fill="#941AF2"
													/>
												</svg>
											</span>
											<label>LastName: {personalInformation.lastName}</label>
										</>
									</li>
									<li>
										<>
											<span className="icon-profile">
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clipPath="url(#clip0_2991_11983)">
														<path
															d="M18 16.3126C18 17.2443 17.2441 18.0001 16.3125 18.0001H1.6875C0.755859 18.0001 6.40939e-08 17.2443 6.40939e-08 16.3126V7.056C-7.05067e-05 6.79993 0.0581364 6.5472 0.170212 6.31696C0.282287 6.08672 0.44529 5.885 0.646875 5.72709C1.52227 5.04155 2.24648 4.48256 6.41953 1.45561C7.01016 1.0267 8.18437 -0.0104058 9 0.000141123C9.81563 -0.0139214 10.9898 1.0267 11.5805 1.45561C15.7535 4.48256 16.4777 5.04155 17.3531 5.72709C17.5547 5.885 17.7177 6.08672 17.8298 6.31696C17.9419 6.5472 18.0001 6.79993 18 7.056V16.3126ZM15.6902 9.40092C15.5988 9.27084 15.4195 9.2392 15.293 9.33413C14.4914 9.92827 13.3418 10.765 11.5805 12.0447C10.9898 12.4771 9.81563 13.5142 9 13.5001C8.18437 13.5107 7.01016 12.4771 6.41953 12.0447C4.6582 10.765 3.51211 9.92827 2.70703 9.33413C2.58047 9.2392 2.39766 9.27084 2.30977 9.40092L1.98984 9.86499C1.94802 9.92501 1.93124 9.99898 1.94305 10.0712C1.95487 10.1434 1.99435 10.2081 2.05313 10.2517C2.8582 10.8458 4.0043 11.6826 5.75508 12.9552C6.46875 13.4755 7.74141 14.6357 9 14.6287C10.2551 14.6357 11.5312 13.4755 12.2449 12.9552C13.9992 11.6826 15.1418 10.8458 15.9469 10.2517C16.0056 10.2081 16.0451 10.1434 16.0569 10.0712C16.0688 9.99898 16.052 9.92501 16.0102 9.86499L15.6902 9.40092Z"
															fill="#941AF2"
														/>
													</g>
													<defs>
														<clipPath id="clip0_2991_11983">
															<rect width="18" height="18" fill="white" />
														</clipPath>
													</defs>
												</svg>
											</span>
											<label>Email: {personalInformation.email}</label>
										</>
									</li>
									<li>
										<>
											<span className="icon-profile">
												<span className="whtsap_icon_cstm ">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														xmlnsXlink="http://www.w3.org/1999/xlink"
														height="800px"
														width="800px"
														version="1.1"
														id="Capa_1"
														viewBox="0 0 58 58"
														xmlSpace="preserve"
													>
														<g>
															<path
																style={{ fill: '#a300ec' }}
																d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5   S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z"
															></path>
															<path
																style={{ fill: 'white' }}
																d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42   c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242   c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169   c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097   c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z"
															></path>
														</g>
													</svg>
												</span>
											</span>
											<label>Phone: {personalInformation.phoneNumber}</label>
										</>
									</li>
								</ul>
							</div>
						</>
					)}
				</div>
			</div>
			{isChangePassword && (
				<ModalComponent
					show={isChangePassword}
					className="share-modal otp-modal"
					handleClose={() => {
						setSecondsLeft(20);
						setIsChangePassword(false);
					}}
					title=""
				>
					{' '}
					<div className="otp-page">
						<div className="heading-otp-popup">
							{personalInformation && (
								<>
									<svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_3033_11283)">
											<path
												d="M13.1289 28.3543L14.078 25.5417L18.8383 27.4003V22.21H21.8042V27.4003L26.52 25.5417L27.5581 28.3543L22.6149 30.0004L25.645 34.0637L23.3514 35.7543L20.2669 31.513L17.301 35.7543L14.8788 34.0637L17.909 30.0004L13.1289 28.3543Z"
												fill="url(#paint0_linear_3033_11283)"
											/>
											<path
												d="M29.1641 28.3543L30.1181 25.5417L34.8784 27.4003V22.21H37.8196V27.4003L42.5107 25.5417L43.5487 28.3543L38.6055 30.0004L41.6357 34.0637L39.3421 35.7543L36.2822 31.513L33.3163 35.7543L30.8942 34.0637L33.9243 30.0004L29.1641 28.3543Z"
												fill="url(#paint1_linear_3033_11283)"
											/>
											<path
												d="M0.862524 34.1628V34.1633C0.856098 37.5621 2.19813 40.8247 4.59419 43.2352C6.99025 45.6458 10.2447 47.0075 13.6434 47.0215H34.8287C35.2079 47.0215 35.5717 46.8708 35.8399 46.6027C36.1081 46.3345 36.2588 45.9707 36.2588 45.5914C36.2588 45.2121 36.1081 44.8484 35.8399 44.5802C35.5717 44.312 35.208 44.1613 34.8287 44.1613H13.6422H13.6407C11.0003 44.1478 8.47311 43.0874 6.61372 41.2127C4.75444 39.3381 3.71474 36.8025 3.72275 34.1623V24.1532C3.71607 21.5139 4.75636 18.9795 6.61552 17.1061C8.47476 15.2325 11.0012 14.1727 13.6407 14.1593L13.6422 14.1592L73.3509 14.1593H73.3523C75.9926 14.1714 78.5203 15.2305 80.3807 17.1043C82.2408 18.9778 83.2818 21.5127 83.2753 24.1528C83.2753 24.1531 83.2752 24.1533 83.2752 24.1536M0.862524 34.1628L85.9287 34.3431C85.6043 34.6676 85.1642 34.8499 84.7054 34.8499C84.2465 34.8499 83.8064 34.6676 83.482 34.3431C83.1575 34.0187 82.9753 33.5786 82.9753 33.1198V24.1528L83.2752 24.1536M0.862524 34.1628L0.862524 24.1528L0.862523 24.1524C0.857379 20.7544 2.19998 17.4931 4.5959 15.0836C6.99167 12.6742 10.2451 11.3132 13.6428 11.299M0.862524 34.1628L13.6422 10.999M83.2752 24.1536L83.2753 33.1198C83.2753 33.4991 83.4259 33.8628 83.6941 34.131C83.9623 34.3992 84.3261 34.5499 84.7054 34.5499C85.0847 34.5499 85.4484 34.3992 85.7166 34.131C85.9848 33.8628 86.1355 33.4991 86.1355 33.1198V24.1528V24.1524C86.1406 20.7536 84.7974 17.4915 82.4004 15.0819C80.0036 12.6724 76.7489 11.312 73.3503 11.299M83.2752 24.1536L73.3503 11.299M73.3503 11.299H73.3509V10.999M73.3503 11.299L86.4355 24.1528C86.4407 20.6746 85.0661 17.3363 82.6131 14.8703C80.1601 12.4044 76.8291 11.0121 73.3509 10.999M73.3503 11.299C73.3501 11.299 73.3499 11.299 73.3497 11.299L73.3509 10.999M73.3503 11.299H13.6428M73.3509 10.999H13.6422M13.6422 10.999L13.6434 11.299C13.6432 11.299 13.643 11.299 13.6428 11.299M13.6422 10.999V11.299H13.6428"
												fill="url(#paint2_linear_3033_11283)"
												stroke="#F7F5FE"
												strokeWidth="0.6"
											/>
											<path
												d="M58.8257 63.1495C58.2355 63.1484 57.6516 63.0284 57.1088 62.7965C56.5661 62.5646 56.0757 62.2257 55.667 61.8L50.2295 56.1796C50.0476 56.0229 49.9004 55.83 49.7972 55.6133C49.6939 55.3966 49.6369 55.1608 49.6297 54.9208C49.6225 54.6809 49.6653 54.4421 49.7555 54.2196C49.8456 53.9971 49.981 53.7958 50.1531 53.6285C50.3253 53.4611 50.5303 53.3315 50.7553 53.2477C50.9803 53.164 51.2202 53.1279 51.4598 53.1419C51.6995 53.1559 51.9336 53.2196 52.1473 53.329C52.361 53.4383 52.5496 53.591 52.7011 53.7772L58.1386 59.3927C58.2323 59.4925 58.3469 59.5705 58.4742 59.6209C58.6015 59.6713 58.7384 59.693 58.8751 59.6843C59.015 59.6708 59.1504 59.6277 59.2722 59.5578C59.3941 59.4879 59.4997 59.3929 59.582 59.279L70.0961 44.7757C70.3649 44.4033 70.7705 44.153 71.2239 44.0798C71.6772 44.0066 72.141 44.1164 72.5133 44.3852C72.8857 44.6539 73.136 45.0596 73.2092 45.5129C73.2824 45.9662 73.1726 46.43 72.9038 46.8024L62.4045 61.2958C62.0281 61.8185 61.5419 62.2526 60.98 62.5677C60.4182 62.8828 59.7943 63.0712 59.1519 63.1198L58.8257 63.1495Z"
												fill="url(#paint3_linear_3033_11283)"
											/>
											<path
												d="M62.33 31.4005H62.3297C58.2317 31.4005 54.2256 32.6157 50.8182 34.8925C47.4107 37.1692 44.755 40.4053 43.1867 44.1914C41.6184 47.9776 41.2081 52.1437 42.0076 56.1631C42.8071 60.1824 44.7805 63.8744 47.6783 66.7722C50.5761 69.67 54.2681 71.6434 58.2874 72.4429C62.3068 73.2424 66.4729 72.8321 70.2591 71.2638C74.0452 69.6955 77.2812 67.0398 79.558 63.6323C81.8348 60.2249 83.05 56.2188 83.05 52.1208V52.1205C83.0447 46.6268 80.86 41.3597 76.9754 37.4751C73.0908 33.5905 67.8237 31.4058 62.33 31.4005ZM62.3294 75.7013C57.6657 75.7012 53.1068 74.3182 49.2291 71.7272C45.3513 69.1362 42.3289 65.4534 40.5442 61.1446C38.7594 56.8359 38.2925 52.0946 39.2023 47.5204C40.1122 42.9463 42.358 38.7446 45.6558 35.4468C48.9536 32.149 53.1552 29.9032 57.7294 28.9933C62.3036 28.0835 67.0448 28.5505 71.3536 30.3352C75.6624 32.12 79.3452 35.1423 81.9362 39.0201C84.5272 42.8978 85.9102 47.4567 85.9103 52.1204C85.9025 58.372 83.4156 64.3654 78.995 68.786C74.5744 73.2066 68.581 75.6935 62.3294 75.7013Z"
												fill="url(#paint4_linear_3033_11283)"
												stroke="#F7F5FE"
												strokeWidth="0.6"
											/>
										</g>
										<defs>
											<linearGradient
												id="paint0_linear_3033_11283"
												x1="13.1289"
												y1="28.9821"
												x2="27.5581"
												y2="28.9821"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<linearGradient
												id="paint1_linear_3033_11283"
												x1="29.1641"
												y1="28.9821"
												x2="43.5487"
												y2="28.9821"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<linearGradient id="paint2_linear_3033_11282" x1="0.5625" y1="29.1603" x2="86.4355" y2="29.1603" gradientUnits="userSpaceOnUse">
												<stop stopColor="#941AF3" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<linearGradient
												id="paint3_linear_3033_11283"
												x1="49.6289"
												y1="53.6035"
												x2="73.2314"
												y2="53.6035"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<linearGradient
												id="paint4_linear_3033_11283"
												x1="38.4492"
												y1="52.1208"
												x2="86.2103"
												y2="52.1208"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<clipPath id="clip0_3033_11283">
												<rect width="87" height="87" fill="white" />
											</clipPath>
										</defs>
									</svg>

									<h6>
										A verification code has been sent to your{' '}
										<span className="whtsap_icon_cstm ">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												xmlnsXlink="http://www.w3.org/1999/xlink"
												height="800px"
												width="800px"
												version="1.1"
												id="Capa_1"
												viewBox="0 0 58 58"
												xmlSpace="preserve"
											>
												<g>
													<path
														style={{ fill: 'green' }}
														d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5   S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z"
													></path>
													<path
														style={{ fill: 'white' }}
														d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42   c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242   c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169   c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097   c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z"
													></path>
												</g>
											</svg>
										</span>{' '}
										mobile number XXXXXX
										{personalInformation.phoneNumber ? personalInformation.phoneNumber.toString().slice(-4) : null} and email address{' '}
										{personalInformation?.email ? personalInformation.email.replace(/^.{4}/, 'xxxx') : null}. Please enter to verify.
									</h6>
								</>
							)}
						</div>
						<div className="time-resend-box-otp">
							<p>{secondsLeft > 0 ? `${secondsLeft} Sec. left to ask for new OTP` : 'You can now resend the OTP.'}</p>
							{secondsLeft === 0 && ( // Only display if secondsLeft is 0
								<Link
									to=""
									onClick={async () => {
										setSecondsLeft(20);
										await getOTP();
									}}
									className={isResetLinkDisabled ? 'disabled-link' : ''}
								>
									Resend OTP
								</Link>
							)}
						</div>
						<div className="input-boxes-top" onPaste={handlePaste}>
							{otp &&
								otp.map((value, index) => {
									return (
										<Input
											ref={(el) => (inputRefs.current[index] = el)} // Assign the ref to manage focus
											key={index}
											type="text" // Use type="text" to allow for better control over input
											value={value}
											onChange={(event: ChangeEvent<HTMLInputElement>) => handleOtpChange(index, event.target.value)}
											maxLength={1} // Limit each input to one character
											onKeyDown={(e) => handleKeyDown(e, index)}
											_key={''}
											name={''}
										/>
									);
								})}
						</div>
						<div className="verify-button-otp">
							{
								<Button
									disabled={otp && otp.some((val) => typeof val === 'string' && !val?.trim())}
									className="cmn-btn"
									label="Verify"
									id={''}
									onClick={() => {
										setIsChangePassword(false);
										ChangePassword();
									}}
								/>
							}
						</div>
					</div>
				</ModalComponent>
			)}

			{resetPassowrd && (
				<ModalComponent show={resetPassowrd} className="share-modal" handleClose={() => setIsResetPassword(false)} title="">
					{loading && <Loader />}
					<div className="box-body-share">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={(values) => {
								handleSubmitClick(values);
							}}
						>
							{({ values, errors, touched, isValid, dirty }) => {
								return (
									<Form>
										<div className={`confirm-password`} id="login" role="tabpanel" aria-labelledby="login-tab">
											<div className="confirm-password-popup">
												<label>
													<svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path
															d="M3 12.002C2.73478 12.002 2.48043 12.1073 2.29289 12.2948C2.10536 12.4824 2 12.7367 2 13.002V25.002C2 25.2672 2.10536 25.5215 2.29289 25.7091C2.48043 25.8966 2.73478 26.002 3 26.002H21C21.2652 26.002 21.5196 25.8966 21.7071 25.7091C21.8946 25.5215 22 25.2672 22 25.002V13.002C22 12.7367 21.8946 12.4824 21.7071 12.2948C21.5196 12.1073 21.2652 12.002 21 12.002H3ZM3 10.002H21C21.7956 10.002 22.5587 10.318 23.1213 10.8806C23.6839 11.4432 24 12.2063 24 13.002V25.002C24 25.7976 23.6839 26.5607 23.1213 27.1233C22.5587 27.6859 21.7956 28.002 21 28.002H3C2.20435 28.002 1.44129 27.6859 0.87868 27.1233C0.316071 26.5607 0 25.7976 0 25.002V13.002C0 12.2063 0.316071 11.4432 0.87868 10.8806C1.44129 10.318 2.20435 10.002 3 10.002Z"
															fill="#941AF2"
														/>
														<path
															d="M12.0039 15C12.2691 15 12.5235 15.1054 12.711 15.2929C12.8986 15.4804 13.0039 15.7348 13.0039 16V22C13.0039 22.2652 12.8986 22.5196 12.711 22.7071C12.5235 22.8946 12.2691 23 12.0039 23C11.7387 23 11.4843 22.8946 11.2968 22.7071C11.1093 22.5196 11.0039 22.2652 11.0039 22V16C11.0039 15.7348 11.1093 15.4804 11.2968 15.2929C11.4843 15.1054 11.7387 15 12.0039 15ZM18.0039 10V8C18.0039 6.4087 17.3718 4.88258 16.2465 3.75736C15.1213 2.63214 13.5952 2 12.0039 2C10.4126 2 8.88648 2.63214 7.76127 3.75736C6.63605 4.88258 6.00391 6.4087 6.00391 8V10H18.0039ZM12.0039 0C14.1256 0 16.1605 0.842855 17.6608 2.34315C19.1611 3.84344 20.0039 5.87827 20.0039 8V12H4.00391V8C4.00391 5.87827 4.84676 3.84344 6.34705 2.34315C7.84734 0.842855 9.88217 0 12.0039 0Z"
															fill="#941AF2"
														/>
													</svg>
													Change Password
												</label>
												<div className="signup-login-form">
													<div className="form-box">
														<div className="input-field">
															<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g clipPath="url(#clip0_853_5915)">
																	<path
																		d="M10.7143 5.6875H10.0714V3.85938C10.0714 1.73164 8.24464 0 6 0C3.75536 0 1.92857 1.73164 1.92857 3.85938V5.6875H1.28571C0.575893 5.6875 0 6.2334 0 6.90625V11.7812C0 12.4541 0.575893 13 1.28571 13H10.7143C11.4241 13 12 12.4541 12 11.7812V6.90625C12 6.2334 11.4241 5.6875 10.7143 5.6875ZM7.92857 5.6875H4.07143V3.85938C4.07143 2.85137 4.93661 2.03125 6 2.03125C7.06339 2.03125 7.92857 2.85137 7.92857 3.85938V5.6875Z"
																		fill="#C1C1C1"
																	/>
																</g>
																<defs>
																	<clipPath id="clip0_853_5915">
																		<rect width="12" height="13" fill="white" />
																	</clipPath>
																</defs>
															</svg>
															<Field
																autoComplete="new-password"
																name="password"
																type={showPasswords.password ? 'text' : 'password'}
																placeholder=""
																className={values && values.password && 'input-value'}
															/>
															<label>
																Password <span style={{ color: 'red' }}>*</span>
															</label>
															<Button
																type="button"
																onClick={() => togglePasswordVisibility('password')}
																className="password-toggle"
																style={{
																	position: 'absolute',
																	right: '10px',
																	top: '50%',
																	transform: 'translateY(-50%)',
																	background: 'none',
																	border: 'none',
																	cursor: 'pointer',
																}}
																id={'pswrd'}
																label={''}
															>
																{showPasswords.password ? (
																	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M10 4.37C3.75 4.37 1.25 10 1.25 10C1.25 10 3.75 15.63 10 15.63C16.25 15.63 18.75 10 18.75 10C18.75 10 16.25 4.37 10 4.37Z"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M10 13.13C11.7259 13.13 13.13 11.7259 13.13 10C13.13 8.27411 11.7259 6.87 10 6.87C8.27411 6.87 6.87 8.27411 6.87 10C6.87 11.7259 8.27411 13.13 10 13.13Z"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																) : (
																	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M12.1083 7.89166L7.89166 12.1083C7.35 11.5667 7.01666 10.825 7.01666 10C7.01666 8.35 8.35 7.01666 10 7.01666C10.825 7.01666 11.5667 7.35 12.1083 7.89166Z"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M14.85 4.80834C13.3917 3.70834 11.725 3.10834 10 3.10834C7.05833 3.10834 4.31666 4.84167 2.40833 7.84167C1.65833 9.01667 1.65833 10.9917 2.40833 12.1667C3.06666 13.2 3.83333 14.0917 4.66666 14.8083"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.01666 16.2750C7.96666 16.6750 8.975 16.8917 10 16.8917C12.9417 16.8917 15.6833 15.1583 17.5917 12.1583C18.3417 10.9833 18.3417 9.00833 17.5917 7.83333C17.3167 7.40000 17.0167 6.99166 16.7083 6.60833"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M12.925 10.5833C12.7083 11.7583 11.75 12.7167 10.575 12.9333"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.89166 12.1083L1.66666 18.3333"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M18.3333 1.66666L12.1083 7.89166"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																)}
															</Button>
														</div>
														{errors.password && touched.password ? <div className="error">{errors.password}</div> : null}
													</div>
													<div className="form-box">
														<div className="input-field">
															<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																<g clipPath="url(#clip0_853_5915)">
																	<path
																		d="M10.7143 5.6875H10.0714V3.85938C10.0714 1.73164 8.24464 0 6 0C3.75536 0 1.92857 1.73164 1.92857 3.85938V5.6875H1.28571C0.575893 5.6875 0 6.2334 0 6.90625V11.7812C0 12.4541 0.575893 13 1.28571 13H10.7143C11.4241 13 12 12.4541 12 11.7812V6.90625C12 6.2334 11.4241 5.6875 10.7143 5.6875ZM7.92857 5.6875H4.07143V3.85938C4.07143 2.85137 4.93661 2.03125 6 2.03125C7.06339 2.03125 7.92857 2.85137 7.92857 3.85938V5.6875Z"
																		fill="#C1C1C1"
																	/>
																</g>
																<defs>
																	<clipPath id="clip0_853_5915">
																		<rect width="12" height="13" fill="white" />
																	</clipPath>
																</defs>
															</svg>
															<Field
																autoComplete="new-password"
																name="confirmPassword"
																type={showPasswords.confirmPassword ? 'text' : 'password'}
																placeholder=""
																className={values && values.confirmPassword && 'input-value'}
															/>
															<label>
																Confirm Password <span style={{ color: 'red' }}>*</span>
															</label>
															<Button
																type="button"
																onClick={() => togglePasswordVisibility('confirmPassword')}
																className="password-toggle"
																style={{
																	position: 'absolute',
																	right: '10px',
																	top: '50%',
																	transform: 'translateY(-50%)',
																	background: 'none',
																	border: 'none',
																	cursor: 'pointer',
																}}
																id={'pswrd'}
																label={''}
															>
																{showPasswords.confirmPassword ? (
																	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M10 4.37C3.75 4.37 1.25 10 1.25 10C1.25 10 3.75 15.63 10 15.63C16.25 15.63 18.75 10 18.75 10C18.75 10 16.25 4.37 10 4.37Z"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M10 13.13C11.7259 13.13 13.13 11.7259 13.13 10C13.13 8.27411 11.7259 6.87 10 6.87C8.27411 6.87 6.87 8.27411 6.87 10C6.87 11.7259 8.27411 13.13 10 13.13Z"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																) : (
																	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M12.1083 7.89166L7.89166 12.1083C7.35 11.5667 7.01666 10.825 7.01666 10C7.01666 8.35 8.35 7.01666 10 7.01666C10.825 7.01666 11.5667 7.35 12.1083 7.89166Z"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M14.85 4.80834C13.3917 3.70834 11.725 3.10834 10 3.10834C7.05833 3.10834 4.31666 4.84167 2.40833 7.84167C1.65833 9.01667 1.65833 10.9917 2.40833 12.1667C3.06666 13.2 3.83333 14.0917 4.66666 14.8083"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.01666 16.2750C7.96666 16.6750 8.975 16.8917 10 16.8917C12.9417 16.8917 15.6833 15.1583 17.5917 12.1583C18.3417 10.9833 18.3417 9.00833 17.5917 7.83333C17.3167 7.40000 17.0167 6.99166 16.7083 6.60833"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M12.925 10.5833C12.7083 11.7583 11.75 12.7167 10.575 12.9333"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.89166 12.1083L1.66666 18.3333"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M18.3333 1.66666L12.1083 7.89166"
																			stroke="#C1C1C1"
																			strokeWidth="1.5"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																)}
															</Button>
														</div>
														{errors.confirmPassword && touched.confirmPassword ? <div className="error">{errors.confirmPassword}</div> : null}
													</div>
													<div className="confirm-password-btn">
														<Button disabled={!(isValid && dirty)} id="loginNow" label="Save password" className="cmn-btn" type="submit" />
													</div>
												</div>
											</div>
										</div>
									</Form>
								);
							}}
						</Formik>
					</div>
				</ModalComponent>
			)}
		</>
	);
};

export default ProfileDetails;

import { Link } from 'react-router-dom';
import './Topbar.css';
import { SidebarMenuEnum } from '../../types/enums/SideBarEnums';
import imgPreview from '../../assets/images/img-preview.png';
import imgNoti from '../../assets/images/img-noti.png';
import { useEffect, useRef, useState } from 'react';
import Shepherd from 'shepherd.js';
import { getTourSteps } from '../../helpers/TakeaTour/TakeaTour';
import 'shepherd.js/dist/css/shepherd.css';
import styles from '../../helpers/TakeaTour/Tour.module.css';
import { IoIosArrowForward, IoMdArrowRoundForward, IoMdArrowRoundBack } from 'react-icons/io';
import { Project } from '../../types/models/ProjectModel';
import UserAvatar from '../../assets/images/user-avatar.png';
import Button from '../button/Index';
import Image from '../image/Index';
import { Notification } from '../../types/models/Notification';
import ApiService from '../../helpers/api/Index';
import IAPIResponseModel from '../../types/interfaces/IAPIResponseModel';
import { showToast } from '../toaster/Index';
import { UserProfileModel } from '../../types/models/PersonalDetailsModel';
import eventEmitter, { enablePointerEvents } from '../../helpers/Utils';
import moment from 'moment-timezone';
interface TopBarProps {
	activeTabHeader: SidebarMenuEnum;
	projects: Project[];
	toggleProfileDetailsSideBox: (isOpen: boolean, type: string) => void;
	isUserProfileChange: boolean;
	isTourDisabled: boolean;
}

const TopBar: React.FC<TopBarProps> = ({ activeTabHeader, projects, toggleProfileDetailsSideBox, isUserProfileChange, isTourDisabled }) => {
	const [isVisible, isSetVisible] = useState(false);
	const [tour, setTour] = useState<Shepherd.Tour | null>(null);
	const [isTourActive, setIsTourActive] = useState(false);
	const [currentStepIndex, setCurrentStepIndex] = useState(0); // Track current step
	const [totalSteps, setTotalSteps] = useState(0); // Track total steps
	const [notifications, setNotifications] = useState<Notification[]>();
	const [userProfile, setUserProfile] = useState<UserProfileModel>();
	const notificationRef = useRef<HTMLDivElement>(null);
	const isProjectNull = !projects || projects.length === 0;
	/**
	 * Toggles the visibility of a div element.
	 */
	const toggleDiv = () => {
		isSetVisible(!isVisible);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
				isSetVisible(false); // Close the notification panel
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [notificationRef]);

	/**
	 * Initializes and starts a new Shepherd tour.
	 * Resets the tour state and adds steps to the tour.
	 * Listens for step changes and updates the current step index.
	 */
	const startTour = () => {
		setCurrentStepIndex(0);
		setTotalSteps(0);
		setIsTourActive(false);

		const newTour = new Shepherd.Tour({
			useModalOverlay: true,
			defaultStepOptions: {
				cancelIcon: {
					enabled: false, // Disabling the default cancel icon
				},
				scrollTo: { behavior: 'smooth', block: 'center' },
			},
		});

		// Get all tour steps
		let steps = getTourSteps(newTour);

		// Normalize attachTo element to always be a string
		steps = steps.map((step) => {
			if (step.attachTo?.element instanceof HTMLElement) {
				throw new Error("HTMLElement passed to 'attachTo.element'. Use a valid CSS selector instead.");
			}
			return step;
		});

		// If there are no projects, filter steps to show only specific steps
		if (!projects || projects.length === 0) {
			steps = steps.filter((step) => step.id && ['step-1', 'step-2', 'step-5', 'step-11'].includes(step.id));
		}

		steps.forEach((step) => newTour.addStep(step));

		// Set the total number of steps
		setTotalSteps(steps.length);

		const cleanupTourState = () => {
			setIsTourActive(false); // Hide buttons
			// Re-enable pointer events for all elements affected by the tour
			steps.forEach((step) => {
				if (step.attachTo?.element && typeof step.attachTo.element === 'string') {
					enablePointerEvents(step.attachTo.element);
				}
			});
		};

		newTour.on('complete', cleanupTourState);
		newTour.on('cancel', cleanupTourState);

		// Listen for step changes
		newTour.on('show', () => {
			const currentStep = newTour.currentStep;
			if (currentStep) {
				setCurrentStepIndex(newTour.steps.indexOf(currentStep) + 1);
			}
		});

		setTour(newTour);
		newTour.start();

		// Set tour active state
		setIsTourActive(true);
	};

	/**
	 * Skips and completes the current tour.
	 * Calls the `complete` method on the Shepherd tour instance.
	 */
	const handleSkipTour = () => {
		if (tour) {
			tour.cancel(); // Ends the tour and triggers cleanup logic
		}
	};

	/**
	 * Advances to the next step in the Shepherd tour.
	 * Calls the `next` method on the Shepherd tour instance.
	 */
	const handleNextStep = () => {
		if (tour) {
			tour.next(); // Go to the next step
		}
	};

	/**
	 * Moves back to the previous step in the Shepherd tour.
	 * Calls the `back` method on the Shepherd tour instance.
	 * Updates the current step index to reflect the previous step.
	 */
	const handlePreviousStep = () => {
		if (tour && currentStepIndex > 0) {
			tour.back(); // Go to the previous step
			const newIndex = currentStepIndex - 1;
			if (newIndex >= 0) {
				setCurrentStepIndex(newIndex);
			}
		}
	};

	/**
	 * Returns the appropriate label for the tour button.
	 * If the current step is the last one, the label will be "Finish tour".
	 * Otherwise, it will be "Skip tour".
	 * @returns The tour button label as a string.
	 */
	const getTourButtonLabel = () => {
		// Show "Finish tour" only on the last step
		if (currentStepIndex === totalSteps - 1) {
			return 'Finish tour';
		}

		// Default to "Skip tour" for other steps
		return 'Skip tour';
	};
	const getMyProfile = async () => {
		// Call the API to retrieve the user's profile data
		await ApiService.myProfile()
			.then((result) => {
				// Check if the API call was successful
				if (result.isSuccess) {
					// Update the state with the response data if successful
					setUserProfile(result.response);
				} else {
					// Show an error message if the API call was not successful
					showToast(result.error.userMessage ?? 'Something went wrong', { type: 'error' });
				}
			})
			.catch((error) => {
				// Handle any errors that occur during the API call
				showToast(error.message ?? 'Something went wrong', { type: 'error' });
			});
	};
	/*This useEffect hook is designed to trigger the getMyProfile function whenever the userProfiles prop changes.
	It ensures that the user's profile data is fetched only when userProfiles is available*/
	useEffect(() => {
		getMyProfile();
	}, [isUserProfileChange]); // Dependency array: runs effect when userProfiles changes

	const fetchNotifications = async () => {
		try {
			const res: IAPIResponseModel<Notification[]> = await ApiService.getAllnotifications();
			if (res.isSuccess) {
				setNotifications(res.response);
			}
		} catch (error) {
			setNotifications([]);
		}
	};

	/**
	 * The deleteAllNotifications function is an asynchronous function that deletes all notifications by calling an
	 *  API method and updates the local state accordingly. It handles both successful and error responses from the
	 * API and catches any errors that occur during execution.
	 */
	const deleteAllNotifications = async () => {
		try {
			//Get notification IDs: The function first tries to extract an array of notification IDs from the notifications array using the map method. If notifications is null or undefined, an empty array is returned.
			const ids: number[] = notifications?.map((notification) => notification.id) || [];
			//Check if IDs exist: The function checks if the ids array is not null or undefined and has at least one element. If not, the function does nothing.
			if (ids && ids.length > 0) {
				//Call API to delete notifications: If ids is valid, the function calls the ApiService.deleteAllNotifications method, passing the ids array as an argument. This method returns a Promise that resolves to an IAPIResponseModel object.
				const res: IAPIResponseModel<Notification[]> = await ApiService.deleteAllNotifications(ids);
				//Handle API response: If the API response is successful (res.isSuccess is true), the function sets the notifications state to an empty array, effectively deleting all notifications.
				if (res.isSuccess) {
					setNotifications([]);
					isSetVisible(false);
				} else {
					//Handle API error: If the API response is not successful, the function displays an error toast message using the showToast function. The error message is either the userMessage from the API response or a default error message.
					showToast(res?.error?.userMessage ?? 'Something went wrong', { type: 'error' });
				}
			}
		} catch (error: any) {
			showToast(error?.message ?? 'Something went wrong', { type: 'error' });
		}
	};

	/**When the component mounts, the effect function is called, setting up the interval to call fetchNotifications every 5 minutes.
	 * When the component is unmounted, the cleanup function is called, clearing the interval to prevent memory leaks. */
	useEffect(() => {
		// Fetch notifications immediately on mount
		fetchNotifications();
		const interval = setInterval(() => {
			fetchNotifications();
		}, 300000); // 300,000 ms = 5 minutes

		// Cleanup the interval on component unmount
		return () => clearInterval(interval);
	}, []);

	const convertUTCToLocal = (utcDateString: string | number | Date) => {
		// Convert the UTC date to the local system time zone
		const localDate = moment.utc(utcDateString).local();
		// Format the date to show time in 12-hour format with AM/PM
		return localDate.format('hh:mm A');
	};

	useEffect(() => {
		const handleNewNotifications = (data: Notification[]) => {
			setNotifications(data); // Update the state with the new notifications
		};
		eventEmitter.on('newNotifications', handleNewNotifications);
		return () => {
			eventEmitter.off('newNotifications', handleNewNotifications); // Clean up the listener
		};
	}, []);

	return (
		<>
			<div className="main-top-bar">
				<div className="topbar-inner">
					<div className="topbar-heading">
						<>
							<h2>{activeTabHeader}</h2>
						</>
					</div>
					<div className="topbar-btn">
						<Link
							onClick={() => toggleProfileDetailsSideBox(true, '')}
							className="white-bg"
							to=""
							id="createproject-link" // Add an ID for targeting in the tour
						>
							<div className="icon">
								<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M8.43224 2.77832H1V12.3572H12.6315V6.90598"
										stroke="white"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M11.998 0.904297V4.90692"
										stroke="white"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M13.9997 2.90527H9.99707"
										stroke="white"
										strokeWidth="1.5"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<span>CREATE NEW PROJECT</span>
						</Link>
						<Link
							className={`gradient-bg ${!isProjectNull && isTourDisabled ? 'disabled' : ''}`}
							to=""
							onClick={isProjectNull || !isTourDisabled ? startTour : undefined} // Handle onClick conditionally
						>
							<div className="icon">
								<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11 7L1 12.5V2.5V2V1L11 7Z" fill="#FF8A65" fillOpacity="0.49" />
									<path d="M1 2V2.5M1 2.5V12.5L11 7L1 1V2.5Z" stroke="black" strokeWidth="0.5" />
									<path
										d="M1.43723 0.271125L1.4372 0.271111C1.17477 0.113583 0.847714 0.109449 0.581204 0.260332L1.43723 0.271125ZM1.43723 0.271125L11.4372 6.27122L11.4373 6.27127M1.43723 0.271125L11.4373 6.27127M11.4373 6.27127C11.6934 6.42473 11.85 6.70145 11.85 7C11.85 7.29854 11.6934 7.57526 11.4373 7.72873L11.4372 7.72877M11.4373 6.27127L11.4372 7.72877M11.4372 7.72877L1.43723 13.7288L1.43719 13.7288M11.4372 7.72877L1.43719 13.7288M1.43719 13.7288C1.17472 13.8864 0.847681 13.8906 0.581232 13.7397M1.43719 13.7288L0.581232 13.7397M0.581232 13.7397C0.314718 13.5887 0.15 13.3061 0.15 13M0.581232 13.7397L0.15 13M0.15 13V1M0.15 13V1M0.15 1C0.15 0.693764 0.314728 0.41121 0.5812 0.260334L0.15 1ZM2.07717 2.63757L1.85 2.50127V2.7662V11.2338V11.4987L2.07717 11.3624L9.13357 7.12862L9.34795 7L9.13357 6.87137L2.07717 2.63757Z"
										fill={isProjectNull || !isTourDisabled ? '#EFBD8A' : '#d3d3d3'} // Set icon color conditionally
										stroke={isProjectNull || !isTourDisabled ? 'white' : '#d3d3d3'} // Set stroke color conditionally
										strokeWidth="0.3"
									/>
								</svg>
							</div>
							<span>Take a Tour</span>
						</Link>

						<div className="admin-user" onClick={() => toggleProfileDetailsSideBox(true, SidebarMenuEnum.ProfileDetails)}>
							<Image
								src={
									userProfile && userProfile.personalDetails && userProfile.personalDetails.profileImage
										? userProfile.personalDetails.profileImage
										: UserAvatar
								}
								alt="User Icon"
							/>

							<h4>
								{`${(userProfile && userProfile?.personalDetails?.firstName) || ''} ${(userProfile && userProfile?.personalDetails?.lastName) || ''}`?.trim()}
							</h4>
						</div>
						<div className="notify" ref={notificationRef}>
							<Button onClick={toggleDiv} id={''} label={''}>
								<>
									<svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M6.97094 17.3053C6.316 17.3053 4.27553 17.3053 2.76376 17.3053C1.95828 17.3053 1.43538 16.4309 1.7956 15.6878L2.72104 13.7786C3.02194 13.1579 3.1786 12.4741 3.1786 11.7801C3.1786 10.9198 3.1786 9.642 3.1786 8.36409C3.1786 6.12879 4.26213 1.6582 9.67976 1.6582C15.0974 1.6582 16.1809 6.12879 16.1809 8.36409C16.1809 9.642 16.1809 10.9198 16.1809 11.7801C16.1809 12.4741 16.3376 13.1579 16.6385 13.7786L17.5639 15.6878C17.9241 16.4309 17.4002 17.3053 16.5948 17.3053H12.3886M6.97094 17.3053C6.97094 19.5406 8.05447 20.6582 9.67976 20.6582C11.305 20.6582 12.3886 19.5406 12.3886 17.3053M6.97094 17.3053C8.66352 17.3053 12.3886 17.3053 12.3886 17.3053"
											stroke="#EFBD8A"
											strokeWidth="1.5"
											strokeLinejoin="round"
										/>
									</svg>
									{notifications && Array.isArray(notifications) && notifications.length > 0 ? (
										<span>
											<p>{notifications.length}</p>
										</span>
									) : null}
								</>
							</Button>
							{isVisible && (
								<div className="notification-panel">
									<div className="inner-notification-panel">
										<div className="top-bar-notification">
											{notifications && notifications.length > 0 && (
												<>
													<h6>Notifications</h6>
													<Link to="" onClick={deleteAllNotifications}>
														Clear all
													</Link>
												</>
											)}
										</div>
										<div className="list-notifications">
											{notifications && Array.isArray(notifications) && notifications.length > 0 ? (
												notifications.map((notification) => {
													return (
														<>
															<div className="single-notification">
																<div className="imgae-single-noti">
																	<Image src={imgPreview} alt="" />
																	<div className="icon-single-noti">
																		<Image src={imgNoti} alt="" />
																	</div>
																</div>
																<div className="notification-text">
																	<h6>
																		{notification.title.includes('background media uploaded successfully')
																			? 'Trailer Media Uploaded Successfully'
																			: notification.title.includes('event media uploaded successfully')
																				? 'Event Media Uploaded Successfully'
																				: notification.title.includes('project created successfully.')
																					? 'Project created Successfully'
																					: notification.title.includes('expiring')
																						? 'Subscription Expiring Soon'
																						: 'Media Uploaded Successfully'}
																	</h6>
																	<p>
																		{
																			// Remove '.mp4' and unwanted phrases from the title
																			notification.title
																				.replace('.mp4', '') // Remove the .mp4 extension
																				.replace('event media uploaded successfully.', '') // Remove 'event Media Uploaded Successfully'
																				.replace('background media uploaded successfully.', '') // Remove 'background Media Uploaded Successfully'
																				.replace('project created successfully.', '') // Remove 'Project created successfully.'
																		}
																	</p>
																</div>
																<div className="notification-action">
																	<div className="text-action-noti">
																		<p>{convertUTCToLocal(notification.createTime)}</p>
																	</div>
																</div>
															</div>
														</>
													);
												})
											) : (
												<div className="no-notifications">
													<p>No new notification</p>
												</div>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			{/* Skip or Finish Tour Button */}
			{isTourActive && (
				<div className={styles.customSkipButton} onClick={handleSkipTour} style={{ fontWeight: 'bold' }}>
					<span>{getTourButtonLabel()}</span>
					<IoIosArrowForward style={{ fontSize: '20px' }} />
				</div>
			)}

			{/* Navigation Buttons */}
			{isTourActive && (
				<div className={styles.customNavButtonsContainer}>
					<Button
						onClick={handlePreviousStep}
						className={styles.customPreviousButton}
						disabled={currentStepIndex === 0} // Disable the button on the first step
						style={{
							color: currentStepIndex === 0 ? 'lightgrey' : 'black', // Light grey for disabled state
							cursor: currentStepIndex === 0 ? 'not-allowed' : 'pointer', // cursor disabled
							pointerEvents: currentStepIndex === 0 ? 'none' : 'auto', // Disable pointer events when on the first step
						}}
						id={''}
						label={''}
					>
						<IoMdArrowRoundBack style={{ fontSize: '20px' }} />
					</Button>

					<Button
						onClick={handleNextStep}
						disabled={currentStepIndex === 10} // Disable the button on the first step
						className={styles.customNextButton}
						id={''}
						label={''}
						style={{
							color: currentStepIndex === 10 ? 'lightgrey' : 'black', // Light grey for disabled state
							cursor: currentStepIndex === 10 ? 'not-allowed' : 'pointer', // cursor disabled
							pointerEvents: currentStepIndex === 10 ? 'none' : 'auto', // Disable pointer events when on the first step
						}}
					>
						<IoMdArrowRoundForward style={{ fontSize: '20px' }} />
					</Button>
				</div>
			)}
		</>
	);
};

export default TopBar;

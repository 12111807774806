import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { App_Urls } from '../../routes/AppUrls';
import ApiService from '../../helpers/api/Index';
import { showToast } from '../../components/toaster/Index';

export const Subscription = () => {
	const { search } = useLocation();
	const navigate = useNavigate(); // Hook for navigation
	// Parse query parameters
	const queryParams = new URLSearchParams(search);
	const email = queryParams.get('email') ?? '';
	const subscriptionID = queryParams.get('subscriptionID') ?? '';

	const handleSubscription = async () => {
		try {
			const result = await ApiService.Subscription(email, subscriptionID);
			// Handle subscription success
			if (result.isSuccess) {
				await ApiService.Refresh_Token();
				showToast('Payment Successful', { type: 'success' });
				// Redirect to Profile page
				navigate(App_Urls.Profile);
			} else {
				// Handle subscription failure
				showToast(result.error.userMessage || 'Something went wrong', { type: 'error' });
				throw new Error('Something went wrong');
			}
		} catch (error: any) {
			showToast(error.message ?? 'Something went wrong', { type: 'error' });
		}
	};

	useEffect(() => {
		handleSubscription();
	}, []); // Empty dependency array ensures this runs only once

	return <></>;
};

export default Subscription;

import React, { useEffect, useState } from 'react';
import Button from '../button/Index';
import { IStepperProps } from '../../types/interfaces/IStepperProps';

interface StepperProps {
	steps: IStepperProps[];
	isNextButtonEnabled: (currentStep: number) => boolean;
	onNextStep?: (currentStep: number) => Promise<void>;
	customButtonLabel?: string;
	getCurrentStep?: (step: number) => void;
	isProfileCreation?: boolean; // New field to indicate profile creation mode
}

/**
 * A stepper component that guides the user through a series of steps.
 *
 * This component allows navigation between different steps, displaying the corresponding
 * content and labels for each step. The `steps` prop is an array of step objects, each containing
 * a `label` and `content`. Navigation buttons are provided to move between steps.
 *
 * @param {Step[]} steps - An array of step objects, each containing a `label` and `content`.
 *
 * @returns {JSX.Element} A stepper component with navigation controls to move between steps.
 */
const Stepper: React.FC<StepperProps> = ({ isProfileCreation, steps, isNextButtonEnabled, onNextStep, customButtonLabel, getCurrentStep }) => {
	const [currentStep, setCurrentStep] = useState(0);
	/**
	 * Advances the stepper to the next step.
	 *
	 * This function increments the `currentStep` state by 1, allowing the user to move
	 * to the next step in the stepper component. It only updates the step if the `currentStep`
	 * is less than the total number of steps minus one, ensuring that the stepper doesn't exceed
	 * the last step.
	 */
	const goToNextStep = async () => {
		if (onNextStep) {
			try {
				await onNextStep(currentStep); // Call the API before proceeding to the next step
			} catch (error) {
				return;
			}
		}
		if (currentStep < steps.length - 1) {
			setCurrentStep(currentStep + 1);
		}
	};

	/**
	 * Moves the stepper to the previous step.
	 *
	 * This function decreases the `currentStep` state by 1, allowing the user to navigate
	 * to the previous step in the stepper component. It only updates the step if the `currentStep`
	 * is greater than 0, ensuring that the stepper doesn't go below the first step.
	 */
	const goToPreviousStep = () => {
		if (currentStep > 0) {
			setCurrentStep(currentStep - 1);
		}
	};

	/**
	 * Calls the `getCurrentStep` callback function with the current step when it changes.
	 *
	 * This hook is used to notify the parent component of the current step.
	 */
	useEffect(() => {
		getCurrentStep?.(currentStep);
	}, [currentStep, getCurrentStep]);
	useEffect(() => {
		if (isProfileCreation) {
			const handleKeyDown = (event: KeyboardEvent) => {
				const activeElement = document.activeElement;
				if (event.key === 'Backspace' && activeElement?.tagName !== 'INPUT') {
					goToPreviousStep();
				} else if (event.key === 'Enter' && isNextButtonEnabled(currentStep)) {
					goToNextStep();
				}
			};
			window.addEventListener('keydown', handleKeyDown);

			return () => {
				window.removeEventListener('keydown', handleKeyDown);
			};
		}
	}, [currentStep, getCurrentStep]);
	// useEffect(() => {
	// 	const handleKeyDown = (event: KeyboardEvent) => {
	// 		if (event.key === 'Backspace' && currentStep == 1) {
	// 			goToPreviousStep();
	// 		} else if (event.key === 'Enter' && isNextButtonEnabled(currentStep)) {
	// 			goToNextStep();
	// 		} else {
	// 		}
	// 	};
	// 	window.addEventListener('keydown', handleKeyDown);

	// 	return () => {
	// 		window.removeEventListener('keydown', handleKeyDown);
	// 	};
	// }, [currentStep, getCurrentStep]);
	return (
		<>
			{steps[currentStep].content}

			{!steps[currentStep].hideStepperButton ? (
				<div className="button-after-login-screens">
					<div className="login-signup-btn d-flex two_btn_sec">
						{currentStep !== 0 && (
							<>
								<Button className="cmn-btn" label="Back" onClick={goToPreviousStep} disabled={currentStep === 0} id="Step_Previous" />
							</>
						)}
						<hr />
						<Button
							className="cmn-btn"
							label={customButtonLabel ? customButtonLabel : 'Next'}
							onClick={goToNextStep}
							disabled={!isNextButtonEnabled(currentStep)}
							id="Step_Next"
						/>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default Stepper;

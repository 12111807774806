// Loader.tsx
import React from 'react';
import './Loader.css'; // Create a CSS file for styling if needed
import Image from '../image/Index';
import streamefy from '../../assets/images/png-ss.png';

interface LoaderProps {
	customMessage?: string;
}
const Loader: React.FC<LoaderProps> = ({ customMessage }) => {
	return (
		<div className="loader">
			<div className="spinner">
				{' '}
				<Image src={streamefy} />
			</div>
			<p>{customMessage ? customMessage : 'Loading...'}</p>
		</div>
	);
};

export default Loader;

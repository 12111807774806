import { IApp_Urls } from '../types/interfaces/IApp_Urls';

export const App_Urls: IApp_Urls = {
	Auth: '/',
	Otp: '/otp',
	Profile: '/profile',
	Subscription: '/subscription',
	DashBoard: '/dashBoard',
	Forgot: '/forgot',
	Reset: '/reset',
	ClientViewProject: '/view',
	AuthorizeProject: '/authorizeProject',
	Play: '/play',
};

import './sidebox.css';
import React, { useEffect, useRef, useState } from 'react';
import ProjectStepper from './ProjectStepper';
import EditProject from './edit/EditProject';
import MyProfile from '../../pages/myProfile/Index';
import { SidebarMenuEnum } from '../../types/enums/SideBarEnums';
import { ProjectEdit } from '../../types/type/ProjectEdit';
import { ICreateProject } from '../../types/interfaces/ICreateProject';
import Button from '../button/Index';
import { confirmAlert } from 'react-confirm-alert';
import { AddProjectModel, Project } from '../../types/models/ProjectModel';
import ApiService from '../../helpers/api/Index';
import Loader from '../loader/Index';
import { ProjectEvents } from './CreateEvent';
import { DescriptionRequestModel } from '../../../src/types/type/EventMedia';
import eventEmitter from '../../../src/helpers/Utils';
import { Constants } from '../../../src/constants/Constants';
import { showToast } from '../toaster/Index';
import { EditProjectBasicDeatils } from '../../../src/types/models/EditProjectBasicDeatilsModel';

interface ShowProfileSideBox {
	isOpen: boolean;
	type: string;
}
interface SideBoxProps {
	projectEdit: ProjectEdit;
	finalizeProjectEdit: (isFinaLizeProject: boolean) => void;
	handleEventMediaInfo: (eventData: any) => void;
	retrieveProjects: () => Promise<void>;
	openSideBoxExternally: (isOpen: boolean) => void;
	showSideBox: ShowProfileSideBox;
	handleActiveTab: (activeTab: SidebarMenuEnum) => void;
	setCurrentProjectId?: (projectId: number) => void;
	setIsUserProfile: (isUserProfile: boolean) => void;
	setProjectRefresh: (isEdit: boolean) => void;
	setEditProject?: (editProject: ProjectEdit) => void;
}
const SideBox: React.FC<SideBoxProps> = ({
	projectEdit,
	finalizeProjectEdit,
	handleEventMediaInfo,
	retrieveProjects,
	showSideBox,
	handleActiveTab,
	setCurrentProjectId,
	setIsUserProfile,
	setProjectRefresh,
	setEditProject,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [stepperKey, setStepperKey] = useState<number>(0);
	const [createProject, setCreateProject] = useState<ICreateProject>({
		projectInfo: undefined,
		crewInfo: [],
		projectEvent: [],
		trailerDetails: [],
	});
	const [projectId, setProjectId] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(false);
	const isOpenRef = useRef<boolean>(isOpen); // Ref to keep track of `isOpen`
	const [projectBasicDetails, setProjectBasicDetails] = useState<EditProjectBasicDeatils>();

	// Handles the project event media information for the project and updates the state
	// with the provided array of project event details.
	const handleCreateEventMediaInfo = (projectEvent: ProjectEvents[]) => {
		setCreateProject({
			...createProject,
			projectEvent: projectEvent, // Updates the projectEvent property in the createProject state
		});
	};
	/**
	 * Checks if the provided project information is considered "empty."
	 *
	 * This function determines if the project information is empty based on the following criteria:
	 * - The projectInfo is undefined or null.
	 * - The description is an empty string, null, or undefined.
	 * - The project ID is 0.
	 * - The isSmartRevision property is false.
	 * - The name is an empty string, null, or undefined.
	 *
	 * @param projectInfo - The project information to check (could be undefined).
	 * @returns true if the project information meets the criteria for being empty, otherwise false.
	 */
	const isProjectInfoEmpty = (projectInfo: AddProjectModel | undefined): boolean => {
		if (!projectInfo) return true; // Return true if projectInfo is undefined or null
		return (
			!projectInfo.description && // Checks if description is an empty string, null, or undefined
			projectInfo.id === 0 && // Checks if id is 0
			projectInfo.isSmartRevision === false && // Checks if isSmartRevision is false
			!projectInfo.name // Checks if name is an empty string, null, or undefined
		);
	};

	/**
	 * Updates the state of the side box and resets relevant project editing properties.
	 *
	 * - If the side box is already open, only the 'isOpen' state is updated.
	 * - Otherwise, the 'isOpen' state of the side box is updated and passed to the setIsOpen function.
	 * - Ensures that the project is not in editing mode by setting 'isEditing' to false.
	 * - Resets the side box type by setting it to an empty string.
	 *
	 * @param newIsOpen - The new state of the side box (true for open, false for closed).
	 */
	const handleSideBoxStateUpdate = (newIsOpen: boolean) => {
		// Update the 'isOpen' state for the side box
		setIsOpen(newIsOpen);

		// If the side box is closing and the project is being edited, refresh the project
		if (newIsOpen === false && projectEdit.isEditing) {
			setProjectRefresh?.(true);
			projectEdit.isEditing = false; // Ensure the project is not in editing mode
		}
		// Reset the side box type and clear project data when closing
		if (newIsOpen === false) {
			showSideBox.type = '';
			resetProjectData();
		}
		// If the side box is already open, update only the 'isOpen' state
		if (showSideBox.isOpen) {
			showSideBox.isOpen = newIsOpen;
		}
	};
	// Utility function to reset project data
	const resetProjectData = () => {
		setCreateProject({
			projectInfo: undefined,
			crewInfo: [],
			projectEvent: [],
			trailerDetails: [],
		});
	};

	/**
	 * Compares the basic project details between the current data (`projectBasicDetails`)
	 * and the original data (`projectData`) to determine if any changes have occurred.
	 *
	 * @param {Object} projectBasicDetails - The current project basic details to compare.
	 * @param {Object} projectData - The original project data to compare against.
	 * @returns {boolean} - Returns `true` if any project basic details have changed, otherwise `false`.
	 */
	const hasProjectBasicDetailsChanged = (projectBasicDetails: EditProjectBasicDeatils, projectData: Project) => {
		return (
			(projectBasicDetails.name?.trim() ?? '') !== (projectData.name?.trim() ?? '') ||
			(projectBasicDetails.description?.trim() ?? '') !== (projectData.description?.trim() ?? '') ||
			(projectBasicDetails?.font?.trim() ?? '') !== (projectData.font?.trim() ?? '') ||
			(projectBasicDetails?.crew?.length ?? 0) !== (projectData.crewMembers?.length ?? 0) ||
			!projectBasicDetails.crew.every(
				(crewMember, index) =>
					crewMember.id === projectData?.crewMembers[index]?.id &&
					(crewMember.name?.trim() ?? '') === (projectData?.crewMembers[index]?.name?.trim() ?? '') &&
					(crewMember.title?.trim() ?? '') === (projectData?.crewMembers[index]?.title?.trim() ?? ''),
			)
		);
	};
	/**
	 * Toggles the state of the side box (open/close) and handles various conditions based on the project's editing status and data changes.
	 *
	 * The function performs the following tasks:
	 * 1. If the side box is being closed (newIsOpen = false) and the project is being edited:
	 *    - If editing project event details, it handles specific actions for closing the side box.
	 *    - If editing basic project details, it compares the current data with the original, and if there are changes, it triggers an API call to save the updated details.
	 * 2. If the side box is being opened or if the project data is not empty:
	 *    - It checks if the create project data is empty and handles the side box state accordingly.
	 *    - If there is data, it confirms the closure with a submission process if necessary.
	 * 3. Updates the `isOpenRef` to reflect the new state of the side box (open or closed).
	 *
	 * @async
	 * @returns {Promise<void>} Resolves after completing the necessary operations based on the side box state.
	 */
	const handleOpenClose = async () => {
		// Toggle the 'isOpen' state (true to false or false to true)
		const newIsOpen = !isOpen;
		// Check if the side box is being closed and if the project is being edited
		if (
			newIsOpen === false &&
			(projectEdit.projectType === Constants.editProjectEvent || projectEdit.projectType === Constants.addProjectEvent) &&
			projectEdit.isEditing
		) {
			// Handle specific actions when closing the side box for editing event details
			await handleEventsOnClose();
			handleSideBoxStateUpdate(newIsOpen);
		} else if (newIsOpen === false && projectEdit.projectType === Constants.editProjectBasicDetails && projectEdit.isEditing) {
			// Check if the side box is being closed and editing basic project details
			if (projectBasicDetails && projectEdit.projectData) {
				// Filter out crew members with empty title and name
				const filteredProjectBasicDetails = {
					...projectBasicDetails,
					crew: projectBasicDetails.crew.filter((crewMember) => crewMember.title !== '' && crewMember.name !== ''),
				};
				// Check if the count of crew members is less than or equal to 1 after filtering
				if (filteredProjectBasicDetails.crew.length <= 1) {
					// Show an error toast if the count is less than or equal to 1
					showToast('Please fill in the details for at least two crew member', {
						type: 'error',
					});
					return;
				}
				// Compare the current projectBasicDetails with the original data, trimming spaces
				const isChanged = hasProjectBasicDetailsChanged(projectBasicDetails, projectEdit.projectData);
				if (isChanged) {
					// If there are changes, make the API call to update basic details
					const result = await ApiService.EditProjectBasicDetail(filteredProjectBasicDetails);
					if (result.isSuccess) {
						// If the API call is successful, show a success toast and refresh the project data
						showToast('Project basic details updated successfully');
						retrieveProjects(); // Retrieve the updated list of projects
					}
				}
				// Update the side box state to closed
				handleSideBoxStateUpdate(newIsOpen);
			}
		} else {
			// Check if the createProject data is empty (no project information or details)
			const isCreateProjectEmpty =
				isProjectInfoEmpty(createProject.projectInfo) &&
				createProject.crewInfo.length === 0 &&
				createProject.projectEvent.length === 0 &&
				createProject.trailerDetails.length === 0;

			// If createProject is empty, close the side box without confirmation
			if (isCreateProjectEmpty) {
				handleSideBoxStateUpdate(newIsOpen);
			} else {
				// If createProject has data, check for specific conditions before closing
				if (newIsOpen === false && showSideBox.type?.toLowerCase() !== 'profiledetails' && !projectEdit.isEditing) {
					// If the side box is being closed and it's not editing the profile details or project, prompt for confirmation
					await handleProjectSubmission();
				} else {
					// Otherwise, just close the side box
					handleSideBoxStateUpdate(newIsOpen);
				}
			}
		}
		// Update the 'isOpen' ref to reflect the new state (open or closed)

		isOpenRef.current = newIsOpen;
	};

	// Construct a structured description request model for backend API
	const constructDescriptionRequestModel = (projectId: number, events: ProjectEvents[]): DescriptionRequestModel => {
		// Creating a structured request model with project ID and events
		const structuredRequestModel: DescriptionRequestModel = {
			ProjectId: projectId, // Using projectId passed as a parameter
			Events: events.map((event) => {
				// Extract the event ID from eventMedia (assuming there's at least one media per event)
				const eventID = event.eventMedia[0]?.eventID || event.id;
				return {
					Id: eventID, // Assign event ID
					Title: event.title?.trim(), // Assign event title
					Medias: event.eventMedia
						.filter((x) => x.isModified)
						.map((media) => {
							// For each media, create a structured object containing media ID and description
							return {
								Id: media.mediaID,
								Description: media.description,
							};
						}),
				};
			}),
		};
		// Return the prepared model for backend API
		return structuredRequestModel;
	};

	// Function to handle closing the events, sending modified event details to backend
	const handleEventsOnClose = async () => {
		if (!projectEdit?.projectData?.id) return; // If there's no project data, exit early
		// Filter out events that have been modified
		const modifiedEvents = createProject?.projectEvent.filter((event) => event.isModified);
		if (!modifiedEvents || modifiedEvents.length === 0) return; // Exit if no events were modified

		// Filter out invalid media (those without required URLs or descriptions)
		const sanitizedEvents = modifiedEvents.map((event) => ({
			...event,
			eventMedia: event.eventMedia.filter((media) => media.isModified && media.eventMediaUrl && media.thumbnailUrl && media.description),
		}));
		// Create the structured request model with the modified and sanitized events
		const structuredRequestModel = constructDescriptionRequestModel(projectEdit?.projectData?.id ?? 0, sanitizedEvents);
		try {
			// Send the model to the backend API to add descriptions for media
			const res = await ApiService.AddDescriptionOfMedia(structuredRequestModel);
			if (res.isSuccess) {
				showToast('Changes saved successfully', { type: 'success' });
				finalizeProjectEdit?.(true); // Mark the project edit as finalized
				// Fetch notifications after successfully saving the event data
				const notificationRes = await ApiService.getAllnotifications();
				if (notificationRes.isSuccess && notificationRes.response) {
					// Emit the fetched notifications using an event emitter
					eventEmitter.emit('newNotifications', notificationRes.response);
				}
			}
		} catch (error: any) {
			// Handle any errors (currently no specific action on error)
		} finally {
			// Ensure to refresh project list and reset the editing state
			retrieveProjects?.();
			setEditProject?.({ isEditing: false, projectData: null, projectType: '' });
		}
	};

	// Effect hook to sync 'isOpen' state with 'projectEdit' and 'showSideBox' states
	useEffect(() => {
		// Determine whether the side box should be open based on the 'projectEdit' and 'showSideBox' states
		const shouldOpen = projectEdit.isEditing || showSideBox.isOpen;
		// If the 'showSideBox' is open, set the user profile state to false (likely to prevent conflicting UI behavior)
		if (showSideBox.isOpen) {
			setIsUserProfile(false);
		}
		// Update the 'isOpen' state based on whether the side box should be open or not
		setIsOpen(shouldOpen);
	}, [projectEdit, showSideBox]); // Re-run the effect when 'projectEdit' or 'showSideBox' changes

	/**
	 * Resets the Stepper component by incrementing the stepper key and toggling the isOpen state.
	 *
	 * This function is used to reset the stepper component to its initial state.
	 * It achieves this by updating the stepper key, which will cause the component to re-render,
	 * and by toggling the isOpen state, which will either open or close the stepper component.
	 */
	const StepperComponentReset = () => {
		// Increment the stepper key to force a re-render of the component
		setStepperKey((prevKey) => prevKey + 1);

		// Toggle the isOpen state to either open or close the stepper component
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		if (showSideBox.type !== SidebarMenuEnum.ProfileDetails) {
			const handleBeforeUnload = (event: BeforeUnloadEvent) => {
				if (!isOpenRef.current) {
					// Check the current value of `isOpen`
					const customMessage = 'Are you sure you want to leave? Your changes may not be saved.';
					event.preventDefault();
					event.returnValue = customMessage;
					return customMessage;
				}
			};

			// Attach the event listener when the component mounts
			window.addEventListener('beforeunload', handleBeforeUnload);

			// Clean up the listener when the component unmounts
			return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			};
		}
	}, [showSideBox.type]); // Dependency to re-run when `showSideBox.type` changes

	const handleProjectSubmission = async () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="modal-content-delete">
						<h5>Discard changes? Selecting 'Yes' deletes unsaved data. Select 'No' to continue editing.</h5>
						<div className="buttons-modal-delete">
							<Button
								className="cmn-btn"
								label="Yes"
								onClick={async () => {
									try {
										setLoading(true); // Show loader before the operation starts
										if (projectId > 0 && !projectEdit.isEditing) {
											await ApiService.DeleteProject(projectId);
											// Check if the response indicates success
											// if (response?.isSuccess) {
											// 	// Update Used Data Api Call
											// 	await ApiService.DeleteUsedData(projectId);
											// } else {
											// 	console.error('Failed to delete the project:', response?.error || 'Unknown error.');
											// }
										}
										// Close the side box and reset states regardless of success/failure
										setProjectId(0);
										setIsOpen(false);
									} catch (error: any) {
										showToast(error?.message ?? 'Something went wrong', { type: 'error' });
									} finally {
										setLoading(false); // Hide loader once the operation is completed
										onClose();
										// Reset all relevant states after confirmation
										projectEdit.isEditing = false; // Reset the editing state
										showSideBox.type = ''; // Reset side box type
										showSideBox.isOpen = false; // Close the side box
										resetProjectData();
										// Force the component to re-render
										setStepperKey((prevKey) => prevKey + 1); // Change stepperKey to force re-render
									}
								}}
								id={''}
							/>
							<Button
								className="cmn-btn"
								label="No"
								onClick={onClose} // Close the modal without making any changes
								id={''}
							/>
						</div>
					</div>
				);
			},
			closeOnClickOutside: false, // Prevent closing on outside click
		});
	};
	return (
		<>
			{loading && <Loader />}
			<div className={`${isOpen ? 'sidebox sideBoxOpen' : 'sidebox'}`}>
				<div
					className={`button-call-box ${isOpen ? 'button-rotate-call' : ''}`}
					onClick={() => {
						handleOpenClose();
					}}
				>
					<svg width="28" height="177" viewBox="0 0 28 177" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M10.6254 28.5C22.4191 23.586 27.192 12.2996 28.625 4.52948V177C28.625 161 18.2919 151.667 13.1254 149C1.12539 144.2 -0.541274 135 0.125393 131V44.5C0.125393 34.5 7.12539 29.6667 10.6254 28.5Z"
							fill="#941AF2"
						/>
						<path d="M9 89L16 82L9 89ZM9 89L16 96L9 89Z" fill="#941AF2" />
						<path d="M16 82L9 89L16 96" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M15 89L22 82L15 89ZM15 89L22 96L15 89Z" fill="#941AF2" />
						<path d="M22 82L15 89L22 96" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
				</div>

				{projectEdit.isEditing ? (
					<EditProject
						retrieveProjects={retrieveProjects}
						toggleOpenClose={handleOpenClose}
						finalizeProjectEdit={finalizeProjectEdit}
						projectEdit={projectEdit}
						handleEventMediaInfo={handleEventMediaInfo}
						handleCreateEventMediaInfo={handleCreateEventMediaInfo}
						handleBasicInfo={setProjectBasicDetails}
					/>
				) : showSideBox.type === SidebarMenuEnum.ProfileDetails ? (
					<MyProfile setIsUserProfile={setIsUserProfile} handleActiveTab={handleActiveTab} handleOpenClose={handleOpenClose} />
				) : (
					<ProjectStepper
						createProject={createProject}
						setCreateProject={setCreateProject}
						setCurrentProjectId={setCurrentProjectId}
						setProgressProjectId={setProjectId}
						key={stepperKey}
						onResetStepper={StepperComponentReset}
					/>
				)}
			</div>
			<div className={`${isOpen ? 'backdrop-sidebox' : ''}`}></div>
		</>
	);
};

export default SideBox;

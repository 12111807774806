import { ApiEndpoints } from '../Endpoints';
import * as handler from './Handler';

// OpenAi function to make the API request
export const DeleteAccount = async () => {
	try {
		const response = await handler.post<string>(ApiEndpoints.DeleteAccount);
		return response;
	} catch (error) {
		console.error('Error calling userDelete API:', error);
		throw error;
	}
};

import { SyntheticEvent, useEffect, useState } from 'react';
import userImg from '../../assets/images/users-Regular.png';
import Input from '../input/Index';
import Button from '../button/Index';
import { CrewMemberModel } from '../../types/models/CrewMember';
import Image from '../image/Index';
interface CrewMemberProps {
	handleCrewInformation: (crew: CrewMemberModel[]) => void;
	isSmartRevision: boolean;
	crewInformation: CrewMemberModel[];
}
const CrewMember: React.FC<CrewMemberProps> = ({ handleCrewInformation, isSmartRevision, crewInformation }) => {
	const [crew, setCrew] = useState<CrewMemberModel[]>(new Array(1).fill([{ name: '', title: '' }]));

	/**
	 * Adds a new crew member to the crew array if the current length is less than 4.
	 */
	const handleAddMoreMedia = () => {
		if (crew.length < 4) {
			setCrew([
				...crew,
				{
					title: '',
					name: '',
				},
			]);
		}
	};

	/**
	 * Removes a crew member at the specified index from the crew array.
	 * @param {number} index - The index of the crew member to remove.
	 */
	const handleRemoveCrew = (index: number, e: SyntheticEvent) => {
		if (e) {
			e.preventDefault(); // Prevent the default form submission behavior
		}
		setCrew((prevCrew) => {
			const updatedCrew = prevCrew.filter((_, i) => i !== index);
			handleCrewInformation(updatedCrew);
			return updatedCrew;
		});
	};

	/**
	 * Updates the crew member at the specified index with the new value from the input field.
	 * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - The change event.
	 * @param {number} index - The index of the crew member to update.
	 */
	const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
		const { name, value } = e.target;
		setCrew((prevCrew) => {
			const updatedCrew = prevCrew.map((member, i) => (i === index ? { ...member, [name]: value } : member));
			handleCrewInformation(updatedCrew);
			return updatedCrew;
		});
	};
	// Sync crew state with the prop (crewInformation) only when it changes
	useEffect(() => {
		if (JSON.stringify(crewInformation) !== JSON.stringify(crew) && crewInformation.length > 0) {
			setCrew(crewInformation); // Update crew only when props change and avoid unnecessary updates
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [crewInformation]); // Only runs when crewInformation prop changes
	return (
		<>
			<div className="main-content-sidebox crew-sidebox">
				<div className="header-sidebox">
					<h5>Crew members</h5>
					<div className={`box-enable-disable ${isSmartRevision ? 'enable-smart-review' : 'disbled-smart-review'}`}>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M13.4927 2.29104L8.8552 0.225543C8.17462 -0.0751808 7.14583 -0.0751808 6.46524 0.225543L1.82776 2.29104C0.656525 2.81335 0.482422 3.52559 0.482422 3.90545C0.482422 4.28531 0.656525 4.99755 1.82776 5.51986L6.46524 7.58536C6.80554 7.73572 7.23288 7.81486 7.66022 7.81486C8.08757 7.81486 8.51491 7.73572 8.8552 7.58536L13.4927 5.51986C14.6639 4.99755 14.838 4.28531 14.838 3.90545C14.838 3.52559 14.6718 2.81335 13.4927 2.29104Z"
								fill="#941AF2"
							/>
							<path
								d="M7.66053 11.9047C7.3598 11.9047 7.05908 11.8414 6.7821 11.7227L1.44822 9.34855C0.633102 8.98452 0 8.01113 0 7.11687C0 6.7924 0.261154 6.53125 0.585619 6.53125C0.910084 6.53125 1.17124 6.7924 1.17124 7.11687C1.17124 7.54421 1.52736 8.09818 1.92305 8.27228L7.25692 10.6464C7.51016 10.7572 7.80297 10.7572 8.05622 10.6464L13.3901 8.27228C13.7858 8.09818 14.1419 7.55213 14.1419 7.11687C14.1419 6.7924 14.4031 6.53125 14.7275 6.53125C15.052 6.53125 15.3131 6.7924 15.3131 7.11687C15.3131 8.00321 14.68 8.98452 13.8649 9.34855L8.53104 11.7227C8.26197 11.8414 7.96125 11.9047 7.66053 11.9047Z"
								fill="#941AF2"
							/>
							<path
								d="M7.66053 15.8305C7.3598 15.8305 7.05908 15.7672 6.7821 15.6485L1.44822 13.2743C0.569792 12.8866 0 12.0081 0 11.0427C0 10.7182 0.261154 10.457 0.585619 10.457C0.910084 10.457 1.17124 10.7182 1.17124 11.0427C1.17124 11.5412 1.46405 11.9923 1.92305 12.1981L7.25692 14.5722C7.51016 14.683 7.80297 14.683 8.05622 14.5722L13.3901 12.1981C13.8412 12.0002 14.1419 11.5412 14.1419 11.0427C14.1419 10.7182 14.4031 10.457 14.7275 10.457C15.052 10.457 15.3131 10.7182 15.3131 11.0427C15.3131 12.0081 14.7434 12.8786 13.8649 13.2743L8.53104 15.6485C8.26197 15.7672 7.96125 15.8305 7.66053 15.8305Z"
								fill="#941AF2"
							/>
						</svg>
						<p>{isSmartRevision ? 'Smart review is enabled' : 'Smart review is disabled'}</p>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9 4.56445V4.55557M9 12.4444V8.22222M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
								stroke="#951CF1"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
				</div>
				<div className="label-box-sidebox">
					<div className="iconbox-label-sidebox">
						<Image src={userImg} alt="" />
					</div>
					<div className="content-label-sidebox">
						<h6>
							Add crew members <span style={{ color: 'red' }}>*</span>
						</h6>
						<p>List the key people behind the event.</p>
					</div>
				</div>
				<div className="form-add-crew">
					{crew?.map((member, index) => (
						<div id={`crew_${index}`} className="fields-box-crew">
							<div className="form-box">
								<div className="input-field">
									<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M9.79418 0.176525C10.0756 0.0599835 10.3771 0 10.6817 0C10.9862 0 11.2877 0.0599835 11.5691 0.176525C11.8504 0.293067 12.1061 0.463885 12.3214 0.679222C12.5368 0.89456 12.7076 1.15021 12.8242 1.43156C12.9407 1.71292 13.0007 2.01447 13.0007 2.31901C13.0007 2.62354 12.9407 2.9251 12.8242 3.20646C12.7076 3.48781 12.5368 3.74345 12.3214 3.95879L11.9846 4.2956L8.70508 1.01603L9.04185 0.679222C9.2572 0.463885 9.51285 0.293067 9.79418 0.176525Z"
											fill="#C1C1C1"
										/>
										<path
											d="M7.78551 1.93457L1.07552 8.64457C0.995526 8.72452 0.937798 8.82404 0.908034 8.93317L0.0229184 12.1786C-0.0384562 12.4036 0.0254601 12.6443 0.190402 12.8092C0.355344 12.9742 0.596019 13.0381 0.821061 12.9767L4.06649 12.0916C4.17562 12.0618 4.2751 12.0041 4.35509 11.9241L11.065 5.21417L7.78551 1.93457Z"
											fill="#C1C1C1"
										/>
									</svg>
									<Input
										type="text"
										_key={'title'}
										onChange={(e) => changeHandler(e, index)}
										name={'title'}
										value={member.title || ''} // Default to empty string if undefined
										maxLength={16}
										// style={{
										// 	borderColor: member.title && member.title.length >= 16 ? 'red' : '',
										// }}
									/>
									<label>Title</label>
								</div>
							</div>
							<div className="form-box">
								<div className="input-field">
									<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M9.79418 0.176525C10.0756 0.0599835 10.3771 0 10.6817 0C10.9862 0 11.2877 0.0599835 11.5691 0.176525C11.8504 0.293067 12.1061 0.463885 12.3214 0.679222C12.5368 0.89456 12.7076 1.15021 12.8242 1.43156C12.9407 1.71292 13.0007 2.01447 13.0007 2.31901C13.0007 2.62354 12.9407 2.9251 12.8242 3.20646C12.7076 3.48781 12.5368 3.74345 12.3214 3.95879L11.9846 4.2956L8.70508 1.01603L9.04185 0.679222C9.2572 0.463885 9.51285 0.293067 9.79418 0.176525Z"
											fill="#C1C1C1"
										/>
										<path
											d="M7.78551 1.93457L1.07552 8.64457C0.995526 8.72452 0.937798 8.82404 0.908034 8.93317L0.0229184 12.1786C-0.0384562 12.4036 0.0254601 12.6443 0.190402 12.8092C0.355344 12.9742 0.596019 13.0381 0.821061 12.9767L4.06649 12.0916C4.17562 12.0618 4.2751 12.0041 4.35509 11.9241L11.065 5.21417L7.78551 1.93457Z"
											fill="#C1C1C1"
										/>
									</svg>
									<Input
										type="text"
										_key={'name'}
										onChange={(e) => changeHandler(e, index)}
										name={'name'}
										value={member.name || ''}
										maxLength={20}
										// style={{
										// 	borderColor: member.name && member.name.length >= 20 ? 'red' : '',
										// }}
									/>
									<label>Name</label>
								</div>
							</div>
							{crew.length > 1 && (
								<div className="cross-crew">
									<Button id={''} label={''} onClick={(e) => handleRemoveCrew(index, e)}>
										<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="13.5" cy="13.5" r="13.5" fill="#EDEDED" />
											<path
												d="M18.637 8.35991C18.4932 8.21696 18.2987 8.13672 18.0959 8.13672C17.8931 8.13672 17.6986 8.21696 17.5548 8.35991L13.4868 12.4279L9.42655 8.35991C9.27972 8.23417 9.09085 8.16846 8.89768 8.17593C8.70451 8.18339 8.52127 8.26347 8.38457 8.40016C8.24788 8.53686 8.1678 8.7201 8.16034 8.91327C8.15287 9.10644 8.21858 9.29531 8.34432 9.44214L12.4046 13.5101L8.33665 17.5704C8.2563 17.6392 8.19104 17.7239 8.14497 17.8191C8.0989 17.9143 8.07301 18.018 8.06893 18.1237C8.06485 18.2294 8.08266 18.3349 8.12125 18.4333C8.15984 18.5318 8.21837 18.6213 8.29317 18.6961C8.36797 18.7709 8.45742 18.8294 8.55592 18.868C8.65441 18.9066 8.75982 18.9244 8.86552 18.9203C8.97123 18.9162 9.07495 18.8904 9.17017 18.8443C9.2654 18.7982 9.35007 18.733 9.41888 18.6526L13.4868 14.5923L17.5471 18.6526C17.694 18.7784 17.8828 18.8441 18.076 18.8366C18.2692 18.8291 18.4524 18.7491 18.5891 18.6124C18.7258 18.4757 18.8059 18.2924 18.8133 18.0993C18.8208 17.9061 18.7551 17.7172 18.6293 17.5704L14.5691 13.5101L18.6293 9.44982C18.7018 9.37897 18.7595 9.29449 18.7991 9.20123C18.8387 9.10797 18.8595 9.00779 18.8602 8.90646C18.8609 8.80514 18.8416 8.70468 18.8033 8.61087C18.765 8.51706 18.7085 8.43177 18.637 8.35991Z"
												fill="#797979"
											/>
										</svg>
									</Button>
								</div>
							)}
						</div>
					))}
					<p className="maximum-crew">
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9 4.56445V4.55557M9 12.4444V8.22222M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
								stroke="#EFBD8A"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						Maximum 4 members &nbsp;<small>(Add atleast 2 crew members to proceed further)</small>
					</p>
				</div>
				<div className="add-more-media-btn">
					{crew.length < 4 && (
						<>
							<Button onClick={handleAddMoreMedia} id={''} label={'Add more crew Member'} disabled={crew.some((x) => !x.name || !x.title)}>
								<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="25" cy="25" r="25" fill="#EFBD8A" />
									<path
										d="M36.8691 25.6313C36.8681 25.3022 36.7369 24.9869 36.5043 24.7543C36.2716 24.5216 35.9563 24.3904 35.6273 24.3895L26.2916 24.3895L26.3004 15.0626C26.2762 14.7499 26.1349 14.4577 25.9047 14.2446C25.6745 14.0316 25.3723 13.9132 25.0586 13.9132C24.7449 13.9132 24.4427 14.0316 24.2125 14.2446C23.9823 14.4577 23.841 14.7499 23.8168 15.0626L23.808 24.3895L14.4811 24.3807C14.31 24.3674 14.1379 24.3897 13.9758 24.4461C13.8137 24.5025 13.665 24.5918 13.539 24.7084C13.413 24.825 13.3125 24.9664 13.2438 25.1237C13.175 25.281 13.1395 25.4508 13.1395 25.6225C13.1395 25.7941 13.175 25.9639 13.2438 26.1212C13.3125 26.2785 13.413 26.4199 13.539 26.5365C13.665 26.6531 13.8137 26.7425 13.9758 26.7989C14.1379 26.8553 14.31 26.8775 14.4811 26.8643L23.808 26.8731L23.808 36.1911C23.8322 36.5039 23.9735 36.796 24.2037 37.0091C24.4339 37.2222 24.7361 37.3406 25.0498 37.3406C25.3635 37.3406 25.6656 37.2222 25.8959 37.0091C26.1261 36.796 26.2674 36.5039 26.2916 36.1911L26.2916 26.8731L35.6096 26.8731C35.7741 26.8749 35.9372 26.8442 36.0897 26.7827C36.2422 26.7211 36.381 26.63 36.498 26.5146C36.6151 26.3991 36.7082 26.2616 36.7719 26.11C36.8356 25.9584 36.8686 25.7957 36.8691 25.6313Z"
										fill="white"
									/>
								</svg>
							</Button>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default CrewMember;

import React from 'react';
import Navigation from './routes/Index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from './components/errorBoundary/Index'

function App() {
	return (
		<>
			<ErrorBoundary>
				<Navigation />
				<ToastContainer />
			</ErrorBoundary>
		</>
	);
}

export default App;

import { BunnyPlaybackVideo } from '../bunnyService/BunnyPlayBackVideo';
import {
	ForgotPassword,
	Get_OTP,
	Login,
	OtpVerification,
	Refresh_Token,
	Register,
	ResetOptRequest,
	ResetPassword,
	ResetPasswordOTP,
	SignInWithFacebook_Get,
	SignInWithFacebook_Post,
	SignInWithGoogle_Get,
	SignInWithGoogle_Post,
	SignInWithInstagram_Get,
	SignInWithInstagram_Post,
	LoadTransactions,
	Verify_OTP,
	DownloadInvoiceData,
} from './Auth';
import {
	BackgroundMedia,
	DeleteBackgroundMediaById,
	GetBackGroundMediaByProjectId,
	postBackgroundMediaUploadFailed,
	reencodeVideo,
} from './BackgroundMedia';
import { AddCrewMember } from './CrewMember';
import { GetEventByProjectId } from './Event';
import { EventMedia, AddDescriptionOfMedia, DeleteMedia, postEventMediaUploadFailed, downloadMedia, DeleteEventWithMedia } from './EventMedia';
import { fetchStreamefyMedia } from './IFrame';
import { addNotification, deleteAllNotifications, getAllnotifications } from './Notifications';
import {
	CancelUserPlan,
	DataTransactionLogs,
	DeleteAccount,
	EditMyProfile,
	GetPlans,
	GetSubscriptionLink,
	IsSubscribed,
	myProfile,
	ProfileResetOTP,
	ResetProfilePassword,
	Subscription,
	UserProfile,
} from './Profile';
import {
	CreateProject,
	DeleteProject,
	EditProjectBasicDetail,
	GetProjectById,
	GetProjects,
	PinProject,
	ProjectSmartRevision,
	GenerateProjectPin,
	ShareProject,
	AuthorizeProjectPin,
	PlayProject,
	ReencodeProjectVideos,
	CheckSmartRevisionVideos,
	GetProjectMediaDetails,
	GetnameIdDetails,
	PrimaryUserProjects,
	UploadThumbnail,
	DeleteUsedData,
	PrimaryUserResetPin,
} from './Project';
import { SupportTicket } from './Support';
import { OpenAi } from './OpenAi';

// Create the exportable object
const ApiService = {
	//#region Auth endpoints registered here
	Login,
	Register,
	ForgotPassword,
	ResetPassword,
	OtpVerification,
	Refresh_Token,
	ResetPasswordOTP,
	ResetOptRequest,
	SupportTicket,
	Subscription,
	IsSubscribed,
	GetSubscriptionLink,
	//#endregion

	//#region OAuth endpoints registered here
	SignInWithGoogle_Get,
	SignInWithGoogle_Post,
	SignInWithInstagram_Get,
	SignInWithInstagram_Post,
	SignInWithFacebook_Get,
	SignInWithFacebook_Post,
	//#endregion

	//#region Project endpoint register here
	GetProjects,
	GetProjectById,
	CreateProject,
	GetEventByProjectId,
	ProjectSmartRevision,
	DeleteProject,
	PinProject,
	EditProjectBasicDetail,
	GenerateProjectPin,
	ShareProject,
	AuthorizeProjectPin,
	PlayProject,
	ReencodeProjectVideos,
	CheckSmartRevisionVideos,
	UploadThumbnail,
	DeleteUsedData,
	//#endregion

	//#region user profile
	UserProfile,
	myProfile,
	EditMyProfile,
	DataTransactionLogs,
	//#endregion

	//#region crewmember endpoints

	AddCrewMember,
	//#endregion

	//#region Upload Background Media
	BackgroundMedia,
	GetBackGroundMediaByProjectId,
	DeleteBackgroundMediaById,
	//#endregion
	//#region Upload Event Media
	EventMedia,
	AddDescriptionOfMedia,
	DeleteMedia,
	DeleteEventWithMedia,
	//#endregion
	//#region Streamefy Video Response
	fetchStreamefyMedia,
	//#endregion
	//#region After Background Media Upload
	postBackgroundMediaUploadFailed,
	//#endregion
	//#region After Event Media Upload
	postEventMediaUploadFailed,
	//#endregion

	//#region  Notifications
	getAllnotifications,
	deleteAllNotifications,
	addNotification,
	//#endregion
	downloadMedia,
	//#region Transcoding
	reencodeVideo,
	//#endregion

	ResetProfilePassword,
	ProfileResetOTP,

	BunnyPlaybackVideo,
	GetProjectMediaDetails,

	Get_OTP,
	Verify_OTP,

	GetnameIdDetails,
	PrimaryUserProjects,
	CancelUserPlan,
	GetPlans,

	LoadTransactions,
	DownloadInvoiceData,

	PrimaryUserResetPin,
	OpenAi,
	DeleteAccount,
};

// Export the object as the default export
export default ApiService;

import React, { useEffect, useState } from 'react';
import Input from '../../../components/input/Index';
import Image from '../../../components/image/Index';
import { Link } from 'react-router-dom';
import { CompanyDetails as companyDetails, EditProfileModel } from '../../../types/models/PersonalDetailsModel';
import Checkbox from '../../../components/checkbox/Index';
import { ImageProps } from '../../../types/type/ImageProps';
import FileUpload from '../../../components/fileUpload/fileUpload';
import { Constants } from '../../../constants/Constants';
import { WaterMarkPositionTypes } from '../../../types/enums/WaterMarkEnum';
import Button from '../../../components/button/Index';
import { LandingPageThemeEnum } from '../../../types/enums/LandingPageThemeEnum';

const logoTypes = ['pdf', 'png', 'jpg', 'jpeg'];
// Interface representing the state for logo and watermark images
interface LogoWatermarkState {
	// Properties representing the logo image
	logo: ImageProps;
	// Properties representing the watermark image
	waterMark: ImageProps;
}
interface CompanyDetailsProps {
	isEdit: boolean;
	companyInformation: companyDetails;
	handleInputChangeforCompanyDetails: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleFileSelect: (file: File | null, type: string, isClear?: boolean) => void;
	handleWaterMarkPosChange: (position: WaterMarkPositionTypes) => void;
	companyWaterMarkSrc: string;
	setEditprofileDetails: React.Dispatch<React.SetStateAction<EditProfileModel>>; // Update this line
	setUploadState: React.Dispatch<React.SetStateAction<LogoWatermarkState>>;
	editcompanyName: string;
	hasIndianCountryCode: boolean;
	backgroundTheme: LandingPageThemeEnum;
	setIsCompanyDetailsError: React.Dispatch<React.SetStateAction<{ companyName: boolean; gstNumber: boolean }>>; // Dispatch function for error state
	isCompanyDetailsError: { companyName: boolean; gstNumber: boolean }; // Updated type to match the expected shape
}
const CompanyDetails: React.FC<CompanyDetailsProps> = ({
	isEdit,
	companyInformation,
	handleInputChangeforCompanyDetails,
	handleFileSelect,
	handleWaterMarkPosChange,
	companyWaterMarkSrc,
	setEditprofileDetails,
	setUploadState,
	editcompanyName,
	hasIndianCountryCode,
	backgroundTheme = LandingPageThemeEnum.Light,
	setIsCompanyDetailsError,
	isCompanyDetailsError,
}) => {
	const [isWaterMarkApply, setIsWaterMarkApply] = useState<boolean>(false);

	const [companyLogo, setCompanyLogo] = useState<ImageProps>({ src: '' });
	const [companyWaterMark, setCompanyWaterMark] = useState<ImageProps>({ src: companyWaterMarkSrc });
	//companyWaterMarkType
	const [companyLogoType, setCompanyLogoType] = useState('');
	const [companyWaterMarkType, setCompanyWaterMarkType] = useState('');
	const [waterMarkPos, setWaterMarkPos] = useState<WaterMarkPositionTypes | null>(WaterMarkPositionTypes.BottomRight);
	const [companyDetails, setCompanyDetails] = useState<{ companyName: string; gstNumber: string }>({
		companyName: editcompanyName,
		gstNumber: companyInformation.gstNumber,
	});
	// Function to toggle the state of applying the watermark
	const handleIsWaterMarkToggle = () => {
		// Toggle the value of isWaterMarkApply
		const newValue = !isWaterMarkApply; // If isWaterMarkApply is true, newValue becomes false, and vice versa
		// Update the state to reflect the new watermark application status
		setIsWaterMarkApply(newValue);
		// Update the editprofileDetails object with the new watermark enable state
		setEditprofileDetails((prev) => ({
			...prev, // Spread the previous state to retain other properties
			isWaterMarkEnable: newValue, // Set isWaterMarkEnable to the new value
		}));
	};

	// Effect hook to update the company watermark state whenever the source changes
	useEffect(() => {
		// Set the company watermark using the current source
		setCompanyWaterMark({ src: companyWaterMarkSrc });
	}, [companyWaterMarkSrc]);
	// Function to handle file selection
	const handleFileSelected = (file: File | null, type: string) => {
		// If a file is selected
		if (file) {
			const reader = new FileReader();
			// On file load completion, process the file data
			reader.onloadend = () => {
				const fileData = { src: reader.result as string, format: file.type };
				// Set the logo or watermark based on the type
				if (type === Constants.companyLogo) {
					const detectedTypelogo = logoTypes.find((type) => file.type.includes(type));
					if (detectedTypelogo) {
						setCompanyLogoType(detectedTypelogo === 'pdf' ? 'pdf' : detectedTypelogo === 'png' ? 'png' : 'jpeg');
					}
					setCompanyLogo(fileData);
					// Set the company logo type (file format) based on the selected file
					handleFileSelect(file, type); // Trigger additional file handling
					setUploadState((prev) => ({
						...prev,
						logo: { src: file ? URL.createObjectURL(file) : '' }, // Update to match ImageProps structure
					}));
				} else if (type === Constants.companyWaterMark) {
					const detectedTypewaterMark = logoTypes.find((type) => file.type?.includes(type));
					if (detectedTypewaterMark) {
						setCompanyWaterMarkType(detectedTypewaterMark === 'pdf' ? 'pdf' : detectedTypewaterMark === 'png' ? 'png' : 'jpeg');
					}
					setCompanyWaterMark(fileData);
					// Set the company watermark type (file format) based on the selected file
					handleFileSelect(file, type); // Trigger additional file handling
					setUploadState((prev) => ({
						...prev,
						waterMark: { src: file ? URL.createObjectURL(file) : '' }, // Update to match ImageProps structure
					}));
				}
			};
			// Read the file as a data URL
			reader.readAsDataURL(file);
			//handleFileSelect(file, type); // Trigger additional file handling
			//Only the flag has been added to set the file; based on this, disable the save button if the file is
			//empty, or disable the active button if a file is added.
		}
	};

	// Function to clear the selected image
	const clearImage = (type: string) => {
		// Clear the company logo if the type matches
		if (type === Constants.companyLogo) {
			setCompanyLogo({ src: '' });
			handleFileSelect(null, Constants.companyLogo); // Notify file handling of the change
			// Reset the file upload state to null; this will disable the save button if the file is empty
			//setIsUpload(prev => ({ ...prev, logo: null }));
			setUploadState((prevState) => ({
				...prevState,
				logo: { src: '' }, // Reset the logo part of the state
			}));
		}
		// Clear the company watermark if the type matches
		else if (type === Constants.companyWaterMark) {
			setCompanyWaterMark({ src: '' });
			handleFileSelect(null, Constants.companyWaterMark, true); // Notify file handling of the change
			//setIsUpload(prev => ({ ...prev, watermark: null }));
			setUploadState((prevState) => ({
				...prevState,
				waterMark: { src: '' }, // Reset the logo part of the state
			}));
			setWaterMarkPos(WaterMarkPositionTypes.BottomRight); // Set default position
		}
	};

	// Function to handle button clicks for setting the watermark position
	const handleButtonClick = (postion: WaterMarkPositionTypes) => {
		// Update the watermark position state and trigger the corresponding handler
		setWaterMarkPos(postion);
		handleWaterMarkPosChange(postion);
	};
	// Handler function to manage changes in the company name input field
	// const handleChangeForCompanyName = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	// Destructure the value from the input event's target
	// 	const { value } = e.target;
	// 	// Update the state with the new company name
	// 	setCompanyName(value);
	// 	value.length >= 50 ? setIsComapnyNameError(true) : setIsComapnyNameError(false);
	// 	// Notify the parent component about the change in company details
	// 	handleInputChangeforCompanyDetails(e);
	// };
	const handleChangeForCompanyDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		if (name === 'companyName') {
			// Update companyName in companyDetails state
			setCompanyDetails((prevDetails) => ({
				...prevDetails,
				companyName: value,
			}));

			// Set company name error state if the name length exceeds 50 characters
			value.length >= 50
				? setIsCompanyDetailsError((prev) => ({ ...prev, companyName: true }))
				: setIsCompanyDetailsError((prev) => ({ ...prev, companyName: false }));
		} else if (name === 'gstNumber') {
			if (value) {
				// GST pattern for validation
				const gstPattern = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
				if (!gstPattern.test(value.toUpperCase())) {
					setIsCompanyDetailsError((prev) => ({ ...prev, gstNumber: true }));
				} else {
					setIsCompanyDetailsError((prev) => ({ ...prev, gstNumber: false }));
				}
			} else {
				// If the value is empty, clear error for gstNumber without validation
				setIsCompanyDetailsError((prev) => ({ ...prev, gstNumber: false }));
			}
			// Update gstNumber in companyDetails state
			setCompanyDetails((prevDetails) => ({
				...prevDetails,
				gstNumber: value.toUpperCase(),
			}));
		}

		// Notify parent component about the change in company details
		handleInputChangeforCompanyDetails(e);
	};

	// Effect hook to set initial states based on company information
	useEffect(() => {
		// Initialize the company logo and watermark states from companyInformation

		const detectedTypelogo = logoTypes.find((type) => companyInformation.logo.includes(type));
		const detectedTypewaterMark = logoTypes.find((type) => companyInformation.waterMark?.includes(type));

		if (detectedTypelogo) {
			setCompanyLogoType(detectedTypelogo);
		}
		if (detectedTypewaterMark) {
			setCompanyWaterMarkType(detectedTypewaterMark);
		}
		setCompanyLogo({ src: companyInformation.logo || '' });
		// Update the upload state with the logo and watermark information from company details
		setUploadState((prevState) => ({
			...prevState,
			logo: { src: companyInformation.logo || '' }, // Set logo src, default to an empty string if not available
			waterMark: { src: companyInformation.waterMark || '' }, // Set watermark src, default to an empty string if not available
		}));
		setCompanyWaterMark({ src: companyInformation.waterMark || '' });
		setWaterMarkPos(
			companyInformation.waterMark ? (companyInformation.watermarkPosition as WaterMarkPositionTypes) : WaterMarkPositionTypes.BottomRight,
		);
		//setWaterMarkPos((companyInformation.watermarkPosition as WaterMarkPositionTypes) || WaterMarkPositionTypes.BottomRight);
		//setWaterMarkPos(companyInformation.watermarkPosition as WaterMarkPositionTypes);
		/* The double negation (!!) is used to convert any value to its boolean equivalent.
		   !!value ensures that:
		  - If value is truthy (e.g., non-empty string, true, or a non-zero number), it converts to true.
		  - If value is falsy (e.g., null, undefined, false, 0, or an empty string), it converts to false.
		*/
		// Set the state for watermark application based on the truthiness of companyInformation.waterMark
		setIsWaterMarkApply(!!companyInformation.waterMark); // Converts the value to a boolean

		// Update the editprofileDetails object with the watermark enable state
		setEditprofileDetails((prev) => ({
			...prev, // Spread the previous state to retain other properties
			isWaterMarkEnable: !!companyInformation.waterMark, // Convert the value to a boolean and set isWaterMarkEnable
		}));
		setCompanyDetails((prev) => ({
			...prev,
			companyName: editcompanyName || '',
			gstNumber: companyInformation.gstNumber || '',
		}));
	}, [companyInformation.logo, companyInformation.waterMark, companyInformation.watermarkPosition, companyInformation, setEditprofileDetails]);
	return (
		<div className="single-tab-profile">
			<div className="company-detail-tab">
				<div className="input-field" style={{ marginBottom: '20px' }}>
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.8332 11.6663V12.833H1.1665V11.6663H12.8332ZM6.25296 3.95487L8.87796 6.57985L4.95817 10.4997H2.33317V7.87467L6.25296 3.95487ZM8.74984 1.45801L11.3748 4.08301L9.4967 5.96114L6.8717 3.33614L8.74984 1.45801Z"
							fill="#C1C1C1"
						/>
					</svg>

					<Input
						disabled={!isEdit}
						required={true}
						value={companyDetails.companyName}
						type="text"
						onChange={handleChangeForCompanyDetails}
						_key={'companyName'}
						name={'companyName'}
						maxLength={50}
						style={{
							borderColor: isCompanyDetailsError.companyName || !companyDetails.companyName.trim() ? 'red' : '',
						}}
					/>
					<label>
						Company Name<span style={{ color: 'red' }}>*</span>
					</label>
				</div>
				{isCompanyDetailsError.companyName && (
					<div className="error">
						<p>The Company name must be 50 characters or lower</p>
					</div>
				)}

				{hasIndianCountryCode && (
					<>
						{/* GST Number Input */}
						<div className="input-field" style={{ marginBottom: '20px' }}>
							<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M12.8332 11.6663V12.833H1.1665V11.6663H12.8332ZM6.25296 3.95487L8.87796 6.57985L4.95817 10.4997H2.33317V7.87467L6.25296 3.95487ZM8.74984 1.45801L11.3748 4.08301L9.4967 5.96114L6.8717 3.33614L8.74984 1.45801Z"
									fill="#C1C1C1"
								/>
							</svg>

							<Input
								disabled={!isEdit}
								required={false}
								value={companyDetails.gstNumber.trim().toUpperCase() || ''}
								type="text"
								onChange={(e) => handleChangeForCompanyDetails(e)}
								_key={'gstNumber'}
								name={'gstNumber'}
								maxLength={15}
							/>
							<label>GST Number</label>
						</div>
						{isCompanyDetailsError.gstNumber && (
							<div className="error">
								<p>Invalid GST Number! Please enter the correct format.</p>
							</div>
						)}
					</>
				)}
				<div className="upload-company-logo-profile upload-water-logo-profile">
					<label>
						Upload your company logo<span style={{ color: 'red' }}>*</span>
					</label>
					{companyLogo?.src === '' && (
						<>
							<div className="upload-clogo-profile">
								<div className="box-upload-profile-clogo">
									<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M0.804026 12.8652C1.24807 12.8652 1.60805 13.2253 1.60805 13.6693C1.60805 15.2083 1.60976 16.2816 1.71863 17.0915C1.82439 17.8783 2.01784 18.2948 2.31453 18.5916C2.61123 18.8883 3.0278 19.0818 3.81443 19.1876C4.62419 19.2964 5.69742 19.2981 7.23623 19.2981H13.6684C15.2072 19.2981 16.2804 19.2964 17.0903 19.1876C17.8769 19.0818 18.2934 18.8883 18.5901 18.5916C18.8869 18.2948 19.0803 17.8783 19.1861 17.0915C19.2949 16.2816 19.2966 15.2083 19.2966 13.6693C19.2966 13.2253 19.6566 12.8652 20.1006 12.8652C20.5447 12.8652 20.9047 13.2253 20.9047 13.6693V13.7282C20.9047 15.1945 20.9047 16.3763 20.7798 17.3058C20.6501 18.2709 20.3725 19.0834 19.7272 19.7287C19.0819 20.3741 18.2695 20.6517 17.3046 20.7814C16.3751 20.9063 15.1934 20.9063 13.7273 20.9063H7.17741C5.71131 20.9063 4.52958 20.9063 3.60016 20.7814C2.63521 20.6517 1.82275 20.3741 1.17747 19.7288C0.53219 19.0834 0.254651 18.2709 0.124914 17.3058C-4.23965e-05 16.3763 -2.0879e-05 15.1945 5.61695e-07 13.7282C5.61695e-07 13.7086 5.61695e-07 13.689 5.61695e-07 13.6693C5.61695e-07 13.2253 0.359979 12.8652 0.804026 12.8652Z"
											fill="#EFBD8A"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M10.4515 0C10.6773 0 10.8926 0.0949065 11.0449 0.261518L15.333 4.95216C15.6327 5.27991 15.61 5.78854 15.2822 6.0882C14.9545 6.38787 14.4459 6.36509 14.1463 6.03734L11.2556 2.87528V14.742C11.2556 15.1861 10.8956 15.5461 10.4515 15.5461C10.0075 15.5461 9.64751 15.1861 9.64751 14.742V2.87528L6.7568 6.03734C6.45717 6.36509 5.9486 6.38787 5.62088 6.0882C5.29316 5.78854 5.27038 5.27991 5.57001 4.95216L9.85817 0.261518C10.0105 0.0949065 10.2258 0 10.4515 0Z"
											fill="#941AF2"
										/>
									</svg>
									<p>
										Drag your logo here, or <span>browse</span>
									</p>
									<FileUpload
										id="fileInput"
										allowedFileTypes={['image/png']}
										onFileSelect={(e) => handleFileSelected(e, Constants.companyLogo)}
										allowedHeight={130}
										allowedWidth={610}
										fileUploadingType="companyLogo"
									/>
									{/* <FileUpload id="fileInput" onFileSelect={(e) => handleFileSelected(e, Constants.companyLogo)} /> */}
								</div>
								<div className="size-and-format-cupload">
									<p>Default size: 610x130px</p>
									<div className="label-format-cupload">
										<label>Format:</label>
										{/* <span>JPEG</span> */}
										<span>PNG</span>
										{/* <span>PDF</span> */}
									</div>
								</div>
							</div>
						</>
					)}
					{companyLogo?.src !== '' && (
						<div className="block-clogo-profile">
							<div className="logo-main-profile">
								{companyLogoType === 'pdf' || companyLogoType === 'application/pdf' ? (
									<iframe src={companyLogo.src} width="716" height="200" title="PDF Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
								) : (
									<Image src={companyLogo.src} alt="" />
								)}
							</div>
							<div className="size-cross-logo">
								<p>
									{/* Format: <span>{companyLogoType === 'jpg' ? 'JPEG' : companyLogoType.toUpperCase()}</span> */}
									Format: <span>{companyLogoType.toUpperCase()}</span>
								</p>
								{isEdit && (
									<Link onClick={() => clearImage(Constants.companyLogo)} to={''}>
										<svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
											<rect y="0.5" width="34" height="34" rx="17" fill="#941AF2" />
											<path
												d="M11.9999 12.5001L22.0001 22.5003M22.0001 12.5001L11.9999 22.5003"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</Link>
								)}
							</div>
						</div>
					)}
				</div>
				<div className="watermark-profile-section">
					<div className="water-heading-switch-profile">
						<div className="heading-box-sp">
							<h6>Enable Watermark</h6>
							<p>This feature will add a watermark to your project videos.</p>
						</div>
						<div className="switch-sidebox">
							<div className="toggle_custom">
								<Checkbox id={''} checked={isWaterMarkApply} onChange={handleIsWaterMarkToggle} disabled={!isEdit} />
								<label className="button_toggle"></label>
							</div>
						</div>
					</div>
				</div>
				{isWaterMarkApply && (
					<>
						<div className="upload-company-logo-profile upload-water-logo-profile">
							<label>Upload Watermark logo</label>
							{companyWaterMark?.src !== '' && (
								<>
									<div className="block-clogo-profile">
										<div className="logo-main-profile">
											{companyWaterMarkType === 'pdf' || companyWaterMarkType === 'application/pdf' ? (
												<iframe
													src={companyWaterMark.src}
													width="716"
													height="200"
													title="PDF Preview"
													style={{ maxWidth: '100%', maxHeight: '200px' }}
												/>
											) : (
												<Image src={companyWaterMark.src} alt="" />
											)}
										</div>
										<div className="size-cross-logo">
											<p>
												{/* Format: <span>{companyWaterMarkType === 'jpg' ? 'JPEG' : companyWaterMarkType.toUpperCase()}</span> */}
												Format: <span>{companyWaterMarkType.toUpperCase()}</span>
											</p>
											{isEdit && (
												<Link onClick={() => clearImage(Constants.companyWaterMark)} to={''}>
													<svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
														<rect y="0.5" width="34" height="34" rx="17" fill="#941AF2" />
														<path
															d="M11.9999 12.5001L22.0001 22.5003M22.0001 12.5001L11.9999 22.5003"
															stroke="white"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</Link>
											)}
										</div>
									</div>
								</>
							)}
							{companyWaterMark.src === '' && (
								<>
									<div className="upload-clogo-profile">
										<div className="box-upload-profile-clogo">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M0.804026 12.8652C1.24807 12.8652 1.60805 13.2253 1.60805 13.6693C1.60805 15.2083 1.60976 16.2816 1.71863 17.0915C1.82439 17.8783 2.01784 18.2948 2.31453 18.5916C2.61123 18.8883 3.0278 19.0818 3.81443 19.1876C4.62419 19.2964 5.69742 19.2981 7.23623 19.2981H13.6684C15.2072 19.2981 16.2804 19.2964 17.0903 19.1876C17.8769 19.0818 18.2934 18.8883 18.5901 18.5916C18.8869 18.2948 19.0803 17.8783 19.1861 17.0915C19.2949 16.2816 19.2966 15.2083 19.2966 13.6693C19.2966 13.2253 19.6566 12.8652 20.1006 12.8652C20.5447 12.8652 20.9047 13.2253 20.9047 13.6693V13.7282C20.9047 15.1945 20.9047 16.3763 20.7798 17.3058C20.6501 18.2709 20.3725 19.0834 19.7272 19.7287C19.0819 20.3741 18.2695 20.6517 17.3046 20.7814C16.3751 20.9063 15.1934 20.9063 13.7273 20.9063H7.17741C5.71131 20.9063 4.52958 20.9063 3.60016 20.7814C2.63521 20.6517 1.82275 20.3741 1.17747 19.7288C0.53219 19.0834 0.254651 18.2709 0.124914 17.3058C-4.23965e-05 16.3763 -2.0879e-05 15.1945 5.61695e-07 13.7282C5.61695e-07 13.7086 5.61695e-07 13.689 5.61695e-07 13.6693C5.61695e-07 13.2253 0.359979 12.8652 0.804026 12.8652Z"
													fill="#EFBD8A"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M10.4515 0C10.6773 0 10.8926 0.0949065 11.0449 0.261518L15.333 4.95216C15.6327 5.27991 15.61 5.78854 15.2822 6.0882C14.9545 6.38787 14.4459 6.36509 14.1463 6.03734L11.2556 2.87528V14.742C11.2556 15.1861 10.8956 15.5461 10.4515 15.5461C10.0075 15.5461 9.64751 15.1861 9.64751 14.742V2.87528L6.7568 6.03734C6.45717 6.36509 5.9486 6.38787 5.62088 6.0882C5.29316 5.78854 5.27038 5.27991 5.57001 4.95216L9.85817 0.261518C10.0105 0.0949065 10.2258 0 10.4515 0Z"
													fill="#941AF2"
												/>
											</svg>
											<p>
												Drag your logo here, or <span>browse</span>
											</p>
											<FileUpload
												id="fileInput"
												allowedFileTypes={['image/png']}
												onFileSelect={(e) => handleFileSelected(e, Constants.companyWaterMark)}
												allowedHeight={50}
												allowedWidth={100}
												fileUploadingType="companyWaterMark"
											/>
											{/* <FileUpload id="fileInput" onFileSelect={(e) => handleFileSelected(e, Constants.companyWaterMark)} /> */}
										</div>
										<div className="size-and-format-cupload">
											<p>Default size: 100x50px</p>
											<div className="label-format-cupload">
												<label>Format:</label>
												{/* <span>JPEG</span> */}
												<span>PNG</span>
												{/* <span>PDF</span> */}
											</div>
										</div>
									</div>
								</>
							)}
						</div>
						{companyWaterMark.src && (
							<div className="position-of-watermark-profile">
								<label>Select position of watermark logo</label>
								<div className="button-group-wp buttons-section-watermark">
									<Button
										className={waterMarkPos === WaterMarkPositionTypes.TopLeft ? 'active' : ''}
										id={'Top left'}
										label={'Top left'}
										onClick={() => handleButtonClick(WaterMarkPositionTypes.TopLeft)}
										disabled={!isEdit}
									>
										<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M17 15.7045L17 17.25L15.4545 17.25L1.54545 17.25L0 17.25L1.84293e-08 15.7045L1.84294e-07 1.79545L2.02723e-07 0.25L1.54546 0.25L15.4545 0.25L17 0.25L17 1.79545L17 15.7045ZM15.4545 15.7045L15.4545 14.1591L15.4545 3.3409L15.4545 1.79545L13.9091 1.79545L3.09091 1.79545L1.54546 1.79545L1.54546 3.3409L1.54546 14.1591L1.54545 15.7045L3.09091 15.7045L13.9091 15.7045L15.4545 15.7045ZM4.78836 11.7391L4.78836 5.55731L10.9702 5.55731L10.9702 7.10277L7.42661 7.10276L12.2505 11.9266L11.1577 13.0194L6.33381 8.19555L6.33381 11.7391L4.78836 11.7391Z"
												fill="black"
											/>
										</svg>
									</Button>
									<Button
										className={waterMarkPos === WaterMarkPositionTypes.TopRight ? 'active' : ''}
										id={'Top Right'}
										label={'Top Right'}
										onClick={() => handleButtonClick(WaterMarkPositionTypes.TopRight)}
										disabled={!isEdit}
									>
										<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M-1.84293e-08 15.4545L0 17L1.54545 17L15.4545 17L17 17L17 15.4545L17 1.54545L17 -5.16946e-07L15.4545 -4.98517e-07L1.54545 -3.32653e-07L-2.02723e-07 -3.14223e-07L-1.84294e-07 1.54545L-1.84293e-08 15.4545ZM1.54545 15.4545L1.54545 13.9091L1.54545 3.0909L1.54545 1.54545L3.09091 1.54545L13.9091 1.54545L15.4545 1.54545L15.4545 3.0909L15.4545 13.9091L15.4545 15.4545L13.9091 15.4545L3.09091 15.4545L1.54545 15.4545ZM12.2116 11.4891L12.2116 5.30731L6.02983 5.30731L6.02983 6.85276L9.5734 6.85276L4.74953 11.6766L5.84234 12.7694L10.6662 7.94555L10.6662 11.4891L12.2116 11.4891Z"
												fill="black"
											/>
										</svg>
									</Button>
									<Button
										className={waterMarkPos === WaterMarkPositionTypes.BottomLeft ? 'active' : ''}
										id={'Bottom left'}
										label={'Bottom left'}
										onClick={() => handleButtonClick(WaterMarkPositionTypes.BottomLeft)}
										disabled={!isEdit}
									>
										<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M15.4545 0L17 0V1.54545L17 15.4545V17H15.4545L1.54545 17H-3.14223e-07V15.4545L-3.14223e-07 1.54545V0L1.54545 0L15.4545 0ZM15.4545 1.54545L13.9091 1.54545L3.0909 1.54545L1.54545 1.54545V3.09091L1.54545 13.9091V15.4545H3.0909L13.9091 15.4545H15.4545V13.9091L15.4545 3.09091V1.54545ZM11.4891 12.2116L5.30731 12.2116V6.02983H6.85276V9.5734L11.6766 4.74953L12.7694 5.84234L7.94555 10.6662L11.4891 10.6662V12.2116Z"
												fill="black"
											/>
										</svg>
									</Button>
									<Button
										className={waterMarkPos === WaterMarkPositionTypes.BottomRight ? 'active' : ''}
										id={'Bottom right'}
										label={'Bottom right'}
										onClick={() => handleButtonClick(WaterMarkPositionTypes.BottomRight)}
										disabled={!isEdit}
									>
										<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M1.54545 0L0 0L0 1.54545L0 15.4545L0 17H1.54545L15.4545 17H17V15.4545L17 1.54545V0L15.4545 0L1.54545 0ZM1.54545 1.54545L3.0909 1.54545L13.9091 1.54545L15.4545 1.54545V3.09091L15.4545 13.9091V15.4545H13.9091L3.0909 15.4545H1.54545L1.54545 13.9091L1.54545 3.09091L1.54545 1.54545ZM5.51086 12.2116L11.6927 12.2116V6.02983H10.1472V9.5734L5.32336 4.74953L4.23056 5.84234L9.05445 10.6662L5.51086 10.6662V12.2116Z"
												fill="black"
											/>
										</svg>
									</Button>
								</div>
							</div>
						)}
					</>
				)}
				<div className="landing_theme_data">
					<hr />
					<h6>Choose landing page theme</h6>
					<div className="Landing_themeInner">
						<div className="dyanmic_logo">
							<div className="theme_thparent light">
								<figure className="theme_logo">
									<Image src={companyLogo.src || companyInformation.logo} alt="" />
								</figure>
							</div>
							<ul className="theme_radiobtn">
								<li className="radio_btn_cstm">
									<Input
										disabled={!isEdit}
										onChange={() => {
											setEditprofileDetails((prev) => ({
												...prev, // Spread the previous state to retain other properties
												backgroundTheme: LandingPageThemeEnum.Light, // Set isWaterMarkEnable to the new value
											}));
										}}
										id="theme_radio_btn"
										type="radio"
										checked={backgroundTheme === LandingPageThemeEnum.Light}
										value={LandingPageThemeEnum.Light}
										name="LIGHT"
										_key={''}
									/>
								</li>
								<li className="theme_text">Light</li>
							</ul>
						</div>

						<div className="dyanmic_logo">
							<div className="theme_thparent dark">
								<figure className="theme_logo">
									<Image src={companyLogo.src || companyInformation.logo} alt="" />
								</figure>
							</div>
							<ul className="theme_radiobtn">
								<li className="radio_btn_cstm">
									<Input
										disabled={!isEdit}
										onChange={() => {
											setEditprofileDetails((prev) => ({
												...prev, // Spread the previous state to retain other properties
												backgroundTheme: LandingPageThemeEnum.Dark, // Set isWaterMarkEnable to the new value
											}));
										}}
										id="theme_radio_btn"
										type="radio"
										value={LandingPageThemeEnum.Dark}
										checked={backgroundTheme === LandingPageThemeEnum.Dark}
										name="DARK"
										_key={''}
									/>
								</li>
								<li className="theme_text">Dark</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompanyDetails;

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import ProfileDetails from './ProfileDetails/Index';
import CompanyDetails from './companyDetails/Index';
import PlanDetails from './Plan/Index';
import Tabs from 'react-bootstrap/esm/Tabs';
import Tab from 'react-bootstrap/esm/Tab';
import './myProfile.css';
import Button from '../../components/button/Index';
import ApiService from '../../helpers/api/Index';
import { showToast } from '../../components/toaster/Index';
import { EditProfileModel, UserProfileModel } from '../../types/models/PersonalDetailsModel';
import { Link } from 'react-router-dom';
import { SidebarMenuEnum } from '../../types/enums/SideBarEnums';
import { WaterMarkPositionTypes } from '../../types/enums/WaterMarkEnum';
import { Constants } from '../../constants/Constants';
import ModalComponent from '../../components/Modal/Index';
import Input from '../../components/input/Index';
import { EmailOtpVerificationModel } from '../../types/models/EmailOtpVerificationModel';
import Loader from '../../components/loader/Index';
import { ImageProps } from '../../types/type/ImageProps';
import { Clear_Local_storage, validatePhoneNumber } from '../../helpers/Utils';
import { UpdatedContactFieldEnum } from '../../../src/types/enums/UpdateFieldEnum';
import { LandingPageThemeEnum } from '../../types/enums/LandingPageThemeEnum';
import { confirmAlert } from 'react-confirm-alert';
import { DeleteAccount } from '../../helpers/api/DeleteAccount';
enum TabEnum {
	PlanDetails = 'PlanDetails',
	CompanyDetails = 'CompanyDetails',
	ProfileDetails = 'ProfileDetails',
}
// Interface representing the state for logo and watermark images
interface LogoWatermarkState {
	// Properties representing the logo image
	logo: ImageProps;

	// Properties representing the watermark image
	waterMark: ImageProps;
}
interface ProfileProps {
	handleActiveTab: (activeTab: SidebarMenuEnum) => void;
	setIsUserProfile: (isUserProfile: boolean) => void;
	handleOpenClose: () => void;
}
const MyProfile: React.FC<ProfileProps> = ({ handleActiveTab, setIsUserProfile, handleOpenClose }) => {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [isChangeContact, setIsChangeContact] = useState<boolean>(false);
	const [phoneError, setPhoneError] = useState<string>('');
	const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true);
	const [validGstNumber, setValidGstNumber] = useState<boolean>(false);
	const [updatedProfileData, setUpdatedProfileData] = useState<UserProfileModel>({
		personalDetails: {
			userName: '',
			firstName: '',
			lastName: '',
			email: '',
			phoneNumber: '',
			profileImage: '',
			isFacebookUser: false,
			isGoogleUser: false,
			isInstaUser: false,
			backgroundTheme: null,
		},
		companyDetails: {
			companyName: '',
			gstNumber: '',
			logo: '',
			waterMark: '',
			watermarkPosition: '',
		},
		planDetails: {
			planName: '',
			expireDate: '',
			totalData: 0,
			dataUsed: 0,
			isCancelled: false,
			startDate: '',
		},
	});
	const [editprofileDetails, setEditprofileDetails] = useState<EditProfileModel>({
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		companyName: '',
		gstNumber: '',
		profileImage: null,
		waterMarkPos: null,
		logo: null,
		waterMark: null,
		useExistingLogo: false,
		isWaterMarkEnable: false,
		backgroundTheme: null,
	});
	const [otp, setOtp] = useState(Array(6).fill(''));
	const [isValidData, setIsValidData] = useState<boolean>(false);
	const [profileData, setProfileData] = useState<UserProfileModel>();
	const [secondsLeft, setSecondsLeft] = useState(20);
	const [isResetLinkDisabled, setIsResetLinkDisabled] = useState(true);
	const [loading, setLoading] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState<TabEnum>(TabEnum.ProfileDetails);
	const inputRefs = useRef<(HTMLInputElement | null)[]>([]); // Ref to manage input fields
	const [uploadState, setUploadState] = useState<LogoWatermarkState>({
		logo: { src: '' },
		waterMark: { src: '' },
	});
	const [isCompanyDetailsError, setIsCompanyDetailsError] = useState<{ companyName: boolean; gstNumber: boolean }>({
		companyName: false,
		gstNumber: false,
	});
	const hasIndianCountryCode = profileData?.personalDetails.phoneNumber?.includes('91');
	const handleInputChangeforPersonalDetails = async (e: React.ChangeEvent<HTMLInputElement>) => {
		var { name, value } = e.target;
		const inputValueElement = document.querySelector('.form-control.input-value') as HTMLInputElement;
		//const inputValue = inputValueElement.value;
		const inputValue = inputValueElement ? inputValueElement.value : value || '';
		//const inputValue = inputValueElement ? inputValueElement.value : '';
		const sanitizedValue = inputValue.replace(/^\+|[-\s]/g, ''); // Remove +, dashes, and spaces
		const formattedValuePrefix = sanitizedValue.slice(0, 2);
		const dbPhoneNumberPrefix = profileData?.personalDetails.phoneNumber?.slice(0, 2);
		// Check if the prefixes match
		if (name === 'phoneNumber' || name === undefined) {
			if (formattedValuePrefix === dbPhoneNumberPrefix && (e.target.className === 'country-name' || e.target.className === 'country')) {
				value = profileData?.personalDetails.phoneNumber ?? '';
				name = 'phoneNumber';
				setUpdatedProfileData((prevData) => ({
					...prevData,
					personalDetails: {
						...prevData.personalDetails,
						[name]: value, // Dynamically set the property based on input name
					},
				}));
				// Clear error if valid
				setPhoneError('');
			} else if (
				profileData?.personalDetails?.phoneNumber !== sanitizedValue ||
				(profileData?.personalDetails?.phoneNumber === sanitizedValue && name === 'phoneNumber')
			) {
				name = 'phoneNumber';
				setUpdatedProfileData((prevData) => ({
					...prevData,
					personalDetails: {
						...prevData.personalDetails,
						[name]: sanitizedValue, // Use sanitized phone number
					},
				}));
				// Check for required phone number first
				if (!sanitizedValue) {
					setPhoneError('Phone number is required'); // Set error message
					return; // Exit if phone number is empty
				}
				// Validate the phone number asynchronously
				const isValid = await validatePhoneNumber('+' + sanitizedValue);
				setIsPhoneNumberValid(isValid);
				if (!isValid) {
					setPhoneError('Invalid phone number'); // Set error message
					return; // Exit if phone number is invalid
				}
				// Clear error if valid
				setPhoneError('');
			}
		} else {
			// Update the profile data state for other fields
			setUpdatedProfileData((prevData) => ({
				...prevData,
				personalDetails: {
					...prevData.personalDetails,
					[name]: value, // Dynamically set the property based on input name
				},
			}));
		}
	};

	// Function to handle changes in company details input fields
	const handleInputChangeforCompanyDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
		// Destructure the name and value from the event target
		const { name, value } = e.target;
		// Update the profile data state with the new company detail
		setEditprofileDetails((prevData) => ({
			...prevData,
			[name]: value, // Dynamically set the property based on input name
		}));
	};

	// Function to toggle edit mode on or off
	const toggleEditMode = () => {
		setIsEdit(!isEdit); // Toggle the edit state between true and false
	};
	// Function to save edits made to the profile
	const Save_Edits = async () => {
		const formData = new FormData(); // Create a new FormData object to hold form data
		// Append personal details to FormData
		formData.append('firstName', updatedProfileData.personalDetails.firstName ?? '');
		formData.append('lastName', updatedProfileData.personalDetails.lastName ?? '');
		formData.append('email', updatedProfileData.personalDetails.email ?? '');
		formData.append('phoneNumber', updatedProfileData.personalDetails.phoneNumber ?? '');
		formData.append('backgroundTheme', (editprofileDetails.backgroundTheme || updatedProfileData.personalDetails.backgroundTheme) ?? '');

		// Append company details to FormData
		formData.append('companyName', editprofileDetails.companyName || (profileData && profileData.companyDetails.companyName) || '');
		formData.append('gstNumber', editprofileDetails.gstNumber || '');

		// Append additional details if they exist
		//formData.append('waterMarkPos', editprofileDetails.waterMarkPos || (profileData && profileData.companyDetails.watermarkPosition) || '');
		formData.append('waterMarkPos', editprofileDetails.waterMarkPos || WaterMarkPositionTypes.BottomRight);
		formData.append('useExistingLogo', editprofileDetails.useExistingLogo.toString());

		formData.append('profileImage', editprofileDetails.profileImage ?? ''); // Append profile image if provided

		formData.append('logo', editprofileDetails.logo ?? '');

		if (!editprofileDetails.useExistingLogo && editprofileDetails.waterMark) formData.append('waterMark', editprofileDetails.waterMark);

		formData.append('isWaterMarkEnable', editprofileDetails?.isWaterMarkEnable ? 'true' : 'false');
		formData.append('isFacebookUser', updatedProfileData?.personalDetails.isFacebookUser ? 'true' : 'false');
		formData.append('isInstaUser', updatedProfileData?.personalDetails.isInstaUser ? 'true' : 'false');
		formData.append('isGoogleUser', updatedProfileData?.personalDetails.isGoogleUser ? 'true' : 'false');

		// Call API service to update the profile with the FormData
		await ApiService.EditMyProfile(formData)
			.then(async (result) => {
				if (result.isSuccess) {
					showToast('Profile updated successfully'); // Show success message on successful update
					toggleEditMode(); // Exit edit mode after saving
					setIsUserProfile(true);
					handleOpenClose();
					getMyProfile();
					//navigate(App_Urls.DashBoard, { replace: false, state: { openSideBox: false } });
				} else {
					showToast(result.error.userMessage ?? 'Something went wrong', { type: 'error' }); // Show error message if update fails
				}
			})
			.catch((error) => {
				showToast(error.message ?? 'Something went wrong', { type: 'error' }); // Handle unexpected errors and show error message
			});
	};
	// Function to validate logo file type before upload
	const isValidLogo = (file: File) => {
		const acceptedFormats = ['image/jpeg', 'image/png']; // Define accepted file formats
		return acceptedFormats.includes(file.type); // Check if file type is valid
	};
	/*The handleFileSelect function manages the selection of files for various purposes
	 *(user profile image, company logo, and watermark) in a user profile editing context.
	 * It handles different scenarios, such as clearing a watermark, processing a file, validating
	 * its format, and updating state accordingly. The function uses early returns for clarity and
	 * includes error handling for unsupported file formats.
	 */
	const handleFileSelect = (file: File | null, type: string, isClear?: boolean) => {
		if (isClear) {
			// Clear the watermark if isClear is true
			if (type === Constants.companyWaterMark) {
				setEditprofileDetails((prev) => ({
					...prev,
					waterMark: null,
				}));
			}
			return; // Exit early if we're just clearing
		}
		if (file == null) {
			// Handle case where file is null and it's for the watermark
			if (type === Constants.companyWaterMark) {
				setEditprofileDetails((prev) => ({
					...prev,
					waterMark: prev.logo,
				}));
			}
			return; // Exit if no file and not for watermark
		}
		if (!isValidLogo(file)) {
			showToast('Invalid logo format. Accepted formats: JPEG, PNG', { type: 'error' });
			return; // Exit if the format is invalid
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);
		// Update profile details based on the type
		setEditprofileDetails((prev) => {
			const updatedDetails = { ...prev };
			switch (type) {
				case Constants.userProfileImage:
					updatedDetails.profileImage = file;
					break;
				case Constants.companyLogo:
					updatedDetails.logo = file;
					break;
				case Constants.companyWaterMark:
					updatedDetails.waterMark = file;
					break;
				default:
					console.warn(`Unhandled file type: ${type}`);
			}
			return updatedDetails;
		});
	};
	const getMyProfile = async () => {
		// Call the API to retrieve the user's profile data
		await ApiService.myProfile()
			.then((result) => {
				// Check if the API call was successful
				if (result.isSuccess) {
					// Update the state with the response data if successful
					setUpdatedProfileData(result.response);
					//added this to hold the privious statedata
					setProfileData(result.response);
					setEditprofileDetails((prev) => ({
						...prev,
						companyName: result.response.companyDetails.companyName,
						backgroundTheme: result?.response?.personalDetails?.backgroundTheme,
					}));
				} else {
					// Show an error message if the API call was not successful
					showToast(result.error.userMessage ?? 'Something went wrong', { type: 'error' });
				}
			})
			.catch((error) => {
				// Handle any errors that occur during the API call
				showToast(error.message ?? 'Something went wrong', { type: 'error' });
			});
	};
	const handleWaterMarkPosChange = (position: WaterMarkPositionTypes) => {
		// Update the edit profile details state with the new watermark position
		setEditprofileDetails((prev) => ({
			// Spread the previous state to retain other properties
			...prev,
			// Set the new watermark position
			waterMarkPos: position,
		}));
	};
	useEffect(() => {
		getMyProfile();
	}, []);
	/**
	 * Validates the user's profile data, ensuring required fields are properly filled out.
	 * Checks include email format, non-empty names, phone number, company name, logo upload, and watermark status.
	 */
	const validateProfileData = () => {
		if (profileData) {
			const { firstName, lastName, email, phoneNumber } = updatedProfileData.personalDetails;
			// Validate email format using a regular expressionl
			const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email ?? '');
			// Ensure company name is not just whitespace
			const isCompanyNameValid = !!editprofileDetails.companyName.trim();
			const isvalidGstNumber = !isCompanyDetailsError.gstNumber;
			// Check that phone number is not empty
			const isPhoneNumberNotEmpty = !!phoneNumber?.trim() && phoneError === '';
			// Ensure first name is not empty
			const isValidFirstName = !!firstName?.trim();
			// Ensure last name is not empty
			// Check last name validity only for non-Insta users
			const isValidLastName = updatedProfileData.personalDetails.isInstaUser
				? true // Skip validation for Insta users
				: !!lastName?.trim(); // For non-Insta users, ensure last name is not empty
			// Check if a logo has been uploaded
			const isValidLogo = !!uploadState.logo.src;
			// Check watermark validity based on whether it's enabled
			const isValidWaterMark = editprofileDetails.isWaterMarkEnable
				? !!uploadState.waterMark.src // Must not be null if enabled
				: true; // Valid if watermark is disabled
			// Aggregate all validations; if any condition is false, the overall validity will be false
			const valid =
				isValidEmail &&
				isCompanyNameValid &&
				isValidFirstName &&
				isPhoneNumberNotEmpty &&
				isValidLastName &&
				isValidLogo &&
				isValidWaterMark &&
				isvalidGstNumber &&
				isPhoneNumberValid; //this case is added to check if the number is valid or not
			// Set the overall validity state
			setIsValidData(valid);
		}
		// else {
		// 	setIsValidData(profileData?.personalDetails.profileImage !== null ||
		// 		editprofileDetails.profileImage !== null);
		// }
	};

	useEffect(() => {
		validateProfileData();
	}, [
		updatedProfileData,
		uploadState,
		editprofileDetails.isWaterMarkEnable,
		editprofileDetails.profileImage,
		profileData?.personalDetails.profileImage,
		editprofileDetails.companyName,
		isPhoneNumberValid,
		phoneError,
		isCompanyDetailsError,
	]);
	/**
	 * useEffect hook that manages a countdown timer.
	 *
	 * This hook decrements the `secondsLeft` state by 1 every second, simulating a countdown.
	 * When the `secondsLeft` value is greater than 0, it sets a timeout to decrease the value
	 * after 1 second. If `secondsLeft` reaches 0, it enables a link by setting `setIsLinkDisabled(false)`.
	 * The hook also cleans up the timer on component unmount to prevent memory leaks.
	 *
	 * @param {number} secondsLeft - The number of seconds left in the countdown.
	 * @param {React.Dispatch<React.SetStateAction<number>>} setSecondsLeft - The state setter for `secondsLeft`.
	 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsLinkDisabled - The state setter for the link's disabled status.
	 */
	useEffect(() => {
		if (secondsLeft > 0 && isChangeContact && activeTab === TabEnum.ProfileDetails) {
			const timer = setTimeout(() => {
				setSecondsLeft(secondsLeft - 1);
			}, 1000);

			return () => clearTimeout(timer); // Cleanup the timer on unmount
		} else {
			setIsResetLinkDisabled(false); // Enable the link after the countdown
		}
	}, [secondsLeft, isChangeContact]);
	const handleOtpChange = (index: number, value: string) => {
		// Allow only numbers and limit input to a single character
		if (value.match(/^\d$/) || value === '') {
			const newOtp = [...otp];
			newOtp[index] = value;
			setOtp(newOtp);

			if (value && index < otp.length - 1) {
				inputRefs.current[index + 1]?.focus();
			}
		}
	};
	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		// Check if the pressed key is 'Backspace' and the current input is empty
		// If so, move focus to the previous input field if it exists
		if (event.key?.toLowerCase() === 'backspace' && !otp[index] && index > 0) {
			inputRefs.current[index - 1]?.focus();
		}
	};
	// Function to verify the OTP entered by the user
	const otpVerification = async () => {
		setLoading(true); // Start loading state
		try {
			// Check if the OTP array is not empty
			if (otp && otp.length > 0) {
				// Prepare the payload for the OTP verification API call
				var payload: EmailOtpVerificationModel = {
					email: profileData?.personalDetails.email ?? '', // Use the previous email or an empty string if undefined
					otp: otp.join('')?.trim(), // Join the OTP array into a single string and trim whitespace
				};

				// Call the API service to verify the OTP
				var res = await ApiService.OtpVerification(payload);

				// Check if the response indicates success
				if (res && res.isSuccess) {
					// If successful, save the edits made to the profile
					await Save_Edits();
					//added this to update the token after chnage the email
					await ApiService.Refresh_Token();
					// Reset the OTP input fields to empty strings
					setOtp(Array(6).fill(''));
				} else {
					// If verification fails, show an error toast with a user-friendly message
					showToast(res.error?.userMessage ?? 'Unable to verify otp', { type: 'error' });
					setOtp(Array(6).fill(''));
				}
			}
		} catch (error) {
			// Handle any unexpected errors
			showToast('An error occurred during OTP verification. Please try again.', { type: 'error' });
		} finally {
			setLoading(false); // End loading state
		}
	};

	// Function to get the OTP for resetting the password
	const getOTP = async (isContactUpdated?: boolean) => {
		try {
			// Call the API service to request an OTP
			var res = await ApiService.ProfileResetOTP(isContactUpdated ?? false, getUpdateContact());

			// Check if the API response indicates success
			if (res.isSuccess) {
				// Show a success toast with the response message
				showToast(res.response, { type: 'success' });
			} else {
				// Show an error toast with the user-friendly error message
				showToast(res.error.userMessage, { type: 'error' });
			}
		} catch (error) {
			// If an error occurs, set a flag indicating a change in contact information
			setIsChangeContact(true);
			setSecondsLeft(20);

			// Show a generic error toast message
			showToast('Something went wrong', { type: 'error' });
		}
	};
	const handleProfileDataSave = async () => {
		// Check if the user is an Instagram user
		const isInstaUser = profileData?.personalDetails.isInstaUser ?? false;
		// Get the email and phone number from updated profile data
		const email = profileData?.personalDetails.email?.toLowerCase() ?? '';
		const phoneNumber = profileData?.personalDetails.phoneNumber ?? '';

		// Check if both email and phone number are null or empty
		const isContactInfoMissing = !email && !phoneNumber;

		if (isInstaUser && isContactInfoMissing) {
			// If Instagram user and no contact info, directly save edits
			await Save_Edits();
		} else {
			// Check if email or phone number has changed
			const hasEmailChanged = email !== (updatedProfileData?.personalDetails.email?.toLowerCase() ?? '');
			const hasPhoneNumberChanged = phoneNumber !== updatedProfileData?.personalDetails.phoneNumber;

			if (hasEmailChanged || hasPhoneNumberChanged) {
				setSecondsLeft(20);
				setIsChangeContact(true);
				await getOTP(true);
			} else {
				// If no changes are detected, proceed to save the edits
				await Save_Edits();
			}
		}
	};
	/**
	 * Reverts any unsaved changes to the profile by restoring the previous profile data,
	 * and exits the edit mode by setting `isEdit` to false.
	 */
	const cancelEdits = () => {
		// Check if profileData exists (i.e., data before the current edits)
		if (profileData) {
			// If it exists, revert profileData to its previous state
			getMyProfile();
		}
		setPhoneError('');
		// Set isEdit to false to exit the edit mode
		setIsEdit(false);
		setIsCompanyDetailsError((prev) => ({
			...prev,
			gstNumber: false,
		}));
	};

	const getUpdateContact = () => {
		const hasEmailChanged = profileData?.personalDetails.email?.toLowerCase() !== (updatedProfileData?.personalDetails.email?.toLowerCase() ?? '');
		if (hasEmailChanged) return UpdatedContactFieldEnum.EMAIL;

		const hasPhoneNumberChanged = profileData?.personalDetails.phoneNumber?.toLowerCase() !== updatedProfileData?.personalDetails.phoneNumber;
		if (hasPhoneNumberChanged) return UpdatedContactFieldEnum.PHONENUMBER;

		return UpdatedContactFieldEnum.NONE;
	};

	// Function to handle paste events
	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		const pastedOtp = event.clipboardData.getData('Text').toUpperCase().replace(/\s+/g, ''); // Remove spaces

		// Ensure the OTP length is valid before updating the state
		if (pastedOtp.length === otp.length) {
			const newOtp = pastedOtp.split('');
			setOtp(newOtp); // Update state with the pasted OTP

			// Focus the last input field after pasting
			inputRefs.current[otp.length - 1]?.focus();
		}
	};
	// Explicitly define the type of onClose parameter
	const handleDeleteAccount = async (onClose: () => void) => {
		setLoading(true); // Show loader when deletion starts
		try {
			// Close modal and handle post-deletion steps
			onClose(); // Close the modal
			const response = await DeleteAccount(); // Wait for DeleteAccount to complete
			console.log('Account deleted successfully:', response);
			Clear_Local_storage();
			window.location.reload();
		} catch (error) {
			console.error('Error deleting account:', error);
			// Optionally handle errors
		} finally {
			setLoading(false); // Hide the loader when done
		}
	};
	const triggerDeleteAccount = async () => {
		try {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className="modal-content-delete">
							<div className="icon-modal-delete">
								<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="39" cy="39" r="38" fill="white" stroke="#EFBD8A" strokeWidth="2" />
									<path
										d="M41.3491 47.128H37.4291V44.44C37.5971 42.312 37.9891 41.472 40.8451 39.848C45.2691 37.216 48.9651 34.472 48.9651 29.936C48.9651 25.68 45.5491 22.936 40.3971 22.936C35.2451 22.936 32.6131 25.456 32.6131 28.648C32.6131 29.152 32.6691 29.712 32.8371 30.44H28.9731C28.6931 29.768 28.5811 28.984 28.5811 28.088C28.5811 24.336 31.8291 19.184 40.5651 19.184C47.6771 19.184 53.3891 23.272 53.3891 29.824C53.3891 36.432 47.2291 40.296 43.8131 42.368C41.0691 44.048 41.3491 44.048 41.3491 47.128ZM36.0851 56.368C36.0851 54.52 37.5411 53.008 39.3891 53.008C41.2371 53.008 42.6931 54.52 42.6931 56.368C42.6931 58.16 41.2371 59.616 39.3891 59.616C37.5411 59.616 36.0851 58.16 36.0851 56.368Z"
										fill="#941AF2"
									/>
								</svg>
							</div>
							<h5>Your Account will be permanently deleted</h5>
							<h5>Are you sure you want to delete your Account? You won't be able to recover any backups.</h5>
							<div className="buttons-modal-delete">
								<Button
									className="cmn-btn"
									label="Yes I'm Sure"
									onClick={() => handleDeleteAccount(onClose)} // Pass onClose to the delete handler
									id={''}
								/>
								<Button className="cmn-btn blank-btn" label="Cancel" onClick={onClose} id={'DeleteAccountCancel'} />
							</div>
						</div>
					);
				},
				closeOnClickOutside: false,
			});
		} catch (error: any) {
			showToast(error?.message ?? ' Something went wrong', { type: 'error' });
		}
	};

	useEffect(() => {
		console.log(editprofileDetails);
	}, [editprofileDetails]);
	return (
		<>
			{/* Conditionally render the loader when loading is true */}
			{loading && <Loader />}
			<div className="stepper-sidebox profile-sidebox">
				<div className="label-box-sidebox">
					<div className="iconbox-label-sidebox">
						<svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M1 34.8556V30.3119C1 24.7912 5.46315 20.3159 10.9687 20.3159H20.0312C25.5369 20.3159 30 24.7912 30 30.3119V34.8556M8.25 34.8556V29.4032M22.75 34.8556V29.4032M22.75 8.5023C22.75 12.5173 19.504 15.7722 15.5 15.7722C11.4959 15.7722 8.25 12.5173 8.25 8.5023C8.25 4.48726 11.4959 1.23242 15.5 1.23242C19.504 1.23242 22.75 4.48726 22.75 8.5023Z"
								stroke="url(#paint0_linear_2991_11970)"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<path
								d="M1 34.8552V30.3115C1 24.7908 5.46315 20.3154 10.9687 20.3154H20.0312C25.5369 20.3154 30 24.7908 30 30.3115V34.8552"
								stroke="url(#paint1_linear_2991_11970)"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<defs>
								<linearGradient id="paint0_linear_2991_11970" x1="1" y1="18.044" x2="30" y2="18.044" gradientUnits="userSpaceOnUse">
									<stop stopColor="#941AF2" />
									<stop offset="1" stopColor="#EFBD8A" />
								</linearGradient>
								<linearGradient id="paint1_linear_2991_11970" x1="1" y1="27.5853" x2="30" y2="27.5853" gradientUnits="userSpaceOnUse">
									<stop stopColor="#941AF2" />
									<stop offset="1" stopColor="#EFBD8A" />
								</linearGradient>
							</defs>
						</svg>
					</div>
					<div className="content-label-sidebox">
						<h6>My profile</h6>
						<p>Manage your plans, personal details, and company information here.</p>
					</div>
				</div>
				<div className="tabs-profile-edit">
					<Tabs
						onSelect={(e) => {
							setActiveTab(e as TabEnum);
						}}
						defaultActiveKey={activeTab}
						id="myProfile"
						className="mb-3"
						fill
					>
						<Tab eventKey={TabEnum.ProfileDetails} title="Personal Details">
							<ProfileDetails
								isEdit={isEdit}
								personalInformation={updatedProfileData.personalDetails}
								handleInputChangeforPersonalDetails={handleInputChangeforPersonalDetails}
								handleFileSelect={handleFileSelect}
								phoneError={phoneError}
								personalDetails={profileData?.personalDetails}
							/>
						</Tab>
						<Tab eventKey={TabEnum.CompanyDetails} title="Company Details">
							<CompanyDetails
								isEdit={isEdit}
								companyInformation={updatedProfileData.companyDetails}
								handleInputChangeforCompanyDetails={handleInputChangeforCompanyDetails}
								handleFileSelect={handleFileSelect}
								handleWaterMarkPosChange={handleWaterMarkPosChange}
								companyWaterMarkSrc={editprofileDetails.waterMark ? URL.createObjectURL(editprofileDetails.waterMark) : ''}
								setEditprofileDetails={setEditprofileDetails}
								setUploadState={setUploadState}
								editcompanyName={editprofileDetails.companyName}
								hasIndianCountryCode={hasIndianCountryCode ?? false}
								backgroundTheme={
									editprofileDetails?.backgroundTheme &&
									Object.values(LandingPageThemeEnum).includes(editprofileDetails?.backgroundTheme as LandingPageThemeEnum)
										? (editprofileDetails?.backgroundTheme as LandingPageThemeEnum)
										: profileData?.personalDetails?.backgroundTheme &&
											  Object.values(LandingPageThemeEnum).includes(profileData?.personalDetails?.backgroundTheme as LandingPageThemeEnum)
											? (profileData?.personalDetails?.backgroundTheme as LandingPageThemeEnum)
											: LandingPageThemeEnum.Light
								}
								setIsCompanyDetailsError={setIsCompanyDetailsError}
								isCompanyDetailsError={isCompanyDetailsError}
							/>
						</Tab>
						<Tab eventKey={TabEnum.PlanDetails} title="Plan Details">
							<PlanDetails planDetails={profileData?.planDetails ?? null} handleOpenClose={handleOpenClose} />
						</Tab>
					</Tabs>
					<div className="trasaction-link">
						<div className="delete-account-container">
							{/* Show delete button only in Personal Details tab */}
							{activeTab === TabEnum.ProfileDetails && (
								<Button className={'cmn-btn'} label={'Delete Account'} onClick={triggerDeleteAccount} id={'DeleteAccountButton'} />
							)}
						</div>
						<Link to="" onClick={() => handleActiveTab(SidebarMenuEnum.Transaction)}>
							Transaction history
							<svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8.8 1L14 6M14 6L8.8 11M14 6L1 6" stroke="#941AF2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
							</svg>
						</Link>
					</div>
				</div>
				<div className="button-after-login-screens">
					<div className="login-signup-btn d-flex two_btn_sec">
						{isEdit && <Button className={'cmn-btn'} label={'Cancel'} disabled={!isEdit} onClick={() => cancelEdits()} id={'CancelButton'} />}

						<hr />
						{isEdit ? (
							<Button className={'cmn-btn'} label={'Save Edits'} disabled={!isValidData} onClick={handleProfileDataSave} id={'SaveButton'} />
						) : (
							<Button className={'cmn-btn'} label={'Edit Profile'} onClick={toggleEditMode} id={'editProfile'} />
						)}
					</div>
				</div>
			</div>
			{isChangeContact && (
				<ModalComponent show={isChangeContact} className="share-modal otp-modal" handleClose={() => setIsChangeContact(false)} title="">
					{' '}
					{loading && <Loader />}
					<div className="otp-page">
						<div className="heading-otp-popup">
							{updatedProfileData.personalDetails && (
								<>
									<svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_3033_11283)">
											<path
												d="M13.1289 28.3543L14.078 25.5417L18.8383 27.4003V22.21H21.8042V27.4003L26.52 25.5417L27.5581 28.3543L22.6149 30.0004L25.645 34.0637L23.3514 35.7543L20.2669 31.513L17.301 35.7543L14.8788 34.0637L17.909 30.0004L13.1289 28.3543Z"
												fill="url(#paint0_linear_3033_11283)"
											/>
											<path
												d="M29.1641 28.3543L30.1181 25.5417L34.8784 27.4003V22.21H37.8196V27.4003L42.5107 25.5417L43.5487 28.3543L38.6055 30.0004L41.6357 34.0637L39.3421 35.7543L36.2822 31.513L33.3163 35.7543L30.8942 34.0637L33.9243 30.0004L29.1641 28.3543Z"
												fill="url(#paint1_linear_3033_11283)"
											/>
											<path
												d="M0.862524 34.1628V34.1633C0.856098 37.5621 2.19813 40.8247 4.59419 43.2352C6.99025 45.6458 10.2447 47.0075 13.6434 47.0215H34.8287C35.2079 47.0215 35.5717 46.8708 35.8399 46.6027C36.1081 46.3345 36.2588 45.9707 36.2588 45.5914C36.2588 45.2121 36.1081 44.8484 35.8399 44.5802C35.5717 44.312 35.208 44.1613 34.8287 44.1613H13.6422H13.6407C11.0003 44.1478 8.47311 43.0874 6.61372 41.2127C4.75444 39.3381 3.71474 36.8025 3.72275 34.1623V24.1532C3.71607 21.5139 4.75636 18.9795 6.61552 17.1061C8.47476 15.2325 11.0012 14.1727 13.6407 14.1593L13.6422 14.1592L73.3509 14.1593H73.3523C75.9926 14.1714 78.5203 15.2305 80.3807 17.1043C82.2408 18.9778 83.2818 21.5127 83.2753 24.1528C83.2753 24.1531 83.2752 24.1533 83.2752 24.1536M0.862524 34.1628L85.9287 34.3431C85.6043 34.6676 85.1642 34.8499 84.7054 34.8499C84.2465 34.8499 83.8064 34.6676 83.482 34.3431C83.1575 34.0187 82.9753 33.5786 82.9753 33.1198V24.1528L83.2752 24.1536M0.862524 34.1628L0.862524 24.1528L0.862523 24.1524C0.857379 20.7544 2.19998 17.4931 4.5959 15.0836C6.99167 12.6742 10.2451 11.3132 13.6428 11.299M0.862524 34.1628L13.6422 10.999M83.2752 24.1536L83.2753 33.1198C83.2753 33.4991 83.4259 33.8628 83.6941 34.131C83.9623 34.3992 84.3261 34.5499 84.7054 34.5499C85.0847 34.5499 85.4484 34.3992 85.7166 34.131C85.9848 33.8628 86.1355 33.4991 86.1355 33.1198V24.1528V24.1524C86.1406 20.7536 84.7974 17.4915 82.4004 15.0819C80.0036 12.6724 76.7489 11.312 73.3503 11.299M83.2752 24.1536L73.3503 11.299M73.3503 11.299H73.3509V10.999M73.3503 11.299L86.4355 24.1528C86.4407 20.6746 85.0661 17.3363 82.6131 14.8703C80.1601 12.4044 76.8291 11.0121 73.3509 10.999M73.3503 11.299C73.3501 11.299 73.3499 11.299 73.3497 11.299L73.3509 10.999M73.3503 11.299H13.6428M73.3509 10.999H13.6422M13.6422 10.999L13.6434 11.299C13.6432 11.299 13.643 11.299 13.6428 11.299M13.6422 10.999V11.299H13.6428"
												fill="url(#paint2_linear_3033_11283)"
												stroke="#F7F5FE"
												strokeWidth="0.6"
											/>
											<path
												d="M58.8257 63.1495C58.2355 63.1484 57.6516 63.0284 57.1088 62.7965C56.5661 62.5646 56.0757 62.2257 55.667 61.8L50.2295 56.1796C50.0476 56.0229 49.9004 55.83 49.7972 55.6133C49.6939 55.3966 49.6369 55.1608 49.6297 54.9208C49.6225 54.6809 49.6653 54.4421 49.7555 54.2196C49.8456 53.9971 49.981 53.7958 50.1531 53.6285C50.3253 53.4611 50.5303 53.3315 50.7553 53.2477C50.9803 53.164 51.2202 53.1279 51.4598 53.1419C51.6995 53.1559 51.9336 53.2196 52.1473 53.329C52.361 53.4383 52.5496 53.591 52.7011 53.7772L58.1386 59.3927C58.2323 59.4925 58.3469 59.5705 58.4742 59.6209C58.6015 59.6713 58.7384 59.693 58.8751 59.6843C59.015 59.6708 59.1504 59.6277 59.2722 59.5578C59.3941 59.4879 59.4997 59.3929 59.582 59.279L70.0961 44.7757C70.3649 44.4033 70.7705 44.153 71.2239 44.0798C71.6772 44.0066 72.141 44.1164 72.5133 44.3852C72.8857 44.6539 73.136 45.0596 73.2092 45.5129C73.2824 45.9662 73.1726 46.43 72.9038 46.8024L62.4045 61.2958C62.0281 61.8185 61.5419 62.2526 60.98 62.5677C60.4182 62.8828 59.7943 63.0712 59.1519 63.1198L58.8257 63.1495Z"
												fill="url(#paint3_linear_3033_11283)"
											/>
											<path
												d="M62.33 31.4005H62.3297C58.2317 31.4005 54.2256 32.6157 50.8182 34.8925C47.4107 37.1692 44.755 40.4053 43.1867 44.1914C41.6184 47.9776 41.2081 52.1437 42.0076 56.1631C42.8071 60.1824 44.7805 63.8744 47.6783 66.7722C50.5761 69.67 54.2681 71.6434 58.2874 72.4429C62.3068 73.2424 66.4729 72.8321 70.2591 71.2638C74.0452 69.6955 77.2812 67.0398 79.558 63.6323C81.8348 60.2249 83.05 56.2188 83.05 52.1208V52.1205C83.0447 46.6268 80.86 41.3597 76.9754 37.4751C73.0908 33.5905 67.8237 31.4058 62.33 31.4005ZM62.3294 75.7013C57.6657 75.7012 53.1068 74.3182 49.2291 71.7272C45.3513 69.1362 42.3289 65.4534 40.5442 61.1446C38.7594 56.8359 38.2925 52.0946 39.2023 47.5204C40.1122 42.9463 42.358 38.7446 45.6558 35.4468C48.9536 32.149 53.1552 29.9032 57.7294 28.9933C62.3036 28.0835 67.0448 28.5505 71.3536 30.3352C75.6624 32.12 79.3452 35.1423 81.9362 39.0201C84.5272 42.8978 85.9102 47.4567 85.9103 52.1204C85.9025 58.372 83.4156 64.3654 78.995 68.786C74.5744 73.2066 68.581 75.6935 62.3294 75.7013Z"
												fill="url(#paint4_linear_3033_11283)"
												stroke="#F7F5FE"
												strokeWidth="0.6"
											/>
										</g>
										<defs>
											<linearGradient
												id="paint0_linear_3033_11283"
												x1="13.1289"
												y1="28.9821"
												x2="27.5581"
												y2="28.9821"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<linearGradient
												id="paint1_linear_3033_11283"
												x1="29.1641"
												y1="28.9821"
												x2="43.5487"
												y2="28.9821"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<linearGradient id="paint2_linear_3033_11282" x1="0.5625" y1="29.1603" x2="86.4355" y2="29.1603" gradientUnits="userSpaceOnUse">
												<stop stopColor="#941AF3" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<linearGradient
												id="paint3_linear_3033_11283"
												x1="49.6289"
												y1="53.6035"
												x2="73.2314"
												y2="53.6035"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<linearGradient
												id="paint4_linear_3033_11283"
												x1="38.4492"
												y1="52.1208"
												x2="86.2103"
												y2="52.1208"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<clipPath id="clip0_3033_11283">
												<rect width="87" height="87" fill="white" />
											</clipPath>
										</defs>
									</svg>

									<h6>
										A verification code has been sent to your {''}
										{profileData?.personalDetails.email !== updatedProfileData.personalDetails.email ? (
											<>
												<span className="whtsap_icon_cstm ">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														xmlnsXlink="http://www.w3.org/1999/xlink"
														height="800px"
														width="800px"
														version="1.1"
														id="Capa_1"
														viewBox="0 0 58 58"
														xmlSpace="preserve"
													>
														<g>
															<path
																style={{ fill: 'green' }}
																d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5   S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z"
															></path>
															<path
																style={{ fill: 'white' }}
																d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42   c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242   c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169   c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097   c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z"
															></path>
														</g>
													</svg>
												</span>
												mobile number XXXXXX{' '}
												{profileData?.personalDetails.phoneNumber ? profileData.personalDetails.phoneNumber.toString().slice(-4) : null}
											</>
										) : profileData?.personalDetails.phoneNumber !== updatedProfileData.personalDetails.phoneNumber ? (
											`email address ${profileData?.personalDetails.email ? profileData.personalDetails.email.replace(/^.{4}/, 'xxxx') : null}`
										) : null}
										. Please enter to verify.
									</h6>
								</>
							)}
						</div>
						<div className="time-resend-box-otp">
							<p>{secondsLeft > 0 ? `${secondsLeft} Sec. left to ask for new OTP` : 'You can now resend the OTP.'}</p>
							<Link
								to=""
								onClick={async () => {
									setSecondsLeft(20);
									await getOTP(true);
								}}
								className={isResetLinkDisabled ? 'disabled-link' : ''}
							>
								Resend OTP
							</Link>
						</div>
						<div className="input-boxes-top" onPaste={handlePaste}>
							{otp &&
								otp.map((value, index) => {
									return (
										<Input
											ref={(el) => (inputRefs.current[index] = el)} // Assign the ref to manage focus
											key={index}
											type="text" // Use type="text" to allow for better control over input
											value={value}
											onChange={(event: ChangeEvent<HTMLInputElement>) => handleOtpChange(index, event.target.value)}
											maxLength={1} // Limit each input to one character
											onKeyDown={(e) => handleKeyDown(e, index)}
											_key={''}
											name={''}
										/>
									);
								})}
						</div>
						<div className="verify-button-otp">
							{
								<Button
									disabled={otp && otp.some((val) => typeof val === 'string' && !val?.trim())}
									className="cmn-btn"
									label="Verify"
									id={''}
									onClick={() => {
										otpVerification();
									}}
								/>
							}
						</div>
					</div>
				</ModalComponent>
			)}
		</>
	);
};
export default MyProfile;

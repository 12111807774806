// ProgressBar.tsx
import React from 'react';
import { ProgressBar as BootstrapProgressBar } from 'react-bootstrap'; // Import the Bootstrap ProgressBar component
import { ProgressBarProps } from '../../types/type/ProgressBarProps'; // Import type definitions for the ProgressBar props

/**
 * ProgressBar Component
 *
 * A reusable progress bar component that displays the current progress of an operation.
 * Utilizes Bootstrap's ProgressBar for styling and display.
 *
 * Props:
 * - progress: The current progress value as a percentage (0 to 100).
 * - label: Optional label to display inside the progress bar. If provided, it overrides the default progress label.
 *
 * @param {ProgressBarProps} props - The properties for the ProgressBar component.
 * @returns {JSX.Element} - The rendered ProgressBar component.
 */
const ProgressBar: React.FC<ProgressBarProps> = ({ progress, label }) => {
	return (
		<div className="progress-wrapper">
			{' '}
			{/* Wrapper for styling the progress bar */}
			<BootstrapProgressBar
				now={progress} // Set the current progress value
				label={label ? `${label}%` : `${progress}%`} // Display the label if provided; otherwise, show progress value
			/>
		</div>
	);
};

export default ProgressBar;

import { OpenAiModel } from '../../types/models/OpenAi';
import { ApiEndpoints } from '../Endpoints';
import * as handler from './Handler';

// OpenAi function to make the API request
export const OpenAi = async (OpenAiRequestBody: OpenAiModel) => {
	try {
		const response = await handler.post<string>(ApiEndpoints.OpenAi, OpenAiRequestBody);
		return response;
	} catch (error) {
		console.error('Error calling OpenAi API:', error);
		throw error;
	}
};

import React, { useEffect, useState, useRef } from 'react';
import Input from '../input/Index';
import Textarea from '../textarea/Index';
import Checkbox from '../checkbox/Index';
import Button from '../button/Index';
import { showToast } from '../toaster/Index';
import { confirmAlert } from 'react-confirm-alert';
import ModalComponent from '../Modal/Index';
import { DescriptionTypeEnum } from '../../types/enums/DescriptionTypeEnum';
import { OpenAi } from '../../helpers/api/OpenAi';
import Loader from '../loader/Index';
import ApiService from '../../helpers/api/Index';
import AIModal from '../AImodal/Index';
interface ProjectInfo {
	id: number;
	name: string;
	description: string;
	isSmartRevision: boolean;
}

interface ProjectNameProps {
	handleProjectInfo: (projectInfo: ProjectInfo) => void;
	projectInformation?: ProjectInfo;
}
const Project: React.FC<ProjectNameProps> = ({ handleProjectInfo, projectInformation }) => {
	const [projectInfo, setProjectInfo] = useState<ProjectInfo>({ id: 0, name: '', description: '', isSmartRevision: false });
	const [aiText, setAIText] = useState('');
	const contentEditableRef = useRef<HTMLTextAreaElement>(null);
	const [showAiResponse, setShowAiReponse] = useState<boolean>(false);
	const handleClosePreview = () => setShowAiReponse(false);
	const [loading, setLoading] = useState(false);
	const confirmIsSmartRevision = async (e: React.ChangeEvent<HTMLInputElement>) => {
		try {
			if (e && e.target) {
				const isChecked = e.target.checked;
				confirmAlert({
					customUI: ({ onClose }) => {
						return (
							<div className="modal-content-delete">
								<div className="icon-modal-delete">
									<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
										<circle cx="39" cy="39" r="38" fill="white" stroke="#EFBD8A" strokeWidth="2" />
										<path
											d="M41.3491 47.128H37.4291V44.44C37.5971 42.312 37.9891 41.472 40.8451 39.848C45.2691 37.216 48.9651 34.472 48.9651 29.936C48.9651 25.68 45.5491 22.936 40.3971 22.936C35.2451 22.936 32.6131 25.456 32.6131 28.648C32.6131 29.152 32.6691 29.712 32.8371 30.44H28.9731C28.6931 29.768 28.5811 28.984 28.5811 28.088C28.5811 24.336 31.8291 19.184 40.5651 19.184C47.6771 19.184 53.3891 23.272 53.3891 29.824C53.3891 36.432 47.2291 40.296 43.8131 42.368C41.0691 44.048 41.3491 44.048 41.3491 47.128ZM36.0851 56.368C36.0851 54.52 37.5411 53.008 39.3891 53.008C41.2371 53.008 42.6931 54.52 42.6931 56.368C42.6931 58.16 41.2371 59.616 39.3891 59.616C37.5411 59.616 36.0851 58.16 36.0851 56.368Z"
											fill="#941AF2"
										/>
									</svg>
								</div>
								<h5>
									{isChecked ? (
										<>
											Enabling Smart-Review will change all source files to low resolution for client review, with each source file change counting as
											one revision. Once enabled, Smart-Review can only be disabled after sharing the project with client. Proceed to enable
											Smart-Review?
										</>
									) : (
										<>
											Disabling Smart-Review will keep all the source files at their original resolution. Once disabled, you will not be able to
											enable Smart-Review. Proceed?
										</>
									)}
								</h5>
								<div className="buttons-modal-delete">
									<Button
										id=""
										className="cmn-btn"
										label={`YES TURN ${isChecked ? 'ON' : 'OFF'}`} /* Conditionally change the label */
										onClick={async () => {
											// Call the handleSmartRevision function with the captured 'checked' value
											await handleChange({ target: { name: 'isSmartRevision', value: isChecked } } as any);
											onClose(); // Close the confirmation modal
										}}
									/>
									<Button id="" className="cmn-btn blank-btn" label="CANCEL" onClick={onClose} />
								</div>
							</div>
						);
					},
					closeOnClickOutside: false,
				});
			}
		} catch (error: any) {
			showToast(error?.message ?? 'Something went wrong');
		}
	};
	/**
	 * Handles changes to input fields and updates the projectInfo state accordingly.
	 *
	 * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - The change event triggered by the input field.
	 */
	const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		// Check if the event and its target exist
		if (e && e.target) {
			// Cast the target to an HTMLInputElement to access its properties
			const target = e.target as HTMLInputElement;
			// Get the type of the input field (e.g. checkbox, text, etc.)
			const { type } = target;
			// Determine the value of the input field based on its type
			const value = type === 'checkbox' ? target.checked : target.value;
			// Update the projectInfo state with the new value
			setProjectInfo((prevState) => ({
				...prevState,
				[e.target.name]: value,
			}));
			// if (type === 'textarea') {
			// 	if (value.toString().length === 0) {
			// 		setAIText('');
			// 	} else if (value.toString().length >= 1) {
			// 		fetchAISuggestions(value.toString());
			// 	}
			// }
		}
	};

	const fetchAISuggestions = async (text: string) => {
		const requestBody = {
			Text: text.trim(),
			DescriptionType: DescriptionTypeEnum.Project,
		};
		try {
			const response = await OpenAi(requestBody);
			setAIText(response.response); // Set the AI response after fetching
			setLoading(false);
		} catch (error) {
			showToast('Error fetching AI suggestions');
		} finally {
			setLoading(false);
		}
	};
	const handleOpenPreview = async (projectDescription: string) => {
		setLoading(true); // Set loading to true to show a loading state
		try {
			if (!projectDescription) {
				setLoading(false);
				// If projectDescription is empty, show an error and stop the process
				showToast('Project description is required', { type: 'error' });
				return;
			}
			// Clear existing AI text if it exists
			if (aiText) {
				setAIText('');
			}
			// Call fetchAISuggestions to get AI-generated text
			await fetchAISuggestions(projectDescription);
			// After fetching suggestions, open the modal
			setShowAiReponse(true);
		} catch (error) {
			showToast('An error occurred while fetching AI suggestions', { type: 'error' });
		}
	};
	const handleAcceptAiDescription = () => {
		// Update the projectInfo with the new description (AI-generated text)
		setProjectInfo((prevState) => ({
			...prevState,
			description: aiText,
		}));
		setShowAiReponse(false);
	};
	const handleRejectAiDescription = () => {
		setShowAiReponse(false); // Close the AI modal without updating anything
	};
	/**
	 * Calls the handleProjectInfo function whenever the projectInfo state changes.
	 */
	// const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
	// 	if (e.key === 'Tab' && aiText) {
	// 		e.preventDefault(); // Prevent default Tab behavior
	// 		//const currentText = contentEditableRef.current?.value || ''; // Get current text from ref
	// 		const newText = aiText; // Append AI-generated text
	// 		if (newText.length > 200) {
	// 			const trimmedText = newText.slice(0, 200); // Trim text to 200 characters
	// 			contentEditableRef.current!.value = trimmedText; // Update contentEditableRef with trimmed text
	// 			setProjectInfo((prev) => ({
	// 				...prev,
	// 				description: trimmedText, // Update the description in projectInfo
	// 			}));
	// 		} else {
	// 			contentEditableRef.current!.value = newText; // Update contentEditableRef if under limit
	// 			setProjectInfo((prev) => ({
	// 				...prev,
	// 				description: newText, // Update the description in projectInfo
	// 			}));
	// 		}
	// 		setAIText(''); // Clear AI text
	// 	}
	// };
	useEffect(() => {
		handleProjectInfo(projectInfo);
	}, [projectInfo]);
	useEffect(() => {
		// If projectInformation is not undefined, update the projectInfo state with its value
		if (projectInformation !== undefined) {
			setProjectInfo(projectInformation);
		}
	}, []); // This effect will only run once, when the component mounts
	return (
		<>
			<div className="main-content-sidebox">
				<div className="header-sidebox">
					<h5>Project information</h5>
					<div className="smart-view-box">
						<div className="head-box-viewbox">
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M9 4.56445V4.55557M9 12.4444V8.22222M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
									stroke="#951CF1"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<h6>Smart Review</h6>
							<div className="switch-sidebox tooltip-container">
								<div className="toggle_custom">
									<Checkbox
										disabled={true}
										id="isSmartRevision"
										onChange={(e) => confirmIsSmartRevision(e)}
										checked={projectInfo.isSmartRevision}
										name="isSmartRevision"
									/>
									<label className="button_toggle"></label>
								</div>
								<div className="tooltip-message">coming soon</div>
							</div>
						</div>
					</div>
				</div>
				<div className="label-box-sidebox">
					<div className="iconbox-label-sidebox">
						<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M15 22H18.5M18.5 22H22M18.5 22V25.5M18.5 22V18.5"
								stroke="url(#paint0_linear_1161_6799)"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<path
								d="M36 18.1463C36 13.5397 36 11.2364 34.6534 9.7392C34.5296 9.6015 34.3986 9.47042 34.2609 9.34656C32.7636 8 30.4604 8 25.8537 8H25.1997C23.1807 8 22.1713 8 21.2307 7.73186C20.7139 7.58455 20.2157 7.37824 19.7462 7.117C18.8915 6.64142 18.1777 5.92761 16.75 4.5L15.787 3.53706C15.3085 3.05858 15.0693 2.81932 14.8179 2.61089C13.7339 1.71232 12.4041 1.16151 11.0022 1.03041C10.6771 1 10.3387 1 9.66206 1C8.11763 1 7.34541 1 6.70218 1.12136C3.87054 1.65562 1.65562 3.87054 1.12136 6.70218C1 7.34541 1 8.11763 1 9.66206M35.9848 25.5C35.922 29.8393 35.6001 32.2993 33.9497 33.9497C31.8996 36 28.5996 36 22 36H15C8.40033 36 5.10051 36 3.05025 33.9497C1 31.8996 1 28.5996 1 22V16.75"
								stroke="url(#paint1_linear_1161_6799)"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<defs>
								<linearGradient id="paint0_linear_1161_6799" x1="15" y1="22" x2="22" y2="22" gradientUnits="userSpaceOnUse">
									<stop stopColor="#941AF2" />
									<stop offset="1" stopColor="#EFBD8A" />
								</linearGradient>
								<linearGradient id="paint1_linear_1161_6799" x1="1" y1="18.5" x2="36" y2="18.5" gradientUnits="userSpaceOnUse">
									<stop stopColor="#941AF2" />
									<stop offset="1" stopColor="#EFBD8A" />
								</linearGradient>
							</defs>
						</svg>
					</div>
					<div className="content-label-sidebox">
						<h6>Please enter your project information here.</h6>
						{/* <p>
							Upload a background media - it's the first thing people will see in your page. <br />
							Recommended size: 1920x1080 (16:9)
						</p> */}
					</div>
				</div>
				<div className="form-box-sidebox">
					<>
						<div className="form-box">
							<div className="input-field">
								<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M9.79418 0.176525C10.0756 0.0599835 10.3771 0 10.6817 0C10.9862 0 11.2877 0.0599835 11.5691 0.176525C11.8504 0.293067 12.1061 0.463885 12.3214 0.679222C12.5368 0.89456 12.7076 1.15021 12.8242 1.43156C12.9407 1.71292 13.0007 2.01447 13.0007 2.31901C13.0007 2.62354 12.9407 2.9251 12.8242 3.20646C12.7076 3.48781 12.5368 3.74345 12.3214 3.95879L11.9846 4.2956L8.70508 1.01603L9.04185 0.679222C9.2572 0.463885 9.51285 0.293067 9.79418 0.176525Z"
										fill="#C1C1C1"
									/>
									<path
										d="M7.78551 1.93457L1.07552 8.64457C0.995526 8.72452 0.937798 8.82404 0.908034 8.93317L0.0229184 12.1786C-0.0384562 12.4036 0.0254601 12.6443 0.190402 12.8092C0.355344 12.9742 0.596019 13.0381 0.821061 12.9767L4.06649 12.0916C4.17562 12.0618 4.2751 12.0041 4.35509 11.9241L11.065 5.21417L7.78551 1.93457Z"
										fill="#C1C1C1"
									/>
								</svg>
								<Input
									maxLength={30}
									required={true}
									type="text"
									_key={'name'}
									onChange={(e) => handleChange(e)}
									name={'name'}
									value={projectInfo.name}
								/>
								<label>
									Project name<span style={{ color: 'red' }}>*</span>
								</label>
							</div>
							{projectInfo.name.length >= 30 && (
								<div className="error ">
									<p>The project name must be 30 characters or lower</p>
								</div>
							)}
						</div>
						<div className="form-box">
							<div className="input-field textarea-field">
								<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M0 2.05263C0 1.50824 0.221249 0.986145 0.615076 0.601202C1.0089 0.216259 1.54305 0 2.1 0H11.9C12.457 0 12.9911 0.216259 13.3849 0.601202C13.7788 0.986145 14 1.50824 14 2.05263V8.89474C14 9.43913 13.7788 9.96122 13.3849 10.3462C12.9911 10.7311 12.457 10.9474 11.9 10.9474H3.9669C3.81544 10.9474 3.66807 10.9954 3.5469 11.0842L1.12 12.8632C1.016 12.9394 0.892337 12.9858 0.762864 12.9972C0.633391 13.0086 0.503224 12.9846 0.38695 12.9278C0.270676 12.8709 0.172889 12.7836 0.104544 12.6755C0.0362003 12.5674 0 12.4429 0 12.3158V2.05263ZM3.5 2.05263C3.31435 2.05263 3.1363 2.12472 3.00503 2.25303C2.87375 2.38135 2.8 2.55538 2.8 2.73684C2.8 2.91831 2.87375 3.09234 3.00503 3.22065C3.1363 3.34897 3.31435 3.42105 3.5 3.42105H10.5C10.6857 3.42105 10.8637 3.34897 10.995 3.22065C11.1263 3.09234 11.2 2.91831 11.2 2.73684C11.2 2.55538 11.1263 2.38135 10.995 2.25303C10.8637 2.12472 10.6857 2.05263 10.5 2.05263H3.5ZM3.5 4.78947C3.31435 4.78947 3.1363 4.86156 3.00503 4.98987C2.87375 5.11819 2.8 5.29222 2.8 5.47368C2.8 5.65515 2.87375 5.82918 3.00503 5.95749C3.1363 6.08581 3.31435 6.15789 3.5 6.15789H10.5C10.6857 6.15789 10.8637 6.08581 10.995 5.95749C11.1263 5.82918 11.2 5.65515 11.2 5.47368C11.2 5.29222 11.1263 5.11819 10.995 4.98987C10.8637 4.86156 10.6857 4.78947 10.5 4.78947H3.5ZM3.5 7.52632C3.31435 7.52632 3.1363 7.5984 3.00503 7.72672C2.87375 7.85503 2.8 8.02906 2.8 8.21053C2.8 8.39199 2.87375 8.56602 3.00503 8.69434C3.1363 8.82265 3.31435 8.89474 3.5 8.89474H6.3C6.48565 8.89474 6.6637 8.82265 6.79497 8.69434C6.92625 8.56602 7 8.39199 7 8.21053C7 8.02906 6.92625 7.85503 6.79497 7.72672C6.6637 7.5984 6.48565 7.52632 6.3 7.52632H3.5Z"
										fill="#C1C1C1"
									/>
								</svg>
								<Textarea
									ref={contentEditableRef}
									onChange={(e) => handleChange(e)}
									name={'description'}
									id="description"
									maxLength={200}
									value={projectInfo.description}
									//onKeyDown={handleKeyDown}
								/>
								<label>
									Project description<span style={{ color: 'red' }}>*</span>
								</label>
								<div className="cstm_ai_btn">
									<Button
										onClick={() => handleOpenPreview(projectInfo.description)}
										className="open-ai-button offset-7"
										label={
											<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="25" height="25">
												<path
													d="M0 0 C-0.35298081 2.9008605 -0.88728296 3.89516676 -3.06640625 5.921875 C-3.89011719 6.52515625 -4.71382812 7.1284375 -5.5625 7.75 C-6.38878906 8.36359375 -7.21507812 8.9771875 -8.06640625 9.609375 C-8.70449219 10.06828125 -9.34257812 10.5271875 -10 11 C-8.7625 11.37125 -7.525 11.7425 -6.25 12.125 C-1.89215512 13.76305517 -0.04932264 15.73057784 2 20 C2.5625 22.625 2.5625 22.625 2 25 C-2.37274617 29.64604281 -8.22371549 31.85127047 -14.5 32.1875 C-15.325 32.125625 -16.15 32.06375 -17 32 C-15.21056082 26.63168245 -9.49329159 24.15046882 -5 21 C-9.29 19.68 -13.58 18.36 -18 17 C-18 15.68 -18 14.36 -18 13 C-18.66 13 -19.32 13 -20 13 C-20.3125 10.75 -20.3125 10.75 -20 8 C-15.15690604 2.67259664 -7.12268652 -0.35613433 0 0 Z M-11 5 C-13.31 6.65 -15.62 8.3 -18 10 C-17.01 10.33 -16.02 10.66 -15 11 C-13.32419948 10.02792965 -13.32419948 10.02792965 -11.8125 8.5625 C-10.884375 7.716875 -9.95625 6.87125 -9 6 C-9.66 5.67 -10.32 5.34 -11 5 Z M-15 14 C-14.67 14.66 -14.34 15.32 -14 16 C-11.64400765 16.46751788 -11.64400765 16.46751788 -8.9375 16.625 C-7.55884766 16.73714844 -7.55884766 16.73714844 -6.15234375 16.8515625 C-5.44207031 16.90054687 -4.73179688 16.94953125 -4 17 C-6.39763185 14.60236815 -7.33273498 14.65719414 -10.625 14.375 C-11.85089844 14.26285156 -11.85089844 14.26285156 -13.1015625 14.1484375 C-13.72804688 14.09945313 -14.35453125 14.05046875 -15 14 Z M-2 22 C-2.99 23.485 -2.99 23.485 -4 25 C-2.68 24.34 -1.36 23.68 0 23 C-0.66 22.67 -1.32 22.34 -2 22 Z "
													fill="#B552D1"
													transform="translate(25,0)"
												/>
												<path
													d="M0 0 C3.0094842 2.0063228 4.78396128 3.55455695 6 7 C6.4375 9.5 6.4375 9.5 6 12 C1.62725383 16.64604281 -4.22371549 18.85127047 -10.5 19.1875 C-11.325 19.125625 -12.15 19.06375 -13 19 C-11.68774191 15.06322574 -9.8038606 14.1728218 -6.4375 11.8125 C-5.42558594 11.09707031 -4.41367187 10.38164062 -3.37109375 9.64453125 C-2.19740234 8.83048828 -2.19740234 8.83048828 -1 8 C-3.64 7.34 -6.28 6.68 -9 6 C-8.67 5.01 -8.34 4.02 -8 3 C-7.05125 3.061875 -6.1025 3.12375 -5.125 3.1875 C-3.578125 3.0946875 -3.578125 3.0946875 -2 3 C-1.34 2.01 -0.68 1.02 0 0 Z M2 9 C1.01 10.485 1.01 10.485 0 12 C1.32 11.34 2.64 10.68 4 10 C3.34 9.67 2.68 9.34 2 9 Z "
													fill="#D48AAA"
													transform="translate(21,13)"
												/>
												<path
													d="M0 0 C0.66 0.33 1.32 0.66 2 1 C-1.49359465 4.59634744 -5.11098131 7.83451967 -9 11 C-9.3125 8.75 -9.3125 8.75 -9 6 C-6.33288317 3.40696975 -3.49367701 1.32518783 0 0 Z "
													fill="#AC3CDE"
													transform="translate(14,2)"
												/>
												<path
													d="M0 0 C2.64 0.33 5.28 0.66 8 1 C7.01 2.485 7.01 2.485 6 4 C3.69 3.67 1.38 3.34 -1 3 C-0.67 2.01 -0.34 1.02 0 0 Z "
													fill="#C46FBD"
													transform="translate(13,16)"
												/>
											</svg>
										}
										type="button"
										id=""
										disabled={!projectInfo.description}
									>
										<span className="assistant_tltp">Your AI Assistant</span>
									</Button>
								</div>
							</div>
						</div>
						{loading && <Loader />}
						{showAiResponse && aiText && (
							<AIModal
								showAIResponse={showAiResponse}
								handleClosePreview={handleClosePreview}
								AiGeneratedDescription={aiText}
								userTypedDescription={projectInfo.description}
								handleAccept={handleAcceptAiDescription}
								handleReject={handleRejectAiDescription}
							/>
						)}
						<p className="maximum-limit-text">Maximum word limit is 200 characters.</p>
					</>
				</div>
			</div>
		</>
	);
};

export default Project;

import axios, { AxiosInstance } from 'axios';
import { Get_Access_Token, Get_Refresh_Token, Set_Access_Token, Set_Refresh_Token } from '../../helpers/Utils';
import IAPIResponseModel from '../../types/interfaces/IAPIResponseModel';
import { ApiEndpoints } from '../Endpoints';
import { useNavigate } from 'react-router-dom';
import { App_Urls } from '../../routes/AppUrls';
import { TrackJS } from 'trackjs';

// Define the type for URL parameters
type Params = { [key: string]: any };

// Utility function to handle errors
export const handleError = <T>(error: any): never => {
	TrackJS.track(error);
	const errResponse: IAPIResponseModel<T> = error.response?.data;
	throw errResponse?.error || error;
};

// Create Axios instances with shared configurations
const createAxiosInstance = (baseURL: string): AxiosInstance => {
	const instance = axios.create({
		baseURL,
	});

	// Request interceptor for adding the Authorization header
	instance.interceptors.request.use((config) => {
		const token = Get_Access_Token();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		if (config.data instanceof FormData) {
			config.headers['Content-Type'] = 'multipart/form-data';
		} else {
			config.headers['Content-Type'] = 'application/json';
		}
		return config;
	}, handleError);

	// Response interceptor for handling 401 errors and refreshing tokens
	instance.interceptors.response.use(
		(response) => response,
		async (error) => {
			const originalRequest = error.config;
			if (error.response?.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				try {
					const refreshToken = Get_Refresh_Token();
					if (!refreshToken) {
						const sessionExpiredError = new Error('Your session has been expired,Redirecting to login.....');
						window.location.href = App_Urls.Auth;
						return Promise.reject(sessionExpiredError);
					}
					const { data } = await authInstance.post(ApiEndpoints.RefreshToken, { refreshToken });
					Set_Access_Token(data.accessToken);
					Set_Refresh_Token(data.refreshToken, data.expiration);
					originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
					return instance(originalRequest);
				} catch (refreshError) {
					const sessionExpiredError = new Error('Your session has been expired,Redirecting to login.....');
					window.location.href = App_Urls.Auth;
					return Promise.reject(sessionExpiredError);
				}
			}
			return Promise.reject(error);
		},
	);

	return instance;
};

// Axios instances for different base URLs
const authInstance = createAxiosInstance(process.env.REACT_APP_AUTH_API_ENDPOINT!);
const axiosInstance = createAxiosInstance(process.env.REACT_APP_API_ENDPOINT!);
// API request functions
export const getAuth = <T>(url: string, params?: Params): Promise<IAPIResponseModel<T>> =>
	authInstance
		.get<IAPIResponseModel<T>>(url, { params })
		.then((response) => response.data)
		.catch(handleError);

export const postAuth = <T>(url: string, payload?: any): Promise<IAPIResponseModel<T>> =>
	authInstance
		.post<IAPIResponseModel<T>>(url, payload)
		.then((response) => response.data)
		.catch(handleError);

export const get = <T>(url: string, params?: Params): Promise<IAPIResponseModel<T>> =>
	axiosInstance
		.get<IAPIResponseModel<T>>(url, { params })
		.then((response) => response.data)
		.catch(handleError);

export const post = <T>(url: string, payload?: any): Promise<IAPIResponseModel<T>> =>
	axiosInstance
		.post<IAPIResponseModel<T>>(url, payload)
		.then((response) => response.data)
		.catch(handleError);

export const delet = <T>(url: string, payload?: any): Promise<IAPIResponseModel<T>> =>
	axiosInstance
		.delete<IAPIResponseModel<T>>(url, { data: payload })
		.then((response) => response.data)
		.catch(handleError);

export const put = <T>(url: string, payload?: any): Promise<IAPIResponseModel<T>> =>
	axiosInstance
		.put<IAPIResponseModel<T>>(url, payload)
		.then((response) => response.data)
		.catch(handleError);

export const patch = <T>(url: string, payload?: any): Promise<IAPIResponseModel<T>> =>
	axiosInstance
		.patch<IAPIResponseModel<T>>(url, payload)
		.then((response) => response.data)
		.catch(handleError);

import { useCallback, useEffect, useState } from 'react';
import ApiService from '../../helpers/api/Index';
import './play.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { showToast } from '../../components/toaster/Index';
import Button from '../../components/button/Index';
import { App_Urls } from '../../routes/AppUrls';
import Loader from '../../components/loader/Index';
import { base64ToUint8Array, convertVttToBlobUrl, getProjAuth } from '../../helpers/Utils';
import VideoPlayer from '../../components/hlsPlayer/Index';
import { StreamefyMediaPlay } from '../../types/models/StreamefyMedia';
import Image from '../../components/image/Index';

const Play = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const navigate = useNavigate();
	const [hlsVideoData, setHlsVideoData] = useState<StreamefyMediaPlay>();
	const [loading, setLoading] = useState<boolean>(false);
	const [showControlPanels, setShowControlPanels] = useState(false);
	const [lastActivity, setLastActivity] = useState<number>(Date.now());
	const [selectedVideoId, setSelectedVideoId] = useState<string>(searchParams.get('mediaId') ? atob(searchParams.get('mediaId')!) : '');
	const [projectId] = useState<string>(searchParams.get('projectId') ?? '');
	const [phoneNumber] = useState<string>(searchParams.get('phoneNumber') ? atob(searchParams.get('phoneNumber')!) : '');
	const [currentPlaybackTime, setCurrentPlaybackTime] = useState<number>(0);
	const [remainingTime, setRemainingTime] = useState<number | null>(null);
	const [isNextVideo, setIsNextVideo] = useState<boolean>(false);
	const [nameId] = useState<string>(searchParams.get('nameId') ?? '');
	const [vttFileUrl, setVttFileUrl] = useState<string | null>(null);
	// Fetch the signed URL for the video
	const fetchSignedUrl = async () => {
		try {
			if (selectedVideoId) {
				setLoading(true);
				const hlsUrl = await ApiService.fetchStreamefyMedia(selectedVideoId);
				if (hlsUrl && hlsUrl.isSuccess) {
					const uint8Array = base64ToUint8Array(hlsUrl?.response.vttFileContent); // Convert base64 to Uint8Array
					const textDecoder = new TextDecoder('utf-8');
					setVttFileUrl(convertVttToBlobUrl(textDecoder.decode(uint8Array)));
					setHlsVideoData(hlsUrl.response);
				} else {
					showToast(hlsUrl?.error?.userMessage ?? 'Unable to fetch data', { type: 'error' });
				}
			} else {
				showToast('Invalid media ID', { type: 'error' });
			}
		} catch (error) {
			showToast('Something went wrong', { type: 'error' });
		} finally {
			setLoading(false);
		}
	};

	// Handle going back to the project view
	const goBack = async () => {
		if (!projectId) showToast('Invalid project Id', { type: 'error' });
		await updateVideoPlayback(currentPlaybackTime);
		//updateVideo
		navigate(`${App_Urls.ClientViewProject}?nameId=${nameId}&projectId=${projectId}`, { replace: true });
	};

	// Handle user activity
	const handleUserActivity = () => {
		setLastActivity(Date.now());
		setShowControlPanels(true); // Show button on user activity
	};

	useEffect(() => {
		fetchSignedUrl(); // Fetch video URL on component mount or when selectedMediaId changes
	}, [selectedVideoId]);

	useEffect(() => {
		// Listen to user activity events
		const activityEvents = ['mousemove', 'keydown', 'click', 'touchstart', 'touchmove'];
		activityEvents.forEach((event) => window.addEventListener(event, handleUserActivity));

		// Check for inactivity every second
		const activityChecker = setInterval(() => {
			const timeSinceLastActivity = Date.now() - lastActivity;
			if (timeSinceLastActivity >= 2000) {
				// 20 seconds of inactivity
				setShowControlPanels(false); // Hide button after inactivity
			}
		}, 1000);

		// Cleanup event listeners and interval
		return () => {
			activityEvents.forEach((event) => window.removeEventListener(event, handleUserActivity));
			clearInterval(activityChecker);
		};
	}, [lastActivity]);

	// Async function to update video playback information
	const updateVideoPlayback = async (duration: number) => {
		// Get the project authorization details from a function
		let projAuth = getProjAuth();

		// If no project authorization exists, show an error message and exit the function
		if (!projAuth) {
			return;
		}

		// Parse the project authorization details (assuming it's a JSON string)
		const parsedAuthProj = JSON.parse(projAuth);

		// Check if mediaId is available; if not, show an error message and exit the function
		if (!selectedVideoId || !hlsVideoData?.mediaId) {
			showToast('Invalid media id', { type: 'error' });
			return;
		}
		// Prepare the payload to be sent to the server
		const payload = {
			phoneNumber: phoneNumber ? phoneNumber : (parsedAuthProj?.phoneNumber ?? null), // Get phone number from parsedAuthProj, default to null if not available
			duration: duration?.toString() ?? '0', // Convert duration to string, or pass undefined if not valid
			mediaId: hlsVideoData?.mediaId, // The mediaId from hlsVideoData (assuming it's part of the current context)
			videoId: selectedVideoId,
		};

		try {
			// Call the API method to update playback information (assuming ApiService.BunnyPlaybackVideo handles this)
			const response = await ApiService.BunnyPlaybackVideo(payload);

			// Check if the API response indicates success
			if (response && response.isSuccess) {
				// No action needed here as there is no success logic provided in the original code
			} else {
				// If the response indicates failure, show an error message from the API response
				showToast(response.error.userMessage ?? 'Something went wrong', { type: 'error' });
			}
		} catch (error: any) {
			// If an error occurs during the API call, catch it and show the error message
			showToast(error.message ?? 'Something went wrong', { type: 'error' });
		}
	};

	return (
		<>
			{loading ? (
				<Loader customMessage="We’re getting your video ready. Please wait." />
			) : (
				<div className="play-video-page">
					<div className="video-wrapper">
						<VideoPlayer
							thumbnailFile={vttFileUrl}
							playbackTime={!isNextVideo ? parseInt(atob(searchParams.get('playbackTime')!)) : 0}
							setCurrentPlaybackTime={setCurrentPlaybackTime}
							updateVideoPlayback={updateVideoPlayback}
							src={hlsVideoData?.hlsUrl ?? ''}
							width="100%"
							autoPlay={true}
							onEndedVideo={() => {
								setIsNextVideo(true);
								setCurrentPlaybackTime(0);
								if (hlsVideoData?.nextVideo?.nextVideoId) setSelectedVideoId(hlsVideoData?.nextVideo?.nextVideoId ?? null);
							}}
							isMuted={false}
							setRemainingTime={setRemainingTime}
							showCustomControls={showControlPanels}
							isShowLoading={true}
						></VideoPlayer>
					</div>
					;
					{showControlPanels && (
						<>
							<Button className="backbutton-videos" id="BACK_TO_VIDEOS" label="BACK TO VIDEOS" onClick={goBack}>
								<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="30" height="30" rx="15" fill="url(#paint0_linear_6690_17024)" />
									<path d="M18 8L11 14.5L18 21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
									<defs>
										<linearGradient id="paint0_linear_6690_17024" x1="0" y1="15" x2="30" y2="15" gradientUnits="userSpaceOnUse">
											<stop stopColor="#941AF2" />
											<stop offset="1" stopColor="#EFBD8A" />
										</linearGradient>
									</defs>
								</svg>
							</Button>
						</>
					)}
					{remainingTime !== null && remainingTime > 0 && hlsVideoData && hlsVideoData?.nextVideo && (
						<>
							<div
								className="play-next-video-net"
								onClick={() => {
									setIsNextVideo(true);
									setCurrentPlaybackTime(0);
									if (hlsVideoData?.nextVideo?.nextVideoId) setSelectedVideoId(hlsVideoData?.nextVideo?.nextVideoId);
								}}
							>
								{
									<>
										{hlsVideoData?.nextVideo?.nextVideoThumbnail && <Image src={hlsVideoData?.nextVideo?.nextVideoThumbnail} />}
										Playing Next video in {remainingTime}s
									</>
								}
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default Play;

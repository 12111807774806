import ThumbnailCarousel from '../../components/thumbnailCarousel/Index';
import ImgTop from '../../assets/images/img-top.png';
import './clientView.css';
import Slider from 'react-slick';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import ApiService from '../../helpers/api/Index';
import { Project, ProjectEvents } from '../../types/models/ProjectModel';
import { showToast } from '../../components/toaster/Index';
import Image from '../../components/image/Index';
import { decodeBase64, encodeBase64, getProjAuth } from '../../helpers/Utils';
import { App_Urls } from '../../routes/AppUrls';
import Loader from '../../components/loader/Index';
import gsap from 'gsap';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import MetaDecorator from '../../components/metaDecorator/Index';
import downloadOriginalVideo from '../../helpers/bunnyService/DownloadVideoService';
import Button from '../../components/button/Index';

const ClientViewProject: React.FC = () => {
	const [project, setProject] = useState<Project>();
	const [selectedEvent, setSelectedEvent] = useState<ProjectEvents>();
	//const [userLogo, setUserLogo] = useState<string | null>(null);
	const [isVisible, setIsVisible] = useState(true); // State to manage visibility
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currentPlayingTrailer, setcurrentPlayingTrailer] = useState<number | null>(null);
	const [showCure, setShowCrue] = useState(false);
	const [isTrailerPanelOpen, setTrailerPanelOpen] = useState<boolean>(false);
	const [countryCode] = useState<string | null>(localStorage.getItem('countryCode') || 'in');
	const projAuth = getProjAuth();
	const parsedAuth = projAuth && JSON.parse(projAuth);
	const dell = () => {
		setTimeout(() => {
			setShowCrue(true);
		}, 10000);
	};
	const [downloadProgress, setDownloadProgress] = useState<{ [bunnyId: string]: number }>({});
	const [downloadingVideos, setDownloadingVideos] = useState<string[]>([]); // To track all currently downloading videos
	const boxRef = useRef(null);
	const headingRef = useRef(null);

	// Importing the `useLocation` hook to access the current URL's query parameters
	const location = useLocation();
	const navigate = useNavigate();

	// Creating a URLSearchParams object to parse the search parameters from the current URL
	const searchParams = new URLSearchParams(location.search);
	// Extracting the `projectId` from the search parameters
	const projectId = searchParams.get('projectId');
	const adminId = searchParams.get('nameId');

	const fetchProjectData = async (projectId: number) => {
		// Ensure projAuth is not null or undefined
		if (!projAuth) {
			showToast('Authorization data is missing or invalid', { type: 'error' });
			return;
		}
		try {
			const phoneNumber = parsedAuth?.phoneNumber ?? null; // safely accessing phoneNumber

			if (!phoneNumber) {
				showToast('Please enter your phoneNumber', { type: 'error' });
				return;
			}

			if (!projectId) {
				showToast('Invaid project id', { type: 'error' });
				return;
			}

			if (!adminId) {
				showToast('Invaid adminId', { type: 'error' });
				return;
			}
			setIsLoading(true);
			const result = await ApiService.PlayProject(projectId, phoneNumber, decodeBase64(adminId));

			// Null check on the result object and response
			if (result && result.isSuccess) {
				if (result.response) {
					setProject(result.response);
				} else {
					showToast('Project data is missing from the response', { type: 'error' });
				}
			} else {
				// Handle case where the response is unsuccessful or undefined
				showToast(result?.error?.userMessage ?? 'Something went wrong', { type: 'error' });
			}
		} catch (error: any) {
			// Handle any error that occurs during the fetch process
			showToast(error?.message ?? 'An error occurred while fetching the project', { type: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	// Function to handle the selection of event media (e.g., when an event is clicked)
	const handleEventMediaSelection = (event: ProjectEvents) => {
		setTrailerPanelOpen(false);
		// Set the selected event in the state
		setSelectedEvent(event);
		// Make the event media component visible
		setIsVisible(true);
	};

	// Function to handle the closing of the event media component
	const handleCloseEventMedia = () => setIsVisible(false);

	const handleBackGroundImageClick = (mediaId: string, playbackTime?: string) => {
		//navigate(App_Urls.Play, { state: { mediaId, projectId, playbackTime }, replace: true });
		const url = `${App_Urls.Play}?nameId=${adminId}&mediaId=${encodeBase64(mediaId ?? '')}&playbackTime=${encodeBase64(playbackTime ?? '')}&projectId=${projectId}&phoneNumber=${encodeBase64(parsedAuth?.phoneNumber)}`;
		window.open(url, '_blank');
	};

	const handleVideoDownload = async (bunnyId: string, mediaName: string) => {
		if (!bunnyId) {
			showToast('Video ID are required', { type: 'error' });
			return;
		}
		//Added Default video name if video name is null
		if (!mediaName) {
			mediaName = 'unknown.mp4';
		}
		// Set progress to 0 when starting a new download for the video
		setDownloadProgress((prev) => ({
			...prev,
			[bunnyId]: 0, // Reset the progress for a video
		}));
		// Add bunnyId to the list of downloading videos
		setDownloadingVideos((prev) => [...prev, bunnyId]);
		try {
			const response = await ApiService.downloadMedia(bunnyId);
			if (response?.isSuccess && response?.response) {
				const { signedUrl } = response.response;
				if (!signedUrl) {
					showToast('Invalid URL', { type: 'error' });
					return;
				}

				await downloadOriginalVideo({ url: signedUrl, mediaName }, ({ progress, videoId }: { progress: number; videoId: string }) => {
					setDownloadProgress((prev) => ({
						...prev,
						[videoId]: progress,
					}));
				});
			} else {
				showToast(response?.error?.userMessage ?? 'Something went wrong', { type: 'error' });
			}
		} catch (error: any) {
			showToast(error?.message ?? 'Something went wrong', { type: 'error' });
		} finally {
			// Remove bunnyId from downloading videos
			setDownloadingVideos((prev) => prev.filter((id) => id !== bunnyId));
		}
	};

	// Function to convert minutes to hours:minutes format
	const convertToHours = (timeString: any) => {
		if (!timeString) return '0m';
		const [hours, minutes, seconds] = timeString.toString().split(':').map(Number);
		const parts = [];
		if (hours) parts.push(`${hours}h`);
		if (minutes) parts.push(`${minutes}m`);
		if (seconds) parts.push(`${seconds}s`);
		return parts.length ? parts.join(' ') : '0m';
	};
	function convertMilliseconds(ms: any) {
		const parts = [];
		let seconds = Math.floor((ms / 1000) % 60);
		let minutes = Math.floor((ms / (1000 * 60)) % 60);
		let hours = Math.floor((ms / (1000 * 60 * 60)) % 24);

		if (hours) parts.push(`${hours}h`);
		if (minutes) parts.push(`${minutes}m`);
		if (seconds) parts.push(`${seconds}s`);
		return parts.length ? parts.join(' ') : '';
	}
	const convertMillisecond = (ms: any): number => {
		if (!ms) return 0;
		const seconds = Math.floor((ms / 1000) % 60);
		const minutes = Math.floor((ms / (1000 * 60)) % 60);
		const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
		// const milliseconds = (ms % 1000).toFixed(3); // To get remaining milliseconds if needed
		return hours * 3600 + minutes * 60 + seconds;
	};

	// Function to convert time string to total seconds
	const timeStringToSeconds = (timeString: any): number => {
		if (!timeString) return 0;
		const [hours, minutes, seconds] = timeString.toString().split(':').map(Number);
		return hours * 3600 + minutes * 60 + seconds;
	};

	// Function to calculate the percentage
	const calculatePercentage = (averageWatchTime: any, totalTime: number): number => {
		const averageSeconds = convertMillisecond(averageWatchTime);
		const totalSeconds = timeStringToSeconds(totalTime);
		return totalSeconds ? (averageSeconds / totalSeconds) * 100 : 0;
	};

	/**
	 * Toggles the state of the trailer panel (open/close).
	 * If the event object `e` is provided, it prevents the default behavior of the event (e.g., for link clicks).
	 * Then, it updates the state `isTrailerPanelOpen` by inverting its current value.
	 * @param {any} e - The event object that may be passed to prevent default behavior.
	 */
	const handleTrailerSideBoxOpenClose = (e: any) => {
		if (e) {
			e.preventDefault(); // Prevent default link behavior
		}
		setTrailerPanelOpen(!isTrailerPanelOpen); // Toggle the trailer panel state
		setIsVisible(false);
	};

	const lastPlayedVideo = (eventId: number = 0, mediaId: number = 0) => {
		let event = null;
		// Check if a valid eventId is provided
		if (eventId > 0) {
			// Attempt to find an event with the matching eventId and media marked as isLastPlayed
			event = project?.events.find((event) => event.id === eventId);
		} else {
			// If eventId is not greater than 0, find the first event with media marked as isLastPlayed
			event = project?.events
				.slice() // Create a shallow copy to avoid mutating the original array
				.reverse() // Reverse the array to start searching from the last element
				.find((event) => event.eventMedias.some((media) => media.isLastPlayed));
		}

		// As a final fallback, select the first event in the list or the one with the matching eventId
		if (!event) {
			event = eventId > 0 ? project?.events.find((event) => event.id === eventId) : project?.events[0];
		}

		let lastPlayedMedia =
			mediaId > 0
				? event?.eventMedias.find((eventMedia) => eventMedia.id === mediaId)
				: event?.eventMedias.find((event) => event.isLastPlayed === true);
		if (!lastPlayedMedia) lastPlayedMedia = event?.eventMedias[0];

		// navigate(App_Urls.Play, {
		// 	state: {
		// 		mediaId: lastPlayedMedia?.bunnyId,
		// 		playbackTime: lastPlayedMedia?.playbackTime ?? 0,
		// 		projectId,
		// 	},
		// 	replace: true,
		// });
		//const url = `${App_Urls.Play}?mediaId=${lastPlayedMedia?.bunnyId}&playbackTime=${lastPlayedMedia?.playbackTime ?? 0}&projectId=${projectId}`;
		const url = `${App_Urls.Play}?nameId=${adminId}&mediaId=${encodeBase64(lastPlayedMedia?.bunnyId ?? '')}&playbackTime=${encodeBase64(lastPlayedMedia?.playbackTime ?? '')}&projectId=${projectId}`;
		window.open(url, '_blank');
	};
	const hnadleReplay = (eventId: number = 0, mediaId: number = 0) => {
		let event = null;
		if (eventId > 0) {
			// Attempt to find an event with the matching eventId and media marked as isLastPlayed
			event = project?.events.find((event) => event.id === eventId);
		} else {
			// If eventId is not greater than 0, find the first event with media marked as isLastPlayed
			event = project?.events
				.slice() // Create a shallow copy to avoid mutating the original array
				.reverse() // Reverse the array to start searching from the last element
				.find((event) => event.eventMedias.some((media) => media.isLastPlayed));
		}
		if (!event) {
			event = eventId > 0 ? project?.events.find((event) => event.id === eventId) : project?.events[0];
		}

		let lastPlayedMedia =
			mediaId > 0
				? event?.eventMedias.find((eventMedia) => eventMedia.id === mediaId)
				: event?.eventMedias.find((event) => event.isLastPlayed === true);
		if (!lastPlayedMedia) lastPlayedMedia = event?.eventMedias[0];
		const url = `${App_Urls.Play}?nameId=${adminId}&mediaId=${encodeBase64(lastPlayedMedia?.bunnyId ?? '')}&playbackTime=${encodeBase64('0')}&projectId=${projectId}`;
		window.open(url, '_blank');
	};

	useEffect(() => {
		dell();
	}, []);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			gsap.to(boxRef.current, {
				height: 0,
				duration: 1.25,
			});

			gsap.to(headingRef.current, {
				scale: 0.7,
				duration: 1.25,
			});
		}, 10000);

		return () => clearTimeout(timeoutId);
	}, []);

	/*Initialize Project Data:
	If a projectId is missing in the URL, a toast notification is shown with an error message.
	The projectId is decoded using decryptProjectId.
	If the decoded ID is not a valid number, an error toast is shown.
	If valid, the function fetches project data using fetchProjectData.
	Check Project Authorization:
	If projectId is present, it checks if a project is authorized by:
	Retrieving a ProjAuthState value from localStorage.
	Decoding the projectId and comparing it with the stored authorization.
	If authorized, it returns true; otherwise, false.
	Conditionally Execute Logic:
	If the project is authenticated (location.state?.isProjectAuthenticated or isAuthorizedProject() returns true):
	Calls initializeProjectData.
	If projectId exists but isn't authenticated, navigates to an authorization route.
	If projectId is missing, navigates to the dashboard.
	*/
	useEffect(() => {
		const initializeProjectData = async () => {
			if (!projectId) {
				showToast('Project ID is missing in the URL', { type: 'error' });
				return;
			}

			const decodedProjectId = decodeBase64(projectId);

			if (isNaN(decodedProjectId)) {
				// Show error if the decoded project ID is invalid
				showToast('Invalid Project ID', { type: 'error' });
				return;
			}

			await fetchProjectData(decodedProjectId);
		};

		const isAuthorizedProject = () => {
			if (!projectId) return false;
			const decodedProjId = decodeBase64(projectId);
			if (!projAuth) return false;

			const parsedAuth = JSON.parse(projAuth);
			return parsedAuth.projectId && parsedAuth.projectId === decodedProjId;
		};

		const handleProjectInit = async () => {
			if (location.state?.isProjectAuthenticated || isAuthorizedProject()) {
				await initializeProjectData();
			} else if (adminId) {
				navigate(`${App_Urls.AuthorizeProject}?nameId=${adminId}&countryCode=${countryCode}`);
			} else {
				navigate(App_Urls.DashBoard);
			}
		};

		handleProjectInit();
	}, [navigate, projectId]);

	const sliderSettings = {
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		draggable: false,
		responsive: [
			{
				breakpoint: 1365,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1.5,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<MetaDecorator
						title={project?.name ?? ''}
						description={project?.description ?? ''}
						keywords={''}
						image_Url={project?.trailerDetails[0].thumbnailUrl}
						url={`${window.origin + App_Urls.ClientViewProject}?nameId=${adminId}&projectId=${projectId}`}
					/>
					<div className="client-view-screen">
						<div className={`top-screen-view ${showCure ? 'contenthidecrue' : ''}`}>
							<ThumbnailCarousel
								currentPlayingTrailer={currentPlayingTrailer ?? -1}
								isVideoMuted={true}
								trailerDetails={project?.trailerDetails ?? []}
							/>
							<div className="wrapper-content-over-preview">
								<div className="data-over-thumbanils">
									{/* <div className="logo-production-png">
										<div className="logo-production-service">
											<Image src={sslog} alt="" />
										</div>
										<span>Production</span>
									</div> */}
									<h1
										ref={headingRef}
										className={`${showCure ? 'reduce-heading' : ''}`}
										style={{ fontFamily: project?.font ? project.font : 'Arial, sans-serif' }}
									>
										{project?.name}
									</h1>
									<div ref={boxRef} className={`details-project-preview ${showCure ? 'hide-project-details' : ''}`}>
										<p>{project?.description}</p>
										<ul className="list-data-crew">
											{project?.crewMembers.map((member, index) => (
												<li key={index}>
													<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
														<g clipPath="url(#clip0_6290_17026)">
															<path
																d="M20 18.5C22.525 18.5 24.5714 16.5975 24.5714 14.25C24.5714 11.9025 22.525 10 20 10C17.475 10 15.4286 11.9025 15.4286 14.25C15.4286 16.5975 17.475 18.5 20 18.5ZM23.2 19.5625H22.6036C21.8107 19.9012 20.9286 20.0938 20 20.0938C19.0714 20.0938 18.1929 19.9012 17.3964 19.5625H16.8C14.15 19.5625 12 21.5613 12 24.025V25.4062C12 26.2861 12.7679 27 13.7143 27H26.2857C27.2321 27 28 26.2861 28 25.4062V24.025C28 21.5613 25.85 19.5625 23.2 19.5625Z"
																fill="white"
															/>
														</g>
														<circle cx="19.5" cy="19.5" r="19" stroke="white" />
														<defs>
															<clipPath id="clip0_6290_17026">
																<rect width="16" height="17" fill="white" transform="translate(12 10)" />
															</clipPath>
														</defs>
													</svg>
													{member.title} : {member.name}
												</li>
											))}
										</ul>
									</div>
									<div className="play-and-trailer-btn">
										<Link
											to=""
											className="play-btn cmn-btn"
											onClick={(e) => {
												e && e.preventDefault();
												lastPlayedVideo();
											}}
										>
											<svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M13.0133 6.97656C14.3466 7.74636 14.3466 9.67086 13.0133 10.4407L3.00903 16.2166C1.6757 16.9864 0.00903314 16.0241 0.0090332 14.4845L0.00903371 2.93267C0.00903378 1.39307 1.6757 0.43082 3.00903 1.20062L13.0133 6.97656Z"
													fill="white"
												/>
											</svg>
											{project?.events?.some((event) => event.eventMedias?.some((media) => media.isLastPlayed === true)) ? 'Resume' : 'Play'}
										</Link>
										<Link className="trailer-btn" to="" onClick={(e) => handleTrailerSideBoxOpenClose(e)}>
											<svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="20" cy="19.5" r="19.5" fill="white" />
												<path
													fill-rule="evenodd"
													clipRule="evenodd"
													d="M20.0001 9.11719C21.766 9.11719 23.2136 9.11719 24.4139 9.18858V29.8169C23.2136 29.8883 21.766 29.8883 20.0001 29.8883C18.234 29.8883 16.7865 29.8883 15.5862 29.8169L15.5862 9.18858C16.7865 9.11719 18.234 9.11719 20.0001 9.11719ZM20.4273 17.8455C21.5274 18.5849 22.0774 18.9546 22.0774 19.5027C22.0774 20.0509 21.5274 20.4206 20.4273 21.1599C19.3122 21.9094 18.7546 22.2841 18.3389 22.009C17.9232 21.7339 17.9232 20.9901 17.9232 19.5027C17.9232 18.0153 17.9232 17.2716 18.3389 16.9965C18.7546 16.7214 19.3122 17.0961 20.4273 17.8455Z"
													fill="#941AF2"
												/>
												<path
													d="M14.0284 13.531H9.84468C10.0464 12.2612 10.425 11.3485 11.1354 10.6381C11.8458 9.92771 12.7585 9.54912 14.0284 9.34737V13.531Z"
													fill="#941AF2"
												/>
												<path
													d="M30.1554 13.531C29.9537 12.2612 29.575 11.3485 28.8647 10.6381C28.1543 9.92771 27.2416 9.54912 25.9718 9.34737V13.531H30.1554Z"
													fill="#941AF2"
												/>
												<path d="M30.3853 18.7238C30.384 17.305 30.3748 16.1079 30.3142 15.0889H25.9718V18.7238H30.3853Z" fill="#941AF2" />
												<path
													d="M28.8647 28.3673C28.1543 29.0778 27.2416 29.4563 25.9718 29.6581V25.4744H30.1554C29.9537 26.7443 29.575 27.6569 28.8647 28.3673Z"
													fill="#941AF2"
												/>
												<path d="M30.3853 20.2816C30.384 21.7005 30.3748 22.8976 30.3142 23.9166H25.9718V20.2816H30.3853Z" fill="#941AF2" />
												<path
													d="M14.0284 25.4744V29.6581C12.7585 29.4563 11.8458 29.0778 11.1354 28.3673C10.425 27.6569 10.0464 26.7443 9.84468 25.4744H14.0284Z"
													fill="#941AF2"
												/>
												<path d="M14.0284 23.9166H9.68589C9.62529 22.8976 9.61613 21.7005 9.61475 20.2816H14.0284V23.9166Z" fill="#941AF2" />
												<path d="M14.0284 18.7238H9.61475C9.61613 17.305 9.62529 16.1079 9.68589 15.0889H14.0284V18.7238Z" fill="#941AF2" />
											</svg>
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="slider-events">
							<Slider {...sliderSettings}>
								{project?.events.map((event) => {
									return (
										<>
											<div className="single-slide">
												<h6>{event.title}</h6>
												{event.eventMedias && event.eventMedias.length > 0 && (
													<>
														<div
															className="box-event-media"
															onClick={(e) => {
																e && e.preventDefault();
																lastPlayedVideo(event?.id);
															}}
														>
															<Image src={event.eventMedias[0].thumbnailUrl} alt="" />
															<Link to="">
																<svg width="51" height="54" viewBox="0 0 51 54" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<g filter="url(#filter0_d_839_9801)">
																		<path
																			d="M35.5 24.4019C37.5 25.5566 37.5 28.4434 35.5 29.5981L18.9502 39.1531C16.9502 40.3078 14.4502 38.8644 14.4502 36.555L14.4502 17.445C14.4502 15.1356 16.9502 13.6922 18.9502 14.8469L35.5 24.4019Z"
																			fill="white"
																		/>
																	</g>
																	<defs>
																		<filter
																			id="filter0_d_839_9801"
																			x="0.449219"
																			y="0.44043"
																			width="50.5508"
																			height="53.1191"
																			filterUnits="userSpaceOnUse"
																			colorInterpolationFilters="sRGB"
																		>
																			<feFlood floodOpacity="0" result="BackgroundImageFix" />
																			<feColorMatrix
																				in="SourceAlpha"
																				type="matrix"
																				values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
																				result="hardAlpha"
																			/>
																			<feOffset />
																			<feGaussianBlur stdDeviation="7" />
																			<feComposite in2="hardAlpha" operator="out" />
																			<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
																			<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_839_9801" />
																			<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_839_9801" result="shape" />
																		</filter>
																	</defs>
																</svg>
															</Link>
														</div>
														<div className="content-below-event-screen-slider">
															<p>{event.eventMedias[0].description}</p>
														</div>
														<p className="open-right-box">
															<>
																<span onClick={() => handleEventMediaSelection(event)}>More Videos</span>
																<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M0.999999 1L5 5L1 9" stroke="#EFBD8A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
																</svg>
															</>
														</p>
													</>
												)}
											</div>
										</>
									);
								})}
							</Slider>
						</div>
						{isVisible && selectedEvent && (
							<div className="videos-event-sidebox">
								<div className="heading-video-evnt-sidebox">
									<h6>{selectedEvent.title}</h6>
									<svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleCloseEventMedia}>
										<circle cx="18.4023" cy="18.4023" r="18.4023" fill="white" />
										<path
											d="M25.4046 11.3951C25.2085 11.2002 24.9434 11.0908 24.667 11.0908C24.3906 11.0908 24.1254 11.2002 23.9294 11.3951L18.3842 16.9402L12.8495 11.3951C12.6493 11.2237 12.3918 11.1341 12.1285 11.1443C11.8652 11.1544 11.6154 11.2636 11.4291 11.4499C11.2428 11.6363 11.1336 11.886 11.1234 12.1494C11.1133 12.4127 11.2028 12.6701 11.3742 12.8703L16.9089 18.4155L11.3638 23.9502C11.2542 24.044 11.1653 24.1594 11.1025 24.2892C11.0397 24.419 11.0044 24.5604 10.9988 24.7045C10.9933 24.8486 11.0175 24.9923 11.0701 25.1265C11.1227 25.2608 11.2025 25.3827 11.3045 25.4847C11.4065 25.5867 11.5284 25.6664 11.6627 25.719C11.7969 25.7716 11.9406 25.7959 12.0847 25.7904C12.2288 25.7848 12.3702 25.7495 12.5 25.6867C12.6298 25.6239 12.7452 25.5349 12.839 25.4254L18.3842 19.8907L23.9189 25.4254C24.119 25.5968 24.3765 25.6864 24.6398 25.6762C24.9031 25.666 25.1529 25.5569 25.3393 25.3706C25.5256 25.1842 25.6347 24.9344 25.6449 24.6711C25.6551 24.4078 25.5655 24.1503 25.3941 23.9502L19.8594 18.4155L25.3941 12.8808C25.4929 12.7842 25.5715 12.669 25.6255 12.5419C25.6796 12.4148 25.7079 12.2782 25.7088 12.1401C25.7098 12.002 25.6834 11.865 25.6312 11.7372C25.579 11.6093 25.502 11.493 25.4046 11.3951Z"
											fill="#EFBD8A"
										/>
									</svg>
								</div>
								{selectedEvent.eventMedias && selectedEvent.eventMedias.length > 0 ? (
									<div className="video-list-sidebox-event">
										{selectedEvent.eventMedias.map((media, index) => (
											<div className="single-box-video-sidebox-event" key={index}>
												<div
													className="left-video-sidebox-event"
													onClick={(e) => {
														e && e.preventDefault();
														lastPlayedVideo(selectedEvent.id, media.id);
													}}
												>
													<Image src={media.thumbnailUrl || ImgTop} alt="Video thumbnail" />
													<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle opacity="0.8" cx="22" cy="22" r="22" fill="black" />
														<path d="M30 21.5L17.25 28.8612L17.25 14.1388L30 21.5Z" fill="white" />
													</svg>
												</div>
												<div className="right-content-sidebox-event">
													<div className="time-and-download-sidebox-event">
														<p>
															{media.playbackTime && media.playbackTime?.toString() === '0'
																? convertToHours(media.totalTime)
																: `${convertMilliseconds(media.playbackTime)} of ${convertToHours(media.totalTime)}`}
														</p>
														<p className="cst_replay_btn">
															{timeStringToSeconds(media.totalTime) === Math.floor(Number(media.playbackTime) / 1000) && (
																<Button
																	id=""
																	label={
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			fill="#fff"
																			height="20px"
																			width="20px"
																			version="1.1"
																			id="Layer_1"
																			viewBox="0 0 300.003 300.003"
																		>
																			<g>
																				<g>
																					<path d="M150.005,0C67.164,0,0.001,67.159,0.001,150c0,82.838,67.162,150.003,150.003,150.003S300.002,232.838,300.002,150    C300.002,67.159,232.844,0,150.005,0z M230.091,172.444c-9.921,37.083-43.801,64.477-83.969,64.477    c-47.93,0-86.923-38.99-86.923-86.923s38.99-86.92,86.923-86.92c21.906,0,41.931,8.157,57.228,21.579l-13.637,23.623    c-11-11.487-26.468-18.664-43.594-18.664c-33.294,0-60.38,27.088-60.38,60.38c0,33.294,27.085,60.38,60.38,60.38    c25.363,0,47.113-15.728,56.038-37.937h-20.765l36.168-62.636l36.166,62.641H230.091z" />
																				</g>
																			</g>
																		</svg>
																	}
																	onClick={() => {
																		hnadleReplay(selectedEvent.id, media.id);
																	}}
																/>
															)}
														</p>

														{downloadingVideos.includes(media.bunnyId) ? (
															<div style={{ width: 40, height: 40 }}>
																<CircularProgressbar
																	value={Math.round(downloadProgress[media.bunnyId] || 0)}
																	text={`${Math.round(downloadProgress[media.bunnyId] || 0)}%`}
																	styles={buildStyles({
																		pathColor: '#4db8ff',
																		textColor: '#f88',
																		trailColor: '#d6d6d6',
																		textSize: '35px',
																	})}
																/>
															</div>
														) : (
															<>
																<div
																	className="download-icon-client-event cstm_dwn_icons"
																	onClick={(e) => {
																		e.stopPropagation(); // Prevent click event from propagating to the parent
																		handleVideoDownload(media.bunnyId, media.mediaName);
																	}}
																>
																	<svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<circle cx="16" cy="16" r="15" fill="#fce4ec" />
																		<path
																			d="M16 2V24M16 24L10 18M16 24L22 18"
																			stroke="#d81b60"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</div>
															</>
														)}
													</div>
													<div className="video-progress-sidebox-event">
														<div className="video-progess-outer-line">
															<div
																style={{
																	height: '3px',
																	backgroundColor: '#414141',
																	borderRadius: '50px',
																	overflow: 'hidden',
																}}
															>
																<div
																	style={{
																		height: '100%',
																		width: `${calculatePercentage(media.playbackTime, media.totalTime)}%`,
																		backgroundColor: '#941af2',
																	}}
																/>
															</div>
														</div>
													</div>
													<p>{media.description}</p>
												</div>
											</div>
										))}
									</div>
								) : (
									<p>No media available.</p>
								)}
							</div>
						)}
						{isTrailerPanelOpen && (
							<div className="videos-event-sidebox trailer-box">
								<div className="heading-video-evnt-sidebox">
									<h5 style={{ color: 'white' }}>Background Media</h5>
									<svg
										width="37"
										height="37"
										viewBox="0 0 37 37"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										onClick={handleTrailerSideBoxOpenClose}
									>
										<circle cx="18.4023" cy="18.4023" r="18.4023" fill="white" />
										<path
											d="M25.4046 11.3951C25.2085 11.2002 24.9434 11.0908 24.667 11.0908C24.3906 11.0908 24.1254 11.2002 23.9294 11.3951L18.3842 16.9402L12.8495 11.3951C12.6493 11.2237 12.3918 11.1341 12.1285 11.1443C11.8652 11.1544 11.6154 11.2636 11.4291 11.4499C11.2428 11.6363 11.1336 11.886 11.1234 12.1494C11.1133 12.4127 11.2028 12.6701 11.3742 12.8703L16.9089 18.4155L11.3638 23.9502C11.2542 24.044 11.1653 24.1594 11.1025 24.2892C11.0397 24.419 11.0044 24.5604 10.9988 24.7045C10.9933 24.8486 11.0175 24.9923 11.0701 25.1265C11.1227 25.2608 11.2025 25.3827 11.3045 25.4847C11.4065 25.5867 11.5284 25.6664 11.6627 25.719C11.7969 25.7716 11.9406 25.7959 12.0847 25.7904C12.2288 25.7848 12.3702 25.7495 12.5 25.6867C12.6298 25.6239 12.7452 25.5349 12.839 25.4254L18.3842 19.8907L23.9189 25.4254C24.119 25.5968 24.3765 25.6864 24.6398 25.6762C24.9031 25.666 25.1529 25.5569 25.3393 25.3706C25.5256 25.1842 25.6347 24.9344 25.6449 24.6711C25.6551 24.4078 25.5655 24.1503 25.3941 23.9502L19.8594 18.4155L25.3941 12.8808C25.4929 12.7842 25.5715 12.669 25.6255 12.5419C25.6796 12.4148 25.7079 12.2782 25.7088 12.1401C25.7098 12.002 25.6834 11.865 25.6312 11.7372C25.579 11.6093 25.502 11.493 25.4046 11.3951Z"
											fill="#EFBD8A"
										/>
									</svg>
								</div>
								{project?.trailerDetails && project.trailerDetails.length > 0 ? (
									project.trailerDetails.map((trailer, index) => (
										<div className="single-box-video-sidebox-event" key={index}>
											<div
												className="left-video-sidebox-event"
												onClick={() => {
													setcurrentPlayingTrailer(trailer.id);
													setTrailerPanelOpen(false);
													handleBackGroundImageClick(trailer.bunnyId, trailer.playbackTime);
												}}
											>
												<img src={trailer?.thumbnailUrl ?? ''} alt={'Video thumbnail'} />
												<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
													<circle opacity="0.8" cx="22" cy="22" r="22" fill="black" />
													<path d="M30 21.5L17.25 28.8612L17.25 14.1388L30 21.5Z" fill="white" />
												</svg>
											</div>
											{downloadingVideos.includes(trailer.bunnyId) ? (
												<div
													style={{
														position: 'absolute',
														width: 40,
														height: 40,
														zIndex: 999,
														top: '45px',
														right: '45px',
													}}
												>
													<CircularProgressbar
														value={Math.round(downloadProgress[trailer.bunnyId] || 0)}
														text={`${Math.round(downloadProgress[trailer.bunnyId] || 0)}%`}
														styles={buildStyles({
															pathColor: '#4db8ff',
															textColor: '#f88',
															trailColor: '#d6d6d6',
															textSize: '35px',
														})}
													/>
												</div>
											) : (
												<div
													className="download-icon-client"
													onClick={(e) => {
														e.stopPropagation(); // Prevent click event from propagating to the parent
														handleVideoDownload(trailer.bunnyId, trailer.mediaName);
													}}
												>
													<svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="16" cy="16" r="15" fill="#fce4ec" />
														<path
															d="M16 2V24M16 24L10 18M16 24L22 18"
															stroke="#d81b60"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</div>
											)}
										</div>
									))
								) : (
									<div className="heading-video-evnt-sidebox">
										<h6>No active Background Media found</h6>
									</div>
								)}
							</div>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default ClientViewProject;

import { Plan } from '../../types/models/Plan';
import { UpdatedContactFieldEnum } from '../../../src/types/enums/UpdateFieldEnum';
import { UserProfileModel } from '../../types/models/PersonalDetailsModel';
import { ApiEndpoints } from '../Endpoints';
import * as handler from './Handler';
import { TransactionResponseModel } from '../../types/models/TransactionResponseModel';

export const UserProfile = async (profileDetails: FormData) => {
	return await handler.post(ApiEndpoints.userProfile, profileDetails);
};
export const myProfile = async () => {
	return await handler.get<UserProfileModel>(ApiEndpoints.myProfile);
};
export const EditMyProfile = async (profile: FormData) => {
	return await handler.post<UserProfileModel>(ApiEndpoints.EditMyProfile, profile);
};

export const ResetProfilePassword = async (palyload: any) => {
	return await handler.postAuth<string>(ApiEndpoints.ResetPasswordFromProfile, palyload);
};
export const ProfileResetOTP = async (isContactUpdated: boolean, updateField: UpdatedContactFieldEnum) => {
	return await handler.postAuth<string>(ApiEndpoints.ResetOTP + `?isContactUpdated=${isContactUpdated}&UpdatedField=${updateField}`);
};
export const Subscription = async (email: string, subscriptionId: string) => {
	return await handler.get<number>(ApiEndpoints.Subscription + '?email=' + email + '&subscriptionId=' + subscriptionId);
};
export const IsSubscribed = async () => {
	return await handler.getAuth<number>(ApiEndpoints.IsSubscribed);
};
export const GetSubscriptionLink = async (planId: number) => {
	return await handler.get<string>(ApiEndpoints.GetSubscriptionLink + planId);
};
export const CancelUserPlan = async () => {
	return await handler.post<string>(ApiEndpoints.CancelUserPlan);
};
export const GetPlans = async () => {
	return await handler.get<Plan[]>(ApiEndpoints.GetPlans);
};
export const DataTransactionLogs = async () => {
	return await handler.get<TransactionResponseModel[]>(ApiEndpoints.DataTransactionLogs);
};

export const DeleteAccount = async () => {
	return await handler.post<string>(ApiEndpoints.DeleteAccount);
};

import { TransactionPayload, TransactionsResponse } from '../../../src/types/models/Transactions';
import { PaginationModel } from '../../../src/types/models/PaginationModel';
import IAPIResponseModel from '../../types/interfaces/IAPIResponseModel';
import { AuthResponseModel } from '../../types/models/AuthResponseModel';
import { EmailOtpVerificationModel } from '../../types/models/EmailOtpVerificationModel';
import { LoginModel } from '../../types/models/LoginModel';
import { RegisterModel } from '../../types/models/RegisterModel';
import { ResetPasswordModel } from '../../types/models/ResetPasswordModel';
import { ApiEndpoints } from '../Endpoints';
import { Get_Refresh_Token, Set_Access_Token, SetCompanyLogo, Set_Refresh_Token, Clear_Local_storage } from '../Utils';
import * as handler from './Handler';

export const Register = async (register: RegisterModel) => {
	return await handler.postAuth<string>(ApiEndpoints.Register, register);
};

export const Login = async (login: LoginModel) => {
	return await handler.postAuth<AuthResponseModel>(ApiEndpoints.Login, login);
};
export const ForgotPassword = async (email: string) => {
	return await handler.postAuth<AuthResponseModel>(ApiEndpoints.ForgotPassword, { email: email });
};

export const ResetPassword = async (payload: ResetPasswordModel) => {
	return await handler.postAuth<string>(ApiEndpoints.ResetPassword, payload);
};
export const OtpVerification = async (payload: EmailOtpVerificationModel) => {
	return await handler.postAuth<AuthResponseModel>(ApiEndpoints.EmailOTPConfirmation, payload);
};

export const SignInWithGoogle_Get = async () => {
	return await handler.getAuth<string>(ApiEndpoints.Google);
};
export const SignInWithGoogle_Post = async (code: string) => {
	return await handler.postAuth<AuthResponseModel>(ApiEndpoints.Google, { code: code });
};

export const SignInWithInstagram_Get = async () => {
	return await handler.getAuth<string>(ApiEndpoints.Instagram);
};

export const SignInWithInstagram_Post = async (code: string) => {
	return await handler.postAuth<AuthResponseModel>(ApiEndpoints.Instagram, { code: code });
};

export const SignInWithFacebook_Get = async () => {
	return await handler.getAuth<string>(ApiEndpoints.Facebook);
};

export const SignInWithFacebook_Post = async (code: string) => {
	return await handler.postAuth<AuthResponseModel>(ApiEndpoints.Facebook, { code: code });
};

export const Refresh_Token = async () => {
	return handler
		.postAuth<AuthResponseModel>(ApiEndpoints.RefreshToken, { refreshToken: Get_Refresh_Token() })
		.then((result: IAPIResponseModel<AuthResponseModel>) => {
			// Check if the sign-in was successful
			if (result.isSuccess && result.response) {
				// Extract tokens and expiration from the response
				const { accessToken, expiration, refreshToken, logo }: AuthResponseModel = result.response;
				// Set the access token and refresh token
				Set_Access_Token(accessToken);
				Set_Refresh_Token(refreshToken, expiration);
				SetCompanyLogo(logo);
			} else {
				if (result.error) Clear_Local_storage();
			}
		});
};

export const ResetPasswordOTP = async () => {
	return handler.postAuth<string>(ApiEndpoints.ResetPasswordOTP);
};

export const ResetOptRequest = (email: string) => {
	return handler.postAuth<string>(ApiEndpoints.RequestOTP, { email: email });
};

export const Get_OTP = (phoneNumber: string) => {
	return handler.postAuth<string>(ApiEndpoints.OTP, { phoneNumber });
};
export const Verify_OTP = (phoneNumber: string, otp: string, nameId: number) => {
	const payload = { phoneNumber, otp, nameId };
	return handler.postAuth<{ email: string; isPrimaryUser: boolean; message: string }>(ApiEndpoints.OTP_VERIFY, payload);
};

export const LoadTransactions = async (payload: TransactionPayload) => {
	return await handler.postAuth<PaginationModel<TransactionsResponse>>(ApiEndpoints.Transactions, payload);
};
export const DownloadInvoiceData = async (invoiceId: string) => {
	return await handler.getAuth<string>(ApiEndpoints.DownloadInvoice + invoiceId);
};

import { jwtDecode } from 'jwt-decode';
import { Constants } from '../constants/Constants';
import { IJwtClaims } from '../types/interfaces/IJwtClaims';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { EventEmitter } from 'events';
import { Media, ResponseData } from '../types/type/BackgroundMedia';

/**
 * Retrieves the access token from local storage.
 *
 * @returns {string|null} The access token, or null if it does not exist.
 */
export const Get_Access_Token = () => {
	return localStorage.getItem(Constants.authToken);
};

/**
 * Sets the access token in local storage.
 *
 * @param {string} token The access token to set.
 */
export const Set_Access_Token = (token: string) => {
	localStorage.setItem(Constants.authToken, token);
};
/**
 * set the logo into local storage.
 *
 */
export const SetCompanyLogo = (logo: string) => {
	localStorage.setItem(Constants.companyLogo, logo);
};
/**
 * Retrieves the logo from local storage.
 *
 * @returns {string|null} The logo token, or null if it does not exist.
 */
export const GetCompanyLogo = (): string | null => {
	return localStorage.getItem(Constants.companyLogo); // Return the logo from local storage
};

/**
 * Retrieves the refresh token from local storage.
 *
 * @returns {string|null} The refresh token, or null if it does not exist.
 */
export const Get_Refresh_Token = () => {
	return localStorage.getItem(Constants.refreshToken);
};

/**
 * Sets the refresh token and its expiration time in local storage.
 *
 * @param {string} token The refresh token to set.
 * @param {string} expirationTime The expiration time of the refresh token.
 */
export const Set_Refresh_Token = (token: string, expirationTime: string) => {
	localStorage.setItem(Constants.refreshToken, token);
	localStorage.setItem(Constants.refreshExpirationTime, expirationTime);
};
/**
 * Removes the access token from local storage.
 */
export const Remove_Access_Token = () => {
	// Remove the item with the key 'authToken' from local storage
	localStorage.removeItem(Constants.authToken);
};
/**
 * Sets the remember me in local storage.
 */
export const Set_Remember_Me = (remember: string) => {
	// Remove the item with the key 'authToken' from local storage
	localStorage.setItem(Constants.rememberMe, remember);
};
/**
 * Retrieves the remember me from local storage.
 *
 * @returns {string|null} The remember me, or null if it does not exist.
 */
export const Get_Remember_Me = () => {
	return localStorage.getItem(Constants.rememberMe);
};

export const Clear_Local_storage = () => {
	// Retrieve the current value of 'RememberMe'
	const rememberMe = Get_Remember_Me();

	// Clear all items from local storage
	localStorage.clear();

	// Restore the 'RememberMe' preference
	if (rememberMe !== null) {
		Set_Remember_Me(rememberMe);
	}
};
/**
 * Decodes the access token and extracts the JWT claims.
 *
 * @returns {IJwtClaims|null} The decoded JWT claims, or null if the token is invalid or does not exist.
 */
export const Get_Claims_From_Token = (): IJwtClaims | null => {
	try {
		var token = Get_Access_Token();
		if (token) {
			return jwtDecode<IJwtClaims>(token);
		} else return null;
	} catch (error) {
		console.error('Invalid token:', error);
		return null;
	}
};
/**
 * Formats a file size in bytes to a human-readable format (e.g. KB, MB, GB, TB)
 *
 * @param {any} bytes The file size in bytes
 * @returns {string} A string representing the file size in a human-readable format
 */
export const formatFileSize = (bytes: any) => {
	// If the file size is 0, return '0 Byte'
	if (bytes === 0) return '0 Byte';

	// Define the base unit (1024) and an array of unit labels
	const k = 1024;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

	// Calculate the exponent (i) to determine the appropriate unit label
	const i = Math.floor(Math.log(bytes) / Math.log(k));

	// Calculate the file size in the appropriate unit and format it to 2 decimal places
	return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

// Method to get the video format from the file
export const getVideoFormat = (file: File): string => {
	// The type property usually contains the MIME type
	const mimeType = file.type;

	// Extract format from MIME type (e.g., "video/mp4" => "mp4")
	const format = mimeType.split('/')[1];

	return format;
};

// Function to encrypt the project ID by converting it to Base64
export const encodeBase64 = (data: string) => {
	// Convert the project ID from a number to a string, then encode it to Base64 format
	const encoded = btoa(data);
	// Return the encoded Base64 string
	return encoded;
};

// Function to decrypt the Base64 encoded project ID
export const decodeBase64 = (data: string) => {
	// Decode the Base64 string back to the original project ID string
	const decoded = atob(data);
	// Parse the decoded string back to an integer and return it
	return parseInt(decoded, 10); // The second argument (10) specifies the base (decimal)
};

// Function to convert a Image file to a base64 string
export const fileToBase64 = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result as string);
		reader.onerror = reject;
		reader.readAsDataURL(file);
	});
};

export const reshuffleArray = (arr: any, index: number) => {
	// Validate the index;
	if (arr && Array.isArray(arr) && arr.length > 0) {
		if (index < 0 || index >= arr.length) {
			console.error('Index out of bounds');
			return arr; // Return the original array if index is invalid
		}

		// Extract the element at the specified index
		const [element] = arr.splice(index, 1);

		// Insert the element at the front of the array
		arr.unshift(element);
	}

	return arr;
};

export const validatePhoneNumber = async (phoneNumber: string): Promise<boolean> => {
	const modifiedNumber = phoneNumber.includes('+') ? phoneNumber : '+' + phoneNumber;

	const phoneNumberObj = parsePhoneNumberFromString(modifiedNumber);

	if (phoneNumberObj) {
		if (phoneNumberObj.country === 'IN') {
			return phoneNumberObj.number.length === 13;
		} else {
			const countryCode = phoneNumberObj.country;
			if (countryCode) {
				return isValidPhoneNumber(phoneNumber, countryCode);
			}
		}
	}
	return false;
};

const isValidPhoneNumber = (value: string, country: CountryCode): boolean => {
	const phoneNumber = parsePhoneNumberFromString(value, country);
	return phoneNumber ? phoneNumber.isValid() : false;
};

export const setProjAuth = (payload: any) => {
	localStorage.setItem(Constants.ProjAuthState, payload);
};

export const getProjAuth = () => {
	return localStorage.getItem(Constants.ProjAuthState);
};
export const fetchCountryCode = async (): Promise<string | null> => {
	try {
		const response = await fetch('https://geolocation-db.com/json/e6256a50-6cef-11ef-9699-010d5e77689d');
		const data = await response.json();
		const countryCode = data.country_code ? data.country_code.toLowerCase() : null; // Return country code in lowercase
		if (countryCode) {
			localStorage.setItem(Constants.countryCode, countryCode); // Store the country code in local storage
		}
		return countryCode;
	} catch (error) {
		console.error('Error fetching country code:', error);
		return null; // Return null in case of error
	}
};

/**
 * eventEmitter is a singleton instance of EventEmitter used in the notification service
 * to emit and listen to custom events for communication between components.
 */

const eventEmitter = new EventEmitter();

// Export the eventEmitter instance for use in other components
export default eventEmitter;

// Utility functions to manage pointer events
export const disablePointerEvents = (selector: string): Promise<void> => {
	return new Promise((resolve) => {
		const element = document.querySelector(selector);
		if (element instanceof HTMLElement) {
			element.style.pointerEvents = 'none';
		}
		resolve(); // Resolve the promise after pointer events are disabled
	});
};

export const enablePointerEvents = (selector: string) => {
	const element = document.querySelector(selector);
	if (element instanceof HTMLElement) {
		element.style.pointerEvents = '';
	}
};

export const downloadPdf = (base64: string, documentName: string = 'download') => {
	const binaryData = atob(base64);
	const arrayBuffer = new Uint8Array(binaryData.length);

	for (let i = 0; i < binaryData.length; i++) {
		arrayBuffer[i] = binaryData.charCodeAt(i);
	}

	// Create a Blob from the binary data
	const blob = new Blob([arrayBuffer], { type: 'application/pdf' });

	// Create a URL for the Blob
	const url = URL.createObjectURL(blob);

	// Create an anchor element to trigger download
	const link = document.createElement('a');
	link.href = url;
	link.download = `${documentName}.pdf`; // Set the file name
	document.body.appendChild(link);
	link.click();

	// Clean up
	document.body.removeChild(link);
	URL.revokeObjectURL(url);
};

export const getVideoDuration = (file: File): Promise<number> => {
	return new Promise((resolve, reject) => {
		try {
			const url = URL.createObjectURL(file); // Create a temporary object URL for the video file
			const video = document.createElement('video'); // Create a video element

			video.preload = 'metadata'; // Load only metadata (like duration)
			video.src = url;

			video.onloadedmetadata = () => {
				URL.revokeObjectURL(url); // Clean up the object URL
				resolve(video.duration); // Return the duration in seconds
			};

			video.onerror = () => {
				URL.revokeObjectURL(url); // Clean up in case of an error
				reject(new Error('Failed to load video metadata. Ensure the file is a valid video.'));
			};
		} catch (error) {
			reject(error);
		}
	});
};

export const IntlDateTimeFormat = (date: string) => {
	if (!date) return;

	const parsedDate = new Date(date);

	if (isNaN(parsedDate.getTime())) {
		return;
	}
	let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	/**In the absence of the Z, JavaScript assumes that it’s in the local timezone, but the format still follows the ISO 8601 standard. */
	if (!date.toLowerCase().includes('z')) {
		date = date + 'Z';
	}
	return new Intl.DateTimeFormat('en-US', {
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		timeZone: userTimeZone,
	}).format(new Date(date));
};

export const IntlDateFormat = (date: string) => {
	if (!date) return;

	const parsedDate = new Date(date);

	if (isNaN(parsedDate.getTime())) {
		return;
	}
	let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	/**In the absence of the Z, JavaScript assumes that it’s in the local timezone, but the format still follows the ISO 8601 standard. */
	if (!date.toLowerCase().includes('z')) {
		date = date + 'Z';
	}
	return new Intl.DateTimeFormat('en-US', {
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		timeZone: userTimeZone,
	}).format(new Date(date));
};

export const base64ToFile = (base64: string, fileName: string): File => {
	// Extract the MIME type and base64 data from the input string
	const matches = base64.match(/^data:(.*);base64,(.*)$/);

	if (!matches) {
		throw new Error('Invalid base64 string');
	}

	const mimeType = matches[1]; // Extracted MIME type (e.g., 'image/png')
	const base64Data = matches[2]; // Extracted base64 data

	// Decode the base64 string to binary data
	const byteCharacters = atob(base64Data);

	// Create a byte array to hold the binary data
	const byteArray = new Uint8Array(byteCharacters.length);

	// Convert each character in the string to a byte in the byte array
	for (let i = 0; i < byteCharacters.length; i++) {
		byteArray[i] = byteCharacters.charCodeAt(i);
	}

	// Create a Blob from the byte array with the appropriate MIME type
	const blob = new Blob([byteArray], { type: mimeType });

	// Create a file from the Blob
	const file = new File([blob], fileName, { type: mimeType });

	return file;
};

// Function to convert the VTT content (Base64 or byte array) to a Blob URL
export const convertVttToBlobUrl = (vttFileContent: string) => {
	// Create a Blob from the VTT file content (which is now a string)
	const blob = new Blob([vttFileContent], { type: 'text/vtt' });
	return URL.createObjectURL(blob); // Create and return the Blob URL
};

// Function to convert the Base64 to Uint8Array
export const base64ToUint8Array = (base64: string): Uint8Array => {
	if (!base64) return new Uint8Array(0);
	const binaryString = atob(base64); // Decode base64 to binary string
	if (!binaryString) return new Uint8Array(0);
	const length = binaryString.length;
	const bytes = new Uint8Array(length);
	for (let i = 0; i < length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes;
};

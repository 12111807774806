import { useEffect, useState } from 'react';
import ModalComponent from '../Modal/Index';
import Input from '../input/Index';
import ApiService from '../../helpers/api/Index';
import { showToast } from '../toaster/Index';
import { App_Urls } from '../../routes/AppUrls';
import Button from '../button/Index';
import { encodeBase64, Get_Claims_From_Token, validatePhoneNumber } from '../../helpers/Utils';
import { Link } from 'react-router-dom';
import Loader from '../loader/Index';
import PhoneInputComponent from '../phoneNumber/Index';
import { ShareProjectModal } from '../../types/models/ShareProject';
import { confirmAlert } from 'react-confirm-alert';
import { Constants } from '../../constants/Constants';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import Swal from 'sweetalert2';

interface ShareProjectProps {
	projectId: number;
	showModal: boolean;
	handleShow: () => void;
	isSmartRevision: boolean;
}
const ShareProject: React.FC<ShareProjectProps> = ({ showModal, handleShow, projectId }) => {
	const [show, setShow] = useState(showModal);
	const [pin, setPin] = useState<string>('');
	const [copied, setCopied] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [countryCode, setCountryCodes] = useState<string | null>(localStorage.getItem('countryCode') || 'in');
	const [payload, setPayload] = useState<ShareProjectModal>({
		url: '',
		email: '', // Default to an empty file
		phoneNumber: '', // Default to an empty file
		isForceUpdate: false,
		projectId: projectId ?? 0,
	});
	const [error, setError] = useState<{ emailError: string | null; phoneError: string | null }>({
		emailError: null,
		phoneError: null,
	});
	const handleClose = () => {
		setShow(!show);
		handleShow();
		resetState();
	};
	useEffect(() => {
		setShow(showModal);
	}, [showModal]);
	const [adminId, setAdminId] = useState<number>(0);

	// Reset the state by clearing email, phone number, and any error messages
	const resetState = () => {
		let dialCode: string = getCountryCallingCode(countryCode?.toUpperCase() as CountryCode);
		// const dialCode = getCountryCallingCode(countryCode?.toUpperCase() as CountryCode);
		if (countryCode?.toUpperCase() === 'JM') {
			dialCode = '+1876';
		}
		setPayload((prev) => ({
			...prev,
			email: '',
			phoneNumber: countryCode ? dialCode : '+91',
			isForceUpdate: false,
		}));
		setError({ emailError: null, phoneError: null }); // Clear any previous error messages
	};
	// Generate a new project PIN or reset the existing one
	const GenerateProjectPin = async (isResetPin?: boolean) => {
		try {
			// Attempt to generate a new project pin, passing the reset flag if specified
			let result = await ApiService.GenerateProjectPin(projectId, isResetPin ?? false);

			// Check if the request was successful
			if (result && result.isSuccess) {
				// Format the generated pin with spaces between each character for better readability
				let spacedNumber = result.response?.toString().split('').join(' ');
				setPin(spacedNumber); // Update the state with the formatted pin
				localStorage.removeItem(Constants.ProjAuthState);
			} else {
				// Show error message if the API call fails
				showToast(result?.error?.userMessage ?? 'Failed to share project');
				handleClose(); // Close the modal or dialog
			}
		} catch (error: any) {
			// Handle unexpected errors
			showToast(error?.message); // Show error message
			handleClose(); // Close the modal or dialog
		}
	};
	/**
	 * Handles the sharing of a project via email and phone number.
	 * This function performs input validation for email and phone number,
	 * sends the sharing request to the API, and manages loading and error states.
	 *
	 */
	const handleShare = async () => {
		let errors: { emailError: string; phoneError: string } = { emailError: '', phoneError: '' };
		// Sanitize and validate the email input
		const sanitizedEmail = payload?.email?.trim();
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
		// Check if email is provided
		if (!sanitizedEmail) {
			errors.emailError = 'Email is required';
		} else if (!emailRegex.test(sanitizedEmail)) {
			errors.emailError = 'Invalid email address';
		}
		// Sanitize phone number by removing dashes and spaces
		const sanitizedPhone = payload?.phoneNumber?.replace(/[-\s]/g, '');
		// Check if phone number is provided
		if (!sanitizedPhone) {
			errors.phoneError = 'Phone number is required';
		} else {
			// Validate the phone number asynchronously
			const isPhoneValid = await validatePhoneNumber(sanitizedPhone);
			if (!isPhoneValid) {
				errors.phoneError = 'Invalid phone number';
			}
		}

		// Update the errors state in a single step
		if (Object.keys(errors).length > 0 && (errors.emailError || errors.phoneError)) {
			setError((prev) => ({ ...prev, ...errors }));
			return; // Exit the function if any errors exist
		}

		// Clear errors if validation passes
		setError((prev) => ({ ...prev, emailError: null, phoneError: null }));

		try {
			setLoading(true); // Set loading state to true before making the API call

			// Call the API service to share the project
			const result = await ApiService.ShareProject(payload);

			// Handle API response
			if (result.isSuccess) {
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: 'Your project has been shared successfully! Please check your email and WhatsApp for the details.',
					confirmButtonText: 'OK',
					customClass: {
						confirmButton: 'cmn-btn',
					},
				});
				resetState();
			} else if (result.error?.developerMessage?.includes('Primary User Already Exists')) {
				await triggerForceUpdate(result.error?.userMessage);
			} else {
				showToast(result.error.userMessage || 'Something went wrong', { type: 'error' }); // Error message
				handleClose(); // Close any modals or UI elements if necessary
			}
		} catch (error: any) {
			showToast(error?.message || 'Something went wrong', { type: 'error' }); // Handle unexpected errors
			handleClose(); // Close any modals or UI elements if necessary
		} finally {
			setLoading(false); // Set loading state to false after the API call
			setPayload((prev) => ({ ...prev, isForceUpdate: false }));
		}
	};

	const triggerForceUpdate = async (message: string) => {
		try {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className="modal-content-delete">
							<div className="icon-modal-delete">
								<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="39" cy="39" r="38" fill="white" stroke="#EFBD8A" strokeWidth="2" />
									<path
										d="M41.3491 47.128H37.4291V44.44C37.5971 42.312 37.9891 41.472 40.8451 39.848C45.2691 37.216 48.9651 34.472 48.9651 29.936C48.9651 25.68 45.5491 22.936 40.3971 22.936C35.2451 22.936 32.6131 25.456 32.6131 28.648C32.6131 29.152 32.6691 29.712 32.8371 30.44H28.9731C28.6931 29.768 28.5811 28.984 28.5811 28.088C28.5811 24.336 31.8291 19.184 40.5651 19.184C47.6771 19.184 53.3891 23.272 53.3891 29.824C53.3891 36.432 47.2291 40.296 43.8131 42.368C41.0691 44.048 41.3491 44.048 41.3491 47.128ZM36.0851 56.368C36.0851 54.52 37.5411 53.008 39.3891 53.008C41.2371 53.008 42.6931 54.52 42.6931 56.368C42.6931 58.16 41.2371 59.616 39.3891 59.616C37.5411 59.616 36.0851 58.16 36.0851 56.368Z"
										fill="#941AF2"
									/>
								</svg>
							</div>
							<h5>{message}</h5>
							<h5> {'Are you sure you want to force update the primary user for this project?'}</h5>
							<div className="buttons-modal-delete">
								<Button
									className="cmn-btn"
									label="Yes I'm Sure"
									onClick={() => {
										setPayload((prev) => {
											const updatedState = { ...prev, isForceUpdate: true };
											return updatedState;
										});

										onClose();
									}}
									id={''}
								/>
								<Button className="cmn-btn blank-btn" label="Cancel" onClick={onClose} id={''} />
							</div>
						</div>
					);
				},
				closeOnClickOutside: false,
			});
		} catch (error: any) {
			showToast(error?.message ?? ' Something went wrong', { type: 'error' });
		}
	};

	// Effect to call `handleAsyncShare` whenever `isForceUpdate` becomes true
	useEffect(() => {
		if (payload.isForceUpdate) {
			//setShow(false)
			handleShare();
		}
	}, [payload.isForceUpdate]);
	/**
	 * Copies the URL and PIN to the clipboard.
	 * - Formats the URL and PIN into a string, with the URL on the first line and the PIN on the second line.
	 * - Uses the Clipboard API to copy the formatted string to the clipboard.
	 * - Displays a success message ("Copied!") for 2 seconds after copying.
	 * - Logs an error if the copying action fails.
	 *
	 * @param {Object} payload - The object containing project URL details.
	 * @param {string} pin - The PIN associated with the project.
	 */
	const copyToClipboard = () => {
		// Format the URL and PIN into a single string, with the URL on the first line and the PIN on the next line
		const formattedText = `Url: ${payload.url}\nPIN: ${pin}`;

		// Use the Clipboard API to copy the formatted text to the clipboard
		navigator.clipboard
			.writeText(formattedText) // Copy the formatted text
			.then(() => {
				// Set copied state to true to indicate successful copy
				setCopied(true);

				// Automatically hide the "Copied!" message after 2 seconds
				setTimeout(() => setCopied(false), 2000);
			})
			.catch((err: any) => {
				// Log an error message if copying to the clipboard fails
				showToast(err.message ?? 'Failed to copy: ', { type: 'error' });
			});
	};

	useEffect(() => {
		// Check if the project is protected
		// If there is no PIN, generate one
		if (!pin) {
			GenerateProjectPin();
		}
		setPayload((prev) => ({
			...prev,
			url: `${window.location.origin}${App_Urls.ClientViewProject}?nameId=${encodeBase64(adminId.toString())}&countryCode=${countryCode}`,
		}));
		// Dependency array: effect runs when isProtected changes
	}, [pin]);

	/**
	 * Handles input changes for email and phone number fields, updating state and managing validation errors.
	 * Clears error messages based on the validity of the input, ensuring user feedback for required fields.
	 */
	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const trimmedValue = value && value?.trim();
		// Update the state for the respective field
		setPayload((prev) => ({ ...prev, [name]: trimmedValue }));

		// Clear errors based on the input field
		if (name === 'email') {
			if (trimmedValue) {
				// Check for valid email format using a regular expression
				const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
				if (emailPattern.test(trimmedValue)) {
					setError((prev) => ({ ...prev, emailError: null }));
				} else {
					setError((prev) => ({ ...prev, emailError: 'Invalid email format' }));
				}
			} else {
				setError((prev) => ({ ...prev, emailError: 'Email is required' }));
			}
		} else if (name === 'phoneNumber') {
			// Sanitize the phone number by removing any dashes or spaces
			const sanitizedPhone = trimmedValue.replace(/[-\s]/g, '');

			// Check if the sanitized phone number is empty
			if (!sanitizedPhone) {
				setError((prev) => ({ ...prev, phoneError: 'Phone number is required' }));
				return; // Exit if phone number is missing
			}
			// Optionally, you can validate phone number format here
			const isValid = await validatePhoneNumber(sanitizedPhone);

			// Handle invalid phone number
			if (!isValid) {
				setError((prev) => ({ ...prev, phoneError: 'Invalid phone number' }));
				return; // Exit if phone number is invalid
			}
			// If phone number is valid, clear the error
			setError((prev) => ({ ...prev, phoneError: null }));
		}
	};
	useEffect(() => {
		const nameId = Get_Claims_From_Token()?.nameid;
		if (nameId) setAdminId(nameId);
	}, [adminId]);
	return (
		<>
			{loading && <Loader />}
			{show && (
				<ModalComponent show={show} className="share-modal" handleClose={handleClose} title="">
					<div className="box-body-share">
						<form>
							<div className="form-field-share">
								<div className="label-form-field">
									<svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M16.8568 4.17756C16.8568 6.02092 15.3624 7.51527 13.5191 7.51527C12.4801 7.51527 11.5521 7.04063 10.94 6.29636L7.12035 8.20618C7.18188 8.45955 7.21449 8.72426 7.21449 8.99655C7.21449 9.27024 7.18156 9.53622 7.11943 9.7907L10.94 11.701C11.5522 10.9567 12.4801 10.4821 13.5191 10.4821C15.3624 10.4821 16.8568 11.9765 16.8568 13.8198C16.8568 15.6632 15.3624 17.1576 13.5191 17.1576C11.6757 17.1576 10.1813 15.6632 10.1813 13.8198C10.1813 13.5468 10.2141 13.2815 10.276 13.0275L6.45463 11.1169C5.84247 11.8603 4.91498 12.3343 3.87678 12.3343C2.03341 12.3343 0.539062 10.8399 0.539062 8.99655C0.539062 7.1532 2.03341 5.65886 3.87678 5.65886C4.91637 5.65886 5.84496 6.13415 6.4571 6.87928L10.2759 4.96986C10.2141 4.71589 10.1813 4.45055 10.1813 4.17756C10.1813 2.33419 11.6757 0.839844 13.5191 0.839844C15.3624 0.839844 16.8568 2.33419 16.8568 4.17756ZM11.6669 4.17756C11.6669 5.20048 12.4962 6.02971 13.5191 6.02971C14.542 6.02971 15.3712 5.20048 15.3712 4.17756C15.3712 3.15464 14.542 2.3254 13.5191 2.3254C12.4962 2.3254 11.6669 3.15464 11.6669 4.17756ZM11.6669 13.8198C11.6669 14.8427 12.4962 15.672 13.5191 15.672C14.542 15.672 15.3712 14.8427 15.3712 13.8198C15.3712 12.7969 14.542 11.9677 13.5191 11.9677C12.4962 11.9677 11.6669 12.7969 11.6669 13.8198ZM3.87678 10.8488C2.85386 10.8488 2.02462 10.0195 2.02462 8.99655C2.02462 7.97365 2.85386 7.14441 3.87678 7.14441C4.8997 7.14441 5.72893 7.97365 5.72893 8.99655C5.72893 10.0195 4.8997 10.8488 3.87678 10.8488Z"
											fill="#941AF2"
										/>
									</svg>
									<label>Share your project</label>
								</div>
								<div className="input-div-share">
									<Input disabled={true} type="text" value={payload.url} onChange={() => {}} _key={''} name={''} />
									{copied ? (
										<>
											<p style={{ position: 'absolute', right: '10px', color: 'green', fontWeight: 'bold', fontSize: '12px', top: '15px' }}>
												Copied!
											</p>
										</>
									) : (
										<>
											<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={copyToClipboard}>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M5.33225 2.58485H12.476C13.4978 2.58485 13.8681 2.69116 14.2411 2.89095C14.6141 3.09073 14.9073 3.384 15.1071 3.75699C15.3069 4.12999 15.4132 4.50023 15.4132 5.52207V12.6658C15.4132 12.9089 15.5097 13.142 15.6816 13.3138C15.8535 13.4857 16.0866 13.5822 16.3296 13.5822C16.5727 13.5822 16.8058 13.4857 16.9777 13.3138C17.1495 13.142 17.2461 12.9089 17.2461 12.6658V5.45151C17.2461 3.81748 17.0756 3.22453 16.7567 2.62701C16.4442 2.03664 15.9614 1.55386 15.371 1.24134C14.7735 0.922413 14.1806 0.751953 12.5465 0.751953H5.33225C5.08919 0.751953 4.85609 0.848507 4.68422 1.02037C4.51235 1.19224 4.4158 1.42535 4.4158 1.6684C4.4158 1.91146 4.51235 2.14456 4.68422 2.31643C4.85609 2.4883 5.08919 2.58485 5.33225 2.58485ZM12.4082 4.72385C12.0352 4.52406 11.6649 4.41775 10.6431 4.41775H3.68722C2.66538 4.41775 2.29513 4.52406 1.92214 4.72385C1.55316 4.91918 1.25142 5.22091 1.05609 5.58989C0.856308 5.96289 0.75 6.33313 0.75 7.35497V14.3108C0.75 15.3318 0.856308 15.7029 1.05609 16.0759C1.25588 16.4489 1.54914 16.7422 1.92214 16.942C2.29513 17.1417 2.66538 17.248 3.68722 17.248H10.6431C11.664 17.248 12.0352 17.1417 12.4082 16.942C12.7812 16.7422 13.0744 16.4489 13.2742 16.0759C13.474 15.7029 13.5803 15.3327 13.5803 14.3108V7.35497C13.5803 6.33313 13.474 5.96289 13.2742 5.58989C13.0789 5.22091 12.7771 4.91918 12.4082 4.72385Z"
													fill="#EFBD8A"
												/>
											</svg>
										</>
									)}
								</div>
							</div>
							<div className="form-field-share">
								<div className="label-form-field">
									<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_1017_4781)">
											<path
												d="M18 16.3126C18 17.2443 17.2441 18.0001 16.3125 18.0001H1.6875C0.755859 18.0001 6.40939e-08 17.2443 6.40939e-08 16.3126V7.056C-7.05067e-05 6.79993 0.0581364 6.5472 0.170212 6.31696C0.282287 6.08672 0.44529 5.885 0.646875 5.72709C1.52227 5.04155 2.24648 4.48256 6.41953 1.45561C7.01016 1.0267 8.18437 -0.0104058 9 0.000141123C9.81563 -0.0139214 10.9898 1.0267 11.5805 1.45561C15.7535 4.48256 16.4777 5.04155 17.3531 5.72709C17.5547 5.885 17.7177 6.08672 17.8298 6.31696C17.9419 6.5472 18.0001 6.79993 18 7.056V16.3126ZM15.6902 9.40092C15.5988 9.27084 15.4195 9.2392 15.293 9.33413C14.4914 9.92827 13.3418 10.765 11.5805 12.0447C10.9898 12.4771 9.81563 13.5142 9 13.5001C8.18437 13.5107 7.01016 12.4771 6.41953 12.0447C4.6582 10.765 3.51211 9.92827 2.70703 9.33413C2.58047 9.2392 2.39766 9.27084 2.30977 9.40092L1.98984 9.86499C1.94802 9.92501 1.93124 9.99898 1.94305 10.0712C1.95487 10.1434 1.99435 10.2081 2.05313 10.2517C2.8582 10.8458 4.0043 11.6826 5.75508 12.9552C6.46875 13.4755 7.74141 14.6357 9 14.6287C10.2551 14.6357 11.5312 13.4755 12.2449 12.9552C13.9992 11.6826 15.1418 10.8458 15.9469 10.2517C16.0056 10.2081 16.0451 10.1434 16.0569 10.0712C16.0688 9.99898 16.052 9.92501 16.0102 9.86499L15.6902 9.40092Z"
												fill="#941AF2"
											/>
										</g>
										<defs>
											<clipPath id="clip0_1017_4781">
												<rect width="18" height="18" fill="white" />
											</clipPath>
										</defs>
									</svg>
									<label>
										Share via Email <span style={{ color: 'red' }}>*</span>
									</label>
								</div>
								<div className="input-div-share">
									<Input
										style={{ borderColor: error && error.emailError ? 'red' : '' }}
										type="email"
										value={payload.email}
										onChange={(e) => handleChange(e)}
										placeholder="Enter email address"
										_key={''}
										name={'email'}
									/>
								</div>
								{error && error.emailError && <div className="error">{error.emailError}</div>}
							</div>
							<div className="form-field-share">
								<div className="label-form-field">
									<span className="whtsap_icon_cstm ">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											xmlnsXlink="http://www.w3.org/1999/xlink"
											height="800px"
											width="800px"
											version="1.1"
											id="Capa_1"
											viewBox="0 0 58 58"
											xmlSpace="preserve"
										>
											<g>
												<path
													style={{ fill: 'green' }}
													d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5   S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z"
												></path>
												<path
													style={{ fill: 'white' }}
													d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42   c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242   c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169   c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097   c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z"
												></path>
											</g>
										</svg>
									</span>

									<label>
										Phone Number <span style={{ color: 'red' }}>*</span>
									</label>
								</div>
								<div className="input-div-share">
									<PhoneInputComponent
										country={countryCode || 'in'}
										disableDropdown={loading}
										value={payload.phoneNumber ?? ''}
										inputProps={{ name: 'phoneNumber', required: true, placeholder: '', disabled: loading }}
										onChange={(value, data, event, formattedValue) => handleChange(event)}
										containerClass={error && error.phoneError ? 'phoneNumberError-border' : ''}
									/>
									{error && error.phoneError && <div className="error">{error.phoneError}</div>}
								</div>
							</div>
							<div className="form-field-share">
								{
									<div className="input-div-share">
										<Input value={pin} disabled={true} type="text" placeholder="Enter PIN" onChange={() => {}} _key={''} name={''} />
										<Link
											className="retry-icon"
											onClick={() => GenerateProjectPin(true)}
											aria-label="Retry"
											style={{
												position: 'absolute',
												right: '10px',
												top: '50%',
												transform: 'translateY(-50%)',
												background: 'transparent',
												border: 'none',
												cursor: 'pointer',
											}}
											to={''}
										>
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" height="24" viewBox="0 0 24 24" width="24">
												<g fill="#000">
													<path d="m6 9.99989h2.954c.26522 0 .51957.10531.70711.29291.18753.1875.29289.4419.29289.7071s-.10536.5196-.29289.7071c-.18754.1875-.44189.2929-.70711.2929h-3.855c-.29156-.0003-.5711-.1163-.77717-.3225-.20607-.2063-.32183-.4859-.32183-.7775v-3.90001c0-.26522.10536-.51957.29289-.70711.18754-.18753.44189-.29289.70711-.29289s.51957.10536.70711.29289c.18753.18754.29289.44189.29289.70711z" />
													<path d="m7.39 10.0899h-2.09c.46343-1.79513 1.53595-3.37356 3.03424-4.4655 1.49829-1.09195 3.32936-1.6296 5.18016-1.52107 1.8507.10853 3.6064.85651 4.9668 2.11608 1.3604 1.25958 2.2411 2.95255 2.4915 4.78949.2505 1.837-.1449 3.704-1.1185 5.2817-.9735 1.5778-2.4649 2.7685-4.2191 3.3685s-3.6623.5721-5.3982-.0789c-1.73594-.651-3.19185-1.8848-4.1189-3.4903h1.722v-1.02c.68015 1.1881 1.74616 2.1079 3.0211 2.6067 1.2749.4988 2.6821.5466 3.9879.1355 1.3059-.4112 2.4318-1.2565 3.191-2.3957.7592-1.1393 1.106-2.5039.9828-3.8673-.1232-1.3635-.709-2.64385-1.6601-3.62853-.9511-.98469-2.2104-1.61449-3.5688-1.78489-1.3584-.17039-2.7342.12889-3.89903.84815-1.16486.71926-2.04872 1.81526-2.50487 3.10607z" />
													<path d="m7 16.6099c.55228 0 1-.4477 1-1s-.44772-1-1-1-1 .4477-1 1 .44772 1 1 1z" />
												</g>
											</svg>
										</Link>
									</div>
								}
							</div>
							<div className="button-submit-share">
								<Button
									disabled={loading}
									className="cmn-btn"
									onClick={(e) => {
										e && e.preventDefault();
										handleShare();
									}}
									id={''}
									label={'Share now'}
								/>
							</div>
						</form>
					</div>
				</ModalComponent>
			)}
		</>
	);
};

export default ShareProject;

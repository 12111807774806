import React, { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import { InputProps } from '../../types/type/InputProps';

/**
 * A reusable input component that renders an HTML `<input>` element.
 *
 * This component provides a standard input field that can be customized with various props,
 * including the input type, value, placeholder text, and more. It also handles input changes
 * by calling the `onChange` function passed as a prop.
 *
 * @param {string | number} [_key] - A unique key for the input element, useful for rendering lists.
 * @param {string} value - The current value of the input field.
 * @param {(event: ChangeEvent<HTMLInputElement>) => void} onChange - Function called when the input value changes.
 * @param {string} [placeholder] - Placeholder text displayed in the input when it's empty.
 * @param {string} [className] - Additional CSS classes to style the input field.
 * @param {string} [type='text'] - The type of the input element, such as "text", "password", "email", etc.
 * @param {string} [name] - The name attribute for the input element, useful for forms.
 * @param {number} [maxLength] - The maximum number of characters allowed in the input.
 * @param {(event: React.FocusEvent<HTMLInputElement>) => void;} onBlur - Function called focus out and focus in into input field
 * @returns {JSX.Element} A customizable input field for user interaction.
 */

const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			_key,
			value,
			onChange,
			placeholder,
			className,
			type = 'text',
			name,
			maxLength,
			required,
			style,
			disabled,
			min,
			max,
			step,
			id,
			checked,
			onKeyDown,
			onBlur,
			onMouseDown,
			onMouseUp,
		},
		ref?: ForwardedRef<HTMLInputElement>,
	) => {
		const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
			onChange?.(event);
		};

		const inputClassName = `${className ? className : ''} ${value ? 'input-value' : ''}`?.trim();

		return (
			<input
				id={id}
				disabled={disabled}
				required={required}
				name={name}
				key={_key}
				className={inputClassName}
				type={type}
				value={value}
				onChange={handleChange}
				placeholder={placeholder}
				maxLength={maxLength}
				style={style}
				ref={ref} // Forward the ref
				onKeyDown={onKeyDown} // Ensure onKeyDown is properly passed
				onBlur={onBlur}
				min={min}
				max={max}
				step={step}
				onMouseDown={onMouseDown}
				onMouseUp={onMouseUp}
				checked={checked}
			/>
		);
	},
);

export default Input;

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, EffectCoverflow } from 'swiper/modules';
import Input from '../input/Index';
import dummyEventMedia from '../../assets/images/dummy-event-media.png';
import dummyEventVideo from '../../assets/images/img-video.png';
import Textarea from '../textarea/Index';
import FileUpload from '../fileUpload/fileUpload';
import Button from '../button/Index';
import { ChangeEvent, useEffect, useState, useRef } from 'react';
import Image from '../image/Index';
import { DeleteMediaById, EventMediaUpload, DeleteEventWithMedia } from '../../helpers/bunnyService/EventMediaUpload';
import { showToast } from '../toaster/Index';
import ProgressBar from '../progressBar/Index';
import ApiService from '../../helpers/api/Index';
import { DescriptionRequestModel } from '../../types/type/EventMedia';
import VideoBg from '../../assets/images/videobg.png';
import { nanoid } from 'nanoid';
import Video from '../video/Index';
import { base64ToFile, reshuffleArray } from '../../helpers/Utils';
import { Constants } from '../../constants/Constants';
import { confirmAlert } from 'react-confirm-alert';
import Loader from '../loader/Index';
import eventEmitter from '../../helpers/Utils';
import { uploadThumbnailToExistingMedia } from '../../helpers/bunnyService/BackgroundMediaUpload';
import Swal from 'sweetalert2';
import { DescriptionTypeEnum } from '../../types/enums/DescriptionTypeEnum';
import ModalComponent from '../Modal/Index';
import { OpenAi } from '../../helpers/api/OpenAi';
import AIModal from '../AImodal/Index';

interface EventMediaModel {
	eventMedia: File | null; //The actual media file to be uploaded
	eventMediaUrl: string;
	thumbnail: File | null; //The thumbnail image file for the media
	thumbnailUrl: string;
	description: string;
	eventID: number;
	mediaID: number;
	progress: number;
	isUploading: boolean;
	uploadId: string;
	isModified: boolean;
}

// Create a new event with the same structure as your initial state
const newEvent: ProjectEvents = {
	id: 0,
	title: '',
	eventMedia: [
		{
			eventMedia: null,
			eventMediaUrl: '',
			thumbnail: null,
			thumbnailUrl: '',
			description: '',
			eventID: 0,
			mediaID: 0,
			progress: 0,
			isUploading: false,
			uploadId: nanoid(),
			isModified: false,
		},
	],
	isModified: false,
};

interface UploadStatus {
	uploadId?: string; // or whatever type it is
	mediaID?: number;
	progress: number;
	isUploading: boolean;
}
export interface ProjectEvents {
	id: number;
	title: string;
	eventMedia: EventMediaModel[];
	isModified: boolean;
}
interface CreateEventProps {
	projectId: number;
	isEditing?: boolean;
	projectType?: string;
	eventId?: number;
	handleEventMediaInfo: (eventMediaInfo: DescriptionRequestModel) => void; // Updated type
	handleCreateEventMediaInfo?: (projectEvent: ProjectEvents[]) => void;
	isSmartRevision?: boolean;
	handleCallBackGroundMediaProgress?: (progress: boolean) => void;
	toggleOpenClose?: () => void;
	eventEditIndex?: number;
	onUploadProgress?: (index: number, progress: number, eventId: number, mediaID: number, uploadId: string) => void; // Add this line
	uploadStatus?: UploadStatus[]; // Add this line
	isVideoUploading?: (value: boolean) => void; // Declare the function prop
	ProjectEvent?: ProjectEvents[];
	retrieveProjects?: () => Promise<void>;
	isEditDelete?: boolean;
	finalizeProjectEdit?: (isFinaLizeProject: boolean) => void;
}

const CreateEvent: React.FC<CreateEventProps> = ({
	projectId,
	isEditing,
	handleEventMediaInfo,
	handleCreateEventMediaInfo,
	isSmartRevision,
	handleCallBackGroundMediaProgress,
	eventEditIndex,
	onUploadProgress,
	uploadStatus,
	isVideoUploading,
	ProjectEvent,
	projectType,
	retrieveProjects,
	isEditDelete = true,
	finalizeProjectEdit,
}) => {
	const [projectEvent, setProjectEvent] = useState<ProjectEvents[]>(
		new Array(1).fill({
			title: '',
			eventMedia: new Array<EventMediaModel>(1).fill({
				eventMedia: null,
				eventMediaUrl: '',
				thumbnail: null,
				thumbnailUrl: '',
				description: '',
				eventID: 0,
				mediaID: 0,
				progress: 0,
				isUploading: false,
				uploadId: nanoid(),
				isModified: false,
			}),
		}),
	);
	const [prevEventIndex, setPrevEventIndex] = useState<number | null>(null);
	const [uploadStatuses, setUploadStatus] = useState<{ uploadId: string; progress: number; isUploading: boolean; mediaID: number }[]>([]);
	const [isDescriptionFocused, setIsDescriptionFocused] = useState(false);
	const swiperRef = useRef<any>(null);
	const [descriptionError, setDescriptionError] = useState(false);
	const [titleError, setTitleError] = useState<{ error: string | null; hasError: boolean; eventIndex?: number }[]>([]);
	const [hasSetProjectEvent, setHasSetProjectEvent] = useState(false);
	// Keep track of previous event index
	const [uploadProgress, setUploadProgress] = useState<{ progress: number; isUploading: boolean }>({ progress: 0, isUploading: false });
	// Declare state for eventID, initial value set to 0
	const [eventID, setEventID] = useState<number>(0);
	const [showAiResponse, setShowAiReponse] = useState<boolean>(false);
	const handleClosePreview = () => setShowAiReponse(false);
	const [aiText, setAIText] = useState('');
	const [selectedEventIndex, setSelectedEventIndex] = useState<number | null>(null);
	const [selectedMediaId, setSelectedMediaId] = useState<number | null>(null);
	// Create a default instance of UploadStatus
	const defaultUploadStatus: UploadStatus = {
		uploadId: '', // or whatever type it is
		mediaID: 0,
		progress: 0,
		isUploading: false,
	};
	const [dataFromEvent, setdataFromEvent] = useState<boolean>(false);
	const [inputKey, setInputKey] = useState(0); // this key will be updated to reset the file input
	const [thumbnailFromEvent, setThumbnailFromEvent] = useState<{ eventIndex: number; mediaIndex: number; mediaUploading: boolean }[]>([]);
	const [newlyAddedMediaIndex, setNewlyAddedMediaIndex] = useState<number | null>(null);
	const [isNewMediaAdded, setIsNewMediaAdded] = useState(false); // Add state to track if new media is added
	const [activeEventIndex, setActiveEventIndex] = useState<number | null>(null);
	const [isBlankMediaDeleted, setIsBlankMediaDeleted] = useState(false);
	const [isNewMediaAddByPlusButton, setNewMediaAddByPlusButton] = useState(false);
	const [activeLoader, setActiveLoader] = useState<{ eventIndex: number | null; mediaIndex: number | null }>({
		eventIndex: null,
		mediaIndex: null,
	});
	const [isActionTriggered, setIsActionTriggered] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [eventMedia, setEventMedia] = useState<EventMediaModel>();
	const [thumbnails, setThumbnails] = useState<string[]>([]);
	const [projectNumber, setProjectNumber] = useState(0);
	const [isShouldShowLoader, setIsShouldShowLoader] = useState(true);
	const [isAiButtonDisabled, setIsAiButtonDisabled] = useState(false);
	const fetchAISuggestions = async (name: string, text: string, eventIndex: number, mediaIndex: number) => {
		setActiveLoader({ eventIndex, mediaIndex });
		const requestBody = {
			Text: text.trim(),
			EventName: name.trim(),
			DescriptionType: DescriptionTypeEnum.Event,
		};
		try {
			const response = await OpenAi(requestBody);
			setAIText(response.response); // Set the AI response after fetching
		} catch (error) {
			showToast('Error fetching AI suggestions');
		} finally {
			setActiveLoader({ eventIndex: null, mediaIndex: null }); // Deactivate loader
		}
	};
	const [isLoading, setIsLoading] = useState(false); // State to track loading
	/**
	 * Handles opening the AI response modal when user clicks on the 'Get AI Suggestions' button.
	 * @param eventIndex The index of the event in the projectEvent state
	 * @param mediaId The media ID of the media whose description is being edited
	 * @returns {Promise<void>}
	 */
	const handleOpenPreview = async (eventIndex: number, mediaId: number) => {
		try {
			setIsAiButtonDisabled(true);
			setSelectedEventIndex(eventIndex); // Store the selected eventIndex
			setSelectedMediaId(mediaId); // Store the selected mediaId
			const event = projectEvent[eventIndex];
			const eventName = event.title;
			const mediaIndex = event.eventMedia.findIndex((media) => media.mediaID === mediaId);
			// Get the first matching media description, if any
			const eventMediaDescription = event.eventMedia
				.map((media) => {
					if (media.mediaID === mediaId) {
						return media.description;
					}
					return undefined; // Explicitly return undefined if no match
				})
				.filter(Boolean)[0];
			if (!eventMediaDescription && !eventName) {
				// If projectDescription is empty, show an error and stop the process
				showToast('Event Name and Event Description is required', { type: 'error' });
				return;
			}
			// Clear existing AI text if it exists
			if (aiText) {
				setAIText('');
			}
			// Call fetchAISuggestions to get AI-generated text
			await fetchAISuggestions(eventName, eventMediaDescription || 'No description available', eventIndex, mediaIndex);
			// After fetching suggestions, open the modal
			setShowAiReponse(true);
			setIsAiButtonDisabled(false);
		} catch (error) {
			showToast('An error occurred while fetching AI suggestions', { type: 'error' });
		}
	};

	const handleAcceptAiDescription = (eventIndex: number, mediaId: number) => {
		// Update the projectEvent with the new description (AI-generated text)
		setProjectEvent((prev) => {
			const updatedProjectEvent = [...prev]; // Create a shallow copy of the array
			const event = updatedProjectEvent[eventIndex];
			// Ensure the event and eventMedia exist
			if (!event || !event.eventMedia) {
				console.error('Event or eventMedia is undefined');
				return prev;
			}

			// Update the event's eventMedia with the new description
			updatedProjectEvent[eventIndex] = {
				...event,
				eventMedia: event.eventMedia.map((media) => {
					if (media.mediaID === mediaId) {
						return {
							...media,
							description: aiText, // Set the new description for the matched media
							isModified: true,
						};
					}
					return media; // Return other media unchanged
				}),
				isModified: true, // Set isModified true for the entire event
			};
			// Optionally handle further actions after the update, such as calling a function to handle the updated media info
			handleEventMediaInfo(constructDescriptionRequestModel(updatedProjectEvent));
			return updatedProjectEvent; // Return the updated array
		});
		setShowAiReponse(false); // Close the AI response modal
	};
	const getUserTypedDescription = (eventIndex: number | null, mediaId: number | null) => {
		if (eventIndex === null || mediaId === null) return 'No description available'; // Handle case when either index is null

		const event = projectEvent[eventIndex];
		if (!event || !event.eventMedia) return 'No description available'; // Handle if event or eventMedia is missing

		const media = event.eventMedia.find((media: any) => media.mediaID === mediaId);
		return media?.description || 'No description available'; // Return description or default message
	};
	const handleRejectAiDescription = () => {
		setShowAiReponse(false); // Close the AI modal without updating anything
	};
	const handleFileSelect = async (file: File, eventIndex: number, eventMediaIndex: number, type: string, uploadId?: string) => {
		try {
			// Derive a unique key for the file based on its name and size
			const mediaKey = `${file.name}_${file.size}`;
			// Check if the file is already in progress or not successfully uploaded
			const uploadStatus = localStorage.getItem(mediaKey);
			if (uploadStatus === 'inProgress') {
				showToast(`The file "${file.name}" is still uploading .So  Please wait till the video got uplaoded`, {
					type: 'info',
				});
				// Update the input key to reset the file input
				setInputKey((prevKey) => prevKey + 1);
				return;
			}
			// check if any upload in progress
			const isAnyVideoUploading = uploadStatuses.some((status) => status.isUploading);
			// check if any eventMedia has the same size or it is uploading
			const isFileSizeMatchingOrUploading = projectEvent.some((event) =>
				event.eventMedia.some((media) => {
					// check if the media size matches the file size or if it is already uploading
					return media.eventMedia?.size === file.size && media.isUploading;
				}),
			);
			if (isAnyVideoUploading && isFileSizeMatchingOrUploading) {
				showToast('Please let upload the first video of same size complete before uploading the next one.', { type: 'error' });
				// Update the input key to reset the file input
				setInputKey((prevKey) => prevKey + 1);
				return; // Exit the function if another upload is in progress or file size matches
			}
			if (file) {
				setdataFromEvent(true);
				if (isVideoUploading) {
					isVideoUploading(true);
				}
				// Perform validation checks for modified events only
				const hasEmptyTitle = projectEvent.some((event, index) => event.title === '' && index === eventIndex);
				const isDuplicateTitle = projectEvent.some((event, index) =>
					projectEvent.some((otherEvent, otherIndex) => otherIndex !== index && otherEvent.title?.toLowerCase() === event.title?.toLowerCase()),
				);
				const updatedTitleErrors = [...titleError];
				if (hasEmptyTitle || isDuplicateTitle) {
					if (hasEmptyTitle) {
						//setTitleError({ error: 'Event title cannot be empty', hasError: true });
						updatedTitleErrors[eventIndex] = { error: 'Event title cannot be empty', hasError: true, eventIndex }; // Update the specific index
					}
					if (isDuplicateTitle) {
						//setTitleError({ error: 'Event title must be unique', hasError: true });
						updatedTitleErrors[eventIndex] = { error: 'Event title must be unique', hasError: true, eventIndex }; // Update the specific index
					}
					setTitleError(updatedTitleErrors); // Set the updated state
					return;
				}
				// Check if eventIndex has changed and reset eventID if needed
				if (prevEventIndex !== eventIndex) {
					setEventID(0); // Reset eventID if the eventIndex changes
					// Reset the event media information to null, clearing any previously stored media details.
					setPrevEventIndex(eventIndex); // Update prevEventIndex to the current eventIndex
					handleCallBackGroundMediaProgress?.(false);
				}

				// Update the event based on the index and type
				const updatedEvents = await Promise.all(
					projectEvent.map(async (event, i) => {
						if (i === eventIndex) {
							if (type === 'eventMedia') {
								// Resolve video duration and update event media
								// const videoDuration = await getVideoDuration(file);
								// Update the eventMedia file
								const updatedEventMedia = event.eventMedia.map((media, idx) => {
									if (idx === eventMediaIndex) {
										return { ...media, eventMedia: file, eventMediaUrl: URL.createObjectURL(file), isModified: true };
									}
									return media;
								});
								setIsActionTriggered(false);
								return { ...event, eventMedia: updatedEventMedia, isModified: true };
							} else if (type === 'eventMediaThumbnail') {
								setIsActionTriggered(true);
								// Check if the media is already uploaded (has a valid mediaID)
								const existingMedia = projectEvent[eventIndex]?.eventMedia[eventMediaIndex];
								const previousMedia = projectEvent[eventIndex]?.eventMedia[eventMediaIndex + 1] || 0;
								if (existingMedia) {
									if (existingMedia.mediaID !== 0 && existingMedia.mediaID !== previousMedia.mediaID && previousMedia.mediaID !== undefined) {
										setActiveLoader({ eventIndex, mediaIndex: eventMediaIndex }); // Set active loader
										// Call API to upload the new thumbnail
										uploadThumbnailToExistingMedia(file, existingMedia.mediaID.toString(), 'EventMedia')
											.then(() => {
												// Handle success response
												showToast('Thumbnail updated successfully!', { type: 'success' });
												setActiveLoader({ eventIndex: null, mediaIndex: null }); // Reset active loader

												// Update projectEvent state with the new thumbnail
												setProjectEvent((prevEvents) => {
													const newEvents = [...prevEvents];
													if (
														eventIndex >= 0 &&
														eventIndex < newEvents.length &&
														eventMediaIndex >= 0 &&
														eventMediaIndex < newEvents[eventIndex].eventMedia.length
													) {
														newEvents[eventIndex].eventMedia[eventMediaIndex] = {
															...newEvents[eventIndex].eventMedia[eventMediaIndex],
															thumbnail: file,
															thumbnailUrl: URL.createObjectURL(file),
														};
														newEvents[eventIndex].isModified = true;
													}
													return newEvents;
												});
											})
											.catch((error: { message: any }) => {
												// Handle error response
												showToast(error?.message ?? 'Failed to update thumbnail.', { type: 'error' });
												setActiveLoader({ eventIndex: null, mediaIndex: null }); // Reset active loader
											});
									} else {
										// Update media-specific states in the upload process
										setThumbnailFromEvent((prevState) => {
											const newState = [...prevState];
											const index = newState.findIndex((item) => item.eventIndex === eventIndex && item.mediaIndex === eventMediaIndex);
											if (index !== -1) {
												newState[index] = { ...newState[index], mediaUploading: true };
											} else {
												newState.push({ eventIndex, mediaIndex: eventMediaIndex, mediaUploading: true });
											}
											return newState;
										});

										// Update the thumbnail file
										const updatedEventMedia = event.eventMedia.map((media, idx) => {
											if (idx === eventMediaIndex) {
												return { ...media, thumbnail: file, thumbnailUrl: URL.createObjectURL(file) };
											}
											return media;
										});
										// Get the specific media based on the index from the updatedEventMedia arrayl
										const selectedMedia = updatedEventMedia[eventMediaIndex];
										//Bind event id related to there selected Medias..
										// Check if selectedMedia.eventID is 0
										if (selectedMedia.eventID === 0) {
											// Find the first non-zero eventID from other media in the updatedEventMedia array
											const existingMediaWithEventID = updatedEventMedia.find((media, idx) => idx !== eventMediaIndex && media.eventID !== 0);

											// If an eventID is found in other media ,
											// otherwise, fall back to event.id or eventID
											if (existingMediaWithEventID) {
												selectedMedia.eventID = existingMediaWithEventID.eventID;
											} else {
												selectedMedia.eventID = event.id !== undefined ? event.id : eventID;
											}
										}

										// Check if the action is in edit mode
										if (isEditing) {
											// Retrieve the event ID from the selected project event based on the current event index
											const getEventID = projectEvent[eventIndex];
											// Check if the event ID is 0
											if (getEventID.id === 0 || getEventID.id === undefined) {
												// If the event ID is 0, check for any eventID in the eventMedia array (excluding 0th index)
												const _event = getEventID.eventMedia.find((media, index) => index !== 0 && media.eventID !== 0);
												// If a non-zero eventID is found ,otherwise fallback to assign 0
												selectedMedia.eventID = _event ? _event.eventID : 0;
											} else {
												// If the event ID is not 0, assign the retrieved event ID to the selected media object
												selectedMedia.eventID = getEventID.id === undefined ? 0 : getEventID.id;
											}
										}
										if (selectedMedia != null) {
											// Check if the media item exists
											// Ensure uploadId is retrieved from selectedMedia
											let uploadId = selectedMedia.uploadId;
											// Create a ProjectEvents object containing only the selected media for upload
											const projectEvents: ProjectEvents = {
												id: event.id,
												title: event.title, // Set the title for the event
												eventMedia: [selectedMedia], // Include the selected media item in the event media array
												isModified: true,
											};
											// Call the EventMediaUpload service to upload the media
											EventMediaUpload(projectEvents, projectId, selectedMedia.eventID, (index, progress, eventId, mediaID) => {
												if (dataFromEvent) {
													if (onUploadProgress) {
														onUploadProgress(index, progress, eventId, mediaID, updatedEventMedia[index].uploadId);
													}
												}
												setThumbnailFromEvent((prevState) =>
													prevState.map((item) =>
														item.eventIndex === eventIndex && item.mediaIndex === eventMediaIndex ? { ...item, mediaUploading: false } : item,
													),
												);

												// Set the state to trigger re-renders
												setUploadProgress({ progress, isUploading: true });
												setEventID(eventId);
												// map eventId with respect to their Index
												updatedEventMedia[index].eventID = eventId;
												// map mediaID with respect to their Index
												updatedEventMedia[index].mediaID = mediaID;
												setProjectEvent((prevEvents) => {
													const newEvents = [...prevEvents];
													// Validate eventIndex
													if (eventIndex < 0 || eventIndex >= newEvents.length) {
														return prevEvents; // Return previous state if index is invalid
													}
													// Validate eventMediaIndex
													const eventMedia = newEvents[eventIndex]?.eventMedia;
													if (!eventMedia || eventMediaIndex < 0 || eventMediaIndex >= eventMedia.length) {
														return prevEvents; // Return previous state if index is invalid
													}
													// Check if the uploadId matches before updating eventId and mediaId
													if (eventMedia[eventMediaIndex].uploadId === uploadId) {
														// Update the specific fields
														newEvents[eventIndex].eventMedia[eventMediaIndex] = {
															...eventMedia[eventMediaIndex], // Spread the existing object
															eventID: eventId,
															mediaID: mediaID,
														};
													}
													return newEvents;
												});
												// Update uploadStatus array
												setUploadStatus((prevStatus) => {
													const newStatus = [...prevStatus];
													const foundIndex = newStatus.findIndex((status) => status.uploadId === uploadId);

													if (foundIndex !== -1) {
														newStatus[foundIndex].progress = progress;
														newStatus[foundIndex].isUploading = true;
													} else {
														newStatus.push({ uploadId, progress, isUploading: true, mediaID });
													}

													return newStatus;
												});
											})
												.then((res) => {
													// Show success toast messages for each result returned from the upload
													res.forEach(async (result) => {
														// Error scenario: Check for "Plan Data Exceeded" as received from backend Error Response
														if (result.message.includes('Plan Data Exceeded') && result.mediaId == null) {
															Swal.fire({
																icon: 'error',
																title: 'Plan Limit Exceeded',
																text: 'Your plan data limit has been exceeded. Please upgrade your plan to continue uploading.',
																confirmButtonText: 'Ok',
																customClass: {
																	confirmButton: 'cmn-btn', // Add your custom class here
																},
															});
															setIsShouldShowLoader(false);
														} else {
															showToast(result.message, { type: 'success' });
															// Add a notification when a success toast is shown
															try {
																await ApiService.addNotification(`${result.fileName} event media uploaded successfully.`);
																finalizeProjectEdit?.(false);
															} catch (error) {
																console.error('Failed to add notification:', error);
															}
															// Fetch notifications after saving the event
															const notificationRes = await ApiService.getAllnotifications();
															if (notificationRes.isSuccess && notificationRes.response) {
																// Emit notifications using the event emitter
																eventEmitter.emit('newNotifications', notificationRes.response);
															}
														}
													});
												})
												.catch((error: any) => {
													// Show error toast message if something goes wrong during the upload
													showToast(error?.message ?? 'Something went wrong during the upload.', { type: 'error' }); // Show error toast
												})
												.finally(() => {
													setProjectEvent((prevState) => {
														const newState = [...prevState];
														// Validate eventIndex
														if (eventIndex < 0 || eventIndex >= newState.length) {
															return prevState; // Return previous state if index is invalid
														}
														// Validate eventMediaIndex
														const eventMedia = newState[eventIndex]?.eventMedia;
														if (!eventMedia || eventMediaIndex < 0 || eventMediaIndex >= eventMedia.length) {
															return prevState; // Return previous state if index is invalid
														}
														// Update the specific fields
														newState[eventIndex].eventMedia[eventMediaIndex] = {
															...eventMedia[eventMediaIndex], // Spread the existing object
															isUploading: false,
															progress: 100,
															// Add other field updates like eventMediaUrl, if needed
														};
														return newState;
													});

													setUploadStatus((prevStatus) => {
														const newStatus = [...prevStatus];
														const foundIndex = newStatus.findIndex((status) => status.uploadId === uploadId);
														if (foundIndex !== -1) {
															newStatus[foundIndex].isUploading = false;
															newStatus[foundIndex].progress = 100;
														}
														return newStatus;
													});
													// Hide the progress bar and reset progress state after the upload completes
													handleCallBackGroundMediaProgress?.(true);
												});
										} else {
											// Handle the case where no media is found for the specified index
											showToast(`No media found for the specified index: ${i}`, { type: 'error' }); // Show error toast
										}
										return { ...event, eventMedia: updatedEventMedia };
									}
								}
							}
						}
						return event;
					}),
				);

				setProjectEvent(updatedEvents);
			}
		} catch (error) {}
	};
	const findEmptyTitleIndex = (slides: HTMLElement[]) => {
		return Array.from(slides).findIndex((slide) => {
			const titleInput = slide.querySelector('input[name="EventTitle"]') as HTMLInputElement;
			return titleInput && titleInput.value.trim().length === 0;
		});
	};
	const findEmptyFieldIndex = (slides: HTMLElement[], activeIndex: number) => {
		// First, check if the active slide has empty fields
		const activeSlide = slides[activeIndex];
		const titleInput = activeSlide.querySelector('input[name="EventTitle"]') as HTMLInputElement;
		const descriptionInput = activeSlide.querySelector('textarea[name="description"]') as HTMLInputElement;
		const fileInput = activeSlide.querySelector('input[type="file"]') as HTMLInputElement;

		// If the active slide has empty fields, return its index
		if (
			(titleInput && titleInput.value.trim().length === 0) ||
			(descriptionInput && descriptionInput.value.trim().length === 0) ||
			fileInput !== null
		) {
			return activeIndex;
		}
		// If the active slide is valid, then check other slides for empty fields
		const emptyFieldIndex = Array.from(slides).findIndex((slide, index) => {
			// Skip the active slide since we already checked it
			if (index === activeIndex) return false;

			const titleInput = slide.querySelector('input[name="EventTitle"]') as HTMLInputElement;
			const descriptionInput = slide.querySelector('textarea[name="description"]') as HTMLInputElement;
			const fileInput = slide.querySelector('input[type="file"]') as HTMLInputElement;
			return (
				(titleInput && titleInput.value.trim().length === 0) || (descriptionInput && descriptionInput.value.trim().length === 0) || fileInput !== null
			);
		});

		// If no empty fields are found in the rest of the slides, return -1
		return emptyFieldIndex === -1 ? -1 : emptyFieldIndex;
	};
	const AddMore = async () => {
		const hasValue = projectEvent.every(
			(event) => event.title && event.eventMedia.every((media) => media.eventMedia && media.thumbnail && media.description),
		);
		if (!hasValue) {
			const { swiper } = swiperRef.current || {};
			if (!swiper) {
				console.warn('No empty events found to navigate to.');
				return;
			}
			const slides = swiper.slides as HTMLElement[];
			const currentIndex = swiper.activeIndex;
			const emptyFieldIndex = findEmptyFieldIndex(slides, currentIndex);
			if (emptyFieldIndex === -1) {
				console.warn('No slide found that starts with "Event 1".');
			} else {
				const emptyTitleIndex = projectEvent.findIndex((x) => !x.title);
				if (emptyTitleIndex > -1) {
					setTitleError((prevErrors) => {
						const updatedErrors = [...prevErrors];
						updatedErrors[emptyTitleIndex] = { error: null, hasError: true, eventIndex: emptyTitleIndex };
						return updatedErrors;
					});
					//swiper.slideTo(emptyFieldIndex);
					//return;
				}
				const descriptionError = projectEvent.some((x) => x.eventMedia.some((evntMedia) => !evntMedia.description));
				if (descriptionError) {
					setDescriptionError(true);
					//swiper.slideTo(emptyFieldIndex);
					//return;
				}
				//else if (emptyFieldIndex > -1) {
				//swiper.slideTo(emptyFieldIndex);
				//return;
				//}
				swiper.slideTo(emptyFieldIndex);
				return;
			}
		}
		// Generate a new uploadId for the new event
		let newUploadId = nanoid();
		const duplicateUploadIdEvent = projectEvent.find((event) => event.eventMedia[0]?.uploadId === newEvent.eventMedia[0].uploadId);
		if (duplicateUploadIdEvent) {
			newUploadId = nanoid(); // Generate a new uploadId if there's a match
		}

		// Create a new event with updated uploadId
		const updatedNewEvent = {
			...newEvent,
			eventMedia: [
				{
					...newEvent.eventMedia[0],
					uploadId: newUploadId,
				},
			],
		};

		// Add the updated new event to the projectEvent state
		setProjectEvent((prevEventsMedia) => [...prevEventsMedia, updatedNewEvent]);

		// Used setTimeout to ensure the state has updated before checking for empty titles
		setTimeout(() => {
			const slides = swiperRef.current?.swiper.slides as HTMLElement[];
			const emptyTitleIndex = findEmptyTitleIndex(slides);
			if (emptyTitleIndex !== -1) {
				swiperRef.current.swiper.slideTo(emptyTitleIndex);
			} else {
				console.warn('No slide found that starts with "Event 1".');
			}
		}, 200); // Adjusted the timeout duration
	};
	const handleAddMoreMediaEvent = (eventIndex: number) => {
		// Create a new empty media object
		const newMedia: EventMediaModel = {
			eventMedia: null,
			eventMediaUrl: '',
			thumbnail: null,
			thumbnailUrl: '',
			description: '',
			eventID: 0,
			mediaID: 0,
			progress: 0,
			isUploading: false,
			uploadId: nanoid(),
			isModified: false,
		};
		// Update the specific project event's media by mapping over the projectEvent array
		const updatedEvents = projectEvent.map((event, index) => {
			// Check if the current event index matches the eventIndex passed as an argument
			if (index === eventIndex) {
				setActiveEventIndex(index);
				setNewlyAddedMediaIndex(0); // Newly added media is at the last index
				// Set the isNewMediaAdded state to true
				setIsNewMediaAdded(true);
				setIsBlankMediaDeleted(false);
				setNewMediaAddByPlusButton(false);
				// Return a new event object with the new media added to the eventMedia array
				return {
					...event,
					eventMedia: [newMedia, ...event.eventMedia], // Add the new media to the eventMedia array
				};
			}
			// If the index doesn't match, return the original event object
			return event;
		});
		// Update the projectEvent state with the new updatedEvents array
		setProjectEvent(updatedEvents);
		console.log('updatedEvents', updatedEvents);
	};
	/**
	 * Removes media from a specific project event.
	 *
	 * @param {number} eventIndex - The index of the event to update.
	 * @param {number} mediaIndex - The index of the media to remove.
	 */
	const handleRemoveMediaEvent = async (eventIndex: number, mediaIndex: number) => {
		try {
			confirmAlert({
				customUI: ({ onClose }) => {
					const handleConfirmDelete = async () => {
						if (!isEditDelete && projectEvent[eventIndex].eventMedia.filter((media) => media.eventMediaUrl && media.thumbnailUrl).length === 1) {
							showToast('You can not delete all the event Media', { type: 'error' });
							onClose();
							return;
						}

						// Get the current event and media
						const eventToUpdate = projectEvent[eventIndex];
						if (!eventToUpdate || mediaIndex >= eventToUpdate.eventMedia.length) {
							console.error('Event or media index out of range');
							return;
						}
						setIsActionTriggered(false);
						// Store the media being deleted
						const deletedMedia: EventMediaModel = eventToUpdate.eventMedia[mediaIndex];
						// Update the specific project event's media
						const updatedEvents = projectEvent.map((event, index) => {
							if (index === eventIndex) {
								const selectedEvent = {
									...event,
									eventMedia: event.eventMedia.filter((_, i) => i !== mediaIndex), // Remove the media at mediaIndex
								};
								if (selectedEvent.eventMedia.length === 0) {
									Object.assign(selectedEvent, newEvent);
									selectedEvent.title = event.title;
								}
								selectedEvent.isModified = true;
								return selectedEvent;
							}
							//setTitleError({ error: null, hasError: false });
							setTitleError((prevErrors) => {
								const updatedErrors = [...prevErrors];
								updatedErrors[eventIndex] = { error: null, hasError: false, eventIndex }; // Ensure the structure matches the expected type
								return updatedErrors;
							});
							setDescriptionError(false);
							return event;
						});

						// Function to handle media deletion
						const handleDeleteMedia = async (mediaID: number) => {
							try {
								const result = await DeleteMediaById(mediaID); // Call the service to delete media
								if (result.success) {
									showToast(result.message, { type: 'success' });
									setDescriptionError(false);
								} else {
									showToast(result.message, { type: 'error' });
								}
							} catch (error) {
								showToast('Error while deleting media:', { type: 'error' });
							}
						};
						if (eventToUpdate.eventMedia.length === 1) {
							const eventID = eventToUpdate.eventMedia[0].eventID || eventToUpdate.id;
							await DeleteEventWithMedia(eventID);
							onClose();
						}
						// Proceed with media deletion if it exists
						else if (deletedMedia && deletedMedia.mediaID !== 0) {
							await handleDeleteMedia(deletedMedia.mediaID);
						}
						setProjectEvent(updatedEvents);
						onClose();
					};

					return (
						<div className="modal-content-delete">
							<div className="icon-modal-delete">
								<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="39" cy="39" r="38" fill="white" stroke="#EFBD8A" strokeWidth="2" />
									<path
										d="M41.3491 47.128H37.4291V44.44C37.5971 42.312 37.9891 41.472 40.8451 39.848C45.2691 37.216 48.9651 34.472 48.9651 29.936C48.9651 25.68 45.5491 22.936 40.3971 22.936C35.2451 22.936 32.6131 25.456 32.6131 28.648C32.6131 29.152 32.6691 29.712 32.8371 30.44H28.9731C28.6931 29.768 28.5811 28.984 28.5811 28.088C28.5811 24.336 31.8291 19.184 40.5651 19.184C47.6771 19.184 53.3891 23.272 53.3891 29.824C53.3891 36.432 47.2291 40.296 43.8131 42.368C41.0691 44.048 41.3491 44.048 41.3491 47.128ZM36.0851 56.368C36.0851 54.52 37.5411 53.008 39.3891 53.008C41.2371 53.008 42.6931 54.52 42.6931 56.368C42.6931 58.16 41.2371 59.616 39.3891 59.616C37.5411 59.616 36.0851 58.16 36.0851 56.368Z"
										fill="#941AF2"
									/>
								</svg>
							</div>
							<h5>Are you sure you want to delete this event media?</h5>
							<div className="buttons-modal-delete">
								<Button
									className="cmn-btn"
									label="Yes, I'm sure"
									onClick={async () => {
										await handleConfirmDelete();
									}}
									id={'confirmEventDelete'}
								/>
								<Button className="cmn-btn blank-btn" label="Cancel" onClick={onClose} id={'cancelEventDelete'} />
							</div>
						</div>
					);
				},
				closeOnClickOutside: false,
			});
		} catch (error: any) {
			showToast(error?.message ?? 'Something went wrong');
		}
	};

	const handleRemoveEventWithMedia = async (eventIndex: number) => {
		try {
			confirmAlert({
				customUI: ({ onClose }) => {
					const handleConfirmDelete = async () => {
						const eventToUpdate = projectEvent[eventIndex];
						if (!eventToUpdate) {
							showToast('Event index out of range', { type: 'error' });
							console.error('Event index out of range');
							return;
						}
						const eventID = eventToUpdate.eventMedia[0]?.eventID || eventToUpdate.id;

						if (eventID) {
							try {
								if (
									!isEditDelete &&
									projectEvent.filter((event) => event.eventMedia.filter((media) => media.eventMediaUrl && media.thumbnailUrl).length > 0).length ===
										1
								) {
									showToast('You can not delete all the events', { type: 'error' });
									onClose();
									return;
								}
								const result = await DeleteEventWithMedia(eventID);
								const toastType = result.success ? 'success' : 'error';
								showToast(result.message, { type: toastType });
								setDescriptionError(false);
							} catch (error) {
								showToast('Error while deleting event:', { type: 'error' });
							}
						}

						const updatedEvents = projectEvent.filter((_, index) => index !== eventIndex);
						//setTitleError({ error: null, hasError: false });
						setTitleError((prevErrors) => {
							const updatedErrors = [...prevErrors];
							updatedErrors[eventIndex] = { error: null, hasError: false, eventIndex }; // Reset the specific index
							return updatedErrors;
						});
						setDescriptionError(false);
						setProjectEvent(updatedEvents);
						onClose();
						retrieveProjects?.();
					};

					return (
						<div className="modal-content-delete">
							<div className="icon-modal-delete">
								<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="39" cy="39" r="38" fill="white" stroke="#EFBD8A" strokeWidth="2" />
									<path
										d="M41.3491 47.128H37.4291V44.44C37.5971 42.312 37.9891 41.472 40.8451 39.848C45.2691 37.216 48.9651 34.472 48.9651 29.936C48.9651 25.68 45.5491 22.936 40.3971 22.936C35.2451 22.936 32.6131 25.456 32.6131 28.648C32.6131 29.152 32.6691 29.712 32.8371 30.44H28.9731C28.6931 29.768 28.5811 28.984 28.5811 28.088C28.5811 24.336 31.8291 19.184 40.5651 19.184C47.6771 19.184 53.3891 23.272 53.3891 29.824C53.3891 36.432 47.2291 40.296 43.8131 42.368C41.0691 44.048 41.3491 44.048 41.3491 47.128ZM36.0851 56.368C36.0851 54.52 37.5411 53.008 39.3891 53.008C41.2371 53.008 42.6931 54.52 42.6931 56.368C42.6931 58.16 41.2371 59.616 39.3891 59.616C37.5411 59.616 36.0851 58.16 36.0851 56.368Z"
										fill="#941AF2"
									/>
								</svg>
							</div>
							<h5>Are you sure you want to delete this event?</h5>
							<div className="buttons-modal-delete">
								<Button className="cmn-btn" label="Yes, I'm sure" onClick={handleConfirmDelete} id={'confirmEventDelete'} />
								<Button className="cmn-btn blank-btn" label="Cancel" onClick={onClose} id={'cancelEventDelete'} />
							</div>
						</div>
					);
				},
				closeOnClickOutside: false,
			});
		} catch (error: any) {
			showToast(error?.message ?? 'Something went wrong');
		}
	};
	const handleDeleteMediaEvent = (eventIndex: number, mediaIndex: number) => {
		setProjectEvent((prevEvents) => {
			return prevEvents.map((event, index) => {
				if (index === eventIndex) {
					const mediaToDelete = event.eventMedia[mediaIndex];

					// Check if the media has eventMediaUrl and not thumbnailUrl, and remove only eventMediaUrl
					if (mediaToDelete.eventMediaUrl && mediaToDelete.eventMedia && !mediaToDelete.thumbnailUrl) {
						return {
							...event,
							eventMedia: event.eventMedia.map(
								(media, idx) => (idx === mediaIndex ? { ...media, eventMediaUrl: '', eventMedia: null } : media), // Clear eventMediaUrl only
							),
						};
					}

					// If it's not the case, remove the whole media item and trigger state updates
					setIsBlankMediaDeleted(true);
					setIsNewMediaAdded(false);
					setNewMediaAddByPlusButton(false);
					setDescriptionError(false);
					setIsActionTriggered(false);

					return {
						...event,
						eventMedia: event.eventMedia.filter((_, idx) => idx !== mediaIndex), // Remove the media at mediaIndex
					};
				}

				// Reset title error for other events
				setTitleError((prevErrors) => {
					const updatedErrors = [...prevErrors];
					updatedErrors[eventIndex] = { error: null, hasError: false, eventIndex };
					return updatedErrors;
				});

				setDescriptionError(false);
				return event; // Return unchanged event
			});
		});
	};

	/**
	 * Handles the title change event for a specific project event.
	 * Updates the title of the event at the specified index with the new value.
	 *
	 * @param {ChangeEvent<HTMLInputElement>} e - The change event object.
	 * @param {number} eventIndex - The index of the event to update.
	 */
	// Handler for title change
	const handleTitleChange = (e: ChangeEvent<HTMLInputElement>, eventIndex: number) => {
		const { value } = e.target;
		const titleError = validateTitle(value, eventIndex, projectEvent);
		setTitleError((prevErrors) => {
			const updatedErrors = [...prevErrors]; // Create a copy of the previous errors
			updatedErrors[eventIndex] = { ...titleError, eventIndex }; // Ensure eventIndex is included
			return updatedErrors;
		});
		//setTitleError(titleError);

		setProjectEvent((prevEvents) => updateEventField(prevEvents, eventIndex, { title: value, isModified: true }));
	};

	// Utility function to update media description
	const updateMediaDescription = (
		events: ProjectEvents[],
		eventIndex: number,
		mediaIndex: number,
		description: string,
		mediaID: number, // Include mediaID
	) => {
		// Log for debugging to check the values being passed
		//console.log('Updating media:', { mediaID });

		// Get all media IDs from the current event excluding the media being updated
		const currentMediaIds = events[eventIndex].eventMedia.map((media) => media.mediaID);

		// Check if the given mediaID already exists in the current eventMedia and is not the current one being updated
		const isMediaIdDuplicated = events[eventIndex].eventMedia.some((media, mIndex) => mIndex !== mediaIndex && media.mediaID === mediaID);

		//console.log('Is media ID duplicated:', isMediaIdDuplicated);

		// If duplication is found, we find the greatest mediaID (max value)
		const newMediaID = isMediaIdDuplicated
			? Math.max(...currentMediaIds, mediaID) // Get the max of existing mediaIDs and current mediaID
			: mediaID;

		//console.log('New media ID after duplication check:', newMediaID);

		return events.map((event, eIndex) => {
			if (eIndex === eventIndex) {
				return {
					...event,
					isModified: true,
					eventMedia: event.eventMedia.map((media, mIndex) =>
						mIndex === mediaIndex
							? { ...media, description, mediaID: newMediaID, isModified: true } // Assign the greatest mediaID
							: media,
					),
				};
			}
			return event;
		});
	};

	// Utility function to validate title
	const validateTitle = (value: string, eventIndex: number, projectEvents: ProjectEvents[]) => {
		if (!value?.trim()) {
			return { hasError: true, error: 'Event title cannot be empty' };
		}
		const isDuplicateTitle = projectEvents.some((event, eIndex) => eIndex !== eventIndex && event.title?.toLowerCase() === value.toLowerCase());
		if (isDuplicateTitle) {
			return { hasError: true, error: 'Event title must be unique' };
		}
		if (value && value.length >= 20) return { hasError: true, error: 'Event title must be  20 characters or fewer' };
		return { hasError: false, error: null };
	};

	// Utility function to update event field
	const updateEventField = (events: ProjectEvents[], eventIndex: number, field: Partial<ProjectEvents>) => {
		return events.map((event, eIndex) => (eIndex === eventIndex ? { ...event, ...field, isModified: true } : event));
	};
	const handleDescriptionChange = (
		e: ChangeEvent<HTMLTextAreaElement>,
		eventIndex: number,
		mediaIndex: number,
		mediaID: number, // Include mediaID
		eventName: string,
		handleEventMediaInfo: (updatedEvents: DescriptionRequestModel) => void,
	) => {
		const { value } = e.target;
		const updatedEvents = updateMediaDescription(projectEvent, eventIndex, mediaIndex, value, mediaID); // Pass mediaID
		setProjectEvent(updatedEvents);
		handleEventMediaInfo(constructDescriptionRequestModel(updatedEvents));
		setDescriptionError(value?.trim() === '');
	};

	// Function to validate event fields
	const validateModifiedEvents = (events: ProjectEvents[]) => {
		const hasEmptyFields = events.some((event) => event.eventMedia.some((media) => !media.eventMediaUrl || !media.thumbnailUrl));
		const hasEmptyDescriptions = events.some((event) => event.eventMedia.some((media) => !media.description?.trim()));

		const hasEmptyTitle = events.some((event) => !event.title?.trim());
		const isDuplicateTitle = events.some((event, index) =>
			events.some((otherEvent, otherIndex) => otherIndex !== index && otherEvent.title?.toLowerCase() === event.title?.toLowerCase()),
		);
		return { hasEmptyFields, hasEmptyDescriptions, hasEmptyTitle, isDuplicateTitle };
	};
	const handleEditEvent = async () => {
		const { hasEmptyFields, hasEmptyDescriptions, hasEmptyTitle, isDuplicateTitle } = validateModifiedEvents(projectEvent);
		if (hasEmptyFields || hasEmptyDescriptions || hasEmptyTitle || isDuplicateTitle) {
			const { swiper } = swiperRef.current || {};
			if (swiper) {
				const slides = swiper.slides as HTMLElement[];
				const currentIndex = swiper.activeIndex;
				const emptyTitleIndex = findEmptyFieldIndex(slides, currentIndex);
				if (emptyTitleIndex !== -1) {
					swiper.slideTo(emptyTitleIndex);
				} else {
					console.warn('No slide found that starts with "Event 1".');
				}
			} else {
				console.warn('No empty events found to navigate to.');
			}
			if (hasEmptyTitle) setTitleError((prevErrors) => [...prevErrors, { error: 'Event title cannot be empty', hasError: true }]);
			if (isDuplicateTitle) setTitleError((prevErrors) => [...prevErrors, { error: 'Event title must be unique', hasError: true }]);
			if (hasEmptyDescriptions) setDescriptionError(true);
			showToast('Please fill all fields before submitting.', { type: 'error' });
			return;
		}
		const sanitizedEvents = projectEvent
			.filter((event) => event.isModified)
			.map((event) => ({
				...event,
				eventMedia: event.eventMedia.filter((media) => media.isModified && media.eventMediaUrl && media.thumbnailUrl && media.description),
			}));

		if (sanitizedEvents.length === 0) {
			finalizeProjectEdit?.(true);
			return;
		}
		const structuredRequestModel = constructDescriptionRequestModel(sanitizedEvents);
		try {
			const res = await ApiService.AddDescriptionOfMedia(structuredRequestModel);
			if (res.isSuccess) {
				showToast(res.response ?? 'Event saved successfully', { type: 'success' });
				// Reset modification flag on successful save
				setProjectEvent((prevEvents) => prevEvents.map((event) => ({ ...event, isModified: false })));
				finalizeProjectEdit?.(true);
				// Fetch notifications after saving the event
				const notificationRes = await ApiService.getAllnotifications();
				if (notificationRes.isSuccess && notificationRes.response) {
					// Emit notifications using the event emitter
					eventEmitter.emit('newNotifications', notificationRes.response);
				}
			} else {
				showToast(res.error.userMessage, { type: 'error' });
			}
		} catch (error: any) {
			showToast(error?.message ?? 'Something went wrong', { type: 'error' });
		} finally {
			retrieveProjects?.();
		}
	};
	const constructDescriptionRequestModel = (events: ProjectEvents[]): DescriptionRequestModel => {
		// Validate and prepare the structured request model for the backend

		const structuredRequestModel: DescriptionRequestModel = {
			ProjectId: projectId, // Assuming you have projectId from props or state
			Events: events.map((event) => {
				const eventID = event.eventMedia[0]?.eventID || event.id;
				return {
					Id: eventID,
					Title: event.title,
					Medias: event.eventMedia
						?.filter((x) => x.isModified)
						.map((media) => {
							return {
								Id: media.mediaID,
								Description: media.description,
							};
						}),
				};
			}),
		};

		return structuredRequestModel;
	};
	/**
	 * Retrieves a project by its ID and fetches its associated events from the API.
	 * If the project ID is valid and the API call is successful, it maps the event data
	 * to a formatted object and updates the project event state.
	 *
	 * @async
	 */
	const getEventProjectById = async () => {
		// Check if projectId is greater than 0
		if (projectId > 0) {
			// Call ApiService to get events by project ID
			await ApiService.GetEventByProjectId(projectId)
				.then(async (result) => {
					// Check if the result is successful
					if (result.isSuccess) {
						// Check if result has response data and it's not empty
						if (result.response && result.response.length > 0) {
							// Map over the response data to extract required fields
							let events = result.response.map((event) => ({
								id: event.id,
								title: event.title,
								isModified: false,
								// Extract event media data, handling file uploads separately
								eventMedia:
									event.eventMedias && event.eventMedias.length > 0
										? event.eventMedias.map((media) => ({
												eventMedia: null, // Initialize eventMedia as null for file upload handling
												eventMediaUrl: media.thumbnailUrl || '', // Ensure there's a fallback if mediaUrl is undefined
												thumbnail: null, // Initialize thumbnail as null for file upload handling
												thumbnailUrl: media.thumbnailUrl || '', // Fallback to empty string if undefined
												description: media.description || '', // Ensure description is a string
												eventID: event.id, // Set the current event's ID
												mediaID: media.id, // Set media ID
												progress: 0, // Initialize progress to 0
												isUploading: false, // Initialize isUploading to false
												uploadId: '', // Initialize uploadId to empty string
												duration: '',
											}))
										: [
												// Use an array to ensure consistency
												{
													eventMedia: null,
													eventMediaUrl: '',
													thumbnail: null,
													thumbnailUrl: '',
													description: '',
													eventID: event.id,
													mediaID: 0, // Default mediaID
													progress: 0,
													isUploading: false,
													uploadId: '',
													duration: '',
												},
											],
							}));
							const reshuffledEvents = reshuffleArray(events, eventEditIndex ?? 0);
							if (projectType === Constants.addProjectEvent) {
								const newMedia: EventMediaModel = {
									eventMedia: null,
									eventMediaUrl: '',
									thumbnail: null,
									thumbnailUrl: '',
									description: '',
									eventID: 0,
									mediaID: 0,
									progress: 0,
									isUploading: false,
									uploadId: nanoid(),
									isModified: false,
								};

								// Add the new media to the first event's media array
								const updatedEvents = [...reshuffledEvents]; // Create a copy of reshuffledEvents
								updatedEvents[0].eventMedia = [newMedia, ...updatedEvents[0].eventMedia]; // Add new media to the beginning of the first event's media array
								setNewMediaAddByPlusButton(true);
								setActiveEventIndex(0);
								setProjectEvent(updatedEvents); // Set the updated events with the new media
								console.log(updatedEvents);
								return;
							}
							//Add the new event to the projectEvent state
							else if (projectType === Constants.editProjectEvent) {
								setProjectEvent(reshuffledEvents);
								return;
							}
						}
					} else {
						// Show error toast if result is not successful
						showToast(result.error.userMessage ?? 'Something went wrong', { type: 'error' });
					}
				})
				.catch((error) => {
					// Catch any errors and show error toast
					showToast(error.message ?? 'Something went wrong', { type: 'error' });
				});
		}
	};
	useEffect(() => {
		// This effect runs when the `projectId` changes.
		// Purpose: To ensure that the project data is fetched if it is not already available.

		// Check if ProjectEvent is undefined or an empty array.
		// This helps determine if we need to fetch data from the server.
		if (!ProjectEvent || ProjectEvent.length === 0) {
			// If ProjectEvent is empty or not set, call the function to fetch project data.
			getEventProjectById();
		}
	}, [projectId]); // Dependency array: Effect runs when `projectId` changes.
	useEffect(() => {
		// This effect runs when either ProjectEvent or hasSetProjectEvent changes.
		// Purpose: To update the local state with the fetched project events if they are available.

		// Check if ProjectEvent has data and if it hasn't been set in state before.
		// This condition ensures that we only set the ProjectEvent once.
		if (ProjectEvent && ProjectEvent.length > 0 && !hasSetProjectEvent) {
			// Set the state with the fetched ProjectEvent data.
			// This updates the component's state to reflect the available project events.
			setProjectEvent(ProjectEvent);

			// Mark hasSetProjectEvent as true to prevent this effect from running again.
			// This is crucial to avoid unnecessary updates and potential infinite loops.
			setHasSetProjectEvent(true); // Prevents future updates
		}
	}, [ProjectEvent, hasSetProjectEvent]); // Dependency array: Effect runs when ProjectEvent or hasSetProjectEvent changes.
	useEffect(() => {
		handleCreateEventMediaInfo?.(projectEvent);
	}, [projectEvent]);

	const isNullOrEmpty = (str: string) => {
		return !str || str.trim() === '';
	};
	const handleRemoveThumbnail = async (eventIndex: number, mediaIndex: number) => {
		try {
			confirmAlert({
				customUI: ({ onClose }) => {
					const handleConfirmRemoveThumbnail = async () => {
						// Update the thumbnail and URL to null
						const updatedEvents = projectEvent.map((event, index) => {
							if (index === eventIndex) {
								const updatedMedia = event.eventMedia.map((media, i) => {
									if (i === mediaIndex) {
										return {
											...media,
											thumbnail: null,
											thumbnailUrl: '', // or null based on your logic
										};
									}
									return media;
								});
								return { ...event, eventMedia: updatedMedia };
							}
							return event;
						});

						// Update the state with the new event list
						setProjectEvent(updatedEvents);
						showToast('Thumbnail removed successfully', { type: 'success' });
						onClose();
					};

					return (
						<div className="modal-content-delete">
							<div className="icon-modal-delete">
								<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="39" cy="39" r="38" fill="white" stroke="#EFBD8A" strokeWidth="2" />
									<path
										d="M41.3491 47.128H37.4291V44.44C37.5971 42.312 37.9891 41.472 40.8451 39.848C45.2691 37.216 48.9651 34.472 48.9651 29.936C48.9651 25.68 45.5491 22.936 40.3971 22.936C35.2451 22.936 32.6131 25.456 32.6131 28.648C32.6131 29.152 32.6691 29.712 32.8371 30.44H28.9731C28.6931 29.768 28.5811 28.984 28.5811 28.088C28.5811 24.336 31.8291 19.184 40.5651 19.184C47.6771 19.184 53.3891 23.272 53.3891 29.824C53.3891 36.432 47.2291 40.296 43.8131 42.368C41.0691 44.048 41.3491 44.048 41.3491 47.128ZM36.0851 56.368C36.0851 54.52 37.5411 53.008 39.3891 53.008C41.2371 53.008 42.6931 54.52 42.6931 56.368C42.6931 58.16 41.2371 59.616 39.3891 59.616C37.5411 59.616 36.0851 58.16 36.0851 56.368Z"
										fill="#941AF2"
									/>
								</svg>
							</div>
							<h5>Are you sure you want to remove the thumbnail for this media?</h5>
							<div className="buttons-modal-delete">
								<Button
									className="cmn-btn"
									label="Yes, I'm sure"
									onClick={async () => {
										await handleConfirmRemoveThumbnail();
									}}
									id={'confirmThumbnailRemove'}
								/>
								<Button className="cmn-btn blank-btn" label="Cancel" onClick={onClose} id={'cancelThumbnailRemove'} />
							</div>
						</div>
					);
				},
				closeOnClickOutside: false,
			});
		} catch (error: any) {
			showToast(error?.message ?? 'Something went wrong');
		}
	};
	/**
	 * Handles the image click event.
	 * Toggles the action state based on whether the click is from the thumbnail.
	 *
	 * @param {boolean} isFromThumbnail - Determines if the action is triggered from the thumbnail.
	 */
	const handleImageClick = (isFromThumbnail: boolean) => {
		// Set the action triggered state based on whether it's from thumbnail
		setIsActionTriggered(isFromThumbnail);
	};

	// Function to open modal
	const openModal = (uploadId: string, projectId: Number) => {
		const selectedMedia = projectEvent.flatMap((x) => x.eventMedia).find((x) => x.uploadId === uploadId);
		setProjectNumber(Number(projectId)); // Convert projectId to a primitive number
		setEventMedia(selectedMedia);
		setIsModalOpen(true);
	};

	/**
	 * Closes the modal, processes the thumbnail data, and updates the event media with the selected thumbnail.
	 * This method is triggered when a thumbnail image is clicked, or when manually triggered for file selection.
	 *
	 * @param {boolean} isFromImageClick - Flag indicating whether the function was called from an image click event.
	 *
	 * @returns {void}
	 */
	const closeModal = (isFromImageClick: boolean) => {
		// Handle case where the thumbnail is not available (null or undefined)
		const thumbnail = thumbnails.at(0);

		// Initialize fileData and fileName only if the thumbnail is not null
		let fileData: File | null = null;
		let fileName: string | null = null;

		if (thumbnail) {
			// Generate file name for the thumbnail (default if not provided)
			fileName = `${eventMedia?.eventMedia?.name ?? 'defaultFileName'}.png`;

			// Convert base64 string to file data (thumbnail)
			fileData = base64ToFile(thumbnail, fileName);
		}

		// Initialize indices to 0 to avoid undefined values
		let eventIndex = -1;
		let mediaIndex = -1;

		if (isFromImageClick) {
			// Loop through project events to find the relevant event and media
			projectEvent.some((event, eventindex) => {
				// Loop through event media to find the relevant media by uploadId
				return event.eventMedia.some((media, index) => {
					if (media.uploadId === eventMedia?.uploadId) {
						// Only update media if fileData is available
						if (fileData) {
							media.thumbnailUrl = thumbnail ?? ''; // Provide fallback for undefined
							media.thumbnail = fileData;
							mediaIndex = index; // Store media index
							eventIndex = eventindex;
						}
						return true; // Stop looping once the correct media is found
					}
					return false; // Continue looping
				});
			});
		}

		// Close modal and reset thumbnails array
		setIsModalOpen(false);
		setThumbnails([]);

		// Call handleFileSelect with proper indices and fileData only if available
		if (fileData) {
			handleFileSelect(fileData, eventIndex, mediaIndex, 'eventMediaThumbnail');
		}
	};

	const generateThumbnailAtSeek = async (seekTime: number) => {
		if (!eventMedia?.eventMediaUrl) return;

		const videoUrl = eventMedia?.eventMedia ? URL.createObjectURL(eventMedia.eventMedia as Blob) : '';

		// Create a video element and set its source
		const video = document.createElement('video');
		video.src = videoUrl;

		video.onloadeddata = async () => {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			if (!ctx) return;

			video.currentTime = seekTime;

			await new Promise((resolve) => {
				video.onseeked = () => {
					const scaleFactor = 0.5; // Resize the thumbnail
					canvas.width = video.videoWidth * scaleFactor;
					canvas.height = video.videoHeight * scaleFactor;
					ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
					const thumbnailUrl = canvas.toDataURL('image/png');
					setThumbnails([thumbnailUrl]);
					resolve(null);
				};
			});
		};
	};

	// Function to create and return the modal JSX
	const renderModal = () => {
		return (
			<ModalComponent title="Upload Video" show={isModalOpen} handleClose={() => closeModal(false)} className="custom-modal-class">
				{/* Embed a video player */}

				<div className="video-container">
					{eventMedia?.eventMediaUrl && (
						<video
							className="custom-video-player"
							src={eventMedia.eventMediaUrl}
							controls
							style={{ width: '100%', maxWidth: '800px', height: 'auto' }}
							onSeeked={(e: React.SyntheticEvent<HTMLVideoElement>) => {
								setIsLoading(true);
								const videoElement = e.currentTarget;
								const seekedSeconds = videoElement.currentTime;
								console.log(`Seeked time: ${seekedSeconds}s.`);
								generateThumbnailAtSeek(seekedSeconds).finally(() => {
									setIsLoading(false); // Stop the loader after thumbnail generation
								});
							}}
						/>
					)}
				</div>
				{isLoading && <Loader />}
				{/* Display thumbnails */}
				{thumbnails.length !== 0 && (
					<div className="thumbnail_inner_cont">
						<h2>Genreated thumbnail below</h2> {/* Heading added here */}
						<div className="thumbnail-parent-cstm">
							{thumbnails.map((thumbnail, index) => (
								<div key={index} className="custom-thumbnail">
									<div className="custom-thumbnail-flex">
										<Image src={thumbnail} alt={`Thumbnail ${index + 1}`} style={{ width: '300px', height: 'auto' }} />
										<Button className="cmn-btn" label="Upload Thumbnail" id="confirmThumbnailRemove" onClick={() => closeModal(true)} />
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</ModalComponent>
		);
	};
	return (
		<>
			<div className="main-content-sidebox">
				<div className="header-sidebox">
					<h5>Event Media</h5>
					<div className={`box-enable-disable ${isSmartRevision ? 'enable-smart-review' : 'disbled-smart-review'}`}>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M13.4927 2.29104L8.8552 0.225543C8.17462 -0.0751808 7.14583 -0.0751808 6.46524 0.225543L1.82776 2.29104C0.656525 2.81335 0.482422 3.52559 0.482422 3.90545C0.482422 4.28531 0.656525 4.99755 1.82776 5.51986L6.46524 7.58536C6.80554 7.73572 7.23288 7.81486 7.66022 7.81486C8.08757 7.81486 8.51491 7.73572 8.8552 7.58536L13.4927 5.51986C14.6639 4.99755 14.838 4.28531 14.838 3.90545C14.838 3.52559 14.6718 2.81335 13.4927 2.29104Z"
								fill="#941AF2"
							/>
							<path
								d="M7.66053 11.9047C7.3598 11.9047 7.05908 11.8414 6.7821 11.7227L1.44822 9.34855C0.633102 8.98452 0 8.01113 0 7.11687C0 6.7924 0.261154 6.53125 0.585619 6.53125C0.910084 6.53125 1.17124 6.7924 1.17124 7.11687C1.17124 7.54421 1.52736 8.09818 1.92305 8.27228L7.25692 10.6464C7.51016 10.7572 7.80297 10.7572 8.05622 10.6464L13.3901 8.27228C13.7858 8.09818 14.1419 7.55213 14.1419 7.11687C14.1419 6.7924 14.4031 6.53125 14.7275 6.53125C15.052 6.53125 15.3131 6.7924 15.3131 7.11687C15.3131 8.00321 14.68 8.98452 13.8649 9.34855L8.53104 11.7227C8.26197 11.8414 7.96125 11.9047 7.66053 11.9047Z"
								fill="#941AF2"
							/>
							<path
								d="M7.66053 15.8305C7.3598 15.8305 7.05908 15.7672 6.7821 15.6485L1.44822 13.2743C0.569792 12.8866 0 12.0081 0 11.0427C0 10.7182 0.261154 10.457 0.585619 10.457C0.910084 10.457 1.17124 10.7182 1.17124 11.0427C1.17124 11.5412 1.46405 11.9923 1.92305 12.1981L7.25692 14.5722C7.51016 14.683 7.80297 14.683 8.05622 14.5722L13.3901 12.1981C13.8412 12.0002 14.1419 11.5412 14.1419 11.0427C14.1419 10.7182 14.4031 10.457 14.7275 10.457C15.052 10.457 15.3131 10.7182 15.3131 11.0427C15.3131 12.0081 14.7434 12.8786 13.8649 13.2743L8.53104 15.6485C8.26197 15.7672 7.96125 15.8305 7.66053 15.8305Z"
								fill="#941AF2"
							/>
						</svg>
						<p>{isSmartRevision ? 'Smart review is enabled' : 'Smart review is disabled'}</p>
						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9 4.56445V4.55557M9 12.4444V8.22222M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
								stroke="#951CF1"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
				</div>
				<div className="label-box-sidebox">
					<div className="iconbox-label-sidebox">
						<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M28 8V16.1359C28 16.6222 28 16.8653 27.8576 16.9636C27.7154 17.0618 27.5209 16.9532 27.1317 16.7357L25.2683 15.6943C25.1366 15.6208 25.0708 15.584 25 15.584C24.9292 15.584 24.8634 15.6208 24.7317 15.6943L22.8684 16.7357C22.4791 16.9532 22.2846 17.0618 22.1424 16.9636C22 16.8653 22 16.6222 22 16.1359V8"
								stroke="#EFBD8A"
								strokeWidth="2"
							/>
							<path
								d="M35 17.6564C35 13.1814 35 10.9439 33.6918 9.48951C33.5717 9.35574 33.4443 9.22841 33.3105 9.10808C31.856 7.8 29.6187 7.8 25.1436 7.8H24.5083C22.547 7.8 21.5664 7.8 20.6527 7.53953C20.1507 7.39642 19.6667 7.19601 19.2106 6.94223C18.3803 6.48024 17.6869 5.78683 16.3 4.4L15.3645 3.46458C14.8997 2.99976 14.6673 2.76734 14.4231 2.56487C13.3701 1.69197 12.0783 1.15689 10.7165 1.02955C10.4006 1 10.0719 1 9.41458 1C7.91427 1 7.16412 1 6.53926 1.1179C3.78853 1.63689 1.63689 3.78853 1.1179 6.53926C1 7.16412 1 7.91427 1 9.41458M34.9852 24.8C34.9242 29.0153 34.6116 31.405 33.0083 33.0083C31.0167 35 27.811 35 21.4 35H14.6C8.18889 35 4.98336 35 2.99167 33.0083C1 31.0167 1 27.811 1 21.4V16.3"
								stroke="url(#paint0_linear_1161_5989)"
								strokeWidth="2"
								strokeLinecap="round"
							/>
							<defs>
								<linearGradient id="paint0_linear_1161_5989" x1="1" y1="18" x2="35" y2="18" gradientUnits="userSpaceOnUse">
									<stop stopColor="#941AF2" />
									<stop offset="1" stopColor="#EFBD8A" />
								</linearGradient>
							</defs>
						</svg>
					</div>
					<div className="content-label-sidebox">
						<h6>{isEditing ? <>Edit Event</> : <>Add event</>}</h6>
						<p>{isEditing ? <>Edit</> : <>Add</>} an event with its media files and description.</p>
					</div>
				</div>
				<div className="main-box-background event-media-main">
					<Swiper
						key={projectEvent.length}
						ref={swiperRef}
						modules={[Navigation, Pagination, Scrollbar, A11y, EffectCoverflow]}
						spaceBetween={0}
						centeredSlides={true}
						slidesPerView={1}
						loop={true}
						simulateTouch={false}
						autoHeight
						navigation={{
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						}}
						scrollbar={{ draggable: true }}
						onSlideChange={() => {}}
					>
						<div className="swiper-button-prev">
							<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M0.963268 10.3349L0.968347 10.3401L0.724799 10.5863C0.225065 11.0914 0.225065 11.9137 0.724799 12.4189L10.4729 22.2727C10.9706 22.7758 11.7775 22.7758 12.2752 22.2727C12.7749 21.7676 12.7749 20.9453 12.2752 20.4401L3.67453 11.7461L3.4311 11.5001L3.67446 11.2539L12.2701 2.55996L12.2701 2.55989C12.7699 2.05473 12.7699 1.23245 12.2701 0.727293C11.7725 0.224236 10.9655 0.224236 10.4678 0.727293L0.963268 10.3349Z"
									fill="black"
									stroke="white"
									strokeWidth="0.7"
								/>
							</svg>
						</div>
						<div className="swiper-button-next">
							<svg width="13" height="23" viewBox="0 0 13 23" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M12.0367 10.3349L12.0317 10.3401L12.2752 10.5863C12.7749 11.0914 12.7749 11.9137 12.2752 12.4189L2.5271 22.2727C2.02944 22.7758 1.22246 22.7758 0.7248 22.2727C0.225066 21.7676 0.225067 20.9453 0.7248 20.4401L9.32547 11.7461L9.5689 11.5001L9.32554 11.2539L0.72995 2.55996L0.729877 2.55989C0.230144 2.05473 0.230144 1.23245 0.729877 0.727293C1.22754 0.224236 2.03452 0.224236 2.53218 0.727293L12.0367 10.3349Z"
									fill="black"
									stroke="white"
									strokeWidth="0.7"
								/>
							</svg>
						</div>
						{projectEvent &&
							Array.isArray(projectEvent) &&
							projectEvent.length > 0 &&
							projectEvent.map((_projectEvent, eventIndex) => {
								const safeEventEditIndex = eventEditIndex ?? 0;
								const label = isEditing
									? eventIndex === 0
										? safeEventEditIndex + 1
										: eventIndex <= safeEventEditIndex
											? eventIndex
											: eventIndex + 1
									: eventIndex + 1;
								return (
									<>
										<SwiperSlide key={`slide${eventIndex}`}>
											<div key={`slide-event-main${eventIndex}`} className="slide-event-main">
												<div className="event-label-index">Event {label}</div>

												{projectEvent.length > 1 && (
													<Button className="remove-event-with-media" onClick={() => handleRemoveEventWithMedia(eventIndex)} id={''} label={''}>
														<svg width="27" height="27" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M1.04297 3.78244H14.2604M10.956 3.78244L10.7325 3.12348C10.5158 2.4849 10.4074 2.16561 10.2065 1.92955C10.0291 1.7211 9.80122 1.55974 9.54382 1.46029C9.25229 1.34766 8.90979 1.34766 8.22464 1.34766H7.07869C6.39353 1.34766 6.05104 1.34766 5.75952 1.46029C5.50212 1.55974 5.27425 1.7211 5.09682 1.92955C4.89588 2.16561 4.78756 2.4849 4.57089 3.12348L4.34732 3.78244M12.6082 3.78244V12.0607C12.6082 13.4243 12.6082 14.1061 12.3381 14.627C12.1005 15.0851 11.7214 15.4575 11.2551 15.691C10.7249 15.9564 10.031 15.9564 8.64297 15.9564H6.66036C5.2724 15.9564 4.57842 15.9564 4.0483 15.691C3.58198 15.4575 3.20286 15.0851 2.96526 14.627C2.69514 14.1061 2.69514 13.4243 2.69514 12.0607V3.78244M9.30384 7.02882V12.71M5.99949 7.02882V12.71"
																stroke="#941AF2"
																stroke-width="1.5"
																stroke-linecap="round"
																stroke-linejoin="round"
															></path>
														</svg>
													</Button>
												)}
												{projectEvent.length > 1 && _projectEvent.eventMedia.every((media) => media.eventMediaUrl && media.thumbnailUrl) && (
													<Button
														className="remove-event-with-media"
														onClick={() => {
															handleRemoveEventWithMedia(eventIndex);
														}}
														id={''}
														label={''}
													>
														<svg width="27" height="27" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M1.04297 3.78244H14.2604M10.956 3.78244L10.7325 3.12348C10.5158 2.4849 10.4074 2.16561 10.2065 1.92955C10.0291 1.7211 9.80122 1.55974 9.54382 1.46029C9.25229 1.34766 8.90979 1.34766 8.22464 1.34766H7.07869C6.39353 1.34766 6.05104 1.34766 5.75952 1.46029C5.50212 1.55974 5.27425 1.7211 5.09682 1.92955C4.89588 2.16561 4.78756 2.4849 4.57089 3.12348L4.34732 3.78244M12.6082 3.78244V12.0607C12.6082 13.4243 12.6082 14.1061 12.3381 14.627C12.1005 15.0851 11.7214 15.4575 11.2551 15.691C10.7249 15.9564 10.031 15.9564 8.64297 15.9564H6.66036C5.2724 15.9564 4.57842 15.9564 4.0483 15.691C3.58198 15.4575 3.20286 15.0851 2.96526 14.627C2.69514 14.1061 2.69514 13.4243 2.69514 12.0607V3.78244M9.30384 7.02882V12.71M5.99949 7.02882V12.71"
																stroke="#941AF2"
																stroke-width="1.5"
																stroke-linecap="round"
																stroke-linejoin="round"
															></path>
														</svg>
													</Button>
												)}

												<div className="form-box">
													{/* Event Label */}
													<div className="input-field">
														<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M9.79418 0.176525C10.0756 0.0599835 10.3771 0 10.6817 0C10.9862 0 11.2877 0.0599835 11.5691 0.176525C11.8504 0.293067 12.1061 0.463885 12.3214 0.679222C12.5368 0.89456 12.7076 1.15021 12.8242 1.43156C12.9407 1.71292 13.0007 2.01447 13.0007 2.31901C13.0007 2.62354 12.9407 2.9251 12.8242 3.20646C12.7076 3.48781 12.5368 3.74345 12.3214 3.95879L11.9846 4.2956L8.70508 1.01603L9.04185 0.679222C9.2572 0.463885 9.51285 0.293067 9.79418 0.176525Z"
																fill="#C1C1C1"
															/>
															<path
																d="M7.78551 1.93457L1.07552 8.64457C0.995526 8.72452 0.937798 8.82404 0.908034 8.93317L0.0229184 12.1786C-0.0384562 12.4036 0.0254601 12.6443 0.190402 12.8092C0.355344 12.9742 0.596019 13.0381 0.821061 12.9767L4.06649 12.0916C4.17562 12.0618 4.2751 12.0041 4.35509 11.9241L11.065 5.21417L7.78551 1.93457Z"
																fill="#C1C1C1"
															/>
														</svg>
														<Input
															required={true}
															type="text"
															_key={'name'}
															onChange={(e) => handleTitleChange(e, eventIndex)}
															name={'EventTitle'}
															value={_projectEvent.title}
															style={{ borderColor: titleError[eventIndex]?.hasError && !_projectEvent.title ? 'red' : '' }}
															//style={{ borderColor: titleError && titleError.hasError && !_projectEvent.title ? 'red' : '' }}
															maxLength={20}
														/>
														<label>
															Event Title<span style={{ color: 'red' }}>*</span>
														</label>
													</div>
													{titleError[eventIndex]?.hasError && ( // Access the specific error for the current eventIndex
														<div className="error">
															<p>{titleError[eventIndex]?.error}</p> {/* Ensure to access the error message correctly */}
														</div>
													)}
												</div>
												<h5 className="media-description">{isEditing ? <>Edit</> : <>Add</>} Media with Description</h5>
												<div className="boxes-event-meida">
													{_projectEvent.eventMedia &&
														_projectEvent.eventMedia.map((media, mediaIndex) => {
															const _mediaId = media.mediaID;
															let mediaDisplayIndex;
															if (eventIndex === activeEventIndex) {
																if (isEditing && isNewMediaAdded) {
																	// For newly added media, assign the last index
																	if (mediaIndex === newlyAddedMediaIndex) {
																		if (mediaIndex === 0) {
																			mediaDisplayIndex = _projectEvent.eventMedia.length; // Last index for new media
																		}
																	} else {
																		mediaDisplayIndex = mediaIndex + 1; // Existing media keeps their original index
																		if (isNewMediaAdded) {
																			mediaDisplayIndex = mediaIndex;
																		}
																	}
																} else if (isEditing && isNewMediaAddByPlusButton) {
																	if (mediaIndex === 0) {
																		mediaDisplayIndex = _projectEvent.eventMedia.length; // Last index for new media
																	} else {
																		mediaDisplayIndex = mediaIndex;
																	}
																} else {
																	// Non-editing mode: Reverse order
																	mediaDisplayIndex = _projectEvent.eventMedia.length - mediaIndex;
																}
															} else {
																// Sort media such that the newest items are at the top for the current event
																const sortedMedia = [..._projectEvent.eventMedia].reverse();

																// find mediaDisplayIndex dynamically based on sorted media
																mediaDisplayIndex = sortedMedia.findIndex((sortedItem) => sortedItem === media) + 1;
																if (isEditing) {
																	mediaDisplayIndex = mediaIndex + 1;
																}
															}

															if (eventIndex === activeEventIndex) {
																if (isEditing && isBlankMediaDeleted) {
																	mediaDisplayIndex = mediaIndex + 1;
																}
															}

															if (!dataFromEvent) {
																if (media.uploadId === '' || typeof media.uploadId === 'undefined') {
																	// Assuming you want to update media.uploadId
																	if (uploadStatus !== undefined && uploadStatus.length !== 0) {
																		uploadStatus.forEach((data) => {
																			if (data.mediaID === media.mediaID) {
																				media.uploadId = data.uploadId || '';
																				media.isUploading = data.isUploading;
																				media.progress = data.progress;
																			}
																		});
																	}
																} else {
																	// Handle the case where media.uploadId is not empty (if needed)
																	media.uploadId = media.uploadId;
																}
															}

															let uploadStatusForMedia = uploadStatuses.find((status) => status.uploadId === media.uploadId);
															if (!dataFromEvent) {
																if (typeof uploadStatusForMedia === 'undefined') {
																	// Find the upload status for the current media using the uploadId
																	const filteredStatus =
																		uploadStatus !== undefined
																			? uploadStatus.filter((item) => item.mediaID === media.mediaID)[0]
																			: defaultUploadStatus;
																	if (filteredStatus != null && filteredStatus.progress !== 0) {
																		var progress = filteredStatus.progress;
																		var uploadId = filteredStatus.uploadId ?? '';
																		var mediaIDs = filteredStatus.mediaID ?? '';
																		var mediaID = Number(mediaIDs); // 123
																		if (uploadStatuses !== undefined) {
																			const uploadStatusesWithData = uploadStatuses.map((data) => {
																				if (data.uploadId === uploadId) {
																					data.progress = progress;
																				}
																				return data;
																			});
																			if (
																				uploadStatusesWithData.length <= 0 ||
																				uploadStatusesWithData.filter((x) => x.uploadId === uploadId).length <= 0
																			) {
																				uploadStatuses.push({ uploadId, progress, isUploading: true, mediaID });
																			}
																			uploadStatusForMedia = uploadStatuses.find((status) => mediaIDs === media.mediaID);
																			media.progress = uploadStatusForMedia?.progress ?? 0; // Use 0 or any other default value
																		}
																	}
																} else {
																	media.progress =
																		(uploadStatus?.filter((item) => item.mediaID === media.mediaID)[0]?.progress ?? 0) >
																		(uploadStatusForMedia?.progress ?? 0)
																			? (uploadStatus?.filter((item) => item.mediaID === media.mediaID)[0]?.progress ?? 0)
																			: (uploadStatusForMedia?.progress ?? 0);
																}
															} else {
																media.isUploading = uploadStatusForMedia?.isUploading ?? false;
																media.progress = uploadStatusForMedia?.progress ?? 0;
															}
															// Pre-computed condition for showing the loader
															const shouldShowLoader =
																(media?.progress === 0 || media?.progress < 0) &&
																!isNullOrEmpty(media?.thumbnailUrl) &&
																!media?.isUploading &&
																!(isEditing && media.mediaID && media.thumbnailUrl);

															let showDeleteThumbnailIcon;
															if (isEditing) {
																if (media.mediaID) {
																	// For recently added media during editing, show the icon after mediaId and progress are 100
																	if (mediaIndex === newlyAddedMediaIndex) {
																		if (!media.isUploading || media.progress === 100) {
																			showDeleteThumbnailIcon = true;
																		}
																	} else {
																		// For all other media with a mediaID (including uploaded media), show the icon if progress is 100
																		showDeleteThumbnailIcon = true;
																	}
																}
															} else {
																// For non-editing mode: show the icon only if progress is 100
																showDeleteThumbnailIcon = media.progress === 100;
															}

															const showSelectThumbnailModal =
																isActionTriggered &&
																!isNullOrEmpty(media?.eventMediaUrl) &&
																(newlyAddedMediaIndex === mediaIndex || mediaIndex === 0) &&
																(!media.thumbnailUrl || media.thumbnailUrl.trim() === '');

															return (
																<>
																	<div key={`single-box-event-media${media.uploadId}`} className="single-box-event-media">
																		{shouldShowLoader && isShouldShowLoader && <div className="loader-overlay-events">{<Loader />}</div>}
																		{activeLoader.eventIndex === eventIndex && activeLoader.mediaIndex === mediaIndex && (
																			<div className="loader-overlay-events">
																				<Loader />
																			</div>
																		)}
																		{/* Media Label */}
																		<div className="event-label-index">Media {mediaDisplayIndex}</div>
																		{showSelectThumbnailModal && !(!media.eventMediaUrl || media.eventMediaUrl.trim() === '') && (
																			<div className="Bg_MdScreen_sec block_MdScreen">
																				<button
																					onClick={() => {
																						handleImageClick(false);
																					}}
																					className="close-icon"
																				>
																					<svg
																						xmlns="http://www.w3.org/2000/svg"
																						width="20"
																						height="20"
																						fill="currentColor"
																						className="bi bi-x"
																						viewBox="0 0 16 16"
																					>
																						<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 6.793l2.646-2.647a.5.5 0 1 1 .708.708L8.707 7.5l2.647 2.646a.5.5 0 1 1-.708.708L8 8.207l-2.646 2.647a.5.5 0 1 1-.708-.708L7.293 7.5 4.646 4.854a.5.5 0 0 1 0-.708z" />
																					</svg>
																				</button>
																				<div className="Media_Scr_parent">
																					<div className="Bg_MdScreenPr">
																						<div className="Bg_MdSrn_inner" onClick={() => openModal(media.uploadId, projectId)}>
																							<ul className="Vdmedia_prnt">
																								<li className="vd_svg">
																									<svg xmlns="http://www.w3.org/2000/svg" width="40" height="25" viewBox="0 0 40 25" fill="none">
																										<path
																											d="M5.64103 0C4.14493 0 2.71012 0.557175 1.65222 1.54895C0.594321 2.54073 0 3.88587 0 5.28846V19.7115C0 21.1141 0.594321 22.4593 1.65222 23.451C2.71012 24.4428 4.14493 25 5.64103 25H23.0769C24.573 25 26.0078 24.4428 27.0657 23.451C28.1236 22.4593 28.718 21.1141 28.718 19.7115V16.6462L35.7313 22.5019C37.3826 23.8808 40 22.7808 40 20.7058V3.58654C40 1.50961 37.3826 0.411539 35.7313 1.79039L28.718 7.64615V5.28846C28.718 3.88587 28.1236 2.54073 27.0657 1.54895C26.0078 0.557175 24.573 0 23.0769 0H5.64103Z"
																											fill="#9747FF"
																										></path>
																									</svg>
																								</li>
																								<li className="vd_text">Select from video</li>
																							</ul>
																						</div>

																						{/* Conditionally render the modal */}
																						{isModalOpen && renderModal()}
																					</div>

																					<div className="Bg_MdScreenPr">
																						<div className="Bg_MdSrn_inner">
																							<ul className="Vdmedia_prnt">
																								<li className="vd_svg">
																									<svg xmlns="http://www.w3.org/2000/svg" width="38" height="32" viewBox="0 0 38 32" fill="none">
																										<path
																											fill-rule="evenodd"
																											clipRule="evenodd"
																											d="M18.9002 0.770948H19.0998C23.1405 0.770948 26.3062 0.770948 28.7773 1.04011C31.3043 1.31495 33.2993 1.89011 34.8655 3.15661C36.4318 4.42453 37.1405 6.03953 37.48 8.08661C37.8125 10.0855 37.8125 12.6483 37.8125 15.9194V16.044C37.8125 18.7484 37.8125 20.9613 37.6305 22.7633C37.4485 24.5766 37.0757 26.0882 36.2393 27.3462C35.8729 27.8996 35.415 28.3988 34.8655 28.8436C33.2993 30.1115 31.3043 30.6853 28.7755 30.9601C26.3063 31.2293 23.1405 31.2293 19.0998 31.2293H18.9002C14.8595 31.2293 11.6938 31.2293 9.22275 30.9601C6.69575 30.6853 4.70075 30.1101 3.1345 28.8436C1.74675 27.7202 1.02925 26.3219 0.6495 24.5851C0.27325 22.8809 0.205 20.7601 0.191 18.1279C0.188667 17.4574 0.1875 16.7481 0.1875 16.0001V15.9179C0.1875 12.6469 0.1875 10.0841 0.52 8.08378C0.8595 6.03811 1.57 4.42311 3.1345 3.1552C4.70075 1.88728 6.69575 1.31353 9.2245 1.0387C11.6938 0.769531 14.8595 0.769531 18.9002 0.769531M9.57275 3.14386C7.33625 3.38753 5.987 3.8522 4.99125 4.65828C3.99375 5.46578 3.4215 6.55661 3.1205 8.36853C2.816 10.2102 2.8125 12.6299 2.8125 15.9987V17.1944L4.56425 15.9534C5.33263 15.4089 6.32794 15.1212 7.34856 15.1485C8.36918 15.1759 9.3388 15.5163 10.061 16.1007L17.5685 22.1782C18.1511 22.6497 18.9205 22.9398 19.7412 22.9973C20.562 23.0548 21.3815 22.8761 22.0555 22.4927L22.577 22.1952C23.5494 21.642 24.725 21.3724 25.9113 21.4303C27.0977 21.4882 28.2247 21.8702 29.108 22.5139L34.0605 26.1222C34.561 25.275 34.8567 24.1615 35.0142 22.5904C35.1857 20.8834 35.1875 18.7555 35.1875 15.9987C35.1875 12.6299 35.184 10.2102 34.8795 8.36853C34.5785 6.55661 34.0045 5.46436 33.0087 4.65686C32.0112 3.85078 30.6637 3.38753 28.4255 3.14386C26.1505 2.89736 23.1615 2.89453 19 2.89453C14.8385 2.89453 11.8477 2.89736 9.57275 3.14386Z"
																											fill="#9747FF"
																										></path>
																									</svg>
																								</li>
																								<li className="vd_text">Upload from Gallery</li>
																							</ul>
																							<div className="fileUpload_event">
																								<FileUpload
																									//ref={fileUploadRef}
																									allowedFileTypes={['image/jpeg', 'image/png']}
																									fileUploadingType="eventMediaThumbnail"
																									id="fileInput"
																									disabled={!media.eventMediaUrl}
																									onFileSelect={(file) =>
																										handleFileSelect(file, eventIndex, mediaIndex, 'eventMediaThumbnail', media.uploadId)
																									}
																								/>
																							</div>
																						</div>
																					</div>
																					{/* FileUpload component with ref */}
																				</div>
																			</div>
																		)}
																		<div className="media-thumnail-event">
																			<div className="single-mt-event">
																				{media.eventMediaUrl ? (
																					<>
																						{media.eventMedia ? (
																							<Video src={media.eventMediaUrl} className="main-data-image" controls={true} />
																						) : (
																							<>
																								<Image src={media.thumbnailUrl ? media.thumbnailUrl : VideoBg} className="main-data-image" />
																								<svg width="51" height="54" viewBox="0 0 51 54" fill="none" xmlns="http://www.w3.org/2000/svg">
																									<g filter="url(#filter0_d_839_9801)">
																										<path
																											d="M35.5 24.4019C37.5 25.5566 37.5 28.4434 35.5 29.5981L18.9502 39.1531C16.9502 40.3078 14.4502 38.8644 14.4502 36.555L14.4502 17.445C14.4502 15.1356 16.9502 13.6922 18.9502 14.8469L35.5 24.4019Z"
																											fill="white"
																										></path>
																									</g>
																									<defs>
																										<filter
																											id="filter0_d_839_9801"
																											x="0.449219"
																											y="0.44043"
																											width="50.5508"
																											height="53.1191"
																											filterUnits="userSpaceOnUse"
																											colorInterpolationFilters="sRGB"
																										>
																											<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
																											<feColorMatrix
																												in="SourceAlpha"
																												type="matrix"
																												values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
																												result="hardAlpha"
																											></feColorMatrix>
																											<feOffset></feOffset>
																											<feGaussianBlur stdDeviation="7"></feGaussianBlur>
																											<feComposite in2="hardAlpha" operator="out"></feComposite>
																											<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
																											<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_839_9801"></feBlend>
																											<feBlend
																												mode="normal"
																												in="SourceGraphic"
																												in2="effect1_dropShadow_839_9801"
																												result="shape"
																											></feBlend>
																										</filter>
																									</defs>
																								</svg>
																							</>
																						)}
																						{media?.isUploading && media.progress > 0 && media.progress < 100 && (
																							<div className="progress-bar-global">
																								<ProgressBar progress={media.progress} label={`${media.progress}`} />
																							</div>
																						)}{' '}
																					</>
																				) : (
																					<>
																						<div className="image-upload-div">
																							<Image src={dummyEventVideo} alt="" />
																						</div>
																						<p>
																							Add Video<span style={{ color: 'red' }}>*</span>
																						</p>
																						<FileUpload
																							key={inputKey} // This key changes when inputKey changes, forcing a re-render
																							fileUploadingType="eventMediaVideo"
																							allowedFileTypes={['video/mp4']}
																							id="fileInput"
																							disabled={
																								!_projectEvent.title ||
																								projectEvent.some((event, index) => index !== eventIndex && event.title === _projectEvent.title)
																							}
																							//disabled={!_projectEvent.title || titleError[eventIndex]?.hasError}
																							//disabled={!_projectEvent.title || titleError.hasError}
																							onFileSelect={(file) => handleFileSelect(file, eventIndex, mediaIndex, 'eventMedia', media.uploadId)}
																						/>
																					</>
																				)}
																			</div>
																			<div
																				className="single-mt-event"
																				onClick={() => {
																					handleImageClick(true);
																				}}
																			>
																				{media.thumbnailUrl ? (
																					<>
																						<Image src={media.thumbnailUrl} alt="" className="main-data-image" />
																						{/* {showDeleteThumbnailIcon && (
																							<Button
																								className="remove-media-event"
																								onClick={() => handleRemoveThumbnail(eventIndex, mediaIndex)}
																								id=""
																								label=""
																							>
																								<svg width="27" height="27" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
																									<path
																										d="M1.04297 3.78244H14.2604M10.956 3.78244L10.7325 3.12348C10.5158 2.4849 10.4074 2.16561 10.2065 1.92955C10.0291 1.7211 9.80122 1.55974 9.54382 1.46029C9.25229 1.34766 8.90979 1.34766 8.22464 1.34766H7.07869C6.39353 1.34766 6.05104 1.34766 5.75952 1.46029C5.50212 1.55974 5.27425 1.7211 5.09682 1.92955C4.89588 2.16561 4.78756 2.4849 4.57089 3.12348L4.34732 3.78244M12.6082 3.78244V12.0607C12.6082 13.4243 12.6082 14.1061 12.3381 14.627C12.1005 15.0851 11.7214 15.4575 11.2551 15.691C10.7249 15.9564 10.031 15.9564 8.64297 15.9564H6.66036C5.2724 15.9564 4.57842 15.9564 4.0483 15.691C3.58198 15.4575 3.20286 15.0851 2.96526 14.627C2.69514 14.1061 2.69514 13.4243 2.69514 12.0607V3.78244M9.30384 7.02882V12.71M5.99949 7.02882V12.71"
																										stroke="#941AF2"
																										strokeWidth="1.5"
																										strokeLinecap="round"
																										strokeLinejoin="round"
																									></path>
																								</svg>
																							</Button>
																						)} */}
																					</>
																				) : (
																					<>
																						<div className="image-upload-div">
																							<Image src={dummyEventMedia} alt="" />
																						</div>
																						<p>
																							Add Thumbnail<span style={{ color: 'red' }}>*</span>
																						</p>

																						{/* <FileUpload
																							allowedFileTypes={['image/jpeg', 'image/png']}
																							fileUploadingType="eventMediaThumbnail"
																							id="fileInput"
																							disabled={!media.eventMediaUrl}
																							onFileSelect={(file) => handleFileSelect(file, eventIndex, mediaIndex, 'eventMediaThumbnail')}
																						/> */}
																					</>
																				)}
																			</div>
																		</div>
																		<div className="description-event">
																			<div className="form-box">
																				<div className="input-field textarea-field">
																					<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path
																							fillRule="evenodd"
																							clipRule="evenodd"
																							d="M0 2.05263C0 1.50824 0.221249 0.986145 0.615076 0.601202C1.0089 0.216259 1.54305 0 2.1 0H11.9C12.457 0 12.9911 0.216259 13.3849 0.601202C13.7788 0.986145 14 1.50824 14 2.05263V8.89474C14 9.43913 13.7788 9.96122 13.3849 10.3462C12.9911 10.7311 12.457 10.9474 11.9 10.9474H3.9669C3.81544 10.9474 3.66807 10.9954 3.5469 11.0842L1.12 12.8632C1.016 12.9394 0.892337 12.9858 0.762864 12.9972C0.633391 13.0086 0.503224 12.9846 0.38695 12.9278C0.270676 12.8709 0.172889 12.7836 0.104544 12.6755C0.0362003 12.5674 0 12.4429 0 12.3158V2.05263ZM3.5 2.05263C3.31435 2.05263 3.1363 2.12472 3.00503 2.25303C2.87375 2.38135 2.8 2.55538 2.8 2.73684C2.8 2.91831 2.87375 3.09234 3.00503 3.22065C3.1363 3.34897 3.31435 3.42105 3.5 3.42105H10.5C10.6857 3.42105 10.8637 3.34897 10.995 3.22065C11.1263 3.09234 11.2 2.91831 11.2 2.73684C11.2 2.55538 11.1263 2.38135 10.995 2.25303C10.8637 2.12472 10.6857 2.05263 10.5 2.05263H3.5ZM3.5 4.78947C3.31435 4.78947 3.1363 4.86156 3.00503 4.98987C2.87375 5.11819 2.8 5.29222 2.8 5.47368C2.8 5.65515 2.87375 5.82918 3.00503 5.95749C3.1363 6.08581 3.31435 6.15789 3.5 6.15789H10.5C10.6857 6.15789 10.8637 6.08581 10.995 5.95749C11.1263 5.82918 11.2 5.65515 11.2 5.47368C11.2 5.29222 11.1263 5.11819 10.995 4.98987C10.8637 4.86156 10.6857 4.78947 10.5 4.78947H3.5ZM3.5 7.52632C3.31435 7.52632 3.1363 7.5984 3.00503 7.72672C2.87375 7.85503 2.8 8.02906 2.8 8.21053C2.8 8.39199 2.87375 8.56602 3.00503 8.69434C3.1363 8.82265 3.31435 8.89474 3.5 8.89474H6.3C6.48565 8.89474 6.6637 8.82265 6.79497 8.69434C6.92625 8.56602 7 8.39199 7 8.21053C7 8.02906 6.92625 7.85503 6.79497 7.72672C6.6637 7.5984 6.48565 7.52632 6.3 7.52632H3.5Z"
																							fill="#C1C1C1"
																						/>
																					</svg>
																					<Textarea
																						onFocus={() => setIsDescriptionFocused(true)}
																						onBlur={() => setIsDescriptionFocused(false)}
																						onChange={(e) => {
																							const currentEventName = projectEvent[eventIndex]?.title || ''; // Get the current event name
																							handleDescriptionChange(e, eventIndex, mediaIndex, _mediaId, currentEventName, handleEventMediaInfo); // Pass the event name
																						}}
																						name={'description'}
																						id="description"
																						value={media.description}
																						style={{
																							borderColor:
																								(descriptionError && !media.description) || (isDescriptionFocused && !media.description) ? 'red' : '',
																						}}
																						maxLength={200}
																						disabled={
																							!media.isUploading &&
																							(media.progress <= 1 ? true : false) &&
																							!!media.mediaID &&
																							!(isEditing && !!media.description) &&
																							!(isEditing && !!media.mediaID && !!media.thumbnailUrl)
																						}
																					></Textarea>
																					<label>
																						Media description<span style={{ color: 'red' }}>*</span>
																					</label>
																				</div>
																				<div className="cstm_ai_btn">
																					<Button
																						onClick={() => handleOpenPreview(eventIndex, media.mediaID)}
																						className="open-ai-button offset-7"
																						label={
																							<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="34" height="34">
																								<path
																									d="M0 0 C-0.35298081 2.9008605 -0.88728296 3.89516676 -3.06640625 5.921875 C-3.89011719 6.52515625 -4.71382812 7.1284375 -5.5625 7.75 C-6.38878906 8.36359375 -7.21507812 8.9771875 -8.06640625 9.609375 C-8.70449219 10.06828125 -9.34257812 10.5271875 -10 11 C-8.7625 11.37125 -7.525 11.7425 -6.25 12.125 C-1.89215512 13.76305517 -0.04932264 15.73057784 2 20 C2.5625 22.625 2.5625 22.625 2 25 C-2.37274617 29.64604281 -8.22371549 31.85127047 -14.5 32.1875 C-15.325 32.125625 -16.15 32.06375 -17 32 C-15.21056082 26.63168245 -9.49329159 24.15046882 -5 21 C-9.29 19.68 -13.58 18.36 -18 17 C-18 15.68 -18 14.36 -18 13 C-18.66 13 -19.32 13 -20 13 C-20.3125 10.75 -20.3125 10.75 -20 8 C-15.15690604 2.67259664 -7.12268652 -0.35613433 0 0 Z M-11 5 C-13.31 6.65 -15.62 8.3 -18 10 C-17.01 10.33 -16.02 10.66 -15 11 C-13.32419948 10.02792965 -13.32419948 10.02792965 -11.8125 8.5625 C-10.884375 7.716875 -9.95625 6.87125 -9 6 C-9.66 5.67 -10.32 5.34 -11 5 Z M-15 14 C-14.67 14.66 -14.34 15.32 -14 16 C-11.64400765 16.46751788 -11.64400765 16.46751788 -8.9375 16.625 C-7.55884766 16.73714844 -7.55884766 16.73714844 -6.15234375 16.8515625 C-5.44207031 16.90054687 -4.73179688 16.94953125 -4 17 C-6.39763185 14.60236815 -7.33273498 14.65719414 -10.625 14.375 C-11.85089844 14.26285156 -11.85089844 14.26285156 -13.1015625 14.1484375 C-13.72804688 14.09945313 -14.35453125 14.05046875 -15 14 Z M-2 22 C-2.99 23.485 -2.99 23.485 -4 25 C-2.68 24.34 -1.36 23.68 0 23 C-0.66 22.67 -1.32 22.34 -2 22 Z "
																									fill="#B552D1"
																									transform="translate(25,0)"
																								/>
																								<path
																									d="M0 0 C3.0094842 2.0063228 4.78396128 3.55455695 6 7 C6.4375 9.5 6.4375 9.5 6 12 C1.62725383 16.64604281 -4.22371549 18.85127047 -10.5 19.1875 C-11.325 19.125625 -12.15 19.06375 -13 19 C-11.68774191 15.06322574 -9.8038606 14.1728218 -6.4375 11.8125 C-5.42558594 11.09707031 -4.41367187 10.38164062 -3.37109375 9.64453125 C-2.19740234 8.83048828 -2.19740234 8.83048828 -1 8 C-3.64 7.34 -6.28 6.68 -9 6 C-8.67 5.01 -8.34 4.02 -8 3 C-7.05125 3.061875 -6.1025 3.12375 -5.125 3.1875 C-3.578125 3.0946875 -3.578125 3.0946875 -2 3 C-1.34 2.01 -0.68 1.02 0 0 Z M2 9 C1.01 10.485 1.01 10.485 0 12 C1.32 11.34 2.64 10.68 4 10 C3.34 9.67 2.68 9.34 2 9 Z "
																									fill="#D48AAA"
																									transform="translate(21,13)"
																								/>
																								<path
																									d="M0 0 C0.66 0.33 1.32 0.66 2 1 C-1.49359465 4.59634744 -5.11098131 7.83451967 -9 11 C-9.3125 8.75 -9.3125 8.75 -9 6 C-6.33288317 3.40696975 -3.49367701 1.32518783 0 0 Z "
																									fill="#AC3CDE"
																									transform="translate(14,2)"
																								/>
																								<path
																									d="M0 0 C2.64 0.33 5.28 0.66 8 1 C7.01 2.485 7.01 2.485 6 4 C3.69 3.67 1.38 3.34 -1 3 C-0.67 2.01 -0.34 1.02 0 0 Z "
																									fill="#C46FBD"
																									transform="translate(13,16)"
																								/>
																							</svg>
																						}
																						type="button"
																						id=""
																						disabled={!media.description?.trim() || isAiButtonDisabled}
																					>
																						<span className="assistant_tltp">Your AI Assistant</span>
																					</Button>
																				</div>
																			</div>
																			<br />
																			<p className="maximum-limit-text">Maximum word limit is 200 characters.</p>
																		</div>
																		{showAiResponse && (
																			<div className="ai-modal-container">
																				{activeLoader.eventIndex === selectedEventIndex && activeLoader.mediaIndex === mediaIndex && (
																					<div className="loader-overlay-events">
																						<Loader />
																					</div>
																				)}
																				{aiText && (
																					<AIModal
																						showAIResponse={showAiResponse}
																						handleClosePreview={handleClosePreview}
																						AiGeneratedDescription={aiText}
																						userTypedDescription={getUserTypedDescription(selectedEventIndex, selectedMediaId)}
																						handleAccept={() => handleAcceptAiDescription(selectedEventIndex!, selectedMediaId!)}
																						handleReject={handleRejectAiDescription}
																					/>
																				)}
																			</div>
																		)}
																		{uploadStatuses.find((status) => status.mediaID === media.mediaID)?.isUploading ? null : media.thumbnailUrl &&
																		  media.eventMediaUrl ? (
																			<Button
																				className="remove-media-event cstm_inner_btn"
																				onClick={() => handleRemoveMediaEvent(eventIndex, mediaIndex)}
																				id={''}
																				label={''}
																			>
																				<svg width="27" height="27" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path
																						d="M1.04297 3.78244H14.2604M10.956 3.78244L10.7325 3.12348C10.5158 2.4849 10.4074 2.16561 10.2065 1.92955C10.0291 1.7211 9.80122 1.55974 9.54382 1.46029C9.25229 1.34766 8.90979 1.34766 8.22464 1.34766H7.07869C6.39353 1.34766 6.05104 1.34766 5.75952 1.46029C5.50212 1.55974 5.27425 1.7211 5.09682 1.92955C4.89588 2.16561 4.78756 2.4849 4.57089 3.12348L4.34732 3.78244M12.6082 3.78244V12.0607C12.6082 13.4243 12.6082 14.1061 12.3381 14.627C12.1005 15.0851 11.7214 15.4575 11.2551 15.691C10.7249 15.9564 10.031 15.9564 8.64297 15.9564H6.66036C5.2724 15.9564 4.57842 15.9564 4.0483 15.691C3.58198 15.4575 3.20286 15.0851 2.96526 14.627C2.69514 14.1061 2.69514 13.4243 2.69514 12.0607V3.78244M9.30384 7.02882V12.71M5.99949 7.02882V12.71"
																						stroke="#941AF2"
																						stroke-width="1.5"
																						stroke-linecap="round"
																						stroke-linejoin="round"
																					></path>
																				</svg>
																			</Button>
																		) : media.eventMediaUrl && !media.thumbnailUrl ? ( // New condition for only media URL
																			<Button
																				className="remove-media-event cstm_inner_btn"
																				onClick={() => handleDeleteMediaEvent(eventIndex, mediaIndex)}
																				id={''}
																				label={''}
																			>
																				<svg width="27" height="27" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path
																						d="M1.04297 3.78244H14.2604M10.956 3.78244L10.7325 3.12348C10.5158 2.4849 10.4074 2.16561 10.2065 1.92955C10.0291 1.7211 9.80122 1.55974 9.54382 1.46029C9.25229 1.34766 8.90979 1.34766 8.22464 1.34766H7.07869C6.39353 1.34766 6.05104 1.34766 5.75952 1.46029C5.50212 1.55974 5.27425 1.7211 5.09682 1.92955C4.89588 2.16561 4.78756 2.4849 4.57089 3.12348L4.34732 3.78244M12.6082 3.78244V12.0607C12.6082 13.4243 12.6082 14.1061 12.3381 14.627C12.1005 15.0851 11.7214 15.4575 11.2551 15.691C10.7249 15.9564 10.031 15.9564 8.64297 15.9564H6.66036C5.2724 15.9564 4.57842 15.9564 4.0483 15.691C3.58198 15.4575 3.20286 15.0851 2.96526 14.627C2.69514 14.1061 2.69514 13.4243 2.69514 12.0607V3.78244M9.30384 7.02882V12.71M5.99949 7.02882V12.71"
																						stroke="#941AF2"
																						stroke-width="1.5"
																						stroke-linecap="round"
																						stroke-linejoin="round"
																					></path>
																				</svg>
																			</Button>
																		) : projectEvent[eventIndex].eventMedia.length > 1 ? (
																			<Button
																				//disabled={projectEvent[eventIndex].eventMedia.length <= 1}
																				className="remove-media-event"
																				onClick={() => handleDeleteMediaEvent(eventIndex, mediaIndex)}
																				id={''}
																				label={''}
																			>
																				<svg width="27" height="27" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path
																						d="M1.04297 3.78244H14.2604M10.956 3.78244L10.7325 3.12348C10.5158 2.4849 10.4074 2.16561 10.2065 1.92955C10.0291 1.7211 9.80122 1.55974 9.54382 1.46029C9.25229 1.34766 8.90979 1.34766 8.22464 1.34766H7.07869C6.39353 1.34766 6.05104 1.34766 5.75952 1.46029C5.50212 1.55974 5.27425 1.7211 5.09682 1.92955C4.89588 2.16561 4.78756 2.4849 4.57089 3.12348L4.34732 3.78244M12.6082 3.78244V12.0607C12.6082 13.4243 12.6082 14.1061 12.3381 14.627C12.1005 15.0851 11.7214 15.4575 11.2551 15.691C10.7249 15.9564 10.031 15.9564 8.64297 15.9564H6.66036C5.2724 15.9564 4.57842 15.9564 4.0483 15.691C3.58198 15.4575 3.20286 15.0851 2.96526 14.627C2.69514 14.1061 2.69514 13.4243 2.69514 12.0607V3.78244M9.30384 7.02882V12.71M5.99949 7.02882V12.71"
																						stroke="#941AF2"
																						stroke-width="1.5"
																						stroke-linecap="round"
																						stroke-linejoin="round"
																					></path>
																				</svg>
																			</Button>
																		) : null}
																		{/* {media.eventMediaUrl || media.thumbnailUrl || media.thumbnail ?
																			(
																				<Button
																					className="remove-media-event"
																					onClick={() => handleRemoveMediaEvent(eventIndex, mediaIndex)}
																					id={''}
																					label={''}
																				// disabled={projectEvent[eventIndex].eventMedia.length <= 1}
																				>
																					<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<circle cx="13.5" cy="13.5" r="13.5" fill="#EDEDED" />
																						<path
																							d="M18.637 8.35991C18.4932 8.21696 18.2987 8.13672 18.0959 8.13672C17.8931 8.13672 17.6986 8.21696 17.5548 8.35991L13.4868 12.4279L9.42655 8.35991C9.27972 8.23417 9.09085 8.16846 8.89768 8.17593C8.70451 8.18339 8.52127 8.26347 8.38457 8.40016C8.24788 8.53686 8.1678 8.7201 8.16034 8.91327C8.15287 9.10644 8.21858 9.29531 8.34432 9.44214L12.4046 13.5101L8.33665 17.5704C8.2563 17.6392 8.19104 17.7239 8.14497 17.8191C8.0989 17.9143 8.07301 18.018 8.06893 18.1237C8.06485 18.2294 8.08266 18.3349 8.12125 18.4333C8.15984 18.5318 8.21837 18.6213 8.29317 18.6961C8.36797 18.7709 8.45742 18.8294 8.55592 18.868C8.65441 18.9066 8.75982 18.9244 8.86552 18.9203C8.97123 18.9162 9.07495 18.8904 9.17017 18.8443C9.2654 18.7982 9.35007 18.733 9.41888 18.6526L13.4868 14.5923L17.5471 18.6526C17.694 18.7784 17.8828 18.8441 18.076 18.8366C18.2692 18.8291 18.4524 18.7491 18.5891 18.6124C18.7258 18.4757 18.8059 18.2924 18.8133 18.0993C18.8208 17.9061 18.7551 17.7172 18.6293 17.5704L14.5691 13.5101L18.6293 9.44982C18.7018 9.37897 18.7595 9.29449 18.7991 9.20123C18.8387 9.10797 18.8595 9.00779 18.8602 8.90646C18.8609 8.80514 18.8416 8.70468 18.8033 8.61087C18.765 8.51706 18.7085 8.43177 18.637 8.35991Z"
																							fill="#797979"
																						/>
																					</svg>
																				</Button>
																			) : (
																				<Button disabled={projectEvent[eventIndex].eventMedia.length <= 1} className="remove-media-event" onClick={() => handleDeleteMediaEvent(eventIndex, mediaIndex)} id={''} label={''}>
																					<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<circle cx="13.5" cy="13.5" r="13.5" fill="#EDEDED" />
																						<path
																							d="M18.637 8.35991C18.4932 8.21696 18.2987 8.13672 18.0959 8.13672C17.8931 8.13672 17.6986 8.21696 17.5548 8.35991L13.4868 12.4279L9.42655 8.35991C9.27972 8.23417 9.09085 8.16846 8.89768 8.17593C8.70451 8.18339 8.52127 8.26347 8.38457 8.40016C8.24788 8.53686 8.1678 8.7201 8.16034 8.91327C8.15287 9.10644 8.21858 9.29531 8.34432 9.44214L12.4046 13.5101L8.33665 17.5704C8.2563 17.6392 8.19104 17.7239 8.14497 17.8191C8.0989 17.9143 8.07301 18.018 8.06893 18.1237C8.06485 18.2294 8.08266 18.3349 8.12125 18.4333C8.15984 18.5318 8.21837 18.6213 8.29317 18.6961C8.36797 18.7709 8.45742 18.8294 8.55592 18.868C8.65441 18.9066 8.75982 18.9244 8.86552 18.9203C8.97123 18.9162 9.07495 18.8904 9.17017 18.8443C9.2654 18.7982 9.35007 18.733 9.41888 18.6526L13.4868 14.5923L17.5471 18.6526C17.694 18.7784 17.8828 18.8441 18.076 18.8366C18.2692 18.8291 18.4524 18.7491 18.5891 18.6124C18.7258 18.4757 18.8059 18.2924 18.8133 18.0993C18.8208 17.9061 18.7551 17.7172 18.6293 17.5704L14.5691 13.5101L18.6293 9.44982C18.7018 9.37897 18.7595 9.29449 18.7991 9.20123C18.8387 9.10797 18.8595 9.00779 18.8602 8.90646C18.8609 8.80514 18.8416 8.70468 18.8033 8.61087C18.765 8.51706 18.7085 8.43177 18.637 8.35991Z"
																							fill="#797979"
																						/>
																					</svg>
																				</Button>
																			)} */}
																		{mediaIndex === 0 && (
																			<Button
																				disabled={
																					!_projectEvent.eventMedia.every(
																						(_media) =>
																							(_media.eventMedia || _media.eventMediaUrl) &&
																							(_media.thumbnail || _media.thumbnailUrl) &&
																							_media.description,
																					)
																				}
																				className="add-media-event"
																				onClick={() => handleAddMoreMediaEvent(eventIndex)}
																				id={''}
																				label={''}
																			>
																				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path
																						d="M15.3583 8.08998C15.3577 7.88721 15.2769 7.69291 15.1335 7.54953C14.9901 7.40615 14.7958 7.32533 14.5931 7.32473L8.84011 7.32473L8.84554 1.5772C8.83062 1.38446 8.74353 1.20444 8.60166 1.07312C8.45979 0.941808 8.2736 0.868862 8.08028 0.868862C7.88697 0.868862 7.70077 0.941808 7.5589 1.07312C7.41703 1.20444 7.32994 1.38446 7.31503 1.5772L7.3096 7.32473L1.56207 7.3193C1.4566 7.31114 1.35058 7.32487 1.25067 7.35963C1.15076 7.39439 1.05912 7.44942 0.981485 7.52128C0.903853 7.59314 0.841912 7.68027 0.799552 7.7772C0.757191 7.87413 0.735324 7.97877 0.735324 8.08455C0.735324 8.19034 0.757192 8.29498 0.799553 8.39191C0.841913 8.48884 0.903853 8.57597 0.981485 8.64783C1.05912 8.71969 1.15076 8.77472 1.25067 8.80948C1.35058 8.84424 1.4566 8.85797 1.56207 8.84981L7.3096 8.85523L7.3096 14.5973C7.32452 14.7901 7.41161 14.9701 7.55348 15.1014C7.69534 15.2327 7.88154 15.3057 8.07486 15.3057C8.26817 15.3057 8.45437 15.2327 8.59623 15.1014C8.7381 14.9701 8.8252 14.7901 8.84011 14.5973L8.84011 8.85523L14.5822 8.85523C14.6835 8.85637 14.7841 8.83742 14.878 8.7995C14.972 8.76158 15.0575 8.70543 15.1297 8.63428C15.2018 8.56314 15.2592 8.47841 15.2984 8.38499C15.3377 8.29157 15.358 8.19131 15.3583 8.08998Z"
																						fill="#EFBD8A"
																					/>
																				</svg>
																			</Button>
																		)}
																	</div>
																</>
															);
														})}
												</div>
											</div>
										</SwiperSlide>
									</>
								);
							})}
					</Swiper>
				</div>
				<div className="add-more-media-btn">
					<Button onClick={AddMore} id={''} label={'Add more Event'}>
						<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="25" cy="25" r="25" fill="#941AF2" />
							<path
								d="M36.8691 25.6313C36.8681 25.3022 36.7369 24.9869 36.5043 24.7543C36.2716 24.5216 35.9563 24.3904 35.6273 24.3895L26.2916 24.3895L26.3004 15.0626C26.2762 14.7499 26.1349 14.4577 25.9047 14.2446C25.6745 14.0316 25.3723 13.9132 25.0586 13.9132C24.7449 13.9132 24.4427 14.0316 24.2125 14.2446C23.9823 14.4577 23.841 14.7499 23.8168 15.0626L23.808 24.3895L14.4811 24.3807C14.31 24.3674 14.1379 24.3897 13.9758 24.4461C13.8137 24.5025 13.665 24.5918 13.539 24.7084C13.413 24.825 13.3125 24.9664 13.2438 25.1237C13.175 25.281 13.1395 25.4508 13.1395 25.6225C13.1395 25.7941 13.175 25.9639 13.2438 26.1212C13.3125 26.2785 13.413 26.4199 13.539 26.5365C13.665 26.6531 13.8137 26.7425 13.9758 26.7989C14.1379 26.8553 14.31 26.8775 14.4811 26.8643L23.808 26.8731L23.808 36.1911C23.8322 36.5039 23.9735 36.796 24.2037 37.0091C24.4339 37.2222 24.7361 37.3406 25.0498 37.3406C25.3635 37.3406 25.6656 37.2222 25.8959 37.0091C26.1261 36.796 26.2674 36.5039 26.2916 36.1911L26.2916 26.8731L35.6096 26.8731C35.7741 26.8749 35.9372 26.8442 36.0897 26.7827C36.2422 26.7211 36.381 26.63 36.498 26.5146C36.6151 26.3991 36.7082 26.2616 36.7719 26.11C36.8356 25.9584 36.8686 25.7957 36.8691 25.6313Z"
								fill="white"
							/>
						</svg>
					</Button>
				</div>
			</div>
			{isEditing && (
				<div className="button-after-login-screens">
					<div className="login-signup-btn d-flex two_btn_sec">
						<hr />
						<Button
							disabled={
								projectEvent.some(
									(event) => !event.title?.trim() || projectEvent.filter((e) => e.title?.toLowerCase() === event.title?.toLowerCase()).length > 1,
								) || descriptionError
								//  ||
								// projectEvent.every((event) => !event.isModified)
							}
							//disabled={proje.some((error) => error?.hasError) || descriptionError}
							className={'cmn-btn'}
							label={'Save Edits'}
							onClick={handleEditEvent}
							id={'editButton'}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default CreateEvent;

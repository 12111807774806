import { ImageProps } from '../../types/type/ImageProps';
import image_not_found from '../../assets/images/Image_not_found.png';
/**
 * A reusable image component that renders an HTML `<img>` element.
 *
 * This component accepts various props to customize the image, including the source (`src`),
 * alternative text (`alt`), dimensions (`width` and `height`), additional CSS classes (`className`),
 * and inline styles (`style`).
 *
 * @param {string} src - The source URL of the image.
 * @param {string} alt - The alternative text for the image, used for accessibility.
 * @param {number | string} [width] - The width of the image, either as a number (pixels) or a string (percentage, etc.).
 * @param {number | string} [height] - The height of the image, either as a number (pixels) or a string (percentage, etc.).
 * @param {string} [className] - Additional CSS classes to style the image.
 * @param {React.CSSProperties} [style] - Inline styles applied to the image element.
 *
 * @returns {JSX.Element} An HTML image element with the specified properties.
 */
const Image: React.FC<ImageProps> = ({ id, src, alt, width, height, className, style, onClick }) => {
	return (
		<img id={id} src={src ? src : image_not_found} alt={alt} width={width} height={height} className={className} style={style} onClick={onClick} />
	);
};

export default Image;

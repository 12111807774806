import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SideBar from '../../components/sidebar/Index';
import TopBar from '../../components/topbar/Index';
import AllProjects from './AllProjects';
import Support from '../../components/support/Index';
import ProjectDetails from './projectDetails/Index';
import SideBox from '../../components/sidebox/Index';
import Transaction from '../../components/transaction/Index';
import { Project } from '../../types/models/ProjectModel';
import ApiService from '../../helpers/api/Index';
import { showToast } from '../../components/toaster/Index';
import { SidebarMenuEnum } from '../../types/enums/SideBarEnums';
import './dashboard.css';
import { ProjectEdit } from '../../types/type/ProjectEdit';
import { App_Urls } from '../../routes/AppUrls';
import { confirmAlert } from 'react-confirm-alert';
import Button from '../../../src/components/button/Index';
import ModalComponent from '../../../src/components/Modal/Index';
import { Get_Claims_From_Token } from '../../../src/helpers/Utils';
import Pricing from '../../components/pricing/Index';

interface DashBoardProps {
	name: string | undefined;
}

const DashBoard: React.FC<DashBoardProps> = ({ name }) => {
	const location = useLocation();
	const [projects, setProjects] = useState<Project[]>([]);
	const [activeTab, setActiveTab] = useState<SidebarMenuEnum>(SidebarMenuEnum.ProjectDetails);
	const [isViewAllProjects, setViewAllProjects] = useState(true);
	const [currentProjectId, setCurrentProjectId] = useState(0);
	const [isSideBoxOpen, setIsSideBoxOpen] = useState((location.state?.openSideBox !== null && location.state?.openSideBox === true) || false);
	const [editProject, setEditProject] = useState<ProjectEdit>({ isEditing: false, projectType: '', projectData: null });
	const [showSideBox, setShowSideBox] = useState({ isOpen: false, type: '' });
	const [isUserProfile, setIsUserPofile] = useState<boolean>(false);
	const [isProjectRefresh, setIsProjectRefresh] = useState(false);
	const [isProjRefresh, setIsProjRefresh] = useState(0);
	const [isTourDisabled, setIsTourDisabled] = useState<boolean>(true);
	const navigate = useNavigate(); // Hook for navigation

	const retrieveProjects = async () => {
		try {
			const result = await ApiService.GetProjects();
			if (result?.isSuccess && result.response?.length > 0) {
				setProjects(result.response);
				setCurrentProjectId(currentProjectId || result.response[0].id);
			}
		} catch (error: any) {
			const errorMessage = error?.message.includes('Network Error') ? 'Failed to load projects. Please try again.' : error?.message;
			showToast(errorMessage, { type: 'error' });
		}
	};

	const handleSelectedProject = (projectId: number) => {
		setViewAllProjects(false);
		setCurrentProjectId(projectId);
		setIsTourDisabled(false);
	};

	const handleActiveTab = (tab: SidebarMenuEnum) => {
		setActiveTab(tab);
		setIsSideBoxOpen([SidebarMenuEnum.ProjectDetails, SidebarMenuEnum.AllProjects].includes(tab));
		if (tab === SidebarMenuEnum.Transaction) {
			setShowSideBox({ isOpen: false, type: '' });
		} else if (tab === SidebarMenuEnum.ProjectDetails || tab === SidebarMenuEnum.AllProjects) {
			setShowSideBox({ isOpen: false, type: 'Transaction' });
		}
	};
	// This function finalizes the editing of a crew member and updates the project state accordingly.
	const finalizeProjectEdit = (isFinalizeProject: boolean) => {
		// Check if the project crew member editing is finalized
		if (isFinalizeProject === true) {
			// Retrieve the latest projects data after editing
			retrieveProjects();

			// Reset the editing state for the project
			setEditProject({ isEditing: false, projectType: '', projectData: null });
			// This will send unique value of isProjRefresh to the ProjectDetails component everytime so that when the user updates the project details the useeffect of that component hits everytime and it refelect the latest updation in project to the dashboard
			setIsProjRefresh(isFinalizeProject ? Math.floor(Math.random() * 100) : 0); //Need to optimize this code in future
			toggleSideBox(false, '');
		}
	};

	const handleEditProject = (isEdit: boolean, type: string, data: Project | null, editEventIndex?: number) => {
		setEditProject({ isEditing: isEdit, projectType: type, projectData: data, eventEditIndex: editEventIndex });
		setIsProjectRefresh(false);
	};

	const handleUpdateProject = (updatedProjects: Project[]) => setProjects(updatedProjects);

	const toggleSideBox = (isOpen: boolean, type: string) => {
		setShowSideBox({ isOpen, type });
	};

	useEffect(() => {
		if (currentProjectId) retrieveProjects();
	}, [currentProjectId]);
	useEffect(() => {
		ApiService.IsSubscribed()
			.then(async (result) => {
				if (!result.isSuccess) {
					navigate(App_Urls.Profile);
				}
			})
			.catch((error) => {
				showToast(error.message ?? 'Something went wrong', { type: 'error' }); // Handle unexpected errors and show error message
			});
		retrieveProjects();
		if (isSideBoxOpen) {
			toggleSideBox(true, '');
		}
	}, []);

	useEffect(() => {
		// Check for stored media items in localStorage
		const storedMedia = localStorage.getItem('activeMedia');
		if (storedMedia) {
			const mediaItems: { mediaId: number; mediaType: string }[] = JSON.parse(storedMedia);
			// Notify upload failures for each stored media item
			const notifyUploadFailures = async () => {
				for (const { mediaId, mediaType } of mediaItems) {
					try {
						if (mediaType === 'Event') {
							await ApiService.postEventMediaUploadFailed(mediaId);
							// Remove the Event mediaId from localStorage after success
							const updatedMediaItems = mediaItems.filter((item) => item.mediaId !== mediaId);
							localStorage.setItem('activeMedia', JSON.stringify(updatedMediaItems));
							//console.log(`Notified upload failure and removed Event mediaId: ${mediaId}`);
						} else if (mediaType === 'Background') {
							await ApiService.postBackgroundMediaUploadFailed(mediaId);
							// Remove the Background mediaId from localStorage after success
							const updatedMediaItems = mediaItems.filter((item) => item.mediaId !== mediaId);
							localStorage.setItem('activeMedia', JSON.stringify(updatedMediaItems));
							///console.log(`Notified upload failure and removed Background mediaId: ${mediaId}`);
						}
					} catch (error) {
						// Show toast for failure
						showToast(`Failed to notify upload failure for ${mediaType} mediaId ${mediaId}`);
					}
				}
			};
			notifyUploadFailures();
		}
	}, []);

	return (
		<>
			<SideBar
				projects={projects}
				handleSelectedProject={handleSelectedProject}
				handleActiveTab={handleActiveTab}
				handleIsViewAllProjects={setViewAllProjects}
				selectedProject_Id={currentProjectId}
				isOpenDropdown={isSideBoxOpen}
				handleIsOpenDropdown={setIsSideBoxOpen}
				activeTab={activeTab}
				isViewAllProjects={isViewAllProjects}
				setIsTourDisabled={setIsTourDisabled}
			/>
			<TopBar
				isUserProfileChange={isUserProfile}
				activeTabHeader={activeTab}
				projects={projects}
				toggleProfileDetailsSideBox={toggleSideBox}
				isTourDisabled={isTourDisabled}
			/>
			<Content
				activeTab={activeTab}
				currentProjectId={currentProjectId}
				projects={projects}
				isViewAllProjects={isViewAllProjects}
				handleEditProject={handleEditProject}
				isProjRefresh={isProjRefresh}
				handleUpdateProject={handleUpdateProject}
				handleSelectedProject={handleSelectedProject}
				isProjectRefresh={isProjectRefresh}
				setIsProjectRefresh={setIsProjectRefresh}
			/>
			<SideBox
				handleActiveTab={handleActiveTab}
				openSideBoxExternally={setIsSideBoxOpen}
				finalizeProjectEdit={finalizeProjectEdit}
				projectEdit={editProject}
				handleEventMediaInfo={(mediaData) => console.log('Event media info:', mediaData)}
				retrieveProjects={retrieveProjects}
				showSideBox={showSideBox}
				setCurrentProjectId={setCurrentProjectId}
				setIsUserProfile={setIsUserPofile}
				setProjectRefresh={setIsProjectRefresh}
				setEditProject={setEditProject}
			/>
		</>
	);
};

interface ContentProps {
	activeTab: SidebarMenuEnum;
	currentProjectId: number;
	projects: Project[];
	isViewAllProjects: boolean;
	handleEditProject: (isEdit: boolean, type: string, data: Project | null, index?: number) => void;
	isProjRefresh: number;
	handleUpdateProject: (updatedProjects: Project[]) => void;
	handleSelectedProject: (projectId: number) => void;
	isProjectRefresh: boolean;
	setIsProjectRefresh: (isEdit: boolean) => void;
}

const Content: React.FC<ContentProps> = ({
	activeTab,
	currentProjectId,
	projects,
	isViewAllProjects,
	handleEditProject,
	isProjRefresh,
	handleUpdateProject,
	handleSelectedProject,
	isProjectRefresh,
	setIsProjectRefresh,
}) => {
	const [showZohoBilling, setShowZohoBilling] = useState<boolean>(false);
	useEffect(() => {
		const getExpirationDate = () => {
			const subscription = Get_Claims_From_Token();
			return subscription?.SubscriptionExpiryDate ? new Date(subscription.SubscriptionExpiryDate) : null;
		};

		const compareDates = (expireDate: Date, targetDate: Date) => {
			return expireDate?.setHours(0, 0, 0, 0) === targetDate.setHours(0, 0, 0, 0);
		};

		const expireDate = getExpirationDate();
		if (!expireDate) return; // If the expiration date is not available, do nothing
		const expireDateMinus3Days = new Date(expireDate);
		expireDateMinus3Days.setDate(expireDateMinus3Days.getDate() - 3);

		const targetDate = new Date();
		if (compareDates(expireDate, targetDate) || compareDates(expireDateMinus3Days, targetDate)) {
			handleConfirm(); // Show pop-up if expiration date matches the target date
		}
	}, [Get_Claims_From_Token()?.SubscriptionExpiryDate]); // Dependency on SubscriptionExpiryDate

	// Handle the confirmation alert
	const handleConfirm = () => {
		try {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className="modal-content-delete">
							<h5>
								<>
									Your Subscription is About to Expire! Your current plan will expire on{' '}
									{new Date(Get_Claims_From_Token()?.SubscriptionExpiryDate ?? new Date()).toLocaleDateString()} Renew now to ensure uninterrupted
									access to all your content and features.
								</>
							</h5>
							<div className="buttons-modal-delete">
								<Button
									id=""
									className="cmn-btn"
									label="Renew Now"
									onClick={async () => {
										setShowZohoBilling(true);
										onClose();
									}}
								/>
								<Button id="" className="cmn-btn blank-btn" label="Remind Me Later" onClick={onClose} />
							</div>
						</div>
					);
				},
				closeOnClickOutside: false,
			});
		} catch (error: any) {
			showToast(error?.message ?? 'Something went wrong');
		}
	};
	const renderContent = () => {
		switch (activeTab) {
			case SidebarMenuEnum.ProjectDetails:
				return isViewAllProjects ? (
					<AllProjects allProjects={projects} handleUpdateProject={handleUpdateProject} handleSelectedProject={handleSelectedProject} />
				) : (
					<ProjectDetails
						isProjectRefresh={isProjectRefresh}
						isProjRefresh={isProjRefresh}
						handleEditProject={handleEditProject}
						currentProjectId={currentProjectId}
						setIsProjectRefresh={setIsProjectRefresh}
					/>
				);
			case SidebarMenuEnum.Support:
				return <Support />;
			case SidebarMenuEnum.Transaction:
				return <Transaction />;
			default:
				return null;
		}
	};

	return (
		<>
			<div className={activeTab === SidebarMenuEnum.Transaction ? 'main-content-wrap transaction_overflow' : 'main-content-wrap'}>
				<div className="wrap-inner">{renderContent()}</div>
			</div>

			{
				<ModalComponent
					show={showZohoBilling}
					className="zoho-modal"
					handleClose={() => {
						setShowZohoBilling(false);
					}}
					title=""
				>
					<Pricing />
				</ModalComponent>
			}
		</>
	);
};

export default DashBoard;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { App_Urls } from './AppUrls';
import useAuth from '../hooks/Index';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const isAuthenticated = useAuth();
	if (!isAuthenticated) {
		// Redirect to authentication page if no token
		return <Navigate to={App_Urls.Auth} />;
	}

	// Render private content if authenticated
	return <>{children}</>;
};

export default ProtectedRoute;

import { Upload } from 'tus-js-client';
import { TrackJS } from 'trackjs';
/**
 * Handles the uploading of a video file using TUS protocol.
 *
 * @param mediaFile -A Video file to be uploaded.
 * @param uploadConfig - Configuration object containing parameter's for TUS upload.
 * @param mediaIndex - Index of the media file.
 * @returns Response :- Resolves when the upload is successful or Rejects if an error occurs.
 */

const uploadVideo = async (
	mediaFile: File,
	uploadConfig: {
		tusEndpoint: string; // TUS server endpoint URL
		videoId: string; // Unique GUID for the video being uploaded
		libraryId: string; // ID of the library where the video is stored
		signature: string; // Authorization signature
		expireTime: number; // Expiration time for the signature
	},
	mediaIndex: number,
	onProgressCallback: (percentCompleted: number) => void, // Progress callback
): Promise<{ mediaIndex: number; message: string }> => {
	// Returning a promise to handle the asynchronous upload process
	return new Promise<{ mediaIndex: number; message: string }>((resolve, reject) => {
		const mediaKey = `${mediaFile.name}_${mediaFile.size}`;
		// Check if the upload is already in progress
		// const uploadStatus = localStorage.getItem(mediaKey);
		// if (uploadStatus === 'inProgress') {
		// 	resolve({ mediaIndex, message: 'This video is still being uploaded.' });
		// 	return;
		// }
		// Set the upload status to inProgress in localStorage
		localStorage.setItem(mediaKey, 'inProgress');
		// Creating a new TUS upload instance
		const upload = new Upload(mediaFile, {
			endpoint: uploadConfig.tusEndpoint,
			retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
			headers: {
				AuthorizationSignature: uploadConfig.signature,
				AuthorizationExpire: uploadConfig.expireTime.toString(),
				VideoId: uploadConfig.videoId,
				LibraryId: uploadConfig.libraryId,
				'Access-Control-Allow-Origin': '*',
			},
			metadata: {
				filetype: mediaFile.type, // File type metadata for the upload
				title: mediaFile.name, // Title metadata for the file (using file name)
			},
			// Error handling function
			onError: function (error) {
				TrackJS?.track(error);
				// Remove inProgress flag if an error occurs
				localStorage.removeItem(mediaKey);
				// Reject the promise with an error message containing the media index
				reject({ mediaIndex, message: `Error during TUS upload: ${error.message}` });
			},
			// Progress tracking function
			onProgress: function (bytesUploaded, bytesTotal) {
				const percentCompleted = Math.round((bytesUploaded * 100) / bytesTotal);
				if (onProgressCallback) {
					onProgressCallback(percentCompleted); // Invoke the progress callback
				}
			},
			// Success callback function
			onSuccess: function () {
				localStorage.removeItem(mediaKey);
				// Resolve the promise with a success message containing the media index
				resolve({ mediaIndex, message: `Media uploaded successfully` });
			},
		});

		// Check for any previous incomplete uploads and resume if found
		upload.findPreviousUploads().then(function (previousUploads) {
			if (previousUploads.length) {
				upload.resumeFromPreviousUpload(previousUploads[0]); // Resume from the previous upload state
			}

			upload.start(); // Starting the upload process
		});
	});
};

export default uploadVideo;

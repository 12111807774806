import { Notification } from '../../types/models/Notification';
import { ApiEndpoints } from '../Endpoints';
import * as handler from './Handler';

export const getAllnotifications = async () => {
	return await handler.get<Notification[]>(ApiEndpoints.GetAllNotifications);
};

export const deleteAllNotifications = async (ids: number[]) => {
	return await handler.post<Notification[]>(ApiEndpoints.DeleteAllNotifications, { notificationIds: ids });
};

// Function to add a new notification
export const addNotification = async (title: string): Promise<Notification> => {
	try {
		// Send a POST request using the handler
		const response = await handler.post<Notification>(ApiEndpoints.AddNotification, { title });
		// Return the response data as a Notification
		return response.response;
	} catch (error) {
		console.error('Error adding notification:', error);
		throw error; // error
	}
};

import { ChartOptions } from 'chart.js';
import React, { FC } from 'react';
import { Line } from 'react-chartjs-2';

interface LineChartProps {
	chartData: {
		labels: string[];
		datasets: {
			label: string;
			data: number[];
			fill: boolean;
			backgroundColor: string;
			borderColor: string;
			tension: number;
		}[];
	};
	options?: ChartOptions<'line'>; // Ensure options are typed for a line chart
	title?: string; // Optional chart title
	customClass?: string; // Optional class for additional styling
}

const LineChart: FC<LineChartProps> = ({ chartData, options = {}, title, customClass }) => {
	const defaultOptions: ChartOptions<'line'> = {
		responsive: true,
		plugins: {
			title: {
				display: !!title,
				text: title || 'Default Chart Title',
			},
			tooltip: {
				mode: 'index',
				intersect: false,
			},
		},
		scales: {
			x: {
				type: 'linear',
				title: {
					display: true,
					text: 'X Axis Label',
				},
			},
			y: {
				type: 'linear',
				title: {
					display: true,
					text: 'Y Axis Label',
				},
			},
		},
		...options,
	};

	return <Line data={chartData} options={defaultOptions} className={customClass} />;
};

export default LineChart;

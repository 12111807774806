import Image from '../../../components/image/Index';
import right_arrow from '../../../assets/images/right_arrow.png';
import './profile.css';

interface WelcomeFunctionProps {
	userName: string | undefined;
	progress: () => number;
}
const Welcome: React.FC<WelcomeFunctionProps> = ({ userName, progress }) => {
	return (
		<>
			<div className="progressbar_main">
				<div className="progressbar_description d-flex justify-content-between">
					<p className="fst-italic text-black">Profile progress</p>
					<p className="fst-italic text-black">{progress()}% Completed</p>
				</div>
				<div className="progress progress_wrapper bg-white rounded-pill overflow-visible">
					<div className="progress-bar position-relative overflow-visible rounded-pill" role="progressbar" style={{ width: `${progress()}%` }}>
						{/* <div className="progress-bar position-relative overflow-visible rounded-pill" role="progressbar"> */}
						<div className="icon position-absolute bg-white rounded-circle">
							<Image src={right_arrow} alt="arrow png" />
						</div>
					</div>
				</div>
			</div>

			<div className="informatiion_section_main">
				<div className="user_sec-main">
					<div className="user_icon d-flex justify-content-center align-items-center">
						<svg width="47" height="54" viewBox="0 0 47 54" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M2 51.8551V45.1179C2 36.932 8.61778 30.2961 16.7812 30.2961H30.2187C38.3823 30.2961 45 36.932 45 45.1179V51.8551M12.75 51.8551V43.7705M34.25 51.8551V43.7705M34.25 12.7795C34.25 18.7328 29.437 23.5589 23.5 23.5589C17.5629 23.5589 12.75 18.7328 12.75 12.7795C12.75 6.82613 17.5629 2 23.5 2C29.437 2 34.25 6.82613 34.25 12.7795Z"
								stroke="url(#paint0_linear_1533_3175)"
								strokeWidth="2.5"
								strokeLinecap="round"
							/>
							<path
								d="M2 51.8549V45.1177C2 36.9317 8.61778 30.2959 16.7812 30.2959H30.2187C38.3823 30.2959 45 36.9317 45 45.1177V51.8549"
								stroke="url(#paint1_linear_1533_3175)"
								strokeWidth="2.5"
								strokeLinecap="round"
							/>
							<defs>
								<linearGradient id="paint0_linear_1533_3175" x1="2" y1="26.9275" x2="45" y2="26.9275" gradientUnits="userSpaceOnUse">
									<stop stopColor="#941AF2" />
									<stop offset="1" stopColor="#EFBD8A" />
								</linearGradient>
								<linearGradient id="paint1_linear_1533_3175" x1="2" y1="41.0754" x2="45" y2="41.0754" gradientUnits="userSpaceOnUse">
									<stop stopColor="#941AF2" />
									<stop offset="1" stopColor="#EFBD8A" />
								</linearGradient>
							</defs>
						</svg>
					</div>
					<div className="user_info">
						<div className="user_name">
							<h3>Hello {userName}</h3>
							<p>Welcome to streamefy</p>
						</div>
						<p className="seventeen_p">
							Congratulations! You've unlocked your project potential. Just a few more steps to customize your experience and you'll be ready to dive
							in! We can't wait to see what you achieve.
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Welcome;

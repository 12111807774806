import { useEffect, useRef, useState } from 'react';
import dummyImage from '../../assets/images/dummy-img.png';
import VideoPlayer from '../hlsPlayer/Index';
import Image from '../image/Index';
import Slider from 'react-slick';
import 'react-circular-progressbar/dist/styles.css';
import { EventMedia } from '../../types/models/EventMedia';
import IncompleteProjectAlert from '../incompleteProjectAlert/Index';
interface ThumbnailCarouselProps {
	trailerDetails: EventMedia[];
	currentPlayingTrailer?: number;
	isVideoMuted?: boolean;
}

const ThumbnailCarousel: React.FC<ThumbnailCarouselProps> = ({ trailerDetails, currentPlayingTrailer, isVideoMuted }) => {
	const [activeTrailerIndex, setActiveTrailerIndex] = useState<number>(0); // State for current active slide
	const [trailers, setTrailers] = useState<EventMedia[]>([]);
	const sliderRef = useRef<any>(null); // Ref for accessing the slider instance
	const [isHovered, setIsHovered] = useState(false);
	useEffect(() => {
		// Shuffle videos when videoUrls prop changes
		const shuffledTrailers = trailerDetails ? trailerDetails : [];
		setTrailers(shuffledTrailers);
	}, [trailerDetails]);

	const handleSlideChange = (current: number) => {
		setActiveTrailerIndex(current);
	};

	const handleVideoEnded = () => {
		// Automatically move to the next slide
		const nextIndex = (activeTrailerIndex + 1) % trailers.length; // Wrap around to first trailer if it's the last one
		setActiveTrailerIndex(nextIndex);
		sliderRef.current?.slickGoTo(nextIndex); // Go to the next slide
	};
	useEffect(() => {
		if (currentPlayingTrailer && currentPlayingTrailer > 0) {
			let index = trailers.findIndex((x) => x.id === currentPlayingTrailer);
			if (index > -1) setActiveTrailerIndex(index);
			else setActiveTrailerIndex(0);
		}
	}, [currentPlayingTrailer]);

	// Sync the slider to the activeVideoIndex
	useEffect(() => {
		if (sliderRef.current && activeTrailerIndex >= 0) {
			sliderRef.current.slickGoTo(activeTrailerIndex); // Move slider to the specific index
		}
	}, [activeTrailerIndex]);

	const settings = {
		fade: true,
		dots: true,
		infinite: true,
		speed: 0, // This ensures an immediate transition without delay
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 30000,
		afterChange: handleSlideChange,
	};

	return (
		<div className="thumbnail-slider-main">
			<Slider ref={sliderRef} {...settings}>
				{trailerDetails && trailerDetails.length > 0 ? (
					trailerDetails.map((trailer, index) => {
						// // Safely access the media details for the current index
						// const mediaDetail = backgroundMediaDetails?.[index]; // Renamed variable for clarity
						return (
							<div key={index}>
								{' '}
								{/* Added key to the wrapping div */}
								{index === activeTrailerIndex && trailer.trailerUrl && (
									<div className="video-wrapper">
										{' '}
										{/* Added a wrapper div */}
										<VideoPlayer
											mediaCount={trailers.length}
											src={trailer.trailerUrl}
											autoPlay={true} // Always autoplay the active video
											onEndedVideo={handleVideoEnded}
											isMuted={isVideoMuted ?? false}
											showCustomControls={false}
										/>
									</div>
								)}
							</div>
						);
					})
				) : (
					<div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
						{/* Fallback when no video URLs are available */}
						<Image src={dummyImage} alt="Thumbnail" />
						{!isHovered && <IncompleteProjectAlert />}
						{isHovered && <IncompleteProjectAlert overlay />}
					</div>
				)}
			</Slider>
		</div>
	);
};

export default ThumbnailCarousel;

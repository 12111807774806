import { VideoProps } from '../../types/type/VideoProps';

/**
 * A reusable video component that renders an HTML `<video>` element.
 *
 * This component accepts various props to customize the video, including the source (`src`),
 * dimensions (`width` and `height`), additional CSS classes (`className`),
 * inline styles (`style`), and optional controls such as autoplay, loop, and muted.
 *
 * @param {string} src - The source URL of the video.
 * @param {number | string} [width] - The width of the video, either as a number (pixels) or a string (percentage, etc.).
 * @param {number | string} [height] - The height of the video, either as a number (pixels) or a string (percentage, etc.).
 * @param {boolean} [controls] - Whether to display the default video controls (play, pause, etc.).
 * @param {boolean} [autoPlay] - Whether to start playing the video automatically.
 * @param {boolean} [loop] - Whether to loop the video after it ends.
 * @param {boolean} [muted] - Whether to mute the video.
 * @param {string} [className] - Additional CSS classes to style the video.
 * @param {React.CSSProperties} [style] - Inline styles applied to the video element.
 *
 * @returns {JSX.Element} An HTML video element with the specified properties.
 */
const Video: React.FC<VideoProps> = ({
	id,
	src,
	width,
	height,
	controls = true,
	autoPlay = false,
	loop = false,
	muted = false,
	className,
	style,
}) => {
	return (
		<video
			id={id}
			src={src}
			width={width}
			height={height}
			controls={controls}
			autoPlay={autoPlay}
			loop={loop}
			muted={muted}
			className={className}
			style={style}
		/>
	);
};

export default Video;

import { CardProps } from '../../types/type/CardProps';

/**
 * A reusable card component that renders a standard HTML div element with card styles.
 *
 * @param {JSX.Element | JSX.Element[]} [children] - Additional content to be rendered inside the card.
 * @param {string} [className] - Additional CSS classes to style the card.
 *
 * @returns {JSX.Element} A styled card element with a title, description, image, and additional content.
 */
const Card: React.FC<CardProps> = ({ _key, children, className }) => (
	<div key={_key} id={_key} className={`card ${className}`}>
		{children && children}
	</div>
);

export default Card;

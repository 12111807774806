import React, { useEffect } from 'react';
import { Project } from '../../../types/models/ProjectModel';
import { Constants } from '../../../constants/Constants';
import CreateEvent, { ProjectEvents } from '../CreateEvent';
import EditProjectDetails from './EditProjectDetails';
import BackgroundMedia from '../BackGroundMedia';
import { EditProjectBasicDeatils } from '../../../../src/types/models/EditProjectBasicDeatilsModel';

interface EditProjectProps {
	projectEdit: {
		isEditing: boolean;
		projectType: string;
		projectData: Project | null;
		eventEditIndex?: number;
	};
	finalizeProjectEdit: (isFinaLizeProject: boolean) => void;
	handleEventMediaInfo: (eventData: any) => void;
	toggleOpenClose: () => void;
	retrieveProjects: () => Promise<void>;
	handleCreateEventMediaInfo?: (projectEvent: ProjectEvents[]) => void;
	handleBasicInfo?: (basicInfo: EditProjectBasicDeatils) => void;
}

const EditProject: React.FC<EditProjectProps> = ({
	projectEdit,
	finalizeProjectEdit,
	handleEventMediaInfo,
	toggleOpenClose,
	retrieveProjects,
	handleCreateEventMediaInfo,
	handleBasicInfo,
}) => {
	const { projectType, isEditing, projectData, eventEditIndex } = projectEdit;
	const projectId = projectData?.id ?? 0;

	useEffect(() => {
		// Any side effects related to projectEdit can be handled here
	}, [projectEdit]);

	const renderComponent = () => {
		switch (projectType) {
			case Constants.editProjectBasicDetails:
				return <EditProjectDetails finalizeProjectEdit={finalizeProjectEdit} projectEdit={projectEdit} handleBasicInfo={handleBasicInfo} />;
			case Constants.editBackgroundMedia:
				return (
					<BackgroundMedia
						isSmartRevision={projectData?.isSmartRevision ?? false}
						retrieveProjects={retrieveProjects}
						toggleOpenClose={toggleOpenClose}
						projectId={projectId}
						isEditing={isEditing}
						isEditDelete={false}
					/>
				);
			case Constants.editProjectEvent:
			case Constants.addProjectEvent:
				return (
					<CreateEvent
						retrieveProjects={retrieveProjects}
						toggleOpenClose={toggleOpenClose}
						projectId={projectId}
						isEditing={isEditing}
						handleEventMediaInfo={handleEventMediaInfo}
						eventEditIndex={eventEditIndex}
						projectType={projectType} // Updated to match new naming conventions
						isEditDelete={false}
						handleCreateEventMediaInfo={handleCreateEventMediaInfo}
						finalizeProjectEdit={finalizeProjectEdit}
					/>
				);
			default:
				return null;
		}
	};

	return <div className="stepper-sidebox">{renderComponent()}</div>;
};

export default EditProject;

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileStepper from './profileSteps/ProfileStepper';
import { Clear_Local_storage, Get_Claims_From_Token } from '../../helpers/Utils';
import { IJwtClaims } from '../../types/interfaces/IJwtClaims';
import { App_Urls } from '../../routes/AppUrls';
import { confirmAlert } from 'react-confirm-alert';
import Button from '../../components/button/Index';
import ModalComponent from '../../components/Modal/Index';
import ApiService from '../../helpers/api/Index';
import Pricing from '../../components/pricing/Index';

export const Profile = () => {
	const [claims, setClaims] = useState<IJwtClaims | null>(null);
	const [showZohoBilling, setShowZohoBilling] = useState(false);
	// const [setcountryCode, isSetCountryCode] = useState<string | null>(null);
	const navigate = useNavigate(); // Hook for navigation

	useEffect(() => {
		// Load the claims once when the component mounts

		const loadedClaims = Get_Claims_From_Token();
		setClaims(loadedClaims);
		if (loadedClaims?.IsProfileActive?.toLowerCase() === 'true') {
			checkSubscriptionStatus(); // Redirect to dashboard
		}
	}, []); // Empty dependency array ensures this runs only once

	const checkSubscriptionStatus = async () => {
		// Call API service to update the profile with the FormData
		var response = await ApiService.IsSubscribed();
		if (response && response.isSuccess) {
			// Redirect to dashboard if profile is active
			navigate(App_Urls.DashBoard); // Redirect to dashboard
		} else {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className="modal-content-delete">
							<div className="icon-modal-delete"></div>
							<div>
								<h5>You don't have any active plan please buy a plan to continue using Streamefy.</h5>
							</div>
							<div className="buttons-modal-delete">
								<Button
									id=""
									className="cmn-btn"
									label="Choose Plan"
									onClick={() => {
										setShowZohoBilling(true);
										onClose();
									}}
								/>
								<Button
									id=""
									className="cmn-btn blank-btn"
									label="Logout"
									onClick={() => {
										handleBuyPlanModelClose();
										onClose();
									}}
								/>
							</div>
						</div>
					);
				},
				closeOnClickOutside: false,
			});
		}
	};
	const handleBuyPlanModelClose = async () => {
		// Remove the access token from local storage and other data as well
		Clear_Local_storage();
		// Navigate to the authentication page, replacing the current URL
		navigate(App_Urls.Auth, { replace: true });
	};

	return (
		<>
			{showZohoBilling && (
				<ModalComponent
					show={showZohoBilling}
					className="zoho-modal"
					handleClose={() => {
						setShowZohoBilling(false);
						handleBuyPlanModelClose();
					}}
					title=""
				>
					<Pricing />
				</ModalComponent>
			)}
			{claims && claims?.IsProfileActive && claims?.IsProfileActive?.toLowerCase() === 'false' && <ProfileStepper name={claims?.unique_name} />}
		</>
	);
};
export default Profile;

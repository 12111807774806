import { useState, useEffect } from 'react';
import { Get_Access_Token } from '../helpers/Utils';

/**
 * Hook check if the user is authenticated
 * 
 * Returns a boolean indicating whether the user is authenticated or not
 */
const useAuth = (): boolean => {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
		const token = Get_Access_Token();
		return token !== null;
	});

	useEffect(() => {
		const token = Get_Access_Token();
		setIsAuthenticated(token !== null);
	}, []);

	return isAuthenticated;
};

export default useAuth;

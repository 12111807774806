import Image from '../../../components/image/Index';
import './profile.css';
import right_arrow from '../../../assets/images/right_arrow.png';
import Input from '../../../components/input/Index';
import { useEffect, useState } from 'react';

interface CompanyNameFunctionProps {
	handleProfileDetailChange: (field: string, value: string) => void;
	companyNameProp: string;
	gstNumberProp: string;
	progress: () => number;
	setValidGstNumber: (isValid: boolean) => void;
}
const CompanyName: React.FC<CompanyNameFunctionProps> = ({
	handleProfileDetailChange,
	companyNameProp,
	gstNumberProp,
	progress,
	setValidGstNumber,
}) => {
	const [companyInfo, setCompanyInfo] = useState<{ companyName: string; gstNumber: string }>({
		companyName: companyNameProp,
		gstNumber: gstNumberProp,
	});
	const [touched, setTouched] = useState(false);
	const [countryCode, isSetCountryCode] = useState<string | null>(null);
	const [isComapnyDetailsError, setIsComapnyDetailsError] = useState<{ companyName: boolean; gstNumber: boolean }>({
		companyName: false,
		gstNumber: false,
	});
	useEffect(() => {
		const storedCountryCode = localStorage.getItem('countryCode');
		if (storedCountryCode) {
			isSetCountryCode(storedCountryCode);
		}
		setValidGstNumber(true);
	}, []);
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;

		if (name === 'companyName') {
			// Update companyName in companyDetails state
			setCompanyInfo((prevDetails) => ({
				...prevDetails,
				companyName: value,
			}));

			// Set company name error state if the name length exceeds 50 characters
			value.length >= 50
				? setIsComapnyDetailsError((prev) => ({ ...prev, companyName: true }))
				: setIsComapnyDetailsError((prev) => ({ ...prev, companyName: false }));
		} else if (name === 'gstNumber') {
			if (value) {
				// GST pattern for validation

				const gstPattern = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
				if (!gstPattern.test(value.toUpperCase())) {
					// If the format is incorrect, set error state for gstNumber
					setIsComapnyDetailsError((prev) => ({ ...prev, gstNumber: true }));
					setValidGstNumber(false);
				} else {
					setIsComapnyDetailsError((prev) => ({ ...prev, gstNumber: false }));
					setValidGstNumber(true);
				}
			} else {
				// If the value is empty, clear error for gstNumber without validation
				setIsComapnyDetailsError((prev) => ({ ...prev, gstNumber: false }));
				setValidGstNumber(true);
			}
			// Update gstNumber in companyDetails state
			setCompanyInfo((prevDetails) => ({
				...prevDetails,
				gstNumber: value?.toUpperCase(),
			}));
		}
		handleProfileDetailChange(name, value); // Notify parent component of the change
	};
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault(); // Prevent the default form submission
	};
	// const handleGstNumberChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	const value = e.target.value;
	// 	setGstNumber(value);
	// 	value.length === 15 ? setIsGstNumberError(false) : setIsGstNumberError(true);
	// 	handleGstNumberChange(value);
	// };
	/**
	 * Handles the blur event, setting 'touched' to true when the user leaves
	 * the input field, indicating interaction for validation or styling.
	 */
	const handleBlur = () => {
		setTouched(true); // set the field as touched
	};
	return (
		<>
			<div className="progressbar_main">
				<div className="progressbar_description d-flex justify-content-between">
					<p className="fst-italic text-black">Profile progress</p>
					<p className="fst-italic text-black">{progress()}% Completed</p>
				</div>
				<div className="progress progress_wrapper bg-white rounded-pill overflow-visible">
					<div className="progress-bar position-relative overflow-visible rounded-pill" role="progressbar" style={{ width: `${progress()}%` }}>
						<div className="icon position-absolute bg-white rounded-circle">
							<Image src={right_arrow} alt="arrow png" />
						</div>
					</div>
				</div>
			</div>
			<div className="informatiion_section_main position-relative">
				<div className="user_sec-main">
					<div className="user_icon icon_without-bg">
						<svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clipPath="url(#clip0_853_5993)">
								<mask id="mask0_853_5993" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="75" height="75">
									<path d="M75 0H0V75H75V0Z" fill="white" />
								</mask>
								<g mask="url(#mask0_853_5993)">
									<path
										d="M49.2188 65.625V4.6875C49.2188 3.39141 48.1711 2.34375 46.875 2.34375H4.6875C3.39141 2.34375 2.34375 3.39141 2.34375 4.6875V72.6563H18.75V58.5938H32.8125V72.6563H72.6563V18.75C72.6563 18.75 72.6563 16.4063 70.3125 16.4063H56.25M37.5 14.0625V18.75M60.9375 28.125V32.8125M60.9375 42.1875V46.875M25.7813 14.0625V18.75M14.0625 14.0625V18.75M37.5 28.125V32.8125M25.7813 28.125V32.8125M14.0625 28.125V32.8125M37.5 42.1875V46.875M25.7813 42.1875V46.875M14.0625 42.1875V46.875"
										stroke="url(#paint0_linear_853_5993)"
										strokeWidth="3"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
								<g clipPath="url(#clip1_853_5993)">
									<mask id="mask1_853_5993" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="75" height="75">
										<path d="M75 0H0V75H75V0Z" fill="white" />
									</mask>
									<g mask="url(#mask1_853_5993)">
										<path
											d="M37.5 14.0625V18.75M60.9375 28.125V32.8125M60.9375 42.1875V46.875M25.7813 14.0625V18.75M14.0625 14.0625V18.75M37.5 28.125V32.8125M25.7813 28.125V32.8125M14.0625 28.125V32.8125M37.5 42.1875V46.875M25.7813 42.1875V46.875M14.0625 42.1875V46.875"
											stroke="url(#paint1_linear_853_5993)"
											strokeWidth="3"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</g>
								</g>
							</g>
							<defs>
								<linearGradient id="paint0_linear_853_5993" x1="2.34375" y1="37.5" x2="72.6562" y2="37.5" gradientUnits="userSpaceOnUse">
									<stop stopColor="#941AF2" />
									<stop offset="1" stopColor="#EFBD8A" />
								</linearGradient>
								<linearGradient id="paint1_linear_853_5993" x1="14.0625" y1="30.4688" x2="60.9375" y2="30.4688" gradientUnits="userSpaceOnUse">
									<stop stopColor="#941AF2" />
									<stop offset="1" stopColor="#EFBD8A" />
								</linearGradient>
								<clipPath id="clip0_853_5993">
									<rect width="75" height="75" fill="white" />
								</clipPath>
								<clipPath id="clip1_853_5993">
									<rect width="75" height="75" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>
					{countryCode === 'in' ? (
						<div className="user_info user_info_h3">
							<div className="user_name user_without-border">
								<h3 className="nineteen_heading">Please enter company name and GST number.</h3>
							</div>
						</div>
					) : (
						<div className="user_info user_info_h3">
							<div className="user_name user_without-border">
								<h3 className="nineteen_heading">Please enter company name.</h3>
							</div>
						</div>
					)}

					<div className="form-main-auth form-main-auth">
						<form onSubmit={handleSubmit}>
							<div className="row">
								<div className="col-md-12">
									{/* Company Name Input */}
									<div className="background-after-login">
										<div className="input-field">
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M12.8332 11.6663V12.833H1.1665V11.6663H12.8332ZM6.25296 3.95487L8.87796 6.57985L4.95817 10.4997H2.33317V7.87467L6.25296 3.95487ZM8.74984 1.45801L11.3748 4.08301L9.4967 5.96114L6.8717 3.33614L8.74984 1.45801Z"
													fill="#C1C1C1"
												/>
											</svg>

											<Input
												required={true}
												value={companyNameProp}
												type="text"
												onChange={(e) => handleChange(e)}
												_key={'companyName'}
												name={'companyName'}
												onBlur={handleBlur}
												maxLength={50}
											/>
											<label>
												Company name here <span style={{ color: 'red' }}>*</span>
											</label>
										</div>
										{!companyInfo.companyName && touched && (
											<div className="error">
												<p>This field is required. Please enter your company name.</p>
											</div>
										)}
										{isComapnyDetailsError.companyName && (
											<div className="error">
												<p>The Company name must be 50 characters or lower</p>
											</div>
										)}
									</div>
									<br />
									{countryCode === 'in' && (
										<div className="background-after-login">
											{/* Conditional Rendering of GST Number Input based on countryCode */}

											<div className="input-field">
												<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M12.8332 11.6663V12.833H1.1665V11.6663H12.8332ZM6.25296 3.95487L8.87796 6.57985L4.95817 10.4997H2.33317V7.87467L6.25296 3.95487ZM8.74984 1.45801L11.3748 4.08301L9.4967 5.96114L6.8717 3.33614L8.74984 1.45801Z"
														fill="#C1C1C1"
													/>
												</svg>

												<Input
													required={false}
													value={gstNumberProp.trim().toUpperCase() || ''}
													type="text"
													onChange={handleChange}
													_key={'gstNumber'}
													name="gstNumber"
													onBlur={handleBlur}
													maxLength={15}
												/>
												<label>
													GST number here <span style={{ color: 'red' }}></span>
												</label>
											</div>

											{/* Show error if GST number is invalid and countryCode is India */}
											{isComapnyDetailsError.gstNumber && countryCode === 'in' && (
												<div className="error">
													<p>Invalid GST Number! Please enter the correct format.</p>
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default CompanyName;

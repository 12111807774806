import { showToast } from '../../components/toaster/Index';
import { EditProjectBasicDeatils } from '../../types/models/EditProjectBasicDeatilsModel';
import { AddProjectModel, PrimaryUserProjectResponse, Project, ProjectSmartRevisionModel } from '../../types/models/ProjectModel';
import { ShareProjectModal } from '../../types/models/ShareProject';
import { ResponseData } from '../../types/type/BackgroundMedia';
import { ApiEndpoints } from '../Endpoints';
import * as handler from '../api/Handler';

export const GetProjects = async () => {
	return await handler.get<Project[]>(ApiEndpoints.GetProjects);
};

export const GetProjectById = async (projectId: number) => {
	return await handler.get<Project>(ApiEndpoints.GetProjectById + projectId);
};
export const CreateProject = async (payload: AddProjectModel) => {
	return await handler.post<number>(ApiEndpoints.Project, payload);
};

export const ProjectSmartRevision = async (payload: ProjectSmartRevisionModel) => {
	return await handler.post<string>(ApiEndpoints.ProjectSmartRevision, payload);
};

export const DeleteProject = async (payload: number) => {
	return await handler.post<string>(ApiEndpoints.DeleteProject + payload);
};

export const PinProject = async (payload: number) => {
	return await handler.post<string>(ApiEndpoints.PinProject, { projectId: payload });
};
export const EditProjectBasicDetail = async (payload: EditProjectBasicDeatils) => {
	return await handler.post<Project>(ApiEndpoints.Edit, payload);
};

export const GenerateProjectPin = async (projectId: number, isResetPin: boolean) => {
	return await handler.post<string>(ApiEndpoints.PinSetup + projectId + `?resetPin=${isResetPin}`);
};
export const ShareProject = async (payload: ShareProjectModal) => {
	return await handler.post<string>(ApiEndpoints.ShareProject, payload);
};
export const AuthorizeProjectPin = async (nameId: number, projectId: number, code: string, phoneNumber: string) => {
	const payload = { nameId, projectId, code, phoneNumber };
	return await handler.post<number>(ApiEndpoints.PinAuthentication, payload);
};
export const PlayProject = async (projectId: number, phoneNumber: string, nameId: number) => {
	const payload = { projectId, phoneNumber, nameId };
	return await handler.post<Project>(ApiEndpoints.ProjectPlay, payload);
};
export const GetProjectMediaDetails = async (projectId: number) => {
	return await handler.get<{ projectName: string; companyLogo: string; eventCount: number; mediaCount: number }>(
		ApiEndpoints.GetProjectMediaDetails + projectId,
	);
};

/**
 * Reencodes videos associated with a given project.
 *
 * @param projectId - The ID of the project for which videos need to be reencoded.
 * @returns A Promise that resolves to the backend response containing the result of the reencoding process.
 */
export const ReencodeProjectVideos = async (projectId: number): Promise<any> => {
	try {
		// Send a GET request to the API endpoint to reencode project videos
		const response = await handler.get(ApiEndpoints.ReencodeProjectVideos + projectId);
		// Return the response from the backend, casting it as ResponseData
		return response.response as ResponseData;
	} catch (error) {
		// Display a toast notification for an unknown error
		showToast('An unknown error occurred', { type: 'error' });
		// Return the error for further handling if necessary
		return error;
	}
};

/**
 * Function to check videos with Smart Revision enabled.
 * @returns The response data if successful, or null in case of an error.
 */
export const CheckSmartRevisionVideos = async (projectId: number): Promise<any> => {
	return await handler.get(ApiEndpoints.CheckSmartRevisonVideos + projectId);
};
export const GetnameIdDetails = async (nameid: number): Promise<any> => {
	return await handler.get(ApiEndpoints.GetnameIdDetails + nameid);
};
export const PrimaryUserProjects = async (nameId: number, phoneNumber: string) => {
	const payload = { nameId, phoneNumber };
	return await handler.post<PrimaryUserProjectResponse[]>(ApiEndpoints.PrimaryUserProjects, payload);
};

export const UploadThumbnail = async (payload: { mediaId: string; thumbnailBase64: string; thumbnailName: string; videoType: string }) => {
	return await handler.post(ApiEndpoints.UpdateThumbnail, payload);
};

export const DeleteUsedData = async (payload: number) => {
	return await handler.post<string>(ApiEndpoints.UpdateUsedData + payload);
};
export const PrimaryUserResetPin = async (projectId: number, phoneNumber: string, nameId: number) => {
	const payload = { projectId, phoneNumber, nameId };
	return await handler.post<string>(ApiEndpoints.PrimaryUserResetPin, payload);
};

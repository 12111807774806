import "./Index.css";

// props type for the IncompleteProjectAlert component
type IncompleteProjectAlertProps = {
    message?: string;
    size?: number;
    fontSize?: string;
    fontWeight?: string;
    overlay?: boolean;
};

// Generic IncompleteProjectAlert component 
const IncompleteProjectAlert = ({
    message = "Project is incomplete..",
    size = 24,
    fontSize = '14px',
    fontWeight = 'bold',
    overlay = false,
}: IncompleteProjectAlertProps) => {
    return (
        // Conditional class based on overlay prop to style differently
        <div className={overlay ? "incompleted-project-alert-overlay" : "incompleted-project-alert-icon"}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                style={{ width: size, height: size }}
            >
                {/* Icon path, stroke for color and rounded caps */}
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
            </svg>
            {/* Conditional rendering of message when overlay is true */}
            {overlay && (
                <span style={{ fontSize, fontWeight, marginLeft: '8px' }}>
                    {message}
                </span>
            )}
        </div>
    );
};

export default IncompleteProjectAlert;

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoutePath, PublicRoutePath } from './RoutePath';
import ProtectedRoute from './ProtectedRoute';
import { App_Urls } from './AppUrls'

function Navigation(props: any) {
	/**
	 * Renders a set of public routes based on the route definitions.
	 *
	 * This function maps over an array of route definitions (`PublicRoutePath`) and creates
	 * a `<Route>` component for each entry. Each route is associated with a path and a
	 * component that will be rendered when the path matches the URL.
	 *
	 * @returns {JSX.Element[]} An array of `<Route>` components for public routes.
	 */
	const PublicRoutes = () => {
		return PublicRoutePath.map((route, index) => <Route key={index} path={route.path} element={<route.component />} />);
	};

	/**
	 * Renders a set of private routes that are protected by authentication.
	 *
	 * This function maps over an array of route definitions (`PrivateRoutePath`) and creates
	 * a `<Route>` for each entry. Each route is wrapped with a `ProtectedRoute` component
	 * to ensure that only authenticated or authorized users can access the routes.
	 *
	 * @returns {JSX.Element[]} An array of `<Route>` components wrapped in `ProtectedRoute`.
	 */
	const PrivateRoutes = () => {
		return PrivateRoutePath.map((route, index) => (
			<Route
				key={index}
				path={route.path}
				element={
					<ProtectedRoute>
						<route.component />
					</ProtectedRoute>
				}
			/>
		));
	};

	return (
		<BrowserRouter>
			<Routes>
				{PublicRoutes()}
				{/* Protected routes are wrapped with ProtectedRoute */}
				{PrivateRoutes()}
				<Route path="*" element={<Navigate to={App_Urls.DashBoard} />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Navigation;

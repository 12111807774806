import { ButtonProps } from '../../types/type/ButtonProps ';

/**
 * A reusable button component that renders a standard HTML button element.
 *
 * This component accepts various props for customization, including an `id`, `label`,
 * `onClick` handler, `disabled` state, and additional CSS classes through the `className` prop.
 *
 * @param {string} id - The unique identifier for the button element.
 * @param {string} label - The text label displayed inside the button.
 * @param {() => void} onClick - The function to be called when the button is clicked.
 * @param {boolean} [disabled] - If `true`, the button is disabled and not clickable.
 * @param {string} [className] - Additional CSS classes to style the button.
 * @param {string} [modelClose] -Added for hanle the modal close
 * @returns {JSX.Element} A styled button element with a label and click handler.
 */
const Button: React.FC<ButtonProps> = ({ id, label, onClick, disabled, className, type, children, style, modelClose }) => (
	<button type={type} className={className} id={id} onClick={onClick} disabled={disabled} style={style} data-bs-dismiss={modelClose}>
		<span>{label}</span>
		{children && children}
	</button>
);

export default Button;

import { useState, ChangeEvent } from 'react';
import FileUploadProps from '../../types/type/FileUploadProps';
import { showToast } from '../toaster/Index';

const FileUpload: React.FC<FileUploadProps> = ({
	onFileSelect,
	allowedFileTypes,
	maxSizeInBytes,
	allowedMultiple,
	id,
	disabled,
	allowedHeight,
	allowedWidth,
	fileUploadingType,
}) => {
	const [fileError, setFileError] = useState<string | null>(null);
	const MAX_FILE_SIZE = 10000000; // 10 MB
	const handleError = (message: string, event: ChangeEvent<HTMLInputElement>) => {
		showToast(message, { type: 'error' });
		event.target.value = '';
	};
	const validateSize = (file: File, event: ChangeEvent<HTMLInputElement>, maxSize: number) => {
		if (file.size > maxSize) {
			handleError(`File size exceeds the limit of 10MB`, event);
			return false;
		}
		return true;
	};
	const isAllowedFileType = (file: File, allowedFileTypes: string[] | undefined, event: ChangeEvent<HTMLInputElement>, errorMessage: string) => {
		if (allowedFileTypes && !allowedFileTypes.includes(file.type)) {
			showToast(errorMessage, { type: 'error' });
			event.target.value = '';
			return false;
		}
		return true;
	};
	const isValidVideoResolution = (height: number, width: number) => {
		return (
			// (height === 480 && width === 640) ||
			// (height === 480 && width === 854) ||
			(height === 720 && width === 1280) || (height === 1080 && width === 1920) || (height === 2160 && width === 3840)
		);
	};

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files && files.length > 0) {
			const file = files[0];
			if (fileUploadingType === 'companyWaterMark' || fileUploadingType === 'companyLogo') {
				// Check for allowed file types
				if (!isAllowedFileType(file, allowedFileTypes, event, 'Invalid format. Accepted format: PNG')) return;
				else if (!validateSize(file, event, MAX_FILE_SIZE)) return;
				// else if (file.type === "application/pdf") {
				// 	setFileError(null); // Clear any previous error
				// 	onFileSelect(file); // Pass the selected file to the parent component
				// }
				const reader = new FileReader();
				reader.onloadend = () => {
					const img = new Image();
					img.src = reader.result as string;
					img.onload = () => {
						const imgHeight = img.height;
						const imgWidth = img.width;
						if (fileUploadingType === 'companyLogo' && (imgHeight !== allowedHeight || imgWidth !== allowedWidth)) {
							showToast(`Invalid CompanyLogo size. Required size: ${allowedWidth}x${allowedHeight} px`, { type: 'error' });
							event.target.value = '';
							return;
						} else if (fileUploadingType === 'companyWaterMark' && (imgHeight !== allowedHeight || imgWidth !== allowedWidth)) {
							showToast(`Invalid Watermark size. Required size: ${allowedWidth}x${allowedHeight} px`, { type: 'error' });
							event.target.value = '';
							return;
						}
						// Call onFileSelect only after validation
						setFileError(null); // Clear any previous error
						onFileSelect(file); // Pass the selected file to the parent component
					};
				};
				reader.readAsDataURL(file);
				return;
			} else if (fileUploadingType === 'profilepic') {
				if (!isAllowedFileType(file, allowedFileTypes, event, 'Invalid format. Accepted formats: PNG & JPEG')) return;
				else if (!validateSize(file, event, MAX_FILE_SIZE)) return;
				setFileError(null); // Clear any previous error
				onFileSelect(file); // Pass the selected file to the parent component
				return;
			} else if (fileUploadingType === 'backgroundMediaVideo' || fileUploadingType === 'eventMediaVideo') {
				if (!isAllowedFileType(file, allowedFileTypes, event, 'Invalid video format. Accepted format: MP4')) return;
				else if (fileUploadingType === 'backgroundMediaVideo' && file.type.startsWith('video/')) {
					const videoElement = document.createElement('video');
					const fileURL = URL.createObjectURL(file);
					videoElement.src = fileURL;
					videoElement.onloadedmetadata = () => {
						if (videoElement.duration > 61) {
							console.log(videoElement.duration);
							showToast('The background media video duration exceeds the limit of 60 seconds', { type: 'error' });
							event.target.value = ''; // Clear the input
							return;
						}
						// Check for allowed resolutions
						const videoHeight = videoElement.videoHeight;
						const videoWidth = videoElement.videoWidth;
						if (!isValidVideoResolution(videoHeight, videoWidth)) {
							showToast('Invalid video resolution. Supported resolutions: 720p,1080p & 4K', { type: 'error' });
							event.target.value = ''; // Clear the input
							return;
						}
						// If duration is valid and resolution is valid, proceed to select the file
						setFileError(null); // Clear any previous error
						onFileSelect(file); // Pass the selected file to the parent component
					};
					return;
				} else if (fileUploadingType === 'eventMediaVideo' && file.type.startsWith('video/')) {
					const videoElement = document.createElement('video');
					const fileURL = URL.createObjectURL(file);
					videoElement.src = fileURL;
					// Check for allowed resolutions
					videoElement.onloadedmetadata = () => {
						const videoHeight = videoElement.videoHeight;
						const videoWidth = videoElement.videoWidth;
						if (!isValidVideoResolution(videoHeight, videoWidth)) {
							showToast('Invalid video resolution. Supported resolutions: 720p,1080p & 4K', { type: 'error' });
							event.target.value = ''; // Clear the input
							return;
						}
						setFileError(null); // Clear any previous error
						onFileSelect(file); // Pass the selected file to the parent component
					};
					return;
				}
			} else if (fileUploadingType === 'backgroundMediaThumbnail' || fileUploadingType === 'eventMediaThumbnail') {
				if (!isAllowedFileType(file, allowedFileTypes, event, 'Invalid format. Accepted formats:PNG & JPEG')) return;
				else if (!validateSize(file, event, MAX_FILE_SIZE)) return;
				setFileError(null); // Clear any previous error
				onFileSelect(file); // Pass the selected file to the parent component
				return;
			}
			// Check for file size
			if (maxSizeInBytes && file.size > maxSizeInBytes) {
				setFileError(`File size exceeds the limit of ${maxSizeInBytes / 1024 / 1024} MB`);
				event.target.value = '';
				return;
			}
			setFileError(null); // Clear any previous error
			onFileSelect(file); // Pass the selected file to the parent component
		}
	};
	// const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
	// 	const files = event.target.files;
	// 	if (files && files.length > 0) {
	// 		const file = files[0];

	// 		// Check for allowed file types
	// 		if (allowedFileTypes && !allowedFileTypes.includes(file.type)) {
	// 			setFileError(`File type not allowed. Allowed types: ${allowedFileTypes.join(', ')}`);
	// 			return;
	// 		}

	// 		// Check for file size
	// 		if (maxSizeInBytes && file.size > maxSizeInBytes) {
	// 			setFileError(`File size exceeds the limit of ${maxSizeInBytes / 1024 / 1024} MB`);
	// 			return;
	// 		}
	// 		setFileError(null); // Clear any previous error
	// 		onFileSelect(file); // Pass the selected file to the parent component
	// 	}
	// };

	return (
		<div>
			<input id={id} type="file" onChange={handleFileChange} multiple={allowedMultiple} disabled={disabled} />
			{fileError && <p style={{ color: 'red' }}>{fileError}</p>}
		</div>
	);
};

export default FileUpload;

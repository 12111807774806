import { showToast } from '../../components/toaster/Index';
import { TrackJS } from 'trackjs';

type DownloadVideosParams = {
	url: string;
};

const extractUrl = (url: string): { videoId: string } | null => {
	// Unified regex pattern to extract the videoId
	const pattern = /https:\/\/[\w.-]+\/([a-f0-9-]+)/;
	const match = url.match(pattern);
	if (!match || !match[1]) {
		showToast('Invalid URL format.', { type: 'error' });
		return null; // return null for failure
	}
	// Extract and return the videoId
	const videoId = match[1];
	return {
		videoId,
	};
};

const downloadOriginalVideo = async (
	{ url, mediaName }: DownloadVideosParams & { mediaName: string },
	updateProgress: (progressData: { progress: number; videoId: string }) => void,
): Promise<void> => {
	try {
		if (!url) {
			showToast('URL is required.', { type: 'error' });
			return;
		}

		const { videoId } = extractUrl(url) ?? {};
		if (!videoId) {
			showToast('Incomplete data extracted from URL.', { type: 'error' });
			return;
		}

		const response = await fetch(url);
		if (!response.ok) {
			showToast(`Failed to fetch video: ${response.statusText}`, { type: 'error' });
			return;
		}

		const totalLength = response.headers.get('Content-Length');
		if (!totalLength) {
			showToast('Content-Length is not available for the video file.', { type: 'error' });
			return;
		}

		const reader = response.body?.getReader();
		if (!reader) {
			showToast('No reader available for the video file.', { type: 'error' });
			return;
		}

		const chunkTotalLength = parseInt(totalLength, 10);
		let receivedLength = 0;
		const combinedStream = new ReadableStream({
			async pull(controller) {
				while (true) {
					const { done, value } = await reader.read();
					if (done) break;
					receivedLength += value.length;
					const progress = (receivedLength / chunkTotalLength) * 100;
					updateProgress({ progress, videoId });
					controller.enqueue(value);
				}
				controller.close();
			},
		});

		const downloadUrl = URL.createObjectURL(await new Response(combinedStream).blob());
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.setAttribute('download', `${mediaName}`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		URL.revokeObjectURL(downloadUrl);
		showToast('Download completed successfully!');
	} catch (error) {
		showToast(`Download not completed successfully: ${(error as Error).message}`, { type: 'error' });
		TrackJS.track(error as Error);
	}
};

export default downloadOriginalVideo;

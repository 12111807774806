import { EventResponseModel } from '../../types/type/BackgroundMedia'; // Import necessary types
import { DescriptionRequestModel, EventMediaCommand } from '../../types/type/EventMedia';
import * as handler from '../api/Handler'; // Import the handler for making API requests
import { ApiEndpoints } from '../Endpoints'; // Import API endpoints
import { TrackJS } from 'trackjs';
/**
 * Fetches pre-signed URLs for background media uploads to BunnyCDN.
 *
 * @param uploadRequest - An object that implements the MediaUploadtoBunny interface, containing details of the media to be uploaded.
 * @returns A promise that resolves to an array of PreSignedUrlResponse objects containing the pre-signed URLs.
 * @throws Throws an error if the API request fails.
 */
export const EventMedia = async (uploadRequest: EventMediaCommand): Promise<EventResponseModel> => {
	try {
		// Send a POST request to the BackgroundMedia endpoint with the uploadRequest payload
		const response = await handler.post<EventResponseModel>(ApiEndpoints.EventMedia, uploadRequest);

		// Return the array of PreSignedUrlResponse objects from the API response
		return response.response;
	} catch (error) {
		// Log the error to the console for debugging purposes
		console.error('Error fetching pre-signed URLs:', error);
		// Rethrow the error to be handled by the calling function
		throw error;
	}
};

/**
 * Adds descriptions to media items for a specific project.
 *
 * @param payload - An object containing the project ID and an array of events with media descriptions.
 * @returns A promise that resolves to the API response model.
 * @throws Throws an error if the API request fails.
 */
export const AddDescriptionOfMedia = async (payload: DescriptionRequestModel) => {
	try {
		const response = await handler.post<any>(ApiEndpoints.Descriptions, payload);
		return response;
	} catch (error) {
		console.error('Error adding media descriptions:', error);
		throw error;
	}
};

/**
 * Deletes media by its ID.
 *
 * @param {number} mediaID - The ID of the media to delete.
 * @returns {Promise<any>} - The API response from the delete request.
 * @throws {Error} - Throws an error if the delete request fails.
 */
export const DeleteMedia = async (mediaID: number): Promise<any> => {
	try {
		// Send a POST request to the 'delete-media' endpoint with the media ID as the payload
		// Construct the URL with the mediaID as a query parameter
		const urlWithQuery = `${ApiEndpoints.DeleteMediaByID}/${mediaID}`;
		// Send a POST request to the constructed URL
		const response = await handler.post<any>(urlWithQuery);

		// Return the API response
		return response; // Adjust this if the API response structure is different
	} catch (error) {
		// Log the error for debugging purposes
		console.error('Error deleting media:', error);

		// Rethrow the error to be handled by the calling function
		throw new Error('Failed to delete media');
	}
};

/**
 * Deletes event and media by it event ID.
 *
 * @param {number} eventId - The ID of the media to delete.
 * @returns {Promise<any>} - The API response from the delete request.
 * @throws {Error} - Throws an error if the delete request fails.
 */
export const DeleteEventWithMedia = async (eventId: number): Promise<any> => {
	try {
		// Send a POST request to the 'delete-media' endpoint with the media ID as the payload
		// Construct the URL with the mediaID as a query parameter
		const urlWithQuery = `${ApiEndpoints.DeleteEventWithMediaById}${eventId}`;
		// Send a POST request to the constructed URL
		const response = await handler.delet<any>(urlWithQuery);

		// Return the API response
		return response; // Adjust this if the API response structure is different
	} catch (error) {
		// Log the error for debugging purposes
		console.error('Error deleting media:', error);

		// Rethrow the error to be handled by the calling function
		throw new Error('Failed to delete media');
	}
};

/**
 * Sends the mediaId to the backend to update the video as failed to upload.
 *
 * @param mediaId - The media ID of the uploaded Events video.
 * @returns A promise that resolves to the response from the backend.
 */
export const postEventMediaUploadFailed = async (mediaId: number): Promise<any> => {
	try {
		// Create the request body
		const requestPayload = {
			mediaId: mediaId,
		};

		// Send a POST request to
		const response = await handler.post(ApiEndpoints.AfterEventMediaUpload, requestPayload);

		// Return the backend response
		return response.response;
	} catch (error) {
		// Handle errors if any occur during the API request
		console.error('Error posting media upload failed:', error);
		throw error;
	}
};

export const downloadMedia = async (videoId: string) => {
	try {
		// Send a POST request to
		return await handler.get<any>(ApiEndpoints.downloadMedia + `?videoId=${videoId}`);
	} catch (error: any) {
		// Handle errors if any occur during the API request
		console.error(error);
		TrackJS.track(error);
		throw error;
	}
};

import './support.css';
import Accordion from 'react-bootstrap/Accordion';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import ApiService from '../../helpers/api/Index';
import IAPIResponseModel from '../../types/interfaces/IAPIResponseModel';
import { GetSupportModel } from '../../types/models/GetSupportModel';
import { showToast } from '../toaster/Index';
import Image from '../image/Index';
import Button from '../../components/button/Index';
import PhoneInputComponent from '../phoneNumber/Index';
import { validatePhoneNumber } from '../../helpers/Utils';
import { CountryCode, getCountryCallingCode, PhoneNumber } from 'libphonenumber-js';

const accordionData = [
	{
		title: 'What is Streamefy?',
		content:
			'Streamefy is a secure, subscription-based OTT (over-the-top) streaming platform designed primarily for  filmmakers and content creators. It allows them to upload, store, and stream their videos securely, providing a seamless viewing experience similar to popular OTT platforms.',
	},
	{
		title: 'Who can use Streamefy?',
		content:
			'Streamefy is ideal for professional filmmakers, particularly in the wedding industry, as well as general users who want a secure platform to stream personal content. It’s also suited for anyone seeking a private, high-quality OTT streaming experience.',
	},
	{
		title: 'Is Streamefy available on mobile and smart TV platforms?',
		content:
			'Yes, Streamefy is available on mobile devices, Amazon Fire TV, and Android TV, providing users with flexibility in how and where they view content.',
	},
	{
		title: 'What are the available subscription plans?',
		content:
			'Streamefy offers various subscription plans, including the luxury Stream, Prestige Plan and Elite Stream, catering to different storage needs and usage levels. Each plan provides access to all features, with the storage capacity varying by plan.',
	},
];
const accordionData2 = [
	{
		title: 'Can I change my subscription plan later?',
		content:
			'Yes, you can upgrade your subscription plan anytime based on your storage needs and usage requirements. As of now we are not supporting downgrading the plan',
	},
	{
		title: 'Can I cancel my subscription at any time?',
		content:
			'Yes, you can cancel your subscription anytime through the account settings. However, you’ll retain access to your content until the end of your billing cycle.',
	},
	{
		title: 'How does Streamefy ensure smooth streaming without buffering?',
		content:
			'Streamefy uses adaptive bitrate streaming to optimize quality based on the viewer’s internet speed, reducing buffering for a smoother experience.',
	},
	{
		title: 'Who has access to my videos?',
		content: 'Only authorized users, such as those you share links with, can access your videos',
	},
];

const Support: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState<File[]>([]);
	const [countryCodes] = useState<string | null>(localStorage.getItem('countryCode') || 'in'); // Set country code from local storage
	const countryCodeISO: CountryCode = (localStorage.getItem('countryCode')?.toUpperCase() as CountryCode) || 'IN';
	const initialValues: GetSupportModel = {
		Email: '',
		Contact: {
			Email: '',
			FirstName: '',
			LastName: '',
		},
		Description: '',
		PhoneNumber: `+${getCountryCallingCode(countryCodeISO)}`,
		Subject: '',
		Files: [],
	};
	// Define validation schema using Yup
	const validationSchema = Yup.object().shape({
		Email: Yup.string().email('Invalid email address').required('Email address is required'),
		Contact: Yup.object().shape({
			FirstName: Yup.string().required('First name is required'),
			LastName: Yup.string().required('Last name is required'),
		}),
		Description: Yup.string().required('Description is required').max(400, 'Must not exceed 400 characters'),
		PhoneNumber: Yup.string()
			.required('Phone number is required')
			.test('is-valid-phone', 'Invalid phone format', function (value) {
				return validatePhoneNumber(value);
			}),
		Subject: Yup.string().required('Subject is required'),
	});

	const handleSubmitClick = async (values: GetSupportModel) => {
		// Step 1: Create a FormData object
		const formData = new FormData();
		// Step 2: Append form values to the FormData object
		formData.append('Contact.FirstName', values.Contact.FirstName);
		formData.append('Contact.LastName', values.Contact.LastName);
		formData.append('Email', values.Email);
		formData.append('Contact.Email', values.Email);
		formData.append('PhoneNumber', values.PhoneNumber);
		formData.append('Subject', values.Subject);
		formData.append('Description', values.Description);
		// Append the file if it exists
		if (files && files.length > 0) {
			for (const file of files) {
				formData.append('Files', file, file.name);
			}
		}
		setLoading(true);
		await ApiService.SupportTicket(formData)
			.then((response: IAPIResponseModel<number>) => {
				if (response) {
					if (response.isSuccess) {
						showToast('Ticket created successfully.');
					} else {
						showToast(response.error?.userMessage || 'Something went wrong ', { type: 'error' });
					}
				}
			})
			.catch((error) => {
				showToast(error.message, { type: 'error' });
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileList = event.target.files;
		if (fileList) {
			const allowedFiles = Array.from(fileList).filter(
				(file) => file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg',
			);
			setFiles((prevFiles) => [...prevFiles, ...allowedFiles]);
		}
	};

	// function to remove a selected file by index
	const handleRemoveFile = (index: number) => {
		setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	};

	return (
		<div className="support-page">
			<h3>Need assistance? We're here to help!</h3>
			<div className="accordians">
				<div className="left-accordians">
					<Accordion>
						{accordionData.map((item, index) => (
							<Accordion.Item eventKey={index.toString()} key={index}>
								<Accordion.Header>{item.title}</Accordion.Header>
								<Accordion.Body>{item.content}</Accordion.Body>
							</Accordion.Item>
						))}
					</Accordion>
				</div>
				<div className="right-accordians">
					<Accordion>
						{accordionData2.map((item, index) => (
							<Accordion.Item eventKey={index.toString()} key={index}>
								<Accordion.Header>{item.title}</Accordion.Header>
								<Accordion.Body>{item.content}</Accordion.Body>
							</Accordion.Item>
						))}
					</Accordion>
				</div>
			</div>
			<div className="form-support">
				<h3>Get in touch with our support team</h3>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values, formikHelpers) => {
						handleSubmitClick(values);
						formikHelpers.resetForm();
						setFiles([]);
					}}
				>
					{({ values, errors, setFieldValue, setFieldTouched, touched, isValid, dirty }) => (
						<Form>
							<div className="row">
								<div className="col-md-6">
									<div className="form-box">
										<div className="input-field">
											<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M6 7C7.89375 7 9.42857 5.4332 9.42857 3.5C9.42857 1.5668 7.89375 0 6 0C4.10625 0 2.57143 1.5668 2.57143 3.5C2.57143 5.4332 4.10625 7 6 7ZM8.4 7.875H7.95268C7.35804 8.15391 6.69643 8.3125 6 8.3125C5.30357 8.3125 4.64464 8.15391 4.04732 7.875H3.6C1.6125 7.875 0 9.52109 0 11.55V11.6106C0 12.3352 0.575893 12.9231 1.28571 12.9231H10.7143C11.4241 12.9231 12 12.3352 12 11.6106V11.55C12 9.52109 10.3875 7.875 8.4 7.875Z"
													fill="#C1C1C1"
												/>
											</svg>
											<Field
												onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
													if (e.key === ' ') {
														e.preventDefault(); // Prevent space key
													}
												}}
												maxLength={50}
												name="Contact.FirstName"
												type="text"
												placeholder=""
												className={values && values.Contact.FirstName && 'input-value'}
											/>
											{/* <Input required={true} type="text" onChange={(e) => console.log(e)} _key={'companyName'} name={'companyName'} /> */}
											<label>First name*</label>
										</div>
										{errors.Contact?.FirstName && touched.Contact?.FirstName ? <div className="error">{errors.Contact.FirstName}</div> : null}
									</div>
								</div>

								<div className="col-md-6">
									<div className="form-box">
										<div className="input-field">
											<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M6 7C7.89375 7 9.42857 5.4332 9.42857 3.5C9.42857 1.5668 7.89375 0 6 0C4.10625 0 2.57143 1.5668 2.57143 3.5C2.57143 5.4332 4.10625 7 6 7ZM8.4 7.875H7.95268C7.35804 8.15391 6.69643 8.3125 6 8.3125C5.30357 8.3125 4.64464 8.15391 4.04732 7.875H3.6C1.6125 7.875 0 9.52109 0 11.55V11.6106C0 12.3352 0.575893 12.9231 1.28571 12.9231H10.7143C11.4241 12.9231 12 12.3352 12 11.6106V11.55C12 9.52109 10.3875 7.875 8.4 7.875Z"
													fill="#C1C1C1"
												/>
											</svg>
											<Field
												onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
													if (e.key === ' ') {
														e.preventDefault(); // Prevent space key
													}
												}}
												maxLength={50}
												name="Contact.LastName"
												type="text"
												placeholder=""
												className={values && values.Contact.LastName && 'input-value'}
											/>
											{/* <Input required={true} type="text" onChange={(e) => console.log(e)} _key={'companyName'} name={'companyName'} /> */}
											<label>Last name*</label>
										</div>
										{errors.Contact?.LastName && touched.Contact?.LastName ? <div className="error">{errors.Contact.LastName}</div> : null}
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6">
									<div className="form-box">
										<div className="input-field">
											{/* <Field name="phoneNumber" type="text" placeholder="" className={values && values.phoneNumber && 'input-value'} /> */}
											<PhoneInputComponent
												country={countryCodes || 'in'}
												value={values.PhoneNumber}
												onChange={(phone) => setFieldValue('PhoneNumber', phone)}
												onFocus={(e) => setFieldTouched('PhoneNumber', true)}
												inputProps={{ name: 'PhoneNumber', required: true, placeholder: '' }}
											/>
										</div>
										{errors.PhoneNumber && touched.PhoneNumber ? <div className="error">{errors.PhoneNumber}</div> : null}
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-box">
										<div className="input-field">
											<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clipPath="url(#clip0_853_5911)">
													<path
														d="M13 11.7814C13 12.4542 12.4541 13.0001 11.7812 13.0001H1.21875C0.545898 13.0001 4.629e-08 12.4542 4.629e-08 11.7814V5.096C-5.09215e-05 4.91106 0.0419874 4.72854 0.122931 4.56225C0.203874 4.39596 0.321599 4.25028 0.467188 4.13623C1.09941 3.64112 1.62246 3.23741 4.63633 1.05127C5.06289 0.741508 5.91094 -0.00751527 6.5 0.000101922C7.08906 -0.0100543 7.93711 0.741508 8.36367 1.05127C11.3775 3.23741 11.9006 3.64112 12.5328 4.13623C12.6784 4.25028 12.7961 4.39596 12.8771 4.56225C12.958 4.72854 13.0001 4.91106 13 5.096V11.7814ZM11.3318 6.78956C11.2658 6.69561 11.1363 6.67276 11.0449 6.74131C10.466 7.17041 9.63574 7.77471 8.36367 8.69893C7.93711 9.01124 7.08906 9.76026 6.5 9.7501C5.91094 9.75772 5.06289 9.01124 4.63633 8.69893C3.36426 7.77471 2.53652 7.17041 1.95508 6.74131C1.86367 6.67276 1.73164 6.69561 1.66816 6.78956L1.43711 7.12471C1.4069 7.16807 1.39478 7.22148 1.40331 7.27363C1.41185 7.32577 1.44036 7.37254 1.48281 7.40401C2.06426 7.83311 2.89199 8.43741 4.15645 9.35655C4.67188 9.73233 5.59102 10.5702 6.5 10.5651C7.40645 10.5702 8.32812 9.73233 8.84355 9.35655C10.1105 8.43741 10.9357 7.83311 11.5172 7.40401C11.5596 7.37254 11.5882 7.32577 11.5967 7.27363C11.6052 7.22148 11.5931 7.16807 11.5629 7.12471L11.3318 6.78956Z"
														fill="#C1C1C1"
													/>
												</g>
												<defs>
													<clipPath id="clip0_853_5911">
														<rect width="13" height="13" fill="white" />
													</clipPath>
												</defs>
											</svg>
											<Field name="Email" type="email" placeholder="" className={values && values.Email && 'input-value'} />
											{/* <Input required={true} type="text" onChange={(e) => console.log(e)} _key={'companyName'} name={'companyName'} /> */}
											<label>Email*</label>
										</div>
										{errors.Email && touched.Email ? <div className="error">{errors.Email}</div> : null}
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="form-box">
										<div className="input-field">
											<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M12.8332 11.6663V12.833H1.1665V11.6663H12.8332ZM6.25296 3.95487L8.87796 6.57985L4.95817 10.4997H2.33317V7.87467L6.25296 3.95487ZM8.74984 1.45801L11.3748 4.08301L9.4967 5.96114L6.8717 3.33614L8.74984 1.45801Z"
													fill="#C1C1C1"
												/>
											</svg>
											<Field maxLength={200} name="Subject" type="text" placeholder="" className={values && values.Subject && 'input-value'} />
											{/* <Input required={true} type="text" onChange={(e) => console.log(e)} _key={'companyName'} name={'companyName'} /> */}
											<label>Subject*</label>
										</div>
										{errors.Subject && touched.Subject ? <div className="error">{errors.Subject}</div> : null}
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-2 offset-10 right-attach-file">
									<i>Max : 200 characters </i>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="form-box">
										<div className="input-field textarea-field">
											<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M0 2.05263C0 1.50824 0.221249 0.986145 0.615076 0.601202C1.0089 0.216259 1.54305 0 2.1 0H11.9C12.457 0 12.9911 0.216259 13.3849 0.601202C13.7788 0.986145 14 1.50824 14 2.05263V8.89474C14 9.43913 13.7788 9.96122 13.3849 10.3462C12.9911 10.7311 12.457 10.9474 11.9 10.9474H3.9669C3.81544 10.9474 3.66807 10.9954 3.5469 11.0842L1.12 12.8632C1.016 12.9394 0.892337 12.9858 0.762864 12.9972C0.633391 13.0086 0.503224 12.9846 0.38695 12.9278C0.270676 12.8709 0.172889 12.7836 0.104544 12.6755C0.0362003 12.5674 0 12.4429 0 12.3158V2.05263ZM3.5 2.05263C3.31435 2.05263 3.1363 2.12472 3.00503 2.25303C2.87375 2.38135 2.8 2.55538 2.8 2.73684C2.8 2.91831 2.87375 3.09234 3.00503 3.22065C3.1363 3.34897 3.31435 3.42105 3.5 3.42105H10.5C10.6857 3.42105 10.8637 3.34897 10.995 3.22065C11.1263 3.09234 11.2 2.91831 11.2 2.73684C11.2 2.55538 11.1263 2.38135 10.995 2.25303C10.8637 2.12472 10.6857 2.05263 10.5 2.05263H3.5ZM3.5 4.78947C3.31435 4.78947 3.1363 4.86156 3.00503 4.98987C2.87375 5.11819 2.8 5.29222 2.8 5.47368C2.8 5.65515 2.87375 5.82918 3.00503 5.95749C3.1363 6.08581 3.31435 6.15789 3.5 6.15789H10.5C10.6857 6.15789 10.8637 6.08581 10.995 5.95749C11.1263 5.82918 11.2 5.65515 11.2 5.47368C11.2 5.29222 11.1263 5.11819 10.995 4.98987C10.8637 4.86156 10.6857 4.78947 10.5 4.78947H3.5ZM3.5 7.52632C3.31435 7.52632 3.1363 7.5984 3.00503 7.72672C2.87375 7.85503 2.8 8.02906 2.8 8.21053C2.8 8.39199 2.87375 8.56602 3.00503 8.69434C3.1363 8.82265 3.31435 8.89474 3.5 8.89474H6.3C6.48565 8.89474 6.6637 8.82265 6.79497 8.69434C6.92625 8.56602 7 8.39199 7 8.21053C7 8.02906 6.92625 7.85503 6.79497 7.72672C6.6637 7.5984 6.48565 7.52632 6.3 7.52632H3.5Z"
													fill="#C1C1C1"
												/>
											</svg>

											<Field
												maxLength={400}
												as="textarea"
												name="Description"
												placeholder=""
												className={values && values.Description && 'input-value'}
											/>
											<label>Description*</label>
										</div>
										{errors.Description && touched.Description ? <div className="error">{errors.Description}</div> : null}
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="attach-file">
										<div className="left-attach-file">
											<label>
												{/* Replace Field with input for file upload */}
												<input
													name="Files"
													type="file"
													className={values && values.Files ? 'input-value' : ''}
													multiple
													onChange={handleFileSelect}
													accept=".jpg, .jpeg, .png"
												/>
												<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M11.363 7.48608L7.08706 12.053C6.72441 12.4583 6.20819 12.6926 5.66428 12.6989C5.12042 12.7053 4.59888 12.4829 4.22683 12.0862C3.40495 11.2284 3.41875 9.87113 4.25791 9.03018L10.0009 2.88914C10.6345 2.18396 11.5361 1.77877 12.4841 1.77326C13.432 1.76775 14.3383 2.16244 14.9799 2.86021C16.3486 4.37255 16.3389 6.67867 14.9574 8.17937L8.86817 14.6847C8.00162 15.5545 6.8154 16.0296 5.58794 15.9986C4.36048 15.9676 3.19979 15.4332 2.37821 14.5208C0.500282 12.4028 0.546739 9.20259 2.48537 7.13997L8.23696 1"
														stroke="black"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
												Attach files
											</label>
										</div>
										<div className="right-attach-file">
											<p>Max : 400 characters </p>
											<div className="format">
												<label>Formats</label>
												<span>JPG</span>
												<span>PNG</span>
											</div>
										</div>
									</div>
								</div>
								<div className="file-preview">
									{files.map((file, index) => (
										<div className="file-thumbnail" key={index}>
											<Image src={URL.createObjectURL(file)} alt={`Selected ${index + 1}`} className="thumbnail-image" />
											<span className="remove-icon" onClick={() => handleRemoveFile(index)}>
												×
											</span>
										</div>
									))}
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="button-box">
										<Button disabled={loading || !(isValid && dirty)} id="contact_us" label="Submit Now" type="submit" className="cmn-btn" />
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default Support;

import * as handler from './Handler';
import { ApiEndpoints } from '../Endpoints';
import { StreamefyMediaPlay } from '../../types/models/StreamefyMedia';

/**
 * Fetches the signed URL data required to generate an iframe URL for a specific video.
 *
 * @param {string} videoId - The unique identifier of the video to fetch the signed URL data.
 * @returns {Promise<SignedUrlResponse>} - A promise that resolves to the signed URL data response.
 * @throws {Error} - Throws an error if the fetch operation fails.
 */
export const fetchStreamefyMedia = async (videoId: string) => {
	try {
		// Fetch the signed URL data from the API
		return await handler.get<StreamefyMediaPlay>(ApiEndpoints.GetMediaHlsUrl, { videoId });
	} catch (error) {
		console.error('Error fetching signed URL:', error);
		// Re-throw the error to be handled by the calling code
		throw error;
	}
};

import { BackgroundMediaStatus, EventMediaStatus } from '../../types/type/UploadingStatus';

//Method to take the current progress data and updates the state by merging it with the existing progress values, using mediaId as the key..
export const updateMediaProgress = <T extends { mediaId: string | number }>(
	progressData: T,
	setProgressValues: React.Dispatch<React.SetStateAction<Record<string | number, T>>>,
) => {
	setProgressValues((prev) => {
		const updatedProgressValues = { ...prev };
		updatedProgressValues[progressData.mediaId] = progressData;
		return updatedProgressValues;
	});
};

// Method to get the Active Project Ids from the media status event emitter progress values of background & event media..
export const getActiveProjectIds = (
	eventMediaProgressValues: Record<number, EventMediaStatus>,
	backgroundMediaProgressValues: Record<number, BackgroundMediaStatus>,
): Set<number> => {
	const allProgressValues = [...Object.values(eventMediaProgressValues), ...Object.values(backgroundMediaProgressValues)];

	// Filter and collect unique project IDs for media still uploading
	return new Set(
		allProgressValues
			.filter(
				(media) =>
					!(
						media.progress === 100 &&
						(media.message === 'Event Media Uploaded successfully' || media.message === 'Background Media Uploaded successfully')
					),
			)
			.map((media) => media.projectId),
	);
};

// State to hold media progress
const uploadState: {
	eventMedia: Record<number, EventMediaStatus>;
	backgroundMedia: Record<number, BackgroundMediaStatus>;
} = {
	eventMedia: {},
	backgroundMedia: {},
};

/**
 * Update or add progress for Event Media
 */
export const updateEventMediaProgress = (mediaId: number, progressData: EventMediaStatus): void => {
	uploadState.eventMedia[mediaId] = progressData;
};

/**
 * Update or add progress for Background Media
 */
export const updateBackgroundMediaProgress = (mediaId: number, progressData: BackgroundMediaStatus): void => {
	uploadState.backgroundMedia[mediaId] = progressData;
};

/**
 * Get all Event Media progress
 */
export const getEventMediaProgress = (): Record<number, EventMediaStatus> => {
	return uploadState.eventMedia;
};

/**
 * Get all Background Media progress
 */
export const getBackgroundMediaProgress = (): Record<number, BackgroundMediaStatus> => {
	return uploadState.backgroundMedia;
};
/**
 * Clear progress for a specific media
 */
export const clearMediaProgress = (type: 'event' | 'background', projectId?: number): void => {
	if (type === 'event') {
		if (projectId !== undefined) {
			// Filter out media entries for the specified projectId
			Object.keys(uploadState.eventMedia).forEach((key) => {
				const mediaId = Number(key);
				if (uploadState.eventMedia[mediaId]?.projectId === projectId) {
					delete uploadState.eventMedia[mediaId];
				}
			});
		} else {
			// Clear all event media
			uploadState.eventMedia = {};
		}
	} else if (type === 'background') {
		if (projectId !== undefined) {
			// Filter out media entries for the specified projectId
			Object.keys(uploadState.backgroundMedia).forEach((key) => {
				const mediaId = Number(key);
				if (uploadState.backgroundMedia[mediaId]?.projectId === projectId) {
					delete uploadState.backgroundMedia[mediaId];
				}
			});
		} else {
			// Clear all background media
			uploadState.backgroundMedia = {};
		}
	}
};

import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { RegisterModel } from '../../types/models/RegisterModel';
import Button from '../button/Index';
import { App_Urls } from '../../routes/AppUrls';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../helpers/api/Index';
import IAPIResponseModel from '../../types/interfaces/IAPIResponseModel';
import { showToast } from '../toaster/Index';
import PhoneInputComponent from '../phoneNumber/Index';
import 'react-phone-input-2/lib/style.css';
import Loader from '../loader/Index';
import { validatePhoneNumber } from '../../helpers/Utils';

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
	firstName: Yup.string()
		.min(2, 'First Name must be at least 2 characters')
		//.max(50, 'First Name must be no more than 50 characters')
		//.matches(/^[A-Za-z\s]+$/, 'First Name field must not include numeric characters.')
		.required('First Name is required'),
	lastName: Yup.string()
		.min(2, 'Last Name must be at least 2 characters')
		//.max(50, 'Last Name must be no more than 50 characters')
		//.matches(/^[A-Za-z\s]+$/, 'First Name field must not include numeric characters.')
		.required('Last Name is required'),
	phoneNumber: Yup.string()
		.required('Phone number is required')
		.test('is-valid-phone', 'Invalid phone format', function (value) {
			return validatePhoneNumber(value);
		}),
	email: Yup.string()
		.email('Invalid email address')
		.required('Email address is required')
		.matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email format'),
	password: Yup.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters long')
		.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
		.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
		.matches(/[0-9]/, 'Password must contain at least one number')
		.matches(/[\W_]/, 'Password must contain at least one special character'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password')], 'Passwords must match')
		.required('Confirm password is required'),
	TermsAndConditions: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions'),
});

const initialValues: RegisterModel = {
	firstName: '',
	lastName: '',
	phoneNumber: '',
	email: '',
	password: '',
	confirmPassword: '',
	TermsAndConditions: false,
};

interface RegisterProps {
	tabId: number;
}

const Register: React.FC<RegisterProps> = ({ tabId }) => {
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	//const [countryCode, setCountryCode] = useState<string | null>(null);
	const [countryCodes] = useState<string | null>(localStorage.getItem('countryCode') || 'in'); // Set country code from local storage
	/**
	 * Handles the submit click event for the registration form.
	 * Calls the ApiService.Register method with the provided values,
	 * and navigates to the OTP page if the registration is successful.
	 * Displays an error toast message if the registration fails.
	 *
	 * @param {RegisterModel} values - The registration form values.
	 */
	const handleSubmitClick = (values: RegisterModel) => {
		setLoading(true);
		ApiService.Register(values)
			.then((response: IAPIResponseModel<string>) => {
				if (response && response.isSuccess) {
					navigate(App_Urls.Otp, { state: { email: values.email, phoneNumber: values.phoneNumber, password: values.password } });
				} else {
					showToast(response.error?.userMessage || 'Something went wrong', { type: 'error' });
				}
			})
			.catch((error) => {
				showToast(error?.message, { type: 'error' });
			})
			.finally(() => {
				setLoading(false);
			});
	};
	// Passowrd show and Hide
	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	// Passowrd show and Hide
	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	return (
		<>
			{loading && <Loader customMessage="Finalizing your registration, please wait..." />}
			<div className={`tab-pane fade ${tabId === 0 ? 'show active' : null}`} id="signup" role="tabpanel" aria-labelledby="signup-tab">
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => {
						handleSubmitClick(values);
					}}
				>
					{({ values, errors, touched, setFieldValue, setFieldTouched, isValid, dirty }) => (
						<Form>
							<div className="signup-login-form">
								<div className="row">
									<div className="col-md-6">
										<div className="form-box">
											<div className="input-field">
												<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M6 7C7.89375 7 9.42857 5.4332 9.42857 3.5C9.42857 1.5668 7.89375 0 6 0C4.10625 0 2.57143 1.5668 2.57143 3.5C2.57143 5.4332 4.10625 7 6 7ZM8.4 7.875H7.95268C7.35804 8.15391 6.69643 8.3125 6 8.3125C5.30357 8.3125 4.64464 8.15391 4.04732 7.875H3.6C1.6125 7.875 0 9.52109 0 11.55V11.6106C0 12.3352 0.575893 12.9231 1.28571 12.9231H10.7143C11.4241 12.9231 12 12.3352 12 11.6106V11.55C12 9.52109 10.3875 7.875 8.4 7.875Z"
														fill="#C1C1C1"
													/>
												</svg>
												<Field
													onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
														if (e.key === ' ') {
															e.preventDefault(); // Prevent space key
														}
													}}
													name="firstName"
													type="text"
													placeholder=""
													className={values && values.firstName && 'input-value'}
													maxLength={50}
												/>
												<label>
													First name <span style={{ color: 'red' }}>*</span>
												</label>
											</div>
											{errors.firstName && touched.firstName ? <div className="error">{errors.firstName}</div> : null}
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-box">
											<div className="input-field">
												<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M6 7C7.89375 7 9.42857 5.4332 9.42857 3.5C9.42857 1.5668 7.89375 0 6 0C4.10625 0 2.57143 1.5668 2.57143 3.5C2.57143 5.4332 4.10625 7 6 7ZM8.4 7.875H7.95268C7.35804 8.15391 6.69643 8.3125 6 8.3125C5.30357 8.3125 4.64464 8.15391 4.04732 7.875H3.6C1.6125 7.875 0 9.52109 0 11.55V11.6106C0 12.3352 0.575893 12.9231 1.28571 12.9231H10.7143C11.4241 12.9231 12 12.3352 12 11.6106V11.55C12 9.52109 10.3875 7.875 8.4 7.875Z"
														fill="#C1C1C1"
													/>
												</svg>
												<Field
													onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
														if (e.key === ' ') {
															e.preventDefault(); // Prevent space key
														}
													}}
													name="lastName"
													type="text"
													placeholder=""
													className={values && values.lastName && 'input-value'}
													maxLength={50}
												/>
												<label>
													Last name <span style={{ color: 'red' }}>*</span>
												</label>
											</div>
											{errors.lastName && touched.lastName ? <div className="error">{errors.lastName}</div> : null}
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-box">
											<div className="input-field">
												{/* <Field name="phoneNumber" type="text" placeholder="" className={values && values.phoneNumber && 'input-value'} /> */}
												<PhoneInputComponent
													country={countryCodes || 'in'}
													value={values.phoneNumber}
													onFocus={(e) => setFieldTouched('phoneNumber', true)}
													onChange={(phone) => setFieldValue('phoneNumber', phone)}
													inputProps={{ name: 'phoneNumber', required: true, placeholder: '' }} /*onChange={phone => this.setState({ phone })}*/
												/>
												<label className={touched.phoneNumber ? 'phoneInputComponent_focus cstm_whtp_fcs' : 'phoneInputComponent'}>
													{touched.phoneNumber && (
														<span className="whtsap_icon_cstm ">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																xmlnsXlink="http://www.w3.org/1999/xlink"
																height="800px"
																width="800px"
																version="1.1"
																id="Capa_1"
																viewBox="0 0 58 58"
																xmlSpace="preserve"
															>
																<g>
																	<path
																		style={{ fill: 'green' }}
																		d="M0,58l4.988-14.963C2.457,38.78,1,33.812,1,28.5C1,12.76,13.76,0,29.5,0S58,12.76,58,28.5   S45.24,57,29.5,57c-4.789,0-9.299-1.187-13.26-3.273L0,58z"
																	></path>
																	<path
																		style={{ fill: 'white' }}
																		d="M47.683,37.985c-1.316-2.487-6.169-5.331-6.169-5.331c-1.098-0.626-2.423-0.696-3.049,0.42   c0,0-1.577,1.891-1.978,2.163c-1.832,1.241-3.529,1.193-5.242-0.52l-3.981-3.981l-3.981-3.981c-1.713-1.713-1.761-3.41-0.52-5.242   c0.272-0.401,2.163-1.978,2.163-1.978c1.116-0.627,1.046-1.951,0.42-3.049c0,0-2.844-4.853-5.331-6.169   c-1.058-0.56-2.357-0.364-3.203,0.482l-1.758,1.758c-5.577,5.577-2.831,11.873,2.746,17.45l5.097,5.097l5.097,5.097   c5.577,5.577,11.873,8.323,17.45,2.746l1.758-1.758C48.048,40.341,48.243,39.042,47.683,37.985z"
																	></path>
																</g>
															</svg>
														</span>
													)}
													Phone number <span style={{ color: 'red' }}>*</span>
												</label>
											</div>
											{errors.phoneNumber && touched.phoneNumber ? <div className="error">{errors.phoneNumber}</div> : null}
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-box">
											<div className="input-field">
												<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clipPath="url(#clip0_853_5911)">
														<path
															d="M13 11.7814C13 12.4542 12.4541 13.0001 11.7812 13.0001H1.21875C0.545898 13.0001 4.629e-08 12.4542 4.629e-08 11.7814V5.096C-5.09215e-05 4.91106 0.0419874 4.72854 0.122931 4.56225C0.203874 4.39596 0.321599 4.25028 0.467188 4.13623C1.09941 3.64112 1.62246 3.23741 4.63633 1.05127C5.06289 0.741508 5.91094 -0.00751527 6.5 0.000101922C7.08906 -0.0100543 7.93711 0.741508 8.36367 1.05127C11.3775 3.23741 11.9006 3.64112 12.5328 4.13623C12.6784 4.25028 12.7961 4.39596 12.8771 4.56225C12.958 4.72854 13.0001 4.91106 13 5.096V11.7814ZM11.3318 6.78956C11.2658 6.69561 11.1363 6.67276 11.0449 6.74131C10.466 7.17041 9.63574 7.77471 8.36367 8.69893C7.93711 9.01124 7.08906 9.76026 6.5 9.7501C5.91094 9.75772 5.06289 9.01124 4.63633 8.69893C3.36426 7.77471 2.53652 7.17041 1.95508 6.74131C1.86367 6.67276 1.73164 6.69561 1.66816 6.78956L1.43711 7.12471C1.4069 7.16807 1.39478 7.22148 1.40331 7.27363C1.41185 7.32577 1.44036 7.37254 1.48281 7.40401C2.06426 7.83311 2.89199 8.43741 4.15645 9.35655C4.67188 9.73233 5.59102 10.5702 6.5 10.5651C7.40645 10.5702 8.32812 9.73233 8.84355 9.35655C10.1105 8.43741 10.9357 7.83311 11.5172 7.40401C11.5596 7.37254 11.5882 7.32577 11.5967 7.27363C11.6052 7.22148 11.5931 7.16807 11.5629 7.12471L11.3318 6.78956Z"
															fill="#C1C1C1"
														/>
													</g>
													<defs>
														<clipPath id="clip0_853_5911">
															<rect width="13" height="13" fill="white" />
														</clipPath>
													</defs>
												</svg>
												<Field name="email" type="email" placeholder="" className={values && values.email && 'input-value'} />
												<label>
													Email address <span style={{ color: 'red' }}>*</span>
												</label>
											</div>
											{errors.email && touched.email ? <div className="error">{errors.email}</div> : null}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-box">
											<div className="input-field">
												<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clipPath="url(#clip0_853_5915)">
														<path
															d="M10.7143 5.6875H10.0714V3.85938C10.0714 1.73164 8.24464 0 6 0C3.75536 0 1.92857 1.73164 1.92857 3.85938V5.6875H1.28571C0.575893 5.6875 0 6.2334 0 6.90625V11.7812C0 12.4541 0.575893 13 1.28571 13H10.7143C11.4241 13 12 12.4541 12 11.7812V6.90625C12 6.2334 11.4241 5.6875 10.7143 5.6875ZM7.92857 5.6875H4.07143V3.85938C4.07143 2.85137 4.93661 2.03125 6 2.03125C7.06339 2.03125 7.92857 2.85137 7.92857 3.85938V5.6875Z"
															fill="#C1C1C1"
														/>
													</g>
													<defs>
														<clipPath id="clip0_853_5915">
															<rect width="12" height="13" fill="white" />
														</clipPath>
													</defs>
												</svg>
												<Field
													autoComplete="new-password"
													name="password"
													type={showPassword ? 'text' : 'password'}
													placeholder=""
													className={values && values.password && 'input-value'}
												/>
												<label>
													Password <span style={{ color: 'red' }}>*</span>
												</label>
												<Button
													type="button"
													onClick={togglePasswordVisibility}
													className="password-toggle"
													style={{
														position: 'absolute',
														right: '10px',
														top: '50%',
														transform: 'translateY(-50%)',
														background: 'none',
														border: 'none',
														cursor: 'pointer',
													}}
													id={''}
													label={''}
												>
													{showPassword ? (
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M10 4.37C3.75 4.37 1.25 10 1.25 10C1.25 10 3.75 15.63 10 15.63C16.25 15.63 18.75 10 18.75 10C18.75 10 16.25 4.37 10 4.37Z"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M10 13.13C11.7259 13.13 13.13 11.7259 13.13 10C13.13 8.27411 11.7259 6.87 10 6.87C8.27411 6.87 6.87 8.27411 6.87 10C6.87 11.7259 8.27411 13.13 10 13.13Z"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													) : (
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M12.1083 7.89166L7.89166 12.1083C7.35 11.5667 7.01666 10.825 7.01666 10C7.01666 8.35 8.35 7.01666 10 7.01666C10.825 7.01666 11.5667 7.35 12.1083 7.89166Z"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M14.85 4.80834C13.3917 3.70834 11.725 3.10834 10 3.10834C7.05833 3.10834 4.31666 4.84167 2.40833 7.84167C1.65833 9.01667 1.65833 10.9917 2.40833 12.1667C3.06666 13.2 3.83333 14.0917 4.66666 14.8083"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.01666 16.2750C7.96666 16.6750 8.975 16.8917 10 16.8917C12.9417 16.8917 15.6833 15.1583 17.5917 12.1583C18.3417 10.9833 18.3417 9.00833 17.5917 7.83333C17.3167 7.40000 17.0167 6.99166 16.7083 6.60833"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M12.925 10.5833C12.7083 11.7583 11.75 12.7167 10.575 12.9333"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.89166 12.1083L1.66666 18.3333"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M18.3333 1.66666L12.1083 7.89166"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													)}
												</Button>
											</div>
											{errors.password && touched.password ? <div className="error">{errors.password}</div> : null}
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-box">
											<div className="input-field">
												<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g clipPath="url(#clip0_853_5915)">
														<path
															d="M10.7143 5.6875H10.0714V3.85938C10.0714 1.73164 8.24464 0 6 0C3.75536 0 1.92857 1.73164 1.92857 3.85938V5.6875H1.28571C0.575893 5.6875 0 6.2334 0 6.90625V11.7812C0 12.4541 0.575893 13 1.28571 13H10.7143C11.4241 13 12 12.4541 12 11.7812V6.90625C12 6.2334 11.4241 5.6875 10.7143 5.6875ZM7.92857 5.6875H4.07143V3.85938C4.07143 2.85137 4.93661 2.03125 6 2.03125C7.06339 2.03125 7.92857 2.85137 7.92857 3.85938V5.6875Z"
															fill="#C1C1C1"
														/>
													</g>
													<defs>
														<clipPath id="clip0_853_5915">
															<rect width="12" height="13" fill="white" />
														</clipPath>
													</defs>
												</svg>
												<Field
													name="confirmPassword"
													type={showConfirmPassword ? 'text' : 'password'}
													placeholder=""
													className={values && values.confirmPassword && 'input-value'}
												/>
												<label>
													Confirm Password <span style={{ color: 'red' }}>*</span>
												</label>
												<Button
													type="button"
													onClick={toggleConfirmPasswordVisibility}
													className="password-toggle"
													style={{
														position: 'absolute',
														right: '10px',
														top: '50%',
														transform: 'translateY(-50%)',
														background: 'none',
														border: 'none',
														cursor: 'pointer',
													}}
													id={''}
													label={''}
												>
													{showConfirmPassword ? (
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M10 4.37C3.75 4.37 1.25 10 1.25 10C1.25 10 3.75 15.63 10 15.63C16.25 15.63 18.75 10 18.75 10C18.75 10 16.25 4.37 10 4.37Z"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M10 13.13C11.7259 13.13 13.13 11.7259 13.13 10C13.13 8.27411 11.7259 6.87 10 6.87C8.27411 6.87 6.87 8.27411 6.87 10C6.87 11.7259 8.27411 13.13 10 13.13Z"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													) : (
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M12.1083 7.89166L7.89166 12.1083C7.35 11.5667 7.01666 10.825 7.01666 10C7.01666 8.35 8.35 7.01666 10 7.01666C10.825 7.01666 11.5667 7.35 12.1083 7.89166Z"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M14.85 4.80834C13.3917 3.70834 11.725 3.10834 10 3.10834C7.05833 3.10834 4.31666 4.84167 2.40833 7.84167C1.65833 9.01667 1.65833 10.9917 2.40833 12.1667C3.06666 13.2 3.83333 14.0917 4.66666 14.8083"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.01666 16.2750C7.96666 16.6750 8.975 16.8917 10 16.8917C12.9417 16.8917 15.6833 15.1583 17.5917 12.1583C18.3417 10.9833 18.3417 9.00833 17.5917 7.83333C17.3167 7.40000 17.0167 6.99166 16.7083 6.60833"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M12.925 10.5833C12.7083 11.7583 11.75 12.7167 10.575 12.9333"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M7.89166 12.1083L1.66666 18.3333"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
															<path
																d="M18.3333 1.66666L12.1083 7.89166"
																stroke="#C1C1C1"
																strokeWidth="1.5"
																strokeLinecap="round"
																strokeLinejoin="round"
															/>
														</svg>
													)}
												</Button>
											</div>
											{errors.confirmPassword && touched.confirmPassword ? <div className="error">{errors.confirmPassword}</div> : null}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="input-checkbox">
											<div className="box-check-field">
												<Field name="TermsAndConditions" type="checkbox" />
												<p>I agree to the terms and conditions as set out by the user agreement</p>
											</div>
											{errors.TermsAndConditions && touched.TermsAndConditions ? <div className="error">{errors.TermsAndConditions}</div> : null}
										</div>
									</div>
								</div>
								<div className="button-after-login-screens">
									<div className="login-signup-btn d-flex two_btn_sec">
										<hr />
										<Button id="sign_Up" disabled={loading || !(isValid && dirty)} label="Sign up now" type="submit" className="cmn-btn" />
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
};

export default Register;

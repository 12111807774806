import { IConstants } from '../types/interfaces/IConstants';
import { IMetaDecoratorsConstants } from '../types/interfaces/IMetaDecoratorsConstants';

export const Constants: IConstants = {
	authToken: 'authToken',
	refreshToken: 'refreshToken',
	refreshExpirationTime: 'expiration',
	companyLogo: 'logo',
	companyWaterMark: 'watermark',
	thumbNail: 'thumbNail',
	backgroundMedia: 'backgroundMedia',
	editProjectBasicDetails: 'editProjectBasicDetails',
	editBackgroundMedia: 'editBackGroundMedia',
	editProjectEvent: 'editProjectEvent',
	userProfileImage: 'userProfileImage',
	profileImage: 'profileImage',
	addProjectEvent: 'addProjectEvent',
	rememberMe: 'isRemember',
	isSubscribed: 'isSubscribed',
	ProjAuthState: 'ProjAuthState',
	countryCode: 'countryCode',
};

export const MetaDecoratorsConstants: IMetaDecoratorsConstants = {
	Default_Description: 'Create and deliver projects effortlessly with our all-in-one platform. We make your project easy, from start to finish.',
	Default_Og_Image: '',
};

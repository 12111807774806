import { Link } from 'react-router-dom';
import './transaction.css';
import { ChangeEvent, useEffect, useState } from 'react';
import { Pagination, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ApiService from '../../helpers/api/Index';
import { downloadPdf } from '../../helpers/Utils';
import { TransactionsResponse, TransactionPayload } from '../../types/models/Transactions';
import Input from '../input/Index';
import Loader from '../loader/Index';
import { showToast } from '../toaster/Index';
const Transaction: React.FC = () => {
	const [pagination, setPagination] = useState<{ currentPage: number; pageSize: number; totalPages: number; totalRecords: number }>({
		currentPage: 1,
		pageSize: 5,
		totalPages: 0,
		totalRecords: 0,
	});
	const [pageSize, setPageSize] = useState(5);
	const [pageNumber, setPageNumber] = useState(1);

	const [transactions, setTransactions] = useState<TransactionsResponse[]>([]);
	const [filteredTransactions, setFilteredTransactions] = useState<TransactionsResponse[]>([]);

	const [dates, setDates] = useState<{ from: Date; to: Date }>({
		from: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Set to current date initially
		to: new Date(), // Set to current date initially
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>('');
	useEffect(() => {
		loadTransactions();
	}, []);
	const handleDateChange = (date: Date | null, key: 'from' | 'to') => {
		// Only update if date is not null
		if (date) {
			setDates((prevDates) => ({
				...prevDates,
				[key]: date,
			}));
		}
	};
	// Load Transactions Function
	const loadTransactions = async () => {
		try {
			const { from, to } = dates;
			const payload: TransactionPayload = {
				startDate: from.toISOString(), // Convert from date to ISO string
				endDate: to.toISOString(), // Convert to date to ISO string
				pageNumber, // Current page number
				pageSize, // Number of items per page
				searchTerm: searchValue,
			};
			setIsLoading(true);

			const { isSuccess, response } = await ApiService.LoadTransactions(payload);

			if (isSuccess) {
				setTransactions(response.items ?? []);
				setPagination((prev) => ({
					...prev,
					totalPages: response.totalPages,
					currentPage: response.currentPage,
					pageSize: response.pageSize,
					totalRecords: response.totalRecords,
				}));
			} else {
				setTransactions([]);
				setPagination((prev) => ({
					...prev,
					totalPages: 0, // default value
					currentPage: 1, // default value
					totalRecords: 0, // default value
				}));
			}
		} catch (error: any) {
			const errorMessage = error?.message ?? 'Failed to load data';
			showToast(errorMessage, { type: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const downloadInvoice = async (invoiceId: string, invoiceName: string) => {
		try {
			let invoiceData = await ApiService.DownloadInvoiceData(invoiceId);
			if (invoiceData && invoiceData.isSuccess && invoiceData.response) {
				downloadPdf(invoiceData?.response, invoiceName);
			} else showToast(invoiceData?.error?.userMessage ?? 'Failed to load invoice data', { type: 'error' });
		} catch (error: any) {
			showToast(error?.message ?? 'Failed to load invoice data', { type: 'error' });
		}
	};
	const handleFilterData = () => {
		let filteredData = transactions;

		// Only filter if searchValue is provided
		if (searchValue) {
			filteredData = filteredData.filter((transactions) =>
				Object.values(transactions).some((value) => String(value).toLowerCase().includes(searchValue.toLowerCase())),
			);
		}

		setFilteredTransactions(filteredData ?? []);
		// Update the records with the filtered data (or all if no searchValue)
	};

	const handlePageChange = (selectedPage: number) => {
		setPageNumber(selectedPage);
	};
	useEffect(() => {
		handleFilterData();
	}, [searchValue, transactions]);
	useEffect(() => {
		loadTransactions();
	}, [dates, pageSize, pageNumber]);

	return (
		<>
			{isLoading && <Loader />}
			<div className="transaction-page">
				<div className="top-filter">
					<div className="left-filter">
						<div className="date-input">
							<DatePicker
								maxDate={new Date()}
								showMonthDropdown // Allows month selection via a dropdown
								showYearDropdown // Allows year selection via a dropdown
								dropdownMode="select" // Display dropdowns for year and month selection
								selected={dates.from}
								onChange={(date: Date | null) => handleDateChange(date, 'from')}
								name="from-date"
								dateFormat="yyyy-MM-dd"
								placeholderText="yyyy-MM-dd"
								toggleCalendarOnIconClick
								showIcon
								icon={
									<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M3.83518 1.1875C3.83518 0.91136 3.61132 0.6875 3.33518 0.6875C3.05904 0.6875 2.83518 0.91136 2.83518 1.1875V2.24034C1.87563 2.31717 1.24569 2.50575 0.782894 2.96855C0.320094 3.43135 0.131521 4.06128 0.0546875 5.02083H13.2823C13.2055 4.06128 13.0169 3.43135 12.5541 2.96855C12.0913 2.50575 11.4614 2.31717 10.5018 2.24034V1.1875C10.5018 0.91136 10.278 0.6875 10.0018 0.6875C9.72571 0.6875 9.50185 0.91136 9.50185 1.1875V2.1961C9.05831 2.1875 8.56118 2.1875 8.00185 2.1875H5.33518C4.77583 2.1875 4.27869 2.1875 3.83518 2.1961V1.1875Z"
											fill="url(#paint0_linear_3056_17796)"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M0 7.52148C0 6.96215 7.57476e-08 6.46502 0.00860008 6.02148H13.3247C13.3333 6.46502 13.3333 6.96215 13.3333 7.52148V8.85482C13.3333 11.369 13.3333 12.6261 12.5523 13.4071C11.7713 14.1882 10.5141 14.1882 8 14.1882H5.33333C2.81917 14.1882 1.5621 14.1882 0.781047 13.4071C7.94729e-08 12.6261 0 11.369 0 8.85482V7.52148ZM10 8.85482C10.3682 8.85482 10.6667 8.55635 10.6667 8.18815C10.6667 7.81995 10.3682 7.52148 10 7.52148C9.6318 7.52148 9.33333 7.81995 9.33333 8.18815C9.33333 8.55635 9.6318 8.85482 10 8.85482ZM10 11.5215C10.3682 11.5215 10.6667 11.223 10.6667 10.8548C10.6667 10.4866 10.3682 10.1882 10 10.1882C9.6318 10.1882 9.33333 10.4866 9.33333 10.8548C9.33333 11.223 9.6318 11.5215 10 11.5215ZM7.33333 8.18815C7.33333 8.55635 7.03487 8.85482 6.66667 8.85482C6.29847 8.85482 6 8.55635 6 8.18815C6 7.81995 6.29847 7.52148 6.66667 7.52148C7.03487 7.52148 7.33333 7.81995 7.33333 8.18815ZM7.33333 10.8548C7.33333 11.223 7.03487 11.5215 6.66667 11.5215C6.29847 11.5215 6 11.223 6 10.8548C6 10.4866 6.29847 10.1882 6.66667 10.1882C7.03487 10.1882 7.33333 10.4866 7.33333 10.8548ZM3.33333 8.85482C3.70152 8.85482 4 8.55635 4 8.18815C4 7.81995 3.70152 7.52148 3.33333 7.52148C2.96515 7.52148 2.66667 7.81995 2.66667 8.18815C2.66667 8.55635 2.96515 8.85482 3.33333 8.85482ZM3.33333 11.5215C3.70152 11.5215 4 11.223 4 10.8548C4 10.4866 3.70152 10.1882 3.33333 10.1882C2.96515 10.1882 2.66667 10.4866 2.66667 10.8548C2.66667 11.223 2.96515 11.5215 3.33333 11.5215Z"
											fill="url(#paint1_linear_3056_17796)"
										/>
										<defs>
											<linearGradient
												id="paint0_linear_3056_17796"
												x1="0.0546875"
												y1="2.85417"
												x2="13.2823"
												y2="2.85417"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<linearGradient id="paint1_linear_3056_17796" x1="0" y1="10.1048" x2="13.3333" y2="10.1048" gradientUnits="userSpaceOnUse">
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
										</defs>
									</svg>
								}
							/>
						</div>
						<span>to</span>
						<div className="date-input">
							<DatePicker
								maxDate={new Date()}
								showMonthDropdown // Allows month selection via a dropdown
								showYearDropdown // Allows year selection via a dropdown
								dropdownMode="select" // Display dropdowns for year and month selection
								selected={dates.to}
								onChange={(date: Date | null) => handleDateChange(date, 'to')}
								name="to-date"
								dateFormat="yyyy-MM-dd"
								placeholderText="yyyy-MM-dd"
								toggleCalendarOnIconClick
								showIcon
								icon={
									<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M3.83518 1.1875C3.83518 0.91136 3.61132 0.6875 3.33518 0.6875C3.05904 0.6875 2.83518 0.91136 2.83518 1.1875V2.24034C1.87563 2.31717 1.24569 2.50575 0.782894 2.96855C0.320094 3.43135 0.131521 4.06128 0.0546875 5.02083H13.2823C13.2055 4.06128 13.0169 3.43135 12.5541 2.96855C12.0913 2.50575 11.4614 2.31717 10.5018 2.24034V1.1875C10.5018 0.91136 10.278 0.6875 10.0018 0.6875C9.72571 0.6875 9.50185 0.91136 9.50185 1.1875V2.1961C9.05831 2.1875 8.56118 2.1875 8.00185 2.1875H5.33518C4.77583 2.1875 4.27869 2.1875 3.83518 2.1961V1.1875Z"
											fill="url(#paint0_linear_3056_17796)"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M0 7.52148C0 6.96215 7.57476e-08 6.46502 0.00860008 6.02148H13.3247C13.3333 6.46502 13.3333 6.96215 13.3333 7.52148V8.85482C13.3333 11.369 13.3333 12.6261 12.5523 13.4071C11.7713 14.1882 10.5141 14.1882 8 14.1882H5.33333C2.81917 14.1882 1.5621 14.1882 0.781047 13.4071C7.94729e-08 12.6261 0 11.369 0 8.85482V7.52148ZM10 8.85482C10.3682 8.85482 10.6667 8.55635 10.6667 8.18815C10.6667 7.81995 10.3682 7.52148 10 7.52148C9.6318 7.52148 9.33333 7.81995 9.33333 8.18815C9.33333 8.55635 9.6318 8.85482 10 8.85482ZM10 11.5215C10.3682 11.5215 10.6667 11.223 10.6667 10.8548C10.6667 10.4866 10.3682 10.1882 10 10.1882C9.6318 10.1882 9.33333 10.4866 9.33333 10.8548C9.33333 11.223 9.6318 11.5215 10 11.5215ZM7.33333 8.18815C7.33333 8.55635 7.03487 8.85482 6.66667 8.85482C6.29847 8.85482 6 8.55635 6 8.18815C6 7.81995 6.29847 7.52148 6.66667 7.52148C7.03487 7.52148 7.33333 7.81995 7.33333 8.18815ZM7.33333 10.8548C7.33333 11.223 7.03487 11.5215 6.66667 11.5215C6.29847 11.5215 6 11.223 6 10.8548C6 10.4866 6.29847 10.1882 6.66667 10.1882C7.03487 10.1882 7.33333 10.4866 7.33333 10.8548ZM3.33333 8.85482C3.70152 8.85482 4 8.55635 4 8.18815C4 7.81995 3.70152 7.52148 3.33333 7.52148C2.96515 7.52148 2.66667 7.81995 2.66667 8.18815C2.66667 8.55635 2.96515 8.85482 3.33333 8.85482ZM3.33333 11.5215C3.70152 11.5215 4 11.223 4 10.8548C4 10.4866 3.70152 10.1882 3.33333 10.1882C2.96515 10.1882 2.66667 10.4866 2.66667 10.8548C2.66667 11.223 2.96515 11.5215 3.33333 11.5215Z"
											fill="url(#paint1_linear_3056_17796)"
										/>
										<defs>
											<linearGradient
												id="paint0_linear_3056_17796"
												x1="0.0546875"
												y1="2.85417"
												x2="13.2823"
												y2="2.85417"
												gradientUnits="userSpaceOnUse"
											>
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
											<linearGradient id="paint1_linear_3056_17796" x1="0" y1="10.1048" x2="13.3333" y2="10.1048" gradientUnits="userSpaceOnUse">
												<stop stopColor="#941AF2" />
												<stop offset="1" stopColor="#EFBD8A" />
											</linearGradient>
										</defs>
									</svg>
								}
							/>
						</div>
					</div>
					<div className="right-filter">
						<div className="search-with-label">
							<label>Search all columns: </label>
							<div className="search-input-box">
								<Input
									type="text"
									onChange={(e: ChangeEvent<HTMLInputElement>) => {
										setSearchValue(e?.target?.value ?? '');
									}}
									_key={'search-input-box'}
									name={'search-input-box'}
								/>
								<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M7.64089 7.63656L11 11M8.77778 4.88889C8.77778 7.03667 7.03667 8.77778 4.88889 8.77778C2.74112 8.77778 1 7.03667 1 4.88889C1 2.74112 2.74112 1 4.88889 1C7.03667 1 8.77778 2.74112 8.77778 4.88889Z"
										stroke="#979797"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</div>
					</div>
				</div>
				<div className="table-transaction">
					<Table responsive>
						<thead>
							<tr>
								<th>Subscription ID</th>
								<th>Plan name</th>
								<th>Purchase date</th>
								<th>Expired on</th>
								<th>Total amount paid</th>
								<th>Download Invoice </th>
							</tr>
						</thead>
						<tbody>
							{Array.isArray(filteredTransactions) && filteredTransactions.length > 0 ? (
								filteredTransactions?.map((transaction, index) => (
									<tr key={'transactions_' + index}>
										<td>{transaction.subscriptionId}</td>
										<td>{transaction.planName}</td>
										<td>{transaction.purchaseDate}</td>
										<td>{transaction.expiredOn}</td>
										<td>INR {transaction.amountPaid}</td>
										<td>
											{transaction.invoiceId !== '0' && (
												<Link to="#" onClick={() => downloadInvoice(transaction.invoiceId, `${transaction.planName}_${transaction.purchaseDate}`)}>
													<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
														<circle cx="15.5" cy="15.5" r="15.5" fill="url(#paint0_linear_3056_17884)" />
														<path
															d="M8 14.2578V20.2794C8 20.7357 8.18126 21.1733 8.50391 21.4959C8.82656 21.8186 9.26416 21.9998 9.72045 21.9998H21.7636C22.2199 21.9998 22.6575 21.8186 22.9802 21.4959C23.3028 21.1733 23.4841 20.7357 23.4841 20.2794V14.2578"
															stroke="white"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M12.2109 14.2588L15.7379 17.6997L19.2648 14.2588"
															stroke="white"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path d="M15.7383 6V15.8926" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
														<defs>
															<linearGradient id="paint0_linear_3056_17884" x1="0" y1="15.5" x2="31" y2="15.5" gradientUnits="userSpaceOnUse">
																<stop stopColor="#941AF2" />
																<stop offset="1" stopColor="#EFBD8A" />
															</linearGradient>
														</defs>
													</svg>
												</Link>
											)}
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={6}>
										<h6>No transactions found</h6>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</div>
				{
					<div className="pagination-container customPagination">
						<Pagination className="justify-content-center">
							<Pagination.First disabled={pagination.currentPage === 1 || pagination.totalPages === 0} onClick={() => handlePageChange(1)} />
							<Pagination.Prev
								disabled={pagination.currentPage === 1 || pagination.totalPages === 0}
								onClick={() => handlePageChange(pagination.currentPage - 1)}
							/>
							<Pagination.Item disabled>{pagination.currentPage || pagination.totalPages === 0}</Pagination.Item>
							<Pagination.Next
								disabled={pagination.currentPage === pagination.totalPages || pagination.totalPages === 0}
								onClick={() => handlePageChange(pagination.currentPage + 1)}
							/>
							<Pagination.Last
								disabled={pagination.currentPage === pagination.totalPages || pagination.totalPages === 0}
								onClick={() => handlePageChange(pagination.totalPages)}
							/>
						</Pagination>

						<select
							onChange={(e) => {
								setPageSize(Number(e.target.value));
							}}
						>
							<option value={5}>5</option>
							<option value={10}>10</option>
							<option value={15}>15</option>
							<option value={20}>20</option>
						</select>
					</div>
				}
			</div>
		</>
	);
};
export default Transaction;

import { IApiEndpoints } from '../types/interfaces/IApiEndpoints';

const auth_endpoint_preffix = '/api/User/';
const oAuth_endpoint_preffix = '/api/OAuth/';
const project_endpoint_prefix = '/api/Project/';
const events_endpoint_prefix = '/api/Event/';
const user_profile_endpoint_prefix = '/api/UserProfile/';
const user_background_media_prefix = 'api/BackgroundMedia/';
const delete_account_prefix = 'api/UserDelete';
const crew_member_endpoint_preffix = '/api/CrewMember/';
const support_endpoint_prefix = '/api/Support/';
const user_event_media_prefix = 'api/Event/';
const iFrame_data_prefix = 'api/StreamefyMedia/';
const auth_profile_prefix = 'api/profile/';
const bunnyVideo_prefix = 'api/BunnyVideo/';
const profile_endpoint_preffix = 'api/Profile/';
const opt_endpoint_preffix = '/api/OTP/';
const transaction_endpoint_preffix = '/api/Transaction/';
const website_endpoint_preffix = '/api/';
const openAi_endpoint_prefix = '/api/OpenAi';
const notificatiob_prefix = 'api/Notification/';

export const ApiEndpoints: IApiEndpoints = {
	//#region Auth endpoints
	Login: auth_endpoint_preffix + 'Login',
	Register: auth_endpoint_preffix + 'Register',
	ForgotPassword: auth_endpoint_preffix + 'ForgotPassword',
	ResetPassword: auth_endpoint_preffix + 'ResetPassword',
	EmailOTPConfirmation: auth_endpoint_preffix + 'EmailOTPConfirmation',
	ResetPasswordOTP: auth_profile_prefix + 'ResetPassword/OTP',
	RequestOTP: auth_endpoint_preffix + 'RequestOTP',
	Transactions: transaction_endpoint_preffix,
	DownloadInvoice: transaction_endpoint_preffix + 'InvoicePDF/',

	RefreshToken: auth_endpoint_preffix + 'RefreshToken',
	Google: oAuth_endpoint_preffix + 'Google',
	Instagram: oAuth_endpoint_preffix + 'Instagram',
	Facebook: oAuth_endpoint_preffix + 'Facebook',
	//#endregion
	//#region Project endpoints
	GetProjects: project_endpoint_prefix,
	GetProjectById: project_endpoint_prefix,
	Project: project_endpoint_prefix,
	DeleteProject: project_endpoint_prefix + 'Delete/',
	PinProject: project_endpoint_prefix + 'Pin',
	Edit: project_endpoint_prefix + 'Edit',
	PinSetup: project_endpoint_prefix + 'PinSetup/',
	ShareProject: project_endpoint_prefix + 'ShareProject',
	ProjectPlay: project_endpoint_prefix + 'Play/',
	ReencodeProjectVideos: project_endpoint_prefix + 'ReencodeProjectVideos/',
	CheckSmartRevisonVideos: project_endpoint_prefix + 'CheckSmartRevisonVideos/',
	UpdateThumbnail: project_endpoint_prefix + 'UpdateThumbnail',
	UpdateUsedData: project_endpoint_prefix + 'UpdateUsedData/',

	Event: events_endpoint_prefix,
	PinAuthentication: project_endpoint_prefix + 'Pin/Authentication',
	//#endregion
	//#region UserProfile endpoint
	userProfile: user_profile_endpoint_prefix,
	myProfile: user_profile_endpoint_prefix,
	EditMyProfile: user_profile_endpoint_prefix + 'Edit',
	//#endregion
	//#region backgroundmedia
	BackgroundMedia: user_background_media_prefix,
	DeleteBackgroundMediaByID: user_background_media_prefix,
	//#endregion
	//#region crewMember endpoint
	CrewMember: crew_member_endpoint_preffix,

	//#region support
	SupportTicket: support_endpoint_prefix + 'GenerateTicket',
	//#endregion
	//#region support
	Subscription: user_profile_endpoint_prefix + 'UserPlan',
	GetSubscriptionLink: user_profile_endpoint_prefix + 'GetSubscriptionLink/',
	IsSubscribed: auth_endpoint_preffix + 'IsSubscribed',
	//#endregion
	//#endregion
	ProjectSmartRevision: project_endpoint_prefix + 'SmartRevision',
	//#region Eventmedia
	EventMedia: user_event_media_prefix,
	Descriptions: user_event_media_prefix + 'Description',
	DeleteMediaByID: user_event_media_prefix + 'Media',
	DeleteEventWithMediaById: user_event_media_prefix,
	//#endregion
	//#region Generate IFrame Signed URL
	GetMediaHlsUrl: iFrame_data_prefix,
	//#endregion
	//#region After Background media upload
	AfterBackgroundMediaUpload: user_background_media_prefix + 'UploadFailed',
	//#endregion
	//#region After Background media upload
	AfterEventMediaUpload: user_event_media_prefix + 'UploadFailed',
	GetAllNotifications: notificatiob_prefix,
	DeleteAllNotifications: notificatiob_prefix + 'Delete',
	AddNotification: notificatiob_prefix,

	downloadMedia: bunnyVideo_prefix + 'download',
	transcoding: bunnyVideo_prefix + 'EncodeProgressByProjectId/',

	ResetPasswordFromProfile: profile_endpoint_preffix + 'ResetPassword',
	ResetOTP: profile_endpoint_preffix + 'ResetPassword/OTP',

	Playback: bunnyVideo_prefix + 'Playback',
	GetProjectMediaDetails: project_endpoint_prefix + 'GetProjectMediaDetails/',

	// Added to send and verify the OTP during project authentication and authorization.
	//#region
	OTP: opt_endpoint_preffix,
	OTP_VERIFY: opt_endpoint_preffix + 'Verify',
	//#endregion
	GetnameIdDetails: project_endpoint_prefix + 'GetnameIdDetails/',
	PrimaryUserProjects: project_endpoint_prefix + 'Web/PrimaryUser/Projects',
	CancelUserPlan: user_profile_endpoint_prefix + 'CancelUserPlan',
	GetPlans: website_endpoint_preffix + 'Plans',

	PrimaryUserResetPin: project_endpoint_prefix + 'PrimaryUser/ResetPin',

	OpenAi: openAi_endpoint_prefix,
	DataTransactionLogs: user_profile_endpoint_prefix + 'DataTransactionLogs',
	DeleteAccount: delete_account_prefix,
};

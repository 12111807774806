import { TextareaProps } from '../../types/type/TextareaProps';
import React, { ForwardedRef } from 'react';
const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
	(
		{ id, name, value, placeholder, rows = 4, cols = 50, maxLength, className, style, onChange, disabled, onFocus, onBlur, onKeyDown },
		ref: ForwardedRef<HTMLTextAreaElement>,
	) => {
		const inputClassName = `${className ? className : ''} ${value ? 'input-value' : ''}`?.trim(); // add 'has-value' class if value is present

		return (
			<textarea
				id={id}
				name={name}
				value={value}
				placeholder={placeholder}
				rows={rows}
				cols={cols}
				maxLength={maxLength}
				className={inputClassName}
				style={style}
				onChange={onChange}
				disabled={disabled} // Added as optional
				onFocus={onFocus}
				onBlur={onBlur}
				onKeyDown={onKeyDown}
				ref={ref}
			/>
		);
	},
);
export default Textarea;

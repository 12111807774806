import PhoneInput from 'react-phone-input-2';
import './phoneNumber.css';

const PhoneInputComponent: React.FC<MyComponentProps> = ({
	country,
	value,
	inputProps,
	onBlur,
	onChange,
	onFocus,
	disableDropdown,
	containerClass,
}) => {
	return (
		<PhoneInput
			country={country || 'in'}
			value={value}
			inputProps={inputProps}
			onBlur={onBlur}
			inputClass={value ? 'input-value' : ''}
			onFocus={onFocus}
			onChange={onChange}
			countryCodeEditable={false}
			disableDropdown={disableDropdown || inputProps?.disabled}
			containerClass={containerClass}
		/>
	);
};
export default PhoneInputComponent;
interface InputProps {
	name?: string;
	autoFocus?: boolean;
	required?: boolean;
	placeholder?: string;
	disabled?: boolean;
}

interface MyComponentProps {
	country?: string;
	value: string;
	inputProps?: InputProps;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onChange?: (value: string, data: CountryData | {}, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => void;
	onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
	disableDropdown?: boolean;
	containerClass?: string;
}
export interface CountryData {
	name: string;
	dialCode: string;
	countryCode: string;
	format: string;
}

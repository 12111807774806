import { toast, ToastOptions } from 'react-toastify';
import { ToastOptionsProps } from '../../types/type/ToastOptionsProps';

/**
 * Displays a toast notification with the specified message and options.
 *
 * This function leverages `react-toastify` to show different types of toast notifications,
 * such as success, error, warning, or info. You can customize the behavior of the toast
 * using the `options` parameter.
 *
 * @param {string} message - The message to display in the toast notification.
 * @param {ToastOptionsProps} [options] - Optional settings to customize the toast notification's behavior.
 * @param {string} [options.position] - The position of the toast on the screen.
 * @param {boolean} [options.hideProgressBar=false] - Whether to hide the progress bar.
 * @param {boolean} [options.closeOnClick=false] - Whether the toast should close when clicked.
 * @param {boolean} [options.pauseOnHover=false] - Whether the toast should pause when hovered.
 * @param {boolean} [options.draggable=false] - Whether the toast is draggable.
 * @param {'success' | 'error' | 'warn' | 'info'} [options.type='success'] - The type of toast to display.
 * @param {number} [options.autoClose=5000] - The duration (in milliseconds) before the toast automatically closes.
 */
export const showToast = (
	message: string,
	options: ToastOptionsProps = {
		position: 'top-right', // Default position if not provided
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		type: 'success',
	},
) => {
	const { type = 'success', autoClose = 2000, draggable, hideProgressBar, closeOnClick, pauseOnHover, position = 'top-right' } = options;

	const toastTypes = {
		success: toast.success,
		error: toast.error,
		warn: toast.warn,
		info: toast.info,
	};

	const showToast = toastTypes[type] || toastTypes.info;

	// Display the toast notification
	showToast(message, {
		autoClose,
		draggable,
		hideProgressBar,
		closeOnClick,
		pauseOnHover,
		position,
	} as ToastOptions); // Ensure options are cast correctly
};
